import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Pagination from '../../common/components/elements/Pagination';
import { PurchasedQuotationsStyles } from '../PurchasedQuotations/PurchasedQuotations.styles';
import { generalAlertError } from '../../common/utils/alerts';
import { listSuspensions, resetSuspensions } from '../../features/afterSale/afterSaleActions';
import ErrorComponent from '../../common/components/ErrorComponent';
import { after_sale_purchase_statues } from '../../common/constants';

function SuspensionsList() {
  const navigate = useNavigate();
  const state = useSelector((store) => store.afterSale.suspensions_page);

  const {
    loading, suspensions, total_pages, page_number, error,
  } = state;

  const [search, setSearch] = useState({
    search: '',
    date_from: '',
    date_to: '',
  });
    // const [steps, setSteps] = useState([]);

  // const {
  //   setState,
  //   state: { run, showBeacon },
  // } = useTourContext();

  // useEffect(() => {
  //   if (localStorage.getItem('suspensions') === 'done') {
  //     setState({ run: false });
  //   } else {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 1500);
  //   }
  // }, [localStorage.getItem('suspensions')]);

  // const fireNewQuoteTour = () => {
  //   localStorage.removeItem('suspensions');
  //   setState({ run: true, tourActive: true });
  // };

  // useEffect(() => {
  //   suspensionsSteps(suspensions, setSteps);
  // }, [suspensions]);

  useEffect(() => {
    (async () => {
      listSuspensions(1, search);
    })();

    return () => {
      resetSuspensions();
    };
  }, []);

  const handleChange = ({ target: { value, name } }) => {
    setSearch({ ...search, [name]: value });
  };

  const searchHandler = async (e) => {
    e.preventDefault();

    if (search.date_from !== '' && search.date_to === '') {
      generalAlertError('Devi selezionare anche data al');
      return;
    }

    if (search.date_from === '' && search.date_to !== '') {
      generalAlertError('Devi selezionare anche data da');
      return;
    }

    const trimmedSearch = {
      ...search,
      search: search.search.trim(),
    };

    listSuspensions(1, trimmedSearch);
  };

  return error === null || error === undefined ? (
    <PurchasedQuotationsStyles>
      {/* <Joyride
      steps={steps}
      continuous
      showBeacon={showBeacon}
      locale={{
        back: 'Indietro',
        close: 'Chiudi',
        last: 'Fine',
        next: 'Prossima',
        open: 'Apri il modale',
        restart: 'Riavvia il tour ',
        skip: 'Salta',
      }}
      styles={{
        options: joyrideOptions,
        buttonNext: {
          backgroundColor: '#00a651',
          outline: 'none',
        },
        buttonBack: {
          color: '#00a651',
          outline: 'none',
        },
        beaconInner: {
          backgroundColor: '#00a651',
        },
        beaconOuter: {
          borderColor: '#00a651',
          backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
        },
        buttonSkip: {
          outline: 'none',
        },
        buttonClose: {
          outline: 'none',
        },
      }}
      run={run}
      callback={(data) => handleCallbackCancellations(data, steps, setState)}
      showSkipButton
      showProgress
    /> */}
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <h5>Sospensione</h5>
        <div className="d-flex">
          <div className="btn btn-success text-white px-5 m-2" onClick={() => navigate('/after-sale/select')}>Vai a Post Vendita</div>
          {/* {localStorage.getItem('suspensions') === 'done' && (
          <div style={{ cursor: 'pointer' }} onClick={() => fireNewQuoteTour()}>
            <button className="btn btn-success my-2 mx-0 px-3">Tutorial</button>
          </div>
        )} */}
        </div>
      </div>
      <div className="d-flex flex-md-row flex-column-reverse">
        <div className="col-xl-8 col-md-7 col-lg-8">
          <div className="mt-3 suspensions-one">
            {loading
              ? (
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <div className="spinner-border" role="status" />
                  <span>Attendere prego...</span>
                </div>
              )
              : (
                <ul className="list-group">
                  {suspensions.length > 0
                    ? suspensions.map((suspension) => (
                      <li key={suspension.id} className="suspensions-two list-group-item d-flex flex-xl-row flex-column justify-content-between align-items-start align-items-xl-center">
                        <div className="me-auto mb-0">
                          <div className="fw-bold">
                            Cliente:
                            {' '}
                            {suspension.quotation.insurance_request.customer.gender !== 'G' ? `${suspension.quotation.insurance_request.customer.name} ${suspension.quotation.insurance_request.customer.surname}` : suspension.quotation.insurance_request.customer.business_name}
                          </div>
                          {suspension.quotation.insurance_request.product
                          && (
                            <>
                              Prodotto:
                              {' '}
                              {suspension.quotation.insurance_request.product.name}
                              <br />
                            </>
                          )}
                          {suspension.quotation.company
                          && (
                            <>
                              Compagnia:
                              {' '}
                              {suspension.quotation.company.name}
                              <br />
                            </>
                          )}
                          {suspension.quotation.insurance_request.vehicle
                          && (
                            <>
                              Targa:
                              {' '}
                              {suspension.quotation.insurance_request.vehicle.vehicle_plate}
                              <br />
                            </>
                          )}
                          {suspension.quotation.insurance_request.moto
                          && (
                            <>
                              Targa:
                              {' '}
                              {suspension.quotation.insurance_request.moto.moto_plate}
                              <br />
                            </>
                          )}

                          {/* <small>
                            Data:
                            {' '}
                            {new Date(suspension.date).toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                          </small>
                          <br /> */}

                          <small>
                            <strong>
                              Creato:
                              {new Date(suspension.created_at).toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                            </strong>
                          </small>
                        </div>

                        <div className="me-4 my-2">
                          <div className="badge bg-dark">{after_sale_purchase_statues.find((status) => status.id === suspension.status)?.name}</div>

                        </div>

                        {
                          suspension.status === 5 ? (
                            <Link to={`/suspensions/${suspension.id}`}>
                              <span className="badge btn-to-quote px-4 py-2 rounded-3">
                                Vai a sospensione
                              </span>
                            </Link>
                          ) : (
                            <Link to={`/after-sale/purchase/suspension/${suspension.id}`}>
                              <span className="badge btn-to-quote px-4 py-2 rounded-3">
                                Vai a sospensione
                              </span>
                            </Link>
                          )
                       }
                      </li>
                    ))
                    : (
                      <li className="list-group-item d-flex justify-content-between align-items-start suspensions-no-result">
                        <div className="ms-2 me-auto">
                          <div className="fw-bold">
                            Nessuno cessazione trovato!
                          </div>
                        </div>
                      </li>
                    )}
                </ul>
              )}
            <div className="mt-2">
              <Pagination total_pages={total_pages} current_page={page_number} onClick={listSuspensions} search={search} />
            </div>
          </div>

        </div>
      </div>
      <div className="col-lg-3 col-md-5 col-xl-3 p-3 quotation-filters suspensions-three">
        <form onSubmit={searchHandler} className="d-flex align-items-end justify-content-center flex-column">
          <input type="text" name="search" className="search-input form-control mb-2" value={search.search} onChange={handleChange} placeholder="Ricerca cliente..." />
          <div className="w-100">
            <label htmlFor="date_from">Data creazione da</label>
            <input type="date" name="date_from" onChange={handleChange} value={search.date_from} className="date-input form-control mb-2" placeholder="Data creazione da" />
          </div>
          <div className="w-100">
            <label htmlFor="date_to">Data creazione al</label>
            <input type="date" name="date_to" className="date-input form-control mb-0" onChange={handleChange} value={search.date_to} min={search.date_from} placeholder="Data creazione al" />
          </div>
          <button type="submit" className="search-btn mt-2">
            Cerca
          </button>
        </form>
      </div>
    </PurchasedQuotationsStyles>
  ) : <ErrorComponent error={error} />;
}

export default SuspensionsList;
