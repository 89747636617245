import axe from '../../services/axios-client/axiosClient';
import caller from './afterSalePurchaseSlice';

const serverError = 'Si è verificato un problema con il server';

export const getAfterSalePurchase = async (afterSaleId, afterSaleType) => {
  caller.afteSalePurchasePending();

  try {
    const response = await axe.get(
      `/after-sale/${afterSaleType}/purchase/${afterSaleId}`,
    );

    if (response.status !== 200) {
      caller.afteSalePurchaseRejected(serverError);
      return;
    }
    caller.afteSalePurchaseSuccess(response.data.data);
  } catch (error) {
    caller.afteSalePurchaseRejected(serverError);
  }
};

export const resetAfterSalePurchase = () => {
  caller.afterSalePurchaseResetState();
};
