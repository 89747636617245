import React from 'react';
import { PreventivassCardStyles } from './PreventivassCard.styles';
import { LeftCol, LogoPriceContainer, PriceContainer } from './AdriaticCard.styles';
import {
  LogoResultContainer, PriceResult, QuoteInformationResultContainer, QuoteInformationResultItem,
} from '../../../../screens/BackOffice/results/cards/PrimaCard.styles';
import { BACKEND_BASE_URL } from '../../../../config';
import { guideType, maxBasedBrokerId } from '../../../constants';

function PreventivassResultCardLinearAndDallbogg({ processingQuote, requestInfo }) {
  return (
    <PreventivassCardStyles className={processingQuote.is_aquista === 1 ? 'selected' : ''}>
      <LeftCol>
        <LogoPriceContainer>
          <LogoResultContainer>
            <img
              src={BACKEND_BASE_URL + processingQuote.company.logo}
              alt={`${processingQuote.company.name} Logo`}
            />
          </LogoResultContainer>
          <PriceContainer>
            <PriceResult>
              <h5>
                Prezzo annuo:
              </h5>
              <h2>
                &euro;
                {Number(processingQuote.amount).toLocaleString('it-IT', {
                  minimumFractionDigits: 2,
                })}
              </h2>
            </PriceResult>
          </PriceContainer>
        </LogoPriceContainer>
        {/* {quotation.manually_added_quotation_documents.length > 0 && (
        <CompanyInformationContainer>
          {quotation.manually_added_quotation_documents.map((doc) => (
            <CompanyInfoElements onClick={() => downloadDocument(doc.id)}>
              <img src={doc.type === 'preventive' ? preventiveIcon : preContractIcon} alt="" />
              <p>{doc.type === 'preventive' ? 'Preventivo' : 'Informazioni Precontrattuali'}</p>
            </CompanyInfoElements>
          ))}
        </CompanyInformationContainer>
        )} */}
        <QuoteInformationResultContainer>
          <QuoteInformationResultItem>
            <h6>
              Compagnia:
            </h6>
            <p>
              {processingQuote.company.name}
            </p>
          </QuoteInformationResultItem>
          <QuoteInformationResultItem>
            <h6>
              Emissione:
            </h6>
            <p>
              Tradizionale
            </p>
          </QuoteInformationResultItem>
          <QuoteInformationResultItem>
            <h6>
              Guida:
            </h6>
            <p>
              {guideType.find((v) => v.id === requestInfo.guide_type).name}
            </p>
          </QuoteInformationResultItem>
          <QuoteInformationResultItem>
            <h6>
              Frequenza pagamento:
            </h6>
            {/* <p>
              {quotation.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}
            </p> */}
          </QuoteInformationResultItem>
        </QuoteInformationResultContainer>
        <QuoteInformationResultContainer>
          <QuoteInformationResultItem>
            <h6>
              Risarcimento:
            </h6>
            <p>
              Rc con
              {' '}
              <b>Risarcimento</b>
              {' '}
              <br />
              {' '}
              <b>Diretto</b>
              {' '}
              e
              {' '}
              <b>RFS</b>
            </p>
          </QuoteInformationResultItem>
          <QuoteInformationResultItem>
            <h6>
              Massimale:
            </h6>
            <p>
              <b>
                {maxBasedBrokerId[requestInfo.broker_agent_id] ? maxBasedBrokerId[requestInfo.broker_agent_id][0] : "6'450'000"}
                &euro;
              </b>
              {' '}
              Danni a persone
              <br />
              <b>{maxBasedBrokerId[requestInfo.broker_agent_id] ? maxBasedBrokerId[requestInfo.broker_agent_id][0] : "1'050'000"}</b>
              {' '}
              Danni a cose
            </p>
          </QuoteInformationResultItem>
        </QuoteInformationResultContainer>
      </LeftCol>

    </PreventivassCardStyles>
  );
}

export default PreventivassResultCardLinearAndDallbogg;
