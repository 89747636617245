import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { AfterSaleCreateFormFlowStyles, AfterSaleCreateFormStyles } from '../../common/AfterSale/AfterSaleCreate.styles';
import { CenteredDivTitle } from '../../common/components/CenteredPageTitle.styles';
import { FormInputSelect } from '../../common/formInputs/FormInputSelect';
import { reasonForReplacement } from '../../common/constants';
import { FormInputTextarea } from '../../common/formInputs/FormInputTextarea';
import { addNewReplacement } from '../../features/afterSale/afterSaleActions';
import { FormInputText } from '../../common/formInputs/FormInputText';
import managementAxe from '../../services/axios-client/managementServisAxeClient';
import { generalAlertError } from '../../common/utils/alerts';
import PageLoading from '../../common/components/elements/PageLoading';

function CreateReplacement() {
  const navigate = useNavigate();
  const { quoteId } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);

  const lazyFunction = () => {
    if (product !== null) {
      if ([1, 3].includes(product)) {
        return yup
          .string()
          .required('Il campo è obbligatorio.')
          .matches(/^[A-Z]{2}[0-9]{3}[A-Z]{2} *$/, 'La targa non e coretta');
      }

      if ([2].includes(product)) {
        return yup
          .string()
          .required('Il campo è obbligatorio.')
          .matches(/^[A-Z]{2}[0-9]{5} *$/, 'La targa non e coretta');
      }
    }

    return yup.string().nullable();
  };

  const schema = yup.object({
    note: yup.string().required('Il campo è obbligatorio'),
    replacement_reason: yup.string().required('Il campo è obbligatorio'),
    plate: yup.lazy(lazyFunction),
  });

  const {
    register,
    formState: { errors, touchedFields },
    trigger,
    setValue,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      note: '',
      replacement_reason: '',
      plate: '',
    },
  });

  const formData = watch();

  const submitData = async (formData) => {
    const triggerValidation = await trigger([
      'note',
      'replacement_reason',
      'plate',
    ]);
    if (!triggerValidation) {
      return;
    }

    addNewReplacement(quoteId, formData, navigate);
  };

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }
    return true;
  };

  const getQuoteProduct = async () => {
    const response = await managementAxe.get(`/broker/after-sale/get-product-type/${quoteId}`);

    if (response.status !== 200) {
      generalAlertError('Qualcosa e andato storto! Riprova!');
      setLoading(false);
      navigate('/after-sale');
      return;
    }

    setProduct(response.data.quote.product_id);
    setLoading(false);
  };

  useEffect(() => {
    if (quoteId) {
      getQuoteProduct();
    }
  }, [quoteId]);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <AfterSaleCreateFormFlowStyles>
      <AfterSaleCreateFormStyles>
        <CenteredDivTitle className="d-flex align-items-center justify-content-center gap-3 mt-3">
          <div className="after-sale-title divider">
            <h2 className="text-center">Completa i dati Sostituzione</h2>
          </div>
        </CenteredDivTitle>

        <div className="form-container-after-sale">
          {[1, 2, 3].includes(product) && (
            <FormInputText
              classNameInput="text-uppercase"
              error={errors.plate?.message}
              label="Targa(Nuova) dell' auto"
              valid={isValid('plate')}
              onChange={(value) => setValue('plate', value.toUpperCase())}
            />
          )}
          <FormInputSelect
            placeholder="Motivo della sostituzione"
            label="Motivo della sostituzione"
            registration={register('replacement_reason')}
            error={errors.replacement_reason?.message}
            valid={isValid('replacement_reason')}
            options={reasonForReplacement}
          />
          <FormInputTextarea
            placeholder="Descrivi cosa hai bisogno di modificare"
            label="Note"
            registration={register('note')}
            rows="3"
            error={errors.note?.message}
          />

        </div>
        <div className="mt-3">
          <div className="d-flex justify-content-between">
            <button
              className="btn btn-questionnaire back backtoStepOne backtoStepTwo backtoStepThree"
              onClick={() => submitData(formData)}
            >
              Continua
            </button>
          </div>
        </div>
      </AfterSaleCreateFormStyles>
    </AfterSaleCreateFormFlowStyles>
  );
}

export default CreateReplacement;
