import React, { useEffect, useState } from 'react';
import moment from 'moment';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Layout } from './components/Layout/Layout';
import InputPrivacy from '../../common/components/inputs/InputPrivacy';
import { greeniaPrivacyPolicy, modifyRequestDataBackoffice } from '../../services/axios-client/axeCommons';
import { FormInputText } from '../../common/formInputs/FormInputText';
import { FormInputDate } from '../../common/formInputs/FormInputDate';
import getAllowedPolicyEffectDate from '../../common/helpers/getAllowedPolicyEffectDate';
import { FormInputAddressComplete } from '../../common/formInputs/FormInputAddressComplete';
import FormReactSelectInputBirthPlace from '../../common/formInputs/FormReactSelectInputBirthPlace';
import getMinAllowedBirthday from '../../common/helpers/getMinAllowedBirthday';
import { FormInputSelect } from '../../common/formInputs/FormInputSelect';
import { FormInputRadio } from '../../common/formInputs/FormInputRadio';
import { genders, getFiscalCode } from '../../common/constants';
import { CenteredDivTitle } from '../../common/components/CenteredPageTitle.styles';
import CenteredPageTitle from '../../common/components/CenteredPageTitle';
import SubProductCard from '../../common/components/SubProductCard';
import PageLoading from '../../common/components/elements/PageLoading';
import { generalAlertError, generalAlertErrorToast } from '../../common/utils/alerts';
import convertToItalianDate from '../../common/helpers/convertToItalianDate';
import { tutelaLegaleGetInitialData, tutelaLegaleSubmitAnswers } from '../../features/formsBackoffice/formsBackofficeActions';
import { resetTutelaLegaleForm } from '../../features/forms/formsActions';
import StorageService from '../../services/StorageService';

const Storage = new StorageService();

const schema = yup.object({
  product: yup.string().required(),
  sub_product: yup.string().required(),
  source: yup.string().required(),
  name: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender !== 'G'
      ? schema
        .required('Il campo è obbligatorio. Inserisci il nome!')
        .matches(
          /^[a-zA-Z ]+$/,
          'Non sono ammessi numeri o caratteri speciali!',
        )
      : schema)),
  surname: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender !== 'G'
      ? schema
        .required('Il campo è obbligatorio. Inserisci il cognome!')
        .matches(
          /^[a-zA-Z ]+$/,
          'Non sono ammessi numeri o caratteri speciali!',
        )
      : schema)),
  date_of_birth: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender !== 'G'
      ? schema.required(
        'Il campo è obbligatorio. Seleziona data di nascita!',
      )
        .test('valid-date', 'La data di nascita non è valida', (value) => {
          if (!value) return true;
          const date = moment(value, 'YYYY-MM-DD', true);
          const year = date.year();
          const currentYear = new Date().getFullYear();

          if (year > currentYear - 18 || year < 1925) {
            return false;
          }
          return date.isValid();
        })
      : schema)),
  commune_of_birth_code: yup
    .string()
    .nullable()
    .when(['gender', 'born_abroad'], ([gender, born_abroad], schema) => (gender !== 'G'
      ? !born_abroad
        ? schema.required(
          'Il campo è obbligatorio. Inserisci luogo di nascita del propietario!',
        )
        : schema
      : schema)),
  gender: yup.string().required('Il campo è obbligatorio. Seleziona genere!'),
  phone: yup
    .string()
    .required('Il campo è obbligatorio. Inserisci numero di telefono!')
    .matches(/^[3]{1}[0-9]{8,9}$/, 'Il numero non e corretto'),
  email: yup
    .string()
    .required('Il campo è obbligatorio. Inserisci Email!')
    .email('Deve essere un email valido! Rimouvi spazi vuoti!')
    .test(
      'brokerEmail',
      'Non puoi impostare la tua email',
      (value) => value !== Storage.getUser().email,
    )
    .test(
      'noSymbolsBeforeAt',
      'Non sono ammessi simboli prima del carattere @',
      (value) => /^[^@]*@/.test(value),
    ),
  residence_commune_code: yup
    .string()
    .required('Il campo è obbligatorio. Seleziona provincia di residenza!'),
  province_of_birth_code: yup
    .string()
    .nullable()
    .when(['gender', 'born_abroad'], ([gender, born_abroad], schema) => (gender !== 'G'
      ? !born_abroad
        ? schema.required(
          'Il campo è obbligatorio. Inserisci luogo di nascita del propietario!',
        )
        : schema
      : schema)),
  born_abroad: yup
    .boolean()
    .required('Il campo è obbligatorio. Seleziona se nato al estero!'),
  country_of_birth_code: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender !== 'G'
      ? schema.required(
        'Il campo è obbligatorio. Seleziona il stato estero!',
      )
      : schema)),
  address: yup
    .string()
    .required('Il campo è obbligatorio. Inserisci indirizzo!'),
  house_number: yup.string().required('Il campo è obbligatorio.').matches(/^[1-9]\d*$/, 'Il numero deve essere maggiore di 0.'),
  postal_code: yup
    .string()
    .required('Il campo è obbligatorio. Inserisci codice postale!')
    .matches(/^[0-9]{5}$/, 'Il codice postale non e coretto'),
  fiscal_code: yup.string().nullable(),
  residence_province_code: yup
    .string()
    .required('Il campo è obbligatorio. Seleziona provincia di residenza!'),
  existing_customer: yup.boolean(),
  active_customer_id: yup.number().nullable(),
  policy_effective_date: yup
    .date()
    .typeError('Il campo è obbligatorio. Seleziona data decorrenza!')
    .required('Il campo è obbligatorio. Seleziona data decorrenza!')
    .min(
      new Date(moment().subtract(1, 'day').format('YYYY-MM-DD')),
      'La data è nel passato',
    )
    .max(
      new Date(moment().add(30, 'day').format('YYYY-MM-DD')),
      'La data è nel futuro',
    ),
  business_name: yup
    .string()
    .nullable()
    .when('gender', {
      is: 'G',
      then: () => yup
        .string()
        .required(
          "Il campo è obbligatorio. Inserisci il nome dell'azienda!",
        ),
    }),
  vat_number: yup
    .string()
    .nullable()
    .when('gender', {
      is: 'G',
      then: () => yup
        .string()
        .matches(/^[0-9]{11}$/, 'Il codice fiscale non è valido')
        .required('Il campo è obbligatorio. Inserisci la tua partita iva!'),
    }),
  company_type: yup
    .string()
    .nullable()
    .when('gender', {
      is: 'G',
      then: () => yup.string().required('Il campo è obbligatorio.'),
    }),
  privacies: yup.array(yup.number()),
});

function BackOfficeTutelaLegale() {
  const navigate = useNavigate();
  const { requestToken } = useParams();

  const state = useSelector((store) => store.forms.tutela_legale);
  const {
    loading,
    states,
    municipalities,
    formData,
    dataPrivacy,
  } = state;

  const [selectedSubProduct, setSelectedSubProduct] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    setValue,
    trigger,
    // getValues,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: {
      product: 'tutela_legale',
      source: 'greenia.it',
      sub_product: '',
      name: '',
      surname: '',
      date_of_birth: '',
      commune_of_birth_code: null,
      gender: '',
      phone: Storage.getUser().phone ? Storage.getUser().phone : '',
      email: '',
      residence_commune_code: '',
      province_of_birth_code: null,
      born_abroad: false,
      country_of_birth_code: 'Z000',
      address: '',
      house_number: '',
      postal_code: '',
      fiscal_code: '',
      residence_province_code: '',
      existing_customer: false,
      active_customer_id: null,
      policy_effective_date: '',

      business_name: '',
      vat_number: '',
      company_type: null,
      privacies: [],
    },
  });

  const userData = watch();

  const updateUserData = (data) => {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      setValue(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    });
    trigger(keys);
  };

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  const validateDataPrivacy = () => {
    let { error, message } = '';

    dataPrivacy.forEach((privacy) => {
      if (privacy.required === 1) {
        const userDataArray = { ...userData }; // make a separate copy of the object
        const index = userDataArray.privacies.indexOf(privacy.id);

        if (index < 0) {
          error = true;
          message = 'Tutti i campi sono obbligatori! Tutta la privacy con la stella deve essere controllata';
        }
      }
    });
    return { error, message };
  };

  const submitForm = (userData) => {
    const { error, message } = validateDataPrivacy();

    if (error) {
      generalAlertError(message);
      return;
    }

    const _userData = { ...userData };
    if (userData.date_of_birth !== '' && userData.date_of_birth !== null) {
      _userData.date_of_birth = convertToItalianDate(userData.date_of_birth);
    } else {
      _userData.date_of_birth = null;
    }
    _userData.policy_effective_date = convertToItalianDate(
      moment(userData.policy_effective_date).format('YYYY-MM-DD'),
    );
    _userData.fiscal_code = userData.gender !== 'G' ? getFiscalCode(userData.date_of_birth, userData, municipalities, states) : null;

    if (_userData.born_abroad && (_userData.country_of_birth_code === null || _userData.country_of_birth_code === '' || _userData.country_of_birth_code == undefined)) {
      window.scrollTo(0, 0);
      generalAlertErrorToast('Devi selezionare uno stato estero!');
      return;
    }
    tutelaLegaleSubmitAnswers(_userData, navigate, requestToken);
  };

  const privacyItemChangeHandler = (value, item) => {
    if (!value.target.checked) {
      const newArray = [...userData.privacies];
      const index = newArray.indexOf(item.id);
      newArray.splice(index, 1);
      updateUserData({
        privacies: newArray,
      });
      return;
    }
    const answersNewArray = [...userData.privacies];
    answersNewArray.push(item.id);
    updateUserData({
      privacies: answersNewArray,
    });
  };

  useEffect(() => {
    tutelaLegaleGetInitialData();

    return () => {
      resetTutelaLegaleForm();
    };
  }, []);

  useEffect(() => {
    const callAsync = async () => {
      const {
        data: {
          data: { userData },
        },
      } = await modifyRequestDataBackoffice(requestToken);

      if (userData.sub_product) {
        setSelectedSubProduct(userData.sub_product);
      }
      updateUserData(userData);
    };

    callAsync();
  }, []);

  useEffect(() => {
    if (userData.gender === 'G') {
      updateUserData({
        born_abroad: false,
        fiscal_code: null,
        name: null,
        surname: null,
        date_of_birth: null,
        commune_of_birth_code: null,
        country_of_birth_code: null,
        province_of_birth_code: null,
      });
    } else {
      updateUserData({
        business_name: '',
        vat_number: '',
        company_type: null,
      });
    }
  }, [userData.gender]);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <Layout>
      {selectedSubProduct === null && (
      <>
        <div className="quote-group">
          <div className="quote-h1 text-center">
            <h1>NUOVO PREVENTIVO</h1>
          </div>
          <div className="quote-h6">
            <h6>Cosa vuoi proteggere?</h6>
          </div>
        </div>
        <div className="container text-center select-product-one">
          <div className="row justify-content-center g-2 g-md-3 ">
            <SubProductCard
              product="Tutela Legale"
              sub_product="Circolazione"
              onSelect={() => {
                setSelectedSubProduct('circolazione');
                setValue('sub_product', 'circolazione');
              }}
            />
          </div>
        </div>
      </>
      )}
      {selectedSubProduct && (
      <form onSubmit={handleSubmit(submitForm)}>
        <CenteredDivTitle className="d-flex align-items-center justify-content-center gap-3">
          <CenteredPageTitle title="Completa i dati" />
        </CenteredDivTitle>
        <div className="tutela-legale-tutorial">

          <div className="form-container">
            <FormInputRadio
              options={genders}
              label="Il contraente è"
              name="gender"
              registration={register('gender')}
              error={errors.gender?.message}
            />

            {userData.gender === 'G' ? (
              <div>
                <FormInputText
                  placeholder="Ragione Sociale"
                  label="Ragione Sociale"
                  registration={register('business_name')}
                  error={errors.business_name?.message}
                  valid={isValid('business_name')}
                />
                <FormInputText
                  placeholder="Partita Iva"
                  label="Partita Iva"
                  registration={register('vat_number')}
                  error={errors.vat_number?.message}
                  valid={isValid('vat_number')}
                />
                <FormInputSelect
                  label="Tipologia azienda"
                  registration={register('company_type')}
                  error={errors.company_type?.message}
                  valid={isValid('company_type')}
                  placeholder="-Seleziona-"
                  options={formData.company_types}
                />
              </div>
            ) : (
              <div>
                <FormInputText
                  placeholder="Nome"
                  label="Nome"
                  registration={register('name')}
                  error={errors.name?.message}
                  valid={isValid('name')}
                />
                <FormInputText
                  placeholder="Cognome"
                  label="Cognome"
                  registration={register('surname')}
                  error={errors.surname?.message}
                  valid={isValid('surname')}
                />
                <FormInputDate
                  label="Data di nascita"
                  registration={register('date_of_birth')}
                  minDate={getMinAllowedBirthday()}
                  maxDate={moment().subtract(18, 'years').format('YYYY-MM-DD')}
                  error={errors.date_of_birth?.message}
                  valid={isValid('date_of_birth')}
                />
                <FormReactSelectInputBirthPlace
                  states={states}
                  options={municipalities}
                  label="Luogo di nascita"
                  name="commune_of_birth_code"
                  values={{
                    commune_of_birth_code: userData.commune_of_birth_code,
                    province_of_birth_code: userData.province_of_birth_code,
                    born_abroad: userData.born_abroad,
                    country_of_birth_code: userData.country_of_birth_code,
                  }}
                  onChange={(value) => updateUserData({
                    commune_of_birth_code: value.commune_of_birth_code,
                    province_of_birth_code: value.province_of_birth_code,
                    born_abroad: value.born_abroad,
                    country_of_birth_code: value.country_of_birth_code,
                  })}
                  valid={isValid('commune_of_birth_code')}
                  error={errors.commune_of_birth_code?.message}
                  valid_country_of_birth_code={isValid('country_of_birth_code')}
                  error_country_of_birth_code={errors.country_of_birth_code?.message}
                />
              </div>
            )}

            <FormInputAddressComplete
              municipalities={municipalities}
              formData={userData}
              isValid={isValid}
              errors={errors}
              register={register}
              updateFormData={updateUserData}
              addressId="autocomplete-address-contractor"
            />

            <FormInputDate
              minDate={getAllowedPolicyEffectDate('min')}
              maxDate={getAllowedPolicyEffectDate('max')}
              registration={register('policy_effective_date')}
              label="Data di inizio della copertura della polizza"
              paragraph="Date valide: da oggi a 30 giorni da oggi"
              error={errors.policy_effective_date?.message}
              valid={isValid('policy_effective_date')}
            />

            <FormInputText
              registration={register('email')}
              label="E-mail"
              error={errors.email?.message}
              valid={isValid('email')}
            />

            <FormInputText
              registration={register('phone')}
              label="Phone"
              paragraph="Numeri di rete fissa non accettati dalle aziende"
              error={errors.phone?.message}
              valid={isValid('phone')}
            />
            <div>
              <h5 className="text-center mt-2">Informativa Privacy e IVASS</h5>
              <div className="text-center text-decoration-underline mt-2 mb-2">
                <a onClick={() => greeniaPrivacyPolicy()} style={{ cursor: 'pointer' }}>
                  <small className="text-center">Greenia Privacy Policy</small>
                </a>
              </div>
              {dataPrivacy.map((privacy, i) => (
                <InputPrivacy
                  required={privacy.required}
                  label={privacy.content}
                  name={privacy.type}
                  id={privacy.type}
                  key={i}
                  checked={userData?.privacies?.includes(privacy.id)}
                  onChange={(value) => privacyItemChangeHandler(value, privacy)}
                />
              ))}
            </div>
          </div>

        </div>

        <div className="footer-buttons button-profession-step">
          <div className="d-flex justify-content-between">
            <button type="submit" className="btn btn-questionnaire">
              Vai a preventivo
            </button>
          </div>
        </div>
      </form>
      )}
    </Layout>
  );
}

export default BackOfficeTutelaLegale;
