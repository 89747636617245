import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import {
  SaluteFormFlowStyles,
  SaluteFormStyles,
  SaluteQuestionsStyles,
  ToolTipHeader,
  TooltipStyles,
  TooltipText,
  TooltipTextBold,
} from './styles/SaluteFormStyles.styles';
import { answersSaluteFieldsBySteps, saluteSchema } from './SaluteFormFlow.validations';
import {
  greeniaPrivacyPolicy,
  modifyRequestData,
  updateActiveCustomer,
} from '../../services/axios-client/axeCommons';
import {
  resetSaluteForm,
  saluteGetCustomerData,
  saluteGetInitialData,
  saluteSearchActiveClient,
  saluteSubmitAnswers,
  saluteUpdateFormData,
} from '../../features/forms/formsActions';
import { FormInputCheckbox } from '../../common/formInputs/FormInputCheckbox';
import convertToItalianDate from '../../common/helpers/convertToItalianDate';
import { useDebouncedEffect } from '../../common/utils/useDebouncedEffect';
import generateFiscalCode from '../../common/helpers/generateFiscalCode';
import {
  generalAlertError,
  generalAlertErrorToast,
} from '../../common/utils/alerts';
import QuoteGenerateLoading from '../../common/components/elements/QuoteGenerateLoading';
import { CenteredDivTitle } from '../../common/components/CenteredPageTitle.styles';
import CenteredPageTitle from '../../common/components/CenteredPageTitle';
import { FormInputText } from '../../common/formInputs/FormInputText';
import ActiveClientModal from '../../common/components/elements/ActiveClientModal';
import { flagResponse, genders, workType } from '../../common/constants';
import { FormInputRadio } from '../../common/formInputs/FormInputRadio';
import { FormInputSelect } from '../../common/formInputs/FormInputSelect';
import { FormInputDate } from '../../common/formInputs/FormInputDate';
import FormReactSelectInputBirthPlace from '../../common/formInputs/FormReactSelectInputBirthPlace';
import { FormInputAddressComplete } from '../../common/formInputs/FormInputAddressComplete';
import InputPrivacy from '../../common/components/inputs/InputPrivacy';
import { FormInputAddressCompleteManual } from '../../common/formInputs/FormInputAddressCompleteManual';
import getMinAllowedBirthday from '../../common/helpers/getMinAllowedBirthday';

function SaluteFormFlow() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchClient, setSearchClient] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const skipCheckGenderRef = useRef(null);
  const state = useSelector((store) => store.forms.salute);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [showManualAdress, setShowManualAdress] = useState(false);
  const {
    loading,
    states,
    municipalities,
    formData,
    dataPrivacy,
    searchResults,
    selectedClientOldData,
    selectedClient,
    selectedClientId,
  } = state;
  const {
    handleSubmit,
    register,
    formState: { errors, touchedFields },
    setValue,
    trigger,
    getValues,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(saluteSchema),
    shouldFocusError: true,
    defaultValues: {
      product: 'salute',
      source: 'greenia.it',
      policy_effective_date: '',
      privacies: [],
      name: '',
      surname: '',
      email: '',
      fiscal_code: '',
      address: '',
      house_number: '',
      postal_code: '',
      phone: '',
      gender: '',
      date_of_birth: '',
      country_of_birth_code: 'Z000',
      province_of_birth_code: null,
      commune_of_birth_code: null,
      born_abroad: false,
      residence_commune_code: '',
      residence_province_code: '',
      active_customer_id: null,
      existing_customer: false,
      work_type: '',
      suffer_from_diseases: '',
      cronic_diseases: '',
      permanent_consequences: '',
      presence_required: '',
    },
  });

  const formDataFlow = watch();

  const updateFormData = (data) => {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      setValue(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    });
    trigger(keys);
  };

  const onSearchChange = (value) => {
    setSearchClient(value);

    if (value.length === 0) {
      saluteUpdateFormData({
        searchResults: [],
      });
    }
  };

  const confirmActiveClient = async (selectedClient) => {
    if (
      JSON.stringify(selectedClientOldData) !== JSON.stringify(selectedClient)
    ) {
      await updateActiveCustomer(selectedClientId, {
        ...selectedClient,
        date_of_birth:
          selectedClient.gender !== 'G'
            ? convertToItalianDate(selectedClient.date_of_birth)
            : null,
      });
    }

    updateFormData({
      born_abroad:
        selectedClient.born_abroad !== null
          ? selectedClient.born_abroad
          : false,
      fiscal_code: selectedClient.fiscal_code,
      gender: selectedClient.gender,
      house_number: selectedClient.house_number,
      name: selectedClient.name,
      phone: selectedClient.phone,
      postal_code: selectedClient.postal_code,
      surname: selectedClient.surname,
      address: selectedClient.address,
      date_of_birth: selectedClient.date_of_birth,
      email: selectedClient.email,
      province_of_birth_code: selectedClient.province_of_birth_code,
      residence_commune_code: selectedClient.residence_city_code, // residence_city_code
      residence_province_code: selectedClient.residence_province_code,
      country_of_birth_code: selectedClient.country_of_birth_code,
      commune_of_birth_code: selectedClient.city_of_birth_code, // city_of_birth_code,
      active_customer_id: selectedClientId,
    });

    setSearchClient('');
    saluteUpdateFormData({
      selectedClient: null,
      selectedClientOldData: null,
      selectedClientId: null,
      searchResults: [],
    });
  };

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  const privacyItemChangeHandler = (value, item) => {
    if (!value.target.checked) {
      const newArray = [...formDataFlow.privacies];
      const index = newArray.indexOf(item.id);
      newArray.splice(index, 1);
      updateFormData({
        privacies: newArray,
      });
      return;
    }
    const answersNewArray = [...formDataFlow.privacies];
    answersNewArray.push(item.id);
    updateFormData({
      privacies: answersNewArray,
    });
  };

  const closeClientModal = () => {
    setSearchClient('');
    saluteUpdateFormData({
      selectedClient: null,
      selectedClientOldData: null,
      selectedClientId: null,
      searchResults: [],
    });
  };

  const getCustomerData = async (customerId) => {
    saluteGetCustomerData(customerId);
  };

  const searchActiveClient = (searchClient) => () => {
    if (searchClient.length === 0) return;
    saluteSearchActiveClient(searchClient);
  };

  useDebouncedEffect(searchActiveClient(searchClient), [searchClient], 400);

  useEffect(() => {
    saluteGetInitialData();

    return () => {
      resetSaluteForm();
    };
  }, []);

  useEffect(() => {
    if (
      location.state !== null
      && location.state.activeCustomerId !== undefined
    ) {
      getCustomerData(location.state.activeCustomerId);
    }
  }, []);

  const getFiscalCode = (birthDate) => {
    const d = birthDate.split('-');
    const fData = {
      name: formDataFlow.name,
      surname: formDataFlow.surname,
      gender: formDataFlow.gender,
      day: d[2],
      month: d[1],
      year: d[0],
    };
    if (formDataFlow.born_abroad) {
      fData.birthplace = states.filter(
        (s) => s.state_code === formDataFlow.country_of_birth_code,
      )[0].name;
      fData.birthplaceProvincia = 'EE';
    } else {
      fData.birthplace = municipalities.filter(
        (municipality) => municipality.cadastral_code === formDataFlow.commune_of_birth_code,
      )[0].name;
      fData.birthplaceProvincia = formDataFlow.province_of_birth_code;
    }
    return generateFiscalCode(fData);
  };

  const validateDataPrivacy = () => {
    let { error, message } = '';

    dataPrivacy.forEach((privacy) => {
      if (privacy.required === 1) {
        const userDataArray = { ...formDataFlow }; // make a separate copy of the object
        const index = userDataArray.privacies.indexOf(privacy.id);

        if (index < 0) {
          error = true;
          message = 'Tutti i campi sono obbligatori! Tutta la privacy con la stella deve essere controllata';
        }
      }
    });
    return { error, message };
  };

  const submitForm = async (formDataFlow) => {
    const _userData = { ...formDataFlow };

    if (_userData.born_abroad && _userData.country_of_birth_code === 'Z000') {
      window.scrollTo(0, 0);
      generalAlertErrorToast('Non puoi selezionare Italia come stato estero!');
      return;
    }

    if (
      _userData.born_abroad
      && (_userData.country_of_birth_code === null
        || _userData.country_of_birth_code === ''
        || _userData.country_of_birth_code == undefined)
    ) {
      window.scrollTo(0, 0);
      generalAlertErrorToast('Devi selezionare uno stato estero!');
      return;
    }

    if (
      formDataFlow.date_of_birth !== ''
      && formDataFlow.date_of_birth !== null
    ) {
      _userData.date_of_birth = moment(formDataFlow.date_of_birth).format(
        'YYYY-MM-DD',
      );
    } else {
      _userData.date_of_birth = null;
    }
    _userData.policy_effective_date = moment(
      formDataFlow.policy_effective_date,
    ).format('YYYY-MM-DD');
    _userData.fiscal_code = formDataFlow.gender !== 'G'
      ? getFiscalCode(formDataFlow.date_of_birth)
      : null;
    delete _userData.existing_customer;

    const validateQuestions = await trigger(answersSaluteFieldsBySteps[currentIndex - 1], {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    if (validateQuestions !== true) {
      return;
    }

    if (
      formDataFlow.suffer_from_diseases == 0
      || formDataFlow.cronic_diseases == 0
      || formDataFlow.permanent_consequences == 0
    ) {
      generalAlertError('Il prodotto non è adeguato alle esigenze del cliente');
      return;
    }
    saluteSubmitAnswers(_userData, navigate);
  };

  const nextButton = async () => {
    const res = await trigger(answersSaluteFieldsBySteps[currentIndex - 1], {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    if (res !== true) {
      return;
    }

    if (currentIndex === 1) {
      if (formDataFlow.born_abroad && formDataFlow.country_of_birth_code === 'Z000') {
        window.scrollTo(0, 0);
        generalAlertErrorToast('Non puoi selezionare Italia come stato estero!');
        return;
      }

      if (
        formDataFlow.born_abroad
          && (formDataFlow.country_of_birth_code === null
            || formDataFlow.country_of_birth_code === ''
            || formDataFlow.country_of_birth_code == undefined)
      ) {
        window.scrollTo(0, 0);
        generalAlertErrorToast('Devi selezionare uno stato estero!');
        return;
      }

      const validation = validateDataPrivacy();
      if (validation.error) {
        generalAlertError(validation.message);
        return;
      }
    }

    if (currentIndex === 2) {
      submitForm();
      return;
    }

    setCurrentIndex(currentIndex + 1);
    setTimeout(() => window.scrollTo(0, 0), 500);
  };

  useEffect(() => {
    if (skipCheckGenderRef.current === true) {
      skipCheckGenderRef.current = false;
      return;
    }

    const triggerValidation = {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    };

    if (
      ['M', 'F'].includes(formDataFlow.gender)
      && formDataFlow.date_of_birth === null
    ) {
      setValue('date_of_birth', '', triggerValidation);
      setValue('country_of_birth_code', 'Z000', triggerValidation);
      setValue('province_of_birth_code', null, triggerValidation);
      setValue('commune_of_birth_code', null, triggerValidation);
      setValue('born_abroad', false, triggerValidation);
      setValue('name', '', triggerValidation);
      setValue('surname', '', triggerValidation);
    }
  }, [formDataFlow.gender, formDataFlow.date_of_birth]);

  useEffect(() => {
    const values = getValues();

    if (values !== undefined) {
      const objectKeys = Object.keys(values);

      objectKeys.forEach((key) => {
        if (values[key] !== '' && values[key] !== null) {
          setValue(key, values[key], {
            shouldTouch: true,
            shouldDirty: true,
            shouldValidate: true,
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    const callAsync = async () => {
      if (
        location.state !== null
        && location.state.requestToken !== undefined
      ) {
        skipCheckGenderRef.current = true;
        const {
          data: {
            data: { userData },
          },
        } = await modifyRequestData(location.state.requestToken);
        updateFormData(userData);
      }
    };

    callAsync();
  }, [location]);

  if (loading) {
    return <QuoteGenerateLoading />;
  }

  return (
    <SaluteFormFlowStyles>
      {currentIndex === 1 && (
        <SaluteFormStyles
          onSubmit={handleSubmit(submitForm)}
        >
          <CenteredDivTitle className="d-flex align-items-center justify-content-center gap-3">
            <CenteredPageTitle title="Completa i dati" />
          </CenteredDivTitle>
          <div className="salute-tutorial">
            {formDataFlow.gender !== 'G' ? (
              <div>
                <FormInputCheckbox
                  registration={register('existing_customer')}
                  label=" Cliente Esistente?"
                />
                {formDataFlow.existing_customer && (
                  <div className="d-flex align-items-start justify-content-center gap-2">
                    <div className="position-relative">
                      <FormInputText
                        placeholder="Nome cliente..."
                        onChange={(value) => onSearchChange(value)}
                        value={searchClient}
                      />
                      {searchResults.length > 0 && (
                        <div
                          className="position-absolute"
                          style={{
                            top: '80%',
                            left: '0%',
                            right: '0%',
                            zIndex: 50,
                          }}
                        >
                          <ul className="list-group">
                            {searchResults.map((customer, index) => (
                              <li
                                className="list-group-item list-group-item-dark"
                                key={index}
                                style={{ cursor: 'pointer' }}
                                onClick={() => getCustomerData(customer.id)}
                              >
                                {customer.name.toUpperCase()
                                  + (customer.fiscal_code !== null
                                    ? ` - (${customer.fiscal_code})`
                                    : '')}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                    <button
                      className="btn btn-questionnaire py-1 px-2 "
                      style={{
                        marginTop: 10,
                        display: 'block',
                        width: '150px',
                        border: '3px solid',
                      }}
                    >
                      Cerca cliente
                    </button>
                  </div>
                )}

                {selectedClient !== null ? (
                  <ActiveClientModal
                    closeClientModal={closeClientModal}
                    municipalities={municipalities}
                    states={states}
                    formData={formData}
                    client={selectedClient}
                    genders={genders}
                    confirmActiveClient={confirmActiveClient}
                  />
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}

            <div className="form-container">
              <FormInputRadio
                options={genders.filter((g) => g.value !== 'G')}
                label="Il contraente è"
                name="gender"
                registration={register('gender')}
                error={errors.gender?.message}
              />

              <div>
                <div>
                  <FormInputText
                    placeholder="Nome"
                    label="Nome"
                    registration={register('name')}
                    error={errors.name?.message}
                    valid={isValid('name')}
                  />
                  <FormInputText
                    placeholder="Cognome"
                    label="Cognome"
                    registration={register('surname')}
                    error={errors.surname?.message}
                    valid={isValid('surname')}
                  />
                  <FormInputDate
                    label="Data di nascita"
                    registration={register('date_of_birth')}
                    minDate={getMinAllowedBirthday()}
                    maxDate="2021-01-01"
                    error={errors.date_of_birth?.message}
                    valid={isValid('date_of_birth')}
                  />
                  <FormReactSelectInputBirthPlace
                    states={states}
                    options={municipalities}
                    label="Luogo di nascita"
                    name="commune_of_birth_code"
                    values={{
                      commune_of_birth_code: formDataFlow.commune_of_birth_code,
                      province_of_birth_code:
                        formDataFlow.province_of_birth_code,
                      born_abroad: formDataFlow.born_abroad,
                      country_of_birth_code: formDataFlow.country_of_birth_code,
                    }}
                    onChange={(value) => updateFormData({
                      commune_of_birth_code: value.commune_of_birth_code,
                      province_of_birth_code: value.province_of_birth_code,
                      born_abroad: value.born_abroad,
                      country_of_birth_code: value.country_of_birth_code,
                    })}
                    valid={isValid('commune_of_birth_code')}
                    error={errors.commune_of_birth_code?.message}
                    valid_country_of_birth_code={isValid(
                      'country_of_birth_code',
                    )}
                    error_country_of_birth_code={
                      errors.country_of_birth_code?.message
                    }
                  />
                </div>
              </div>
              <FormInputText
                registration={register('email')}
                label="E-mail"
                error={errors.email?.message}
                valid={isValid('email')}
              />
              <FormInputText
                registration={register('phone')}
                label="Phone"
                paragraph="Numeri di rete fissa non accettati dalle aziende"
                error={errors.phone?.message}
                valid={isValid('phone')}
              />

              {showManualAdress ? (
                <FormInputAddressCompleteManual
                  municipalities={municipalities}
                  formData={formDataFlow}
                  isValid={isValid}
                  errors={errors}
                  register={register}
                  updateFormData={updateFormData}
                />
              ) : (
                <FormInputAddressComplete
                  municipalities={municipalities}
                  formData={formDataFlow}
                  isValid={isValid}
                  errors={errors}
                  register={register}
                  updateFormData={updateFormData}
                  addressId="autocomplete-address-contractor"
                  setShow={setShowManualAdress}
                />
              )}

              <FormInputSelect
                label="Tipo di lavoro"
                registration={register('work_type')}
                error={errors.work_type?.message}
                valid={isValid('work_type')}
                placeholder="-Seleziona-"
                options={workType}
              />

              <FormInputDate
                label="Data Decorrenza"
                registration={register('policy_effective_date')}
                minDate={moment().format('YYYY-MM-DD')}
                error={errors.policy_effective_date?.message}
                valid={isValid('policy_effective_date')}
              />
            </div>
          </div>
          <div>
            <h5 className="text-center">Informativa Privacy e IVASS</h5>

            <div className="text-center text-decoration-underline mt-2">
              <a
                onClick={() => greeniaPrivacyPolicy()}
                style={{ cursor: 'pointer' }}
              >
                <small className="text-center">Greenia Privacy Policy</small>
              </a>
            </div>
            {dataPrivacy.map((privacy, i) => (
              <InputPrivacy
                required={privacy.required}
                label={privacy.content}
                name={privacy.type}
                id={privacy.type}
                key={i}
                checked={formDataFlow?.privacies?.includes(privacy.id)}
                onChange={(value) => privacyItemChangeHandler(value, privacy)}
              />
            ))}
          </div>

        </SaluteFormStyles>
      )}

      {currentIndex === 2 && (
        <SaluteQuestionsStyles
          className=" mx-auto"

        >
          <CenteredDivTitle className="d-flex align-items-center justify-content-center  mb-5">
            <CenteredPageTitle title="Compila il Questonario" />
          </CenteredDivTitle>

          <div>
            <div>
              <div className="d-flex justify-content-start align-items-start gap-2 position-relative">
                <div>
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    onClick={() => setShowTooltip(!showTooltip)}
                    className="text-success cursor-pointer"
                    size="lg"
                  />
                </div>
                <p className="text-center">
                  Non ho sofferto, non soffro attualmente, e non mi sono
                  affidato a cure di un medico negli ultimi 5 anni per una delle
                  malattie elencate nel punto interrogativo
                </p>
                {showTooltip && (
                  <TooltipStyles className="position-absolute border start-0 mt-5 p-3">
                    <ToolTipHeader className="mb-3">
                      ELENCO MALATTIE
                    </ToolTipHeader>

                    <TooltipTextBold>
                      MALATTIE DELL'APPARATO RESPIRATORIO:
                    </TooltipTextBold>
                    <TooltipText>
                      Asma, Enfisema, BPCO, Gravi infezioni broncopolmonari,
                      Bronchiectasie, Pleurite, Pneumotorace, Tbc, Ipertensione
                      polmonare, Polpi delle corde vocali;
                    </TooltipText>

                    <TooltipTextBold>
                      MALATTIE DELL'APPARATO CARDIOCIRCOLATORIO:
                    </TooltipTextBold>
                    <TooltipText>
                      Infarto, Angina pectoris, Pericardite, Aneurisma
                      dell?aorta, Ipertensione arteriosa (nonostante
                      l'assunzione di terapia medicamentosa), Valvulopatie,
                      Arteriopatie, Varici arti inferiori, Tromboflebiti;
                    </TooltipText>

                    <TooltipTextBold>
                      MALATTIE DELL'APPARATO DIGERENTE:
                    </TooltipTextBold>
                    <TooltipText>
                      Ulcera gastroduodenale, Colite ulcerosa, Morbo di Crohn,
                      Diverticolosi del colon, Epatite virale, Pancreatite
                      acuta, Cirrosi, Calcolosi biliare;
                    </TooltipText>

                    <TooltipTextBold>
                      MALATTIE DELL?APPARATO UROGENITALE:
                    </TooltipTextBold>
                    <TooltipText>
                      Nefriti, Insufficienza renale, Ipertrofia della prostata,
                      Cisti ovariche, Fibroma uterino;
                    </TooltipText>

                    <TooltipTextBold>
                      MALATTIE DELL?APPARATO OSTEOARTICOLARE:
                    </TooltipTextBold>
                    <TooltipText>
                      Artrite, Artrosi, Osteoporosi fratturativa, Ernia del
                      disco;
                    </TooltipText>

                    <TooltipTextBold>
                      MALATTIE DEL SISTEMA NERVOSO:
                    </TooltipTextBold>
                    <TooltipText>
                      Aneurisma encefalico, Meningioma, Morbo di Parkinson,
                      Epilessia, Sclerosi multipla, Demenze;
                    </TooltipText>

                    <TooltipTextBold>
                      MALATTIE ENDOCRINO METABOLICHE:
                    </TooltipTextBold>
                    <TooltipText>
                      Diabete mellito, Malattie della tiroide, Malattie delle
                      ghiandole surrenali;
                    </TooltipText>

                    <TooltipTextBold>MALATTIE DEL SANGUE:</TooltipTextBold>
                    <TooltipText>Anemia, Leucemia, Linfoma;</TooltipText>

                    <TooltipTextBold>MALATTIE DEL CONNETTIVO:</TooltipTextBold>
                    <TooltipText>
                      Artrite reumatoide, Connettivite mista, Lupus eritematoso
                      sistemico, Sclerodermia, Poliarterite nodosa;
                    </TooltipText>

                    <TooltipTextBold> MALATTIE DELL?OCCHIO:</TooltipTextBold>
                    <TooltipText>Glaucoma, Distacco retina;</TooltipText>
                  </TooltipStyles>
                )}
              </div>
              <FormInputRadio
                name="suffer_from_diseases"
                registration={register('suffer_from_diseases')}
                error={errors.suffer_from_diseases?.message}
                options={flagResponse}
              />
            </div>
            <div>
              <p className="text-center">
                Non sono affetto da malattie croniche/malformazioni fisiche che
                richiedano specifiche terapie continuative e/o accertamenti
                periodici e non ho subito esami strumentali con esiti positivi
                negli ultimi 5 anni (EGC, RMN, TAC o esami di alta diagnostica)
              </p>
              <FormInputRadio
                name="cronic_diseases"
                registration={register('cronic_diseases')}
                error={errors.cronic_diseases?.message}
                options={flagResponse}
              />
            </div>
            <div>
              <p className="text-center">
                Non ho subito negli ultimi 5 anni traumi (cranico, toracico o
                lombare), lesioni interne da trauma interventi chirurgici per
                malattie che hanno comportato conseguenze permanenti a distanza
              </p>
              <FormInputRadio
                name="permanent_consequences"
                registration={register('permanent_consequences')}
                error={errors.permanent_consequences?.message}
                options={flagResponse}
              />
            </div>
            <div>
              <p className="text-center">
                Dipendente con obbligo di segnalazione presenza
              </p>
              <FormInputRadio
                name="presence_required"
                registration={register('presence_required')}
                error={errors.presence_required?.message}
                options={flagResponse}
              />
            </div>
          </div>

        </SaluteQuestionsStyles>
      )}
      <div className="footer-buttons button-travel-step">
        <div className="d-flex justify-content-between">
          {currentIndex !== 1 && (
          <button
            className="btn btn-questionnaire back backtoStepOne backtoStepTwo backtoStepThree"
            onClick={() => setCurrentIndex(1)}
          >
            Indietro
          </button>
          )}
          {currentIndex === 1 ? (
            <button
              type="button"
              onClick={() => nextButton()}
              className="btn btn-questionnaire"
            >
              Continua
            </button>
          ) : (
            <button type="button" onClick={() => submitForm(formDataFlow)} className="btn btn-questionnaire">
              Vai a preventivo
            </button>
          )}
        </div>
      </div>
    </SaluteFormFlowStyles>
  );
}

export default SaluteFormFlow;
