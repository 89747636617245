import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import quotationsAxe from '../../services/axios-client/quotationsAxeClient';
import { apiDownloadDocument } from '../../services/axios-client/axeCommons';
import {
  collisioneCompletedDataReset,
  getCollisioneCompletedData,
} from '../../features/purchases/purchasesActions';
import PageLoading from '../../common/components/elements/PageLoading';
import ErrorComponent from '../../common/components/ErrorComponent';
import { TutelaLegaleCompletedScreenStyles } from './completedScreens.styles';
import CompletedQuoteScreenAutoAfterSale from '../../common/components/elements/CompletedQuoteScreenAutoAfterSale';
import TutelaLegaleInfoModal from '../../common/components/redesignComponents/Modal/TutelaLegaleInfoModal';
import pdfIMG from '../../assets/images/purchase/pdf.png';
import downloadIMG from '../../assets/redesign_icons/download.svg';
import axe from '../../services/axios-client/axiosClient';
import { downloadFile } from '../../services/axios-client/axeHelpers';
import ContractDocuments from '../../common/components/elements/ContractDocuments';

function CollisioneCompletedScreen() {
  const { quoteId } = useParams();
  const [residenceInfo, setResidenceInfo] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { loading, quote, error } = useSelector(
    (store) => store.purchases.tutela_legale_completed,
  );
  const { document_types } = useSelector((store) => store.afterSale);

  const collectMunicipalities = () => {
    try {
      quotationsAxe.get('/form-data/communes').then((res) => {
        const commune = res.data.data.find(
          (commune) => commune.cadastral_code
            == quote.insurance_request.customer.residence_commune_code,
        );
        setResidenceInfo(commune);
      });
    } catch (err) {
      // Do nothing
    }
  };

  const downloadResourceDocs = async (id) => {
    const res = await axe.get(`/download-resource-document/${id}/0`, {
      responseType: 'blob',
    });

    downloadFile(res.data);
  };

  const collectQuoteData = () => {
    getCollisioneCompletedData(quoteId);
  };

  const downloadDocument = async (documentId) => {
    const response = await apiDownloadDocument(documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  useEffect(() => {
    collectQuoteData();

    return () => {
      collisioneCompletedDataReset();
    };
  }, [quoteId]);

  useEffect(() => {
    if (quote && quote.status === 7) {
      collectMunicipalities();
    }
  }, [quote]);

  if (loading) {
    return <PageLoading />;
  }

  if (loading === false && error !== null) {
    return <ErrorComponent error={error} />;
  }

  // const documentsRequests = [
  //   ...quote.insurance_request.document_requests.filter(
  //     (dr) => dr.document !== null,
  //   ),
  //   ...quote.document_requests.filter((dr) => dr.document !== null),
  // ];

  const documents = [...quote.insurance_request.documents, ...quote.documents];

  return (
    <>
      {showModal && (
        <TutelaLegaleInfoModal
          quotation={{
            product_name: quote.risk_information.product_name,
            massimale: quote.risk_information.massimale,
            amount: quote.amount,
            tax_total: quote.tax_total,
            information: quote.risk_information.information,
          }}
          setShowModal={setShowModal}
        />
      )}
      <TutelaLegaleCompletedScreenStyles className="row">
        <div className="col-xl-12">
          <div className="row mb-2">
            <div className="col-12">
              <ContractDocuments
                downloadDocument={downloadDocument}
                insuranceRequest={quote.insurance_request}
                quote={quote}
                collectQuoteData={collectQuoteData}
              />
            </div>
          </div>
        </div>
        <div className="col-xl-12">
          <div className="row mb-2">
            <div className="col-12 col-md-6">
              <div className="card border mb-2 polizze-emessa-status-step-one">
                <div className="card-header bg-secondary text-white">
                  <h2 className="h3 mb-0 card-title">Anagrafica</h2>
                </div>
                <div className="card-body">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      {quote.insurance_request.customer.gender === 'G' && (
                        <>
                          {' '}
                          <b>Compagnia:</b>
                          {' '}
                          {quote.insurance_request.customer.business_name}
                        </>
                      )}
                      {quote.insurance_request.customer.gender !== 'G' && (
                        <>
                          <b>Nome e Cognome:</b>
                          {' '}
                          {quote.insurance_request.customer.name}
                          {' '}
                          {quote.insurance_request.customer.surname}
                        </>
                      )}
                    </li>
                    <li className="list-group-item">
                      <b>Email:</b>
                      {' '}
                      {quote.insurance_request.customer.email}
                    </li>
                    <li className="list-group-item">
                      <b>Telefono:</b>
                      {' '}
                      {quote.insurance_request.customer.phone}
                    </li>
                    <li className="list-group-item">
                      <b>Indirizo:</b>
                      {' '}
                      {quote.insurance_request.customer.address}
                      ,
                      {' '}
                      {quote.insurance_request.customer.house_number}
                      ,
                      {' '}
                      {quote.insurance_request.customer.postal_code}
                    </li>
                    <li className="list-group-item">
                      <b>Residenza:</b>
                      {' '}
                      {residenceInfo !== null && residenceInfo.name}
                    </li>
                    <li className="list-group-item">
                      <b>Citta:</b>
                      {' '}
                      {residenceInfo !== null
                        && residenceInfo.communal_territory.name}
                    </li>
                    <li className="list-group-item">
                      <b>Prodotto:</b>
                      {' '}
                      {quote.product.name}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="card border polizze-emessa-status-step-two">
                <div className="card-header bg-secondary text-white">
                  <h2 className="h3 mb-0 card-title">Dati Collisione</h2>
                </div>
                <div className="card-body">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <b>Compagnia:</b>
                      {' '}
                      {quote.company.name}
                    </li>
                    <li className="list-group-item">
                      <b>Nome Prodotto:</b>
                      {' '}
                      {quote.risk_information.product_name}
                    </li>
                    {quote.product_id != 13
                    && (
                    <li className="list-group-item">
                      <b>Massimale:</b>
                      {' '}
                      {quote.risk_information.massimale}
                      {' '}
                      &euro;
                    </li>
                    )}
                    {quote.product_id == 13 && (
                    <li className="list-group-item">
                      <b>Massimale:</b>
                      {' '}
                      {parseFloat(quote.massimale).toFixed(2)}
                      {' '}
                      &euro;
                    </li>
                    )}
                    <li className="list-group-item">
                      <b>Stato:</b>
                      {' '}
                      {quote.status === 7 && 'Polizza Emmesa'}
                    </li>
                    <li className="list-group-item">
                      <b>Data Decorrenza:</b>
                      {' '}
                      {moment(quote.insurance_request.policy_effective_date)
                        .toDate()
                        .toLocaleDateString('it-IT')}
                    </li>
                    <li className="list-group-item">
                      <b>Frequenza Pagamento:</b>
                      {' '}
                      {quote.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}
                    </li>
                    <li className="list-group-item">
                      <b>Pagato:</b>
                      {' '}
                      {quote.amount_paid === null
                        ? '0.00'
                        : parseFloat(quote.amount_paid).toFixed(2)}
                      {' '}
                      &euro;
                    </li>
                    <li className="list-group-item">
                      <b>Da Pagare:</b>
                      {' '}
                      {quote.amount_paid === quote.amount
                        ? '0.00'
                        : (
                          Math.ceil(quote.amount * 100) / 100
                                                                                      - quote.amount_paid
                        ).toFixed(2)}
                      {' '}
                      &euro;
                    </li>
                    <li className="list-group-item">
                      <b>Totale:</b>
                      {' '}
                      {quote.amount.toFixed(2)}
                      {' '}
                      &euro;
                    </li>
                    <li className="list-group-item">
                      <b>Provvigioni:</b>
                      {' '}
                      {quote.commision.toLocaleString()}
                      {' '}
                      &euro;
                    </li>
                    {quote.company_id === 16 && (
                      <li className="list-group-item">
                        <div className="d-flex align-items-center justify-content-start gap-2">
                          <p className="mb-0">
                            <b>Informazioni Prodotto:</b>
                          </p>
                          {' '}
                          <p
                            className="text-decoration-underline mb-0 text-primary cursor-pointer"
                            onClick={() => downloadResourceDocs(13)}
                          >
                            {' '}
                            Informazioni Precontrattuali
                          </p>
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card border mb-2 polizze-emessa-status-step-four">
          <div className="card-header bg-secondary text-white">
            <h2 className="h3 mb-0 card-title">Documenti</h2>
          </div>
          <div className="card-body">
            <ol className="list-group list-group-numbered">
              {documents.filter((dr) => (dr.document_type_id !== 34 && dr.document_type_id !== 35 && dr.document_type_id !== 36 && dr.document_type_id !== 37 && dr.document_type_id !== 38 && dr.document_type_id !== 39 && dr.document_type_id !== 40 && dr.document_type_id !== 41)).map((document, index) => (
                <li
                  className="list-group-item d-flex justify-content-between align-items-start position-relative"
                  key={index}
                >
                  <div className="ms-2 me-auto">
                    <div className="fw-bold mb-2">
                      {document.document_type.name}
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <img
                        src={pdfIMG}
                        className="img-fluid"
                        width="25"
                        alt="PDF"
                      />
                      <div className="mx-2">
                        <h5 className="h6 mb-0">
                          {document.original_filename}
                        </h5>
                      </div>
                      <div className="d-flex align-items-center justify-content-center">
                        <button
                          className="doc-btn border-0 bg-transparent"
                          onClick={() => downloadDocument(document.id)}
                        >
                          <img src={downloadIMG} width="25" alt="" />
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ol>
          </div>
        </div>

        {quote.initial_quotation !== undefined && (
          <div className="card border">
            <div className="card-header bg-secondary text-white">
              <h2 className="h3 mb-0 card-title">
                Documenti nel preventivo iniziale
              </h2>
            </div>
            <div className="card-body">
              <ol className="list-group list-group-numbered">
                {quote.initial_quotation.insurance_request.documents.map(
                  (document, index) => (
                    <li
                      className="list-group-item d-flex justify-content-between align-items-start"
                      key={index}
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold mb-2">
                          {document.document_type.name}
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <img
                            src={pdfIMG}
                            className="img-fluid"
                            width="25"
                            alt="PDF"
                          />
                          <div className="mx-2">
                            <h5 className="h6 mb-0">
                              {document.original_filename}
                            </h5>
                          </div>
                          <div className="d-flex align-items-center justify-content-center">
                            <button
                              className="doc-btn border-0 bg-transparent"
                              onClick={() => downloadDocument(document.id)}
                            >
                              <img src={downloadIMG} width="25" alt="" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div>
                        {document.signable_document === 1
                        && document.is_signed_valid ? (
                          <span className="badge bg-primary rounded-pill me-1">
                            Firmato
                          </span>
                          ) : (
                            <span className="badge bg-danger rounded-pill me-1">
                              Non Richiede Firma
                            </span>
                          )}
                      </div>
                    </li>
                  ),
                )}
                {quote.initial_quotation.documents.map((document, index) => (
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start"
                    key={index}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold mb-2">
                        {document.document_type.name}
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <img
                          src={pdfIMG}
                          className="img-fluid"
                          width="25"
                          alt="PDF"
                        />
                        <div className="mx-2">
                          <h5 className="h6 mb-0">
                            {document.original_filename}
                          </h5>
                        </div>
                        <div className="d-flex align-items-center justify-content-center">
                          <button
                            className="doc-btn border-0 bg-transparent"
                            onClick={() => downloadDocument(document.id)}
                          >
                            <img src={downloadIMG} width="25" alt="" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div>
                      {document.signable_document === 1
                      && document.is_signed_valid ? (
                        <span className="badge bg-primary rounded-pill me-1">
                          Firmato
                        </span>
                        ) : (
                          <span className="badge bg-danger rounded-pill me-1">
                            Non Richiede Firma
                          </span>
                        )}
                    </div>
                  </li>
                ))}
              </ol>
            </div>
          </div>
        )}
        {/* //? todo do we need it here or not? */}
        <CompletedQuoteScreenAutoAfterSale
          quote={quote}
          document_types={document_types}
        />
      </TutelaLegaleCompletedScreenStyles>
    </>
  );
}

export default CollisioneCompletedScreen;
