import React, {
  createRef, useEffect, useRef, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { MotoCompletedScreenStyles } from './completedScreens.styles';
import quotationsAxe from '../../services/axios-client/quotationsAxeClient';
import {
  apiDownloadDocument, apiDownloadOptionalDocument, apiOptionalDocumentMethodManualUpload, documentMethodManualUpload,
} from '../../services/axios-client/axeCommons';
import { getMotoCompletedData, motoCompletedDataReset } from '../../features/purchases/purchasesActions';
import PageLoading from '../../common/components/elements/PageLoading';
import ErrorComponent from '../../common/components/ErrorComponent';
import CompletedQuoteScreenAutoAfterSale from '../../common/components/elements/CompletedQuoteScreenAutoAfterSale';
import { generalAlertError, generalAlertSuccessToast } from '../../common/utils/alerts';
import pdfIMG from '../../assets/images/purchase/pdf.png';
import downloadIMG from '../../assets/redesign_icons/download.svg';
import uploadIcon from '../../assets/redesign_icons/upload.svg';
import { ActionsWrapperResultPage } from '../../common/components/redesignComponents/Purchase/SignatureType.styles';
import { getQuotationTypeName } from '../../common/constants';
import ContractDocuments from '../../common/components/elements/ContractDocuments';
import { downloadFile } from '../../services/axios-client/axeHelpers';
import axe from '../../services/axios-client/axiosClient';

function MotoCompletedScreen() {
  const [residenceInfo, setResidenceInfo] = useState(null);
  const { quoteId } = useParams();
  const uploadPolizzaEmessa = useRef(null); // 13 - Polizza emessa
  const uploadQuietanzaSemestrale = useRef(null); // 32 - Quietanza Semestrale
  const uploadPrivacyRef = useRef(null); // 12 - Privacy Greenia
  const uploadPreContractRef = useRef(null); // 29 - Percontrattuale Greenia
  const uploadDichiarazioniDocRef = useRef(null); // 28 - Dichiarazioni del contraente
  const uploadQuestionarioAutoDocRef = useRef(null); // 30 - Questionario Auto
  const uploadMergedDocFileRef = useRef(null); // 34 - Merged Files
  const [optionalRefs, setOptionalRefs] = useState({});
  const { document_types } = useSelector(
    (store) => store.afterSale,
  );
  const { loading, quote, error } = useSelector((store) => store.purchases.moto_completed);
  const [documentsRequests, setDocumentsRequests] = useState([]);

  const collectMunicipalities = () => {
    try {
      quotationsAxe.get('/form-data/communes').then((res) => {
        const commune = res.data.data.find(
          (commune) => commune.cadastral_code
            == quote.insurance_request.customer.residence_commune_code,
        );
        setResidenceInfo(commune);
      });
    } catch (err) {
      // Do nothing
    }
  };

  const downloadDocument = async (documentId) => {
    const response = await apiDownloadDocument(documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const downloadOptionalDocument = async (documentId) => {
    const response = await apiDownloadOptionalDocument(documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const collectQuoteData = () => {
    getMotoCompletedData(quoteId);
  };

  useEffect(() => {
    collectQuoteData();

    return () => {
      motoCompletedDataReset();
    };
  }, [quoteId]);

  useEffect(() => {
    if (quote && quote.status === 7) {
      collectMunicipalities();
    }
  }, [quote]);

  const fireSignedDocUpload = (typeId, docId) => {
    if ([13, 35, 36, 37, 38, 39, 40, 41].includes(typeId)) {
      uploadPolizzaEmessa.current.click();
    }
    if (typeId === 32) {
      uploadQuietanzaSemestrale.current.click();
    }
    if (typeId === 29) {
      uploadPreContractRef.current.click();
    }

    if (typeId === 12) {
      uploadPrivacyRef.current.click();
    }

    if (typeId === 26) {
      optionalRefs[docId].current.click();
    }

    if (typeId === 28) {
      uploadDichiarazioniDocRef.current.click();
    }

    if (typeId === 30) {
      uploadQuestionarioAutoDocRef.current.click();
    }

    if (typeId === 34) {
      uploadMergedDocFileRef.current.click();
    }
  };

  const downloadInvoice = async (quoteId) => {
    const res = await axe.get(`/list/invoices/${quoteId}/download`, {
      responseType: 'blob',
    });
    const contentDisposition = res.headers['content-disposition'];
    const file_name = contentDisposition.split('filename="')[1].split('"')[0];
    downloadFile(res.data, file_name);
  };

  const uploadSignedOptionalDocument = async (documentId, file) => {
    const uploadedFile = file[0];
    const reader = new FileReader();
    reader.readAsDataURL(uploadedFile);
    if (uploadedFile.type !== 'application/pdf') {
      generalAlertError('Formato di file errato. Il file deve essere in .pdf');
      return;
    }
    reader.onload = () => {
      apiOptionalDocumentMethodManualUpload(quote.id, documentId, {
        document: reader.result.replace('data:application/pdf;base64,', ''),
      }).then(() => {
        collectQuoteData();
        generalAlertSuccessToast('File caricato');
      });
    };
  };

  const uploadSignedDocument = async (documentId, files) => {
    const uploadedFile = files[0];
    const reader = new FileReader();

    reader.readAsDataURL(uploadedFile);
    if (uploadedFile.type !== 'application/pdf') {
      generalAlertError('Formato di file errato. Il file deve essere in .pdf');
      return;
    }
    reader.onload = () => {
      documentMethodManualUpload(quote.id, documentId, {
        document: reader.result.replace('data:application/pdf;base64,', ''),
      }).then(() => {
        collectQuoteData();
        generalAlertSuccessToast('File caricato');
      });
    };
  };

  useEffect(() => {
    if (quote) {
      setDocumentsRequests([
        ...quote.insurance_request.document_requests.filter(
          (dr) => dr.document !== null,
        ),
        ...quote.document_requests.filter((dr) => dr.document !== null),
      ]);

      const optionalDocs = {};
      documentsRequests.filter((d) => d.optional_document_request_id !== undefined).forEach((doc) => {
        optionalDocs[doc.id] = createRef(null);
      });

      setOptionalRefs(optionalDocs);
    }
  }, [quote]);

  const get_amount_to_pay = () => {
    let primaGuaranteesPrices = 0;

    if (quote.company_id === 2) {
      quote.guarantees.forEach((guarantee) => {
        primaGuaranteesPrices += parseFloat(guarantee.amount) || 0;
      });
    }

    const amount_to_pay = parseFloat((quote.amount / quote.payment_frequency) + quote.brokerage_amount + (quote.quotation_services && quote.quotation_services.length > 0 ? quote.quotation_services[0].amount_gross : 0));

    return (amount_to_pay + (primaGuaranteesPrices / quote.payment_frequency)).toFixed(2);
  };

  if (loading) {
    return <PageLoading />;
  }

  if (loading === false && error !== null) {
    return <ErrorComponent error={error} />;
  }

  return (
    <MotoCompletedScreenStyles className="row">
      <div className="col-xl-12">
        <div className="row mb-2">
          <div className="col-12">
            <ContractDocuments
              downloadDocument={downloadDocument}
              insuranceRequest={quote.insurance_request}
              quote={quote}
              collectQuoteData={collectQuoteData}
            />
          </div>
        </div>
      </div>
      <div className="col-xl-12">
        <div className="row mb-2">
          <div className="col-12 col-md-6">
            <div className="card border mb-2 polizze-emessa-status-step-one">
              <div className="card-header bg-secondary text-white">
                <h2 className="h3 mb-0 card-title">Anagrafica</h2>
              </div>
              <div className="card-body">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    {quote.insurance_request.customer.gender === 'G' && (
                      <>
                        {' '}
                        <b>Compagnia:</b>
                        {' '}
                        {quote.insurance_request.customer.business_name}
                      </>
                    )}
                    {quote.insurance_request.customer.gender !== 'G' && (
                      <>
                        <b>Nome e Cognome:</b>
                        {' '}
                        {quote.insurance_request.customer.name}
                        {' '}
                        {quote.insurance_request.customer.surname}
                      </>
                    )}
                  </li>
                  <li className="list-group-item">
                    <b>Email:</b>
                    {' '}
                    {quote.insurance_request.customer.email}
                  </li>
                  <li className="list-group-item">
                    <b>Telefono:</b>
                    {' '}
                    {quote.insurance_request.customer.phone}
                  </li>
                  <li className="list-group-item">
                    <b>Indirizo:</b>
                    {' '}
                    {quote.insurance_request.customer.address}
                    ,
                    {' '}
                    {quote.insurance_request.customer.house_number}
                    ,
                    {' '}
                    {quote.insurance_request.customer.postal_code}
                  </li>
                  <li className="list-group-item">
                    <b>Residenza:</b>
                    {' '}
                    {residenceInfo !== null && residenceInfo.name}
                  </li>
                  <li className="list-group-item">
                    <b>Citta:</b>
                    {' '}
                    {residenceInfo !== null
                      && residenceInfo.communal_territory.name}
                  </li>
                  <li className="list-group-item">
                    <b>Prodotto:</b>
                    {' '}
                    {quote.product.name}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="card border polizze-emessa-status-step-two">
              <div className="card-header bg-secondary text-white">
                <h2 className="h3 mb-0 card-title">Dati Veicolo</h2>
              </div>
              <div className="card-body">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <b>Targa:</b>
                    {' '}
                    {quote.insurance_request.moto.moto_plate}
                  </li>
                  <li className="list-group-item">
                    <b>Marca:</b>
                    {' '}
                    {quote.insurance_request.moto.brand.name}
                  </li>
                  <li className="list-group-item">
                    <b>Modello:</b>
                    {' '}
                    {quote.insurance_request.moto.model.name}
                  </li>
                  <li className="list-group-item">
                    <b>Antifurto:</b>
                    {' '}
                    {quote.insurance_request.moto.theft_protection.name}
                  </li>
                  <li className="list-group-item">
                    <b>Moto Comprata:</b>
                    {' '}
                    {quote.insurance_request.moto.moto_purchased_year}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="card border mb-2">
              <div className="card-header bg-secondary text-white">
                <h2 className="h3 mb-0 card-title">Dati Responsabilita Civile</h2>
              </div>
              <div className="card-body">
                {quote.company_id == 1 ? (
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <b>Compagnia: </b>
                      {' '}
                      {quote.company.name}
                    </li>
                    <li className="list-group-item">
                      <b>Stato:</b>
                      {' '}
                      {quote.status === 7 && 'Polizza Emmesa'}
                    </li>
                    <li className="list-group-item">
                      <b>Data Decorrenza:</b>
                      {' '}
                      {moment(quote.insurance_request.policy_effective_date)
                        .toDate()
                        .toLocaleDateString('it-IT')}
                    </li>
                    <li className="list-group-item">
                      <b>Frequenza Pagamento:</b>
                      {' '}
                      {quote.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}
                    </li>
                    <li className="list-group-item">
                      <b>Pagato:</b>
                      {' '}
                      {quote.amount_paid === null
                        ? '0'
                        : quote.amount_paid.toLocaleString()}
                      {' '}
                      &euro;
                    </li>
                    <li className="list-group-item">
                      <b>Da Pagare:</b>
                      {' '}
                      {quote.amount_paid === quote.amount
                        ? '0'
                        : (quote.amount - quote.amount_paid).toLocaleString()}
                      {' '}
                      &euro;
                    </li>
                    <li className="list-group-item">
                      <b>Prossimo Pagamento:</b>
                      {' '}
                      {moment(quote.insurance_request.policy_effective_date)
                        .add(Math.floor(365 / quote.payment_frequency), 'days')
                        .toDate()
                        .toLocaleDateString('it-IT')}
                    </li>
                    <li className="list-group-item">
                      <b>Premio:</b>
                      {' '}
                      {quote.amount.toLocaleString()}
                      {' '}
                      &euro;
                    </li>
                    <li className="list-group-item">
                      <b>Provvigioni:</b>
                      {' '}
                      {quote.commision.toLocaleString()}
                      {' '}
                      &euro;
                    </li>
                    <li className="list-group-item">
                      <b>Brokeraggio:</b>
                      {' '}
                      {quote.brokerage_amount.toFixed(2)}
                      {' '}
                      &euro;
                    </li>
                    {quote.quotation_services.length > 0 && (
                      <li className="list-group-item">
                        <b>Servizio:</b>
                        {' '}
                        {quote.quotation_services[0].amount_gross.toFixed(2)}
                        {' '}
                        &euro;
                      </li>
                    )}
                    <li className="list-group-item">
                      <b>Totale:</b>
                      {' '}
                      {(
                        quote.amount
                        + quote.brokerage_amount
                        + (quote.quotation_services.length > 0
                          ? quote.quotation_services[0].amount_gross
                          : 0)
                      ).toFixed(2)}
                      {' '}
                      &euro;
                    </li>
                  </ul>
                ) : (
                  ''
                )}
                {quote.company_id == 2 ? (
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <b>Compagnia: </b>
                      {' '}
                      Ipitq ma
                    </li>
                    <li className="list-group-item">
                      <b>Stato:</b>
                      {' '}
                      {quote.status === 7 && 'Polizza Emmesa'}
                    </li>
                    <li className="list-group-item">
                      <b>Data Decorrenza:</b>
                      {' '}
                      {moment(quote.insurance_request.policy_effective_date)
                        .toDate()
                        .toLocaleDateString('it-IT')}
                    </li>
                    <li className="list-group-item">
                      <b>Polizza Pagata:</b>
                      {' '}
                      SI
                    </li>
                    <li className="list-group-item">
                      <b>Pagato:</b>
                      {' '}
                      {quote.amount_paid === null
                        && get_amount_to_pay().toLocaleString()}
                      {(quote.amount_paid !== null && (quote.amount_paid !== quote.amount))
                        && get_amount_to_pay().toLocaleString()}
                      {quote.amount_paid === quote.amount
                        && (get_amount_to_pay() * quote.payment_frequency).toLocaleString()}
                      {' '}
                      &euro;
                    </li>
                    <li className="list-group-item">
                      <b>Da Pagare:</b>
                      {' '}
                      {quote.amount_paid === quote.amount
                        ? '0'
                        : get_amount_to_pay().toLocaleString()}
                      {' '}
                      &euro;
                    </li>
                    <li className="list-group-item">
                      <b>Prossimo Pagamento:</b>
                      {' '}
                      {moment(quote.insurance_request.policy_effective_date)
                        .add(Math.floor(365 / quote.payment_frequency), 'days')
                        .toDate()
                        .toLocaleDateString('it-IT')}
                    </li>
                    <li className="list-group-item">
                      <b>Provvigioni:</b>
                      {' '}
                      {quote.commision.toLocaleString()}
                      {' '}
                      &euro;
                    </li>
                    {quote.quotation_services.length > 0 && (
                      <li className="list-group-item">
                        <b>Servizio:</b>
                        {' '}
                        {quote.quotation_services[0].amount_gross.toFixed(2)}
                        {' '}
                        &euro;
                      </li>
                    )}
                    <li className="list-group-item">
                      <b>Totale:</b>
                      {' '}
                      {get_amount_to_pay() * quote.payment_frequency}
                      {' '}
                      &euro;
                    </li>
                  </ul>
                ) : (
                  ''
                )}
                {quote.company_id === 9 || quote.company_id === 10 ? (
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <b>Compagnia: </b>
                      {' '}
                      {quote.company.name}
                    </li>
                    <li className="list-group-item">
                      <b>Stato:</b>
                      {' '}
                      {quote.status === 7 && 'Polizza Emmesa'}
                    </li>
                    <li className="list-group-item">
                      <b>Data Decorrenza:</b>
                      {' '}
                      {moment(quote.insurance_request.policy_effective_date)
                        .toDate()
                        .toLocaleDateString('it-IT')}
                    </li>
                    <li className="list-group-item">
                      <b>Polizza Pagata:</b>
                      {' '}
                      SI
                    </li>
                    <li className="list-group-item">
                      <b>Totale:</b>
                      {' '}
                      {quote.amount.toLocaleString()}
                      {' '}
                      &euro;
                    </li>
                    <li className="list-group-item">
                      <b>Provvigioni:</b>
                      {' '}
                      {quote.commision.toLocaleString()}
                      {' '}
                      &euro;
                    </li>
                    {quote.quotation_services.length > 0 && (
                      <li className="list-group-item">
                        <b>Servizio:</b>
                        {' '}
                        {quote.quotation_services[0].amount_gross.toFixed(2)}
                        {' '}
                        &euro;
                      </li>
                    )}
                    <li className="list-group-item">
                      <b>Totale:</b>
                      {' '}
                      {(
                        quote.amount
                        + quote.brokerage_amount
                        + (quote.quotation_services.length > 0
                          ? quote.quotation_services[0].amount_gross
                          : 0)
                      ).toFixed(2)}
                      {' '}
                      &euro;
                    </li>
                  </ul>
                ) : (
                  ''
                )}

                {quote.company_id === 11 || quote.company_id === 12 || quote.company_id === 13 || quote.company_id === 14 ? (
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <b>Compagnia: </b>
                      {' '}
                      {quote.company.name}
                    </li>
                    <li className="list-group-item">
                      <b>Stato:</b>
                      {' '}
                      {quote.status === 7 && 'Polizza Emmesa'}
                    </li>
                    <li className="list-group-item">
                      <b>Data Decorrenza:</b>
                      {' '}
                      {moment(quote.insurance_request.policy_effective_date)
                        .toDate()
                        .toLocaleDateString('it-IT')}
                    </li>
                    <li className="list-group-item">
                      <b>Polizza Pagata:</b>
                      {' '}
                      SI
                    </li>
                    <li className="list-group-item">
                      <b>Totale:</b>
                      {' '}
                      {quote.amount.toLocaleString()}
                      {' '}
                      &euro;
                    </li>
                    <li className="list-group-item">
                      <b>Provvigioni:</b>
                      {' '}
                      {quote.commision.toLocaleString()}
                      {' '}
                      &euro;
                    </li>
                    {quote.quotation_services.length > 0 && (
                      <li className="list-group-item">
                        <b>Servizio:</b>
                        {' '}
                        {quote.quotation_services[0].amount_gross.toFixed(2)}
                        {' '}
                        &euro;
                      </li>
                    )}
                    <li className="list-group-item">
                      <b>Totale:</b>
                      {' '}
                      {(
                        quote.amount
                        + quote.brokerage_amount
                        + (quote.quotation_services.length > 0
                          ? quote.quotation_services[0].amount_gross
                          : 0)
                      ).toFixed(2)}
                      {' '}
                      &euro;
                    </li>
                  </ul>
                ) : (
                  ''
                )}
                {quote.company_id === null ? (
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <b>Compagnia: </b>
                      {' '}
                      {quote.preventivas_company.company_name}
                    </li>
                    <li className="list-group-item">
                      <b>Stato:</b>
                      {' '}
                      {quote.status === 7 && 'Polizza Emmesa'}
                    </li>
                    <li className="list-group-item">
                      <b>Data Decorrenza:</b>
                      {' '}
                      {moment(quote.insurance_request.policy_effective_date)
                        .toDate()
                        .toLocaleDateString('it-IT')}
                    </li>
                    <li className="list-group-item">
                      <b>Polizza Pagata:</b>
                      {' '}
                      SI
                    </li>
                    <li className="list-group-item">
                      <b>Totale:</b>
                      {' '}
                      {quote.amount.toLocaleString()}
                      {' '}
                      &euro;
                    </li>
                    <li className="list-group-item">
                      <b>Provvigioni:</b>
                      {' '}
                      {quote.commision.toLocaleString()}
                      {' '}
                      &euro;
                    </li>
                    {quote.quotation_services.length > 0 && (
                      <li className="list-group-item">
                        <b>Servizio:</b>
                        {' '}
                        {quote.quotation_services[0].amount_gross.toFixed(2)}
                        {' '}
                        &euro;
                      </li>
                    )}
                    <li className="list-group-item">
                      <b>Totale:</b>
                      {' '}
                      {(
                        quote.amount
                        + quote.brokerage_amount
                        + (quote.quotation_services.length > 0
                          ? quote.quotation_services[0].amount_gross
                          : 0)
                      ).toFixed(2)}
                      {' '}
                      &euro;
                    </li>
                  </ul>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          {quote.risk_quotations.map((risk_quotation) => (
            <div className="col-12 col-md-6">
              <div className="card border mb-2 polizze-emessa-status-step-three">
                <div className="card-header bg-secondary text-white">
                  <h2 className="h3 mb-0 card-title">
                    Dati
                    {' '}
                    {getQuotationTypeName(risk_quotation.quote_type)}
                  </h2>
                </div>
                <div className="card-body">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <b>Compagnia: </b>
                      {' '}
                      {risk_quotation.company.name}
                    </li>
                    <li className="list-group-item">
                      <b>Stato:</b>
                      {' '}
                      {risk_quotation.status === 7 && 'Polizza Emmesa'}
                    </li>
                    <li className="list-group-item">
                      <b>Data Decorrenza:</b>
                      {' '}
                      {moment(
                        risk_quotation.insurance_request.policy_effective_date,
                      )
                        .toDate()
                        .toLocaleDateString('it-IT')}
                    </li>
                    <li className="list-group-item">
                      <b>Frequenza Pagamento:</b>
                      {' '}
                      {risk_quotation.payment_frequency === 1
                        ? 'Annuo'
                        : 'Semestrale'}
                    </li>
                    <li className="list-group-item">
                      <b>Pagato:</b>
                      {' '}
                      {risk_quotation.amount_paid === null
                        ? '0'
                        : risk_quotation.amount_paid.toLocaleString()}
                      {' '}
                      &euro;
                    </li>
                    <li className="list-group-item">
                      <b>Premio:</b>
                      {' '}
                      {risk_quotation.amount.toLocaleString()}
                      {' '}
                      &euro;
                    </li>
                    <li className="list-group-item">
                      <b>Totale:</b>
                      {' '}
                      {risk_quotation.amount.toFixed(2)}
                      {' '}
                      &euro;
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ))}
          {quote.guarantees.length !== 0 && (
            <div className="col-12 col-md-6">
              <div className="card border mb-2 polizze-emessa-status-step-three">
                <div className="card-header bg-secondary text-white">
                  <h2 className="h3 mb-0 card-title">Dati Garanzie</h2>
                </div>
                <div className="card-body">
                  <ul className="list-group list-group-flush">
                    {quote.guarantees.map((guarantee, index) => (
                      <li className="list-group-item" key={index}>
                        <b>Garanzia: </b>
                        {' '}
                        {quote.quotation_data_prima.quotation_data_prima_guarantees.find((g) => g.slug === guarantee.guarantees_slug) !== undefined && quote.quotation_data_prima.quotation_data_prima_guarantees.find((g) => g.slug === guarantee.guarantees_slug).name}
                        <br />
                        <b>Premio Totale: </b>
                        {' '}
                        {guarantee.amount}
                        {' '}
                        &euro;
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          )}
          <div className="col-12">
            <div className="card border">
              <div className="card-header bg-secondary text-white">
                <h2 className="h3 mb-0 card-title">Documenti</h2>
              </div>
              <div className="card-body">
                <ol className="list-group list-group-numbered">
                  {quote.insurance_request.documents
                    .filter(
                      (dr) => dr.document_type_id !== 34 && dr.document_type_id !== 35 && dr.document_type_id !== 36 && dr.document_type_id !== 37 && dr.document_type_id !== 38 && dr.document_type_id !== 39 && dr.document_type_id !== 40 && dr.document_type_id !== 41,
                    )
                    .map((document, index) => (
                      <li
                        className="list-group-item d-flex justify-content-between align-items-start"
                        key={index}
                      >
                        <div className="ms-2 me-auto">
                          <div className="fw-bold mb-2">
                            {document.document_type.name}
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <img
                              src={pdfIMG}
                              className="img-fluid"
                              width="25"
                              alt="PDF"
                            />
                            <div className="mx-2">
                              <h5 className="h6 mb-0">
                                {document.original_filename}
                              </h5>
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                              <button
                                className="doc-btn border-0 bg-transparent"
                                onClick={() => downloadDocument(document.id)}
                              >
                                <img src={downloadIMG} width="25" alt="" />
                              </button>
                            </div>
                            {(document.signable_document === 1 && (document.is_signed_valid === 0 || (document.is_signed_valid === null && document.signed_document_filename === null))) && (
                            <ActionsWrapperResultPage>
                              {' '}
                              {([13, 35, 36, 37, 38, 39, 40, 41].includes(document.document_type.id)) && (
                              <div className="action-button" onClick={() => fireSignedDocUpload(document.document_type_id)}>
                                <img src={uploadIcon} alt="" width={25} />
                                <input ref={uploadPolizzaEmessa} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                              </div>
                              )}
                              {document.document_type.id === 32 && (
                              <div className="action-button" onClick={() => fireSignedDocUpload(document.document_type_id)}>
                                <img src={uploadIcon} alt="" width={25} />
                                <input ref={uploadQuietanzaSemestrale} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                              </div>
                              )}
                              {document.document_type.id === 29
                                && (
                                  <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                    <img src={uploadIcon} alt="Carica" width={25} />
                                    <input ref={uploadPreContractRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                                  </div>
                                )}
                              {document.document_type.id === 28
                                && (
                                  <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                    <img src={uploadIcon} alt="Carica" width={25} />
                                    <input ref={uploadDichiarazioniDocRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                                  </div>
                                )}
                              {document.document_type.id === 30
                                && (
                                  <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                    <img src={uploadIcon} alt="Carica" width={25} />
                                    <input ref={uploadQuestionarioAutoDocRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                                  </div>
                                )}
                              {document.document_type.id === 34
                                && (
                                  <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                    <img src={uploadIcon} alt="Carica" width={25} />
                                    <input ref={uploadMergedDocFileRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                                  </div>
                                )}
                              {document.optional_document_request_id
                                && (
                                  <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id, document.id) : () => { }}>
                                    <img src={uploadIcon} alt="Carica" width={25} />
                                    <input ref={optionalRefs[document.id]} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedOptionalDocument(document.id, e.target.files)} />

                                  </div>
                                )}
                              {document.document_type.id === 12
                                && (
                                  <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                    <img src={uploadIcon} alt="Carica" width={25} />
                                    <input ref={uploadPrivacyRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                                  </div>
                                )}
                            </ActionsWrapperResultPage>
                            )}
                          </div>
                          {(document.signable_document === 1 && document.is_signed_valid === 0 && document.signed_document_filename === null && document.motivation !== null) && (
                          <p className="text-danger mt-2">
                            {document.motivation}
                          </p>
                          )}
                        </div>
                        <div>
                          {document.signable_document === 1 ? (
                            document.is_signed_valid === null && document.signed_document_filename !== null ? (
                              <span className="badge bg-warning rounded-pill position-absolute top-25 end-0 me-2">In Verifica</span>
                            ) : document.is_signed_valid === 0 ? (
                              <span className="badge bg-danger rounded-pill position-absolute top-25 end-0 me-2">Firma Non Valida</span>
                            ) : document.is_signed_valid === null && document.signed_document_filename === null ? (
                              <span className="badge bg-warning rounded-pill position-absolute top-25 end-0 me-2">Da Firmare</span>
                            ) : document.is_signed_valid === 1 && document.signed_document_filename !== null ? (
                              <span className="badge bg-primary rounded-pill position-absolute top-25 end-0 me-2">Firmato</span>
                            ) : null
                          ) : (
                            <span className="badge bg-danger rounded-pill position-absolute top-25 end-0 me-2">Non Richiede Firma</span>
                          )}
                          {' '}

                        </div>
                      </li>
                    ))}
                  {quote.documents.map((document, index) => (
                    <li
                      className="list-group-item d-flex justify-content-between align-items-start"
                      key={index}
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold mb-2">
                          {document.document_type.name}
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <img
                            src={pdfIMG}
                            className="img-fluid"
                            width="25"
                            alt="PDF"
                          />
                          <div className="mx-2">
                            <h5 className="h6 mb-0">
                              {document.original_filename}
                            </h5>
                          </div>
                          <div className="d-flex align-items-center justify-content-center">
                            <button
                              className="doc-btn border-0 bg-transparent"
                              onClick={() => downloadDocument(document.id)}
                            >
                              <img src={downloadIMG} width="25" alt="" />
                            </button>
                          </div>
                          {(document.signable_document === 1 && document.is_signed_valid !== 1 && document.signed_document_filename !== null) && (
                            <ActionsWrapperResultPage>
                              {' '}
                              {document.document_type.id === 13 && (
                                <div className="action-button" onClick={() => fireSignedDocUpload(document.document_type_id)}>
                                  <img src={uploadIcon} alt="" width={25} />
                                  <input ref={uploadPolizzaEmessa} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                                </div>
                              )}
                              {document.document_type.id === 32 && (
                                <div className="action-button" onClick={() => fireSignedDocUpload(document.document_type_id)}>
                                  <img src={uploadIcon} alt="" width={25} />
                                  <input ref={uploadQuietanzaSemestrale} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                                </div>
                              )}
                              {document.document_type.id === 29
                                && (
                                  <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                    <img src={uploadIcon} alt="Carica" width={25} />
                                    <input ref={uploadPreContractRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                                  </div>
                                )}
                              {document.document_type.id === 28
                                && (
                                  <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                    <img src={uploadIcon} alt="Carica" width={25} />
                                    <input ref={uploadDichiarazioniDocRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                                  </div>
                                )}
                              {document.document_type.id === 30
                                && (
                                  <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                    <img src={uploadIcon} alt="Carica" width={25} />
                                    <input ref={uploadQuestionarioAutoDocRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                                  </div>
                                )}
                              {document.optional_document_request_id
                                && (
                                  <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id, document.id) : () => { }}>
                                    <img src={uploadIcon} alt="Carica" width={25} />
                                    <input ref={optionalRefs[document.id]} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedOptionalDocument(document.id, e.target.files)} />

                                  </div>
                                )}
                              {document.document_type.id === 12
                                && (
                                  <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                    <img src={uploadIcon} alt="Carica" width={25} />
                                    <input ref={uploadPrivacyRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                                  </div>
                                )}
                            </ActionsWrapperResultPage>
                          )}
                        </div>
                        {(document.signable_document === 1 && document.is_signed_valid === 0 && document.signed_document_filename === null && document.motivation !== null) && (
                          <p className="text-danger mt-2">
                            {document.motivation}
                          </p>
                        )}
                      </div>
                      <div>
                        {document.signable_document === 1
                          && document.is_signed_valid ? (
                            <span className="badge bg-primary rounded-pill me-1">
                              Firmato
                            </span>
                          ) : (
                            <span className="badge bg-danger rounded-pill me-1">
                              Non Richiede Firma
                            </span>
                          )}
                      </div>
                    </li>
                  ))}

                  {documentsRequests.map((dr, index) => (
                    <li
                      className="list-group-item d-flex justify-content-between align-items-start"
                      key={index}
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold mb-2">
                          {dr.optional_document_type.name}
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <img
                            src={pdfIMG}
                            className="img-fluid"
                            width="25"
                            alt="PDF"
                          />
                          <div className="mx-2">
                            <h5 className="h6 mb-0">
                              {dr.document.original_filename}
                            </h5>
                          </div>
                          <div className="d-flex align-items-center justify-content-center">
                            <button
                              className="doc-btn border-0 bg-transparent"
                              onClick={() => downloadOptionalDocument(dr.document.id)}
                            >
                              <img src={downloadIMG} width="25" alt="" />
                            </button>
                          </div>
                          {(document.signable_document === 1 && document.is_signed_valid !== 1 && document.signed_document_filename !== null) && (
                            <ActionsWrapperResultPage>
                              {' '}
                              {document.document_type.id === 13 && (
                                <div className="d-flex flex-column">
                                  <div className="action-button" onClick={() => fireSignedDocUpload(document.document_type_id)}>
                                    <img src={uploadIcon} alt="" width={25} />
                                    <input ref={uploadPolizzaEmessa} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                                  </div>

                                </div>
                              )}
                              {document.document_type.id === 32 && (
                                <div className="action-button" onClick={() => fireSignedDocUpload(document.document_type_id)}>
                                  <img src={uploadIcon} alt="" width={25} />
                                  <input ref={uploadQuietanzaSemestrale} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                                </div>
                              )}
                              {document.document_type.id === 29
                                && (
                                  <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                    <img src={uploadIcon} alt="Carica" width={25} />
                                    <input ref={uploadPreContractRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                                  </div>
                                )}
                              {document.document_type.id === 28
                                && (
                                  <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                    <img src={uploadIcon} alt="Carica" width={25} />
                                    <input ref={uploadDichiarazioniDocRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                                  </div>
                                )}
                              {document.document_type.id === 30
                                && (
                                  <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                    <img src={uploadIcon} alt="Carica" width={25} />
                                    <input ref={uploadQuestionarioAutoDocRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                                  </div>
                                )}
                              {document.optional_document_request_id
                                && (
                                  <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id, document.id) : () => { }}>
                                    <img src={uploadIcon} alt="Carica" width={25} />
                                    <input ref={optionalRefs[document.id]} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedOptionalDocument(document.id, e.target.files)} />

                                  </div>
                                )}
                              {document.document_type.id === 12
                                && (
                                  <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                    <img src={uploadIcon} alt="Carica" width={25} />
                                    <input ref={uploadPrivacyRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                                  </div>
                                )}
                            </ActionsWrapperResultPage>
                          )}
                        </div>
                        {(document.signable_document === 1 && document.is_signed_valid === 0 && document.signed_document_filename === null && document.motivation !== null) && (
                          <p className="text-danger mt-2">
                            {document.motivation}
                          </p>
                        )}
                      </div>
                      <div>
                        {document.signable_document === 1 ? (
                          document.is_signed_valid === null && document.signed_document_filename !== null ? (
                            <span className="badge bg-warning rounded-pill position-absolute top-25 end-0 me-2">In Verifica</span>
                          ) : document.is_signed_valid === 0 ? (
                            <span className="badge bg-danger rounded-pill position-absolute top-25 end-0 me-2">Firma Non Valida</span>
                          ) : document.is_signed_valid === null && document.signed_document_filename === null ? (
                            <span className="badge bg-warning rounded-pill position-absolute top-25 end-0 me-2">Da Firmare</span>
                          ) : document.is_signed_valid === 1 && document.signed_document_filename !== null ? (
                            <span className="badge bg-primary rounded-pill position-absolute top-25 end-0 me-2">Firmato</span>
                          ) : null
                        ) : (
                          <span className="badge bg-danger rounded-pill position-absolute top-25 end-0 me-2">Non Richiede Firma</span>
                        )}
                        {' '}

                      </div>
                    </li>
                  ))}
                  {quote.quote_type === 'res_civile'
                    && quote.payments.length === 1 && (
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div className="fw-bold mb-2">Fattura:</div>
                          <div className="d-flex align-items-center justify-content-between">
                            <img
                              src={pdfIMG}
                              className="img-fluid"
                              width="25"
                              alt="PDF"
                            />
                            <div className="mx-2">
                              <h5 className="h6 mb-0">Scarica Fattura</h5>
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                              <button
                                className="doc-btn border-0 bg-transparent"
                                onClick={() => downloadInvoice(quote.id)}
                              >
                                <img src={downloadIMG} width="25" alt="" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div>
                          <span className="badge bg-danger rounded-pill position-absolute top-25 end-0 me-2">
                            Non Richiede Firma
                          </span>
                        </div>
                      </li>
                  )}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CompletedQuoteScreenAutoAfterSale quote={quote} document_types={document_types} />
    </MotoCompletedScreenStyles>
  );
}

export default MotoCompletedScreen;
