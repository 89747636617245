import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AfterSaleCreateFormFlowStyles, AfterSaleCreateFormStyles } from '../../common/AfterSale/AfterSaleCreate.styles';
import { CenteredDivTitle } from '../../common/components/CenteredPageTitle.styles';
import { FormInputDate } from '../../common/formInputs/FormInputDate';
import { FormInputTextarea } from '../../common/formInputs/FormInputTextarea';
import { addNewSuspension } from '../../features/afterSale/afterSaleActions';

const schema = yup.object({
  note: yup.string().required('Il campo è obbligatorio'),
  suspension_date: yup
    .string()
    .required('Il campo è obbligatorio')
    .test('is-future-date', 'La data non può essere nel passato', (value) => moment(value, 'YYYY-MM-DD', true).isSameOrAfter(moment(), 'day'))
    .test(
      'is-within-6-months',
      'La data di sospensione non può essere più di 6 mesi nel futuro',
      (value) => moment(value, 'YYYY-MM-DD').isBefore(moment().add(6, 'months'), 'day'),
    ),
  riactivation_date: yup
    .string()
    .required('Il campo è obbligatorio')
    .test(
      'is-after-suspension',
      'La data di riattivazione deve essere successiva alla data di sospensione',
      function (value) {
        const { suspension_date } = this.parent;
        return moment(value, 'YYYY-MM-DD', true).isAfter(
          moment(suspension_date, 'YYYY-MM-DD', true),
          'day',
        );
      },
    )
    .test(
      'is-at-least-15-days',
      'La data di riattivazione deve essere almeno 15 giorni dopo la data di sospensione',
      function (value) {
        const { suspension_date } = this.parent;
        return moment(value, 'YYYY-MM-DD', true).isSameOrAfter(
          moment(suspension_date, 'YYYY-MM-DD').add(15, 'days'),
          'day',
        );
      },
    ),
});

function CreateSuspension() {
  const { quoteId } = useParams();
  const navigate = useNavigate();
  const {
    register, formState: { errors, touchedFields }, trigger, watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      note: '',
      suspension_date: '',
      riactivation_date: '',
    },
  });

  const formData = watch();

  const submitData = async (formData) => {
    const triggerValidation = await trigger(['note', 'suspension_date', 'riactivation_date']);
    if (!triggerValidation) {
      return;
    }

    addNewSuspension(quoteId, formData, navigate);
  };

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }
    return true;
  };

  return (
    <AfterSaleCreateFormFlowStyles>
      <AfterSaleCreateFormStyles>
        <CenteredDivTitle className="d-flex align-items-center justify-content-center gap-3 mt-3">
          <div className="after-sale-title divider">
            <h2 className="text-center">Completa Dati Per Sospensione</h2>
          </div>
        </CenteredDivTitle>
        <div className="form-container-after-sale">
          <FormInputDate
            label="Data Sospensione"
            maxDate={moment().add(6, 'months').format('YYYY-MM-DD')}
            registration={register('suspension_date')}
            error={errors.suspension_date?.message}
            valid={isValid('suspension_date')}
          />
          <FormInputDate
            label="Data Riattivazione"
            minDate={formData.suspension_date ? moment(formData.suspension_date).add(15, 'days').format('YYYY-MM-DD') : moment().add(15, 'days').format('YYYY-MM-DD')}
            registration={register('riactivation_date')}
            error={errors.riactivation_date?.message}
            valid={isValid('riactivation_date')}
          />
          <FormInputTextarea
            placeholder="Note"
            label="Nota"
            registration={register('note')}
            rows="3"
            error={errors.note?.message}
            valid={isValid('note')}
          />
        </div>
        <div className="mt-3">
          <div className="d-flex justify-content-between">
            <button
              className="btn btn-questionnaire "
              onClick={() => submitData(formData)}
            >
              Continua
            </button>
          </div>
        </div>
      </AfterSaleCreateFormStyles>
    </AfterSaleCreateFormFlowStyles>
  );
}

export default CreateSuspension;
