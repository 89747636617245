import caller from './afterSaleSlice';
import managementAxe from '../../services/axios-client/managementServisAxeClient';
import {
  generalAlertError,
  generalAlertSuccess,
} from '../../common/utils/alerts';

const serverError = 'Si è verificato un problema con il server';

export const addNewCancellation = async (
  quotationId,
  data,
  navigate,
) => {
  caller.cancellationPending();

  const response = await managementAxe.post(
    `/broker/after-sale/cancellation/quotation/${quotationId}`,
    data,
  );

  if (response.status !== 200) {
    caller.cancellationRejected(serverError);
    return;
  }

  const { after_sale_type, after_sale_id } = response.data.data;

  caller.cancellationFulfilled();
  navigate(`/after-sale/purchase/${after_sale_type}/${after_sale_id}`);
};

export const addNewReplacement = async (quotationId, data, navigate) => {
  caller.replacementPending();

  const response = await managementAxe.post(
    `/broker/after-sale/replacement/quotation/${quotationId}`,
    data,
  );

  if (response.status !== 200) {
    caller.replacementRejected(serverError);
    return;
  }

  const { after_sale_type, after_sale_id } = response.data.data;

  caller.replacementFulfilled();
  navigate(`/after-sale/purchase/${after_sale_type}/${after_sale_id}`);
};

export const addNewModification = async (
  quotationId,
  data,
  navigate,
) => {
  caller.modificationPending();

  const response = await managementAxe.post(
    `/broker/after-sale/modification/quotation/${quotationId}`,
    data,
  );

  if (response.status !== 200) {
    caller.modificationRejected(serverError);
    return;
  }

  const { after_sale_type, after_sale_id } = response.data.data;

  caller.modificationFulfilled();
  navigate(`/after-sale/purchase/${after_sale_type}/${after_sale_id}`);
};

export const sendDocument = async (quotationId, data, resetEverything) => {
  caller.sendDocumentPending();

  const response = await managementAxe.post(
    `broker/after-sale/quotation/${quotationId}/send-document`,
    data,
  );

  if (response.status === 404 && response.data.error === 'policy_not_found') {
    generalAlertError('La polizza non e caricata');
    caller.sendDocumentFulfilled();
    resetEverything();
    return;
  }

  if (response.status !== 200) {
    caller.sendDocumentRejected(serverError);
    return;
  }

  caller.sendDocumentFulfilled();
  generalAlertSuccess('Il documento e stato inviato');
  resetEverything();
};

export const getDocumentsForSend = async (quotationId) => {
  caller.getDocumentsToSendPending();

  const response = await managementAxe.get(`/broker/after-sale/get-documents/${quotationId}`);

  if (response.status !== 200) {
    caller.getDocumentsToSendRejected('Error');
  }

  caller.getDocumentsToSendSuccess(response.data);
};

// Cancellations

export const listCancellations = async (page, searchData) => {
  caller.cancellationsPagePending();

  try {
    const response = await managementAxe.get('/broker/after-sale/cancellations', {
      params: {
        page,
        ...searchData,
      },
    });

    if (response.status !== 200) {
      caller.cancellationsPageRejected(serverError);
      return;
    }

    caller.cancellationsPageFulfilled(response.data);
  } catch (error) {
    caller.cancellationsPageRejected('Errore Interno');
  }
};

export const resetCancellations = () => {
  caller.cancellationsPageReset();
};

export const getCancellation = async (cancellationRequestId) => {
  caller.cancellationPagePending();

  const response = await managementAxe.get(
    `/broker/after-sale/cancellation/${cancellationRequestId}`,
  );

  if (response.status !== 200) {
    caller.cancellationPageRejected(serverError);
    return;
  }

  caller.cancellationPageFulfilled(response.data.data);
};

export const resetCancellation = () => {
  caller.cancellationPageReset();
};

// Replacements

export const listReplacements = async (page, searchData) => {
  caller.replacementsPagePending();

  try {
    const response = await managementAxe.get('/broker/after-sale/replacements', {
      params: {
        ...searchData,
        page,
      },
    });

    if (response.status !== 200) {
      caller.replacementsPageRejected(serverError);
      return;
    }
    caller.replacementsPageFulfilled(response.data);
  } catch (error) {
    caller.replacementsPageRejected('Errore Interno');
  }
};

export const resetReplacements = () => {
  caller.replacementsPageReset();
};

export const getReplacement = async (replacementRequestId) => {
  caller.replacementPagePending();

  const response = await managementAxe.get(
    `/broker/after-sale/replacement/${replacementRequestId}`,
  );

  if (response.status !== 200) {
    caller.replacementPageRejected(serverError);
    return;
  }
  caller.replacementPageFulfilled(response.data.data);
};

export const resetReplacement = () => {
  caller.replacementPageReset();
};

// Modifications

export const listModifications = async (page, searchData) => {
  caller.modificationsPagePending();

  try {
    const response = await managementAxe.get('/broker/after-sale/modifications', {
      params: {
        page,
        ...searchData,
      },
    });

    if (response.status !== 200) {
      caller.modificationsPageRejected(serverError);
      return;
    }

    caller.modificationsPageFulfilled(response.data);
  } catch (error) {
    caller.modificationsPageRejected('Errore Interno');
  }
};

export const resetModifications = () => {
  caller.modificationsPageReset();
};

export const getModification = async (modificationRequestId) => {
  caller.modificationPagePending();

  const response = await managementAxe.get(
    `/broker/after-sale/modification/${modificationRequestId}`,
  );

  if (response.status !== 200) {
    caller.modificationPageRejected(serverError);
    return;
  }

  caller.modificationPageFulfilled(response.data.data);
};

export const resetModification = () => {
  caller.modificationPageReset();
};

// Send Documents

export const listSendDocuments = async (page, searchData) => {
  caller.sendDocumentsPagePending();

  try {
    const response = await managementAxe.get(
      '/broker/after-sale/sent-documents',
      {
        params: {
          page,
          ...searchData,
        },
      },
    );

    if (response.status !== 200) {
      caller.sendDocumentsPageRejected(serverError);
      return;
    }

    caller.sendDocumentsPageFulfilled(response.data);
  } catch (error) {
    caller.sendDocumentsPageRejected('Errore Interno');
  }
};

export const resetSendDocuments = () => {
  caller.sendDocumentsPageReset();
};

export const resetSendDocumentsModal = () => {
  caller.sendDocumentsReset();
};

export const getDocumentTypes = async (type) => {
  caller.getDocumentTypesPending();

  try {
    const response = await managementAxe.get(`/broker/after-sale/get-document-types/${type}`);

    if (response.status !== 200) {
      caller.getDocumentTypesRejected(serverError);
      return;
    }

    caller.getDocumentTypesFulfilled(response.data);
  } catch (error) {
    caller.getDocumentTypesRejected(serverError);
  }
};

export const addNewSuspension = async (
  quotationId,
  data,
  navigation,
) => {
  caller.suspensionPending();

  const response = await managementAxe.post(
    `/broker/after-sale/suspension/quotation/${quotationId}`,
    data,
  );

  if (response.status !== 200) {
    caller.suspensionRejected(serverError);
    return;
  }

  caller.suspensionFulfilled();
  navigation(`/after-sale/purchase/${response.data.data.after_sale_type}/${response.data.data.after_sale_id}`);
};

// Suspensions

export const listSuspensions = async (page, searchData) => {
  caller.suspensionsPageRejected();

  try {
    const response = await managementAxe.get('/broker/after-sale/suspensions', {
      params: {
        page,
        ...searchData,
      },
    });

    if (response.status !== 200) {
      caller.suspensionsPageRejected(serverError);
      return;
    }

    caller.suspensionsPageFulfilled(response.data);
  } catch (error) {
    caller.suspensionsPageRejected('Errore Interno');
  }
};

export const resetSuspensions = () => {
  caller.suspensionsPageReset();
};

export const getSuspension = async (suspensionRequestId) => {
  caller.suspensionPagePending();

  const response = await managementAxe.get(
    `/broker/after-sale/suspension/${suspensionRequestId}`,
  );

  if (response.status !== 200) {
    caller.suspensionPageRejected(serverError);
    return;
  }

  caller.suspensionPageFulfilled(response.data.data);
};

export const resetSuspension = () => {
  caller.suspensionPageRejected();
};

export const listAfterSales = async () => {
  caller.listAfterSalesPending();
  try {
    const res = await managementAxe.get('/broker/after-sale/in-progress/list');

    if (res.status !== 200) {
      caller.listAfterSalesReject('Errore Interno');
      return;
    }
    caller.listAfterSalesSuccess(res.data);
  } catch (error) {
    caller.listAfterSalesReject('Errore Interno');
  }
};

export const resetListAfterSales = () => {
  caller.listAfterSalesReset();
};
