import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { generalAlertError, generalAlertErrorToast } from '../../common/utils/alerts';

import generateFiscalCode from '../../common/helpers/generateFiscalCode';
import { greeniaPrivacyPolicy, modifyRequestDataBackoffice } from '../../services/axios-client/axeCommons';
import { homeSchema } from './BackofficeHomeFormFlow.validations';
import { Layout } from './components/Layout/Layout';
import InputPrivacy from '../../common/components/inputs/InputPrivacy';
import { FormInputDate } from '../../common/formInputs/FormInputDate';
import FormReactSelectHouseProvinceInput from '../../common/formInputs/FormReactSelectHouseProvinceInput';
import { FormInputText } from '../../common/formInputs/FormInputText';
import { genders, houseTypes, ownerTypes } from '../../common/constants';
import { FormInputSelect } from '../../common/formInputs/FormInputSelect';
import { FormInputAddressComplete } from '../../common/formInputs/FormInputAddressComplete';
import { FormInputAddressCompleteManual } from '../../common/formInputs/FormInputAddressCompleteManual';
import FormReactSelectInputBirthPlace from '../../common/formInputs/FormReactSelectInputBirthPlace';
import getMinAllowedBirthday from '../../common/helpers/getMinAllowedBirthday';
import { FormInputRadio } from '../../common/formInputs/FormInputRadio';
import { HomeFormStyles } from '../HouseProduct/styles/HomeFormFlow.styles';
import { CenteredDivTitle } from '../../common/components/CenteredPageTitle.styles';
import CenteredPageTitle from '../../common/components/CenteredPageTitle';
import { houseGetInitialData, resetHouseForm } from '../../features/forms/formsActions';
import { houseSubmitAnswers } from '../../features/formsBackoffice/formsBackofficeActions';
import QuoteGenerateLoading from '../../common/components/elements/QuoteGenerateLoading';

function BackOfficeHome() {
  const navigate = useNavigate();
  const { requestToken } = useParams();

  const [showManualAdress, setShowManualAdress] = useState(false);

  const skipCheckGenderRef = useRef(null);

  const state = useSelector((store) => store.forms.house);
  const {
    loading,
    states,
    municipalities,
    formData,
    dataPrivacy,

  } = state;
  const {
    handleSubmit,
    register,
    formState: { errors, touchedFields },
    setValue,
    trigger,
    getValues,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(homeSchema),
    shouldFocusError: true,
    defaultValues: {
      product: 'casa',
      source: 'greenia.it',
      policy_effective_date: '',
      privacies: [],
      name: '',
      surname: '',
      email: '',
      fiscal_code: '',
      residence_province_code: '',
      address: '',
      house_number: '',
      postal_code: '',
      phone: '',
      gender: '',
      date_of_birth: '',
      country_of_birth_code: 'Z000',
      province_of_birth_code: null,
      commune_of_birth_code: null,
      born_abroad: false,
      residence_commune_code: '',
      active_customer_id: null,
      existing_customer: false,
      owner_typology: '',
      house_commune_code: '',
      house_province_code: '',
      square_meters: '',
      house_type: '',
      business_name: '',
      vat_number: '',
      company_type: null,
    },
  });

  const formDataFlow = watch();

  const updateFormData = (data) => {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      setValue(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    });
    trigger(keys);
  };

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  const privacyItemChangeHandler = (value, item) => {
    if (!value.target.checked) {
      const newArray = [...formDataFlow.privacies];
      const index = newArray.indexOf(item.id);
      newArray.splice(index, 1);
      updateFormData({
        privacies: newArray,
      });
      return;
    }
    const answersNewArray = [...formDataFlow.privacies];
    answersNewArray.push(item.id);
    updateFormData({
      privacies: answersNewArray,
    });
  };

  //   const closeClientModal = () => {
  //     setSearchClient('');
  //     houseUpdateFormData({
  //       selectedClient: null,
  //       selectedClientOldData: null,
  //       selectedClientId: null,
  //       searchResults: [],
  //     });
  //   };

  //   const getCustomerData = async (customerId) => {
  //     houseGetCustomerData(customerId);
  //   };

  //   const searchActiveClient = (searchClient) => () => {
  //     if (searchClient.length === 0) return;
  //     houseSearchActiveClient(searchClient);
  //   };

  //   useDebouncedEffect(searchActiveClient(searchClient), [searchClient], 400);

  useEffect(() => {
    houseGetInitialData();

    return () => {
      resetHouseForm();
    };
  }, []);

  //   useEffect(() => {
  //     if (location.state !== null && location.state.activeCustomerId !== undefined) {
  //       getCustomerData(location.state.activeCustomerId);
  //     }
  //   }, []);

  const getFiscalCode = (birthDate) => {
    const d = birthDate.split('-');
    const fData = {
      name: formDataFlow.name,
      surname: formDataFlow.surname,
      gender: formDataFlow.gender,
      day: d[2],
      month: d[1],
      year: d[0],
    };
    if (formDataFlow.born_abroad) {
      fData.birthplace = states.filter(
        (s) => s.state_code === formDataFlow.country_of_birth_code,
      )[0].name;
      fData.birthplaceProvincia = 'EE';
    } else {
      fData.birthplace = municipalities.filter(
        (municipality) => municipality.cadastral_code === formDataFlow.commune_of_birth_code,
      )[0].name;
      fData.birthplaceProvincia = formDataFlow.province_of_birth_code;
    }
    return generateFiscalCode(fData);
  };

  const validateDataPrivacy = () => {
    let { error, message } = '';

    dataPrivacy.forEach((privacy) => {
      if (privacy.required === 1) {
        const userDataArray = { ...formDataFlow }; // make a separate copy of the object
        const index = userDataArray.privacies.indexOf(privacy.id);

        if (index < 0) {
          error = true;
          message = 'Tutti i campi sono obbligatori! Tutta la privacy con la stella deve essere controllata';
        }
      }
    });
    return { error, message };
  };

  const submitForm = (formDataFlow) => {
    const { error, message } = validateDataPrivacy();

    if (error) {
      generalAlertError(message);
      return;
    }

    const _userData = { ...formDataFlow };

    if (_userData.born_abroad && _userData.country_of_birth_code === 'Z000') {
      window.scrollTo(0, 0);
      generalAlertErrorToast('Non puoi selezionare Italia come stato estero!');
      return;
    }

    if (_userData.born_abroad && (_userData.country_of_birth_code === null || _userData.country_of_birth_code === '' || _userData.country_of_birth_code == undefined)) {
      window.scrollTo(0, 0);
      generalAlertErrorToast('Devi selezionare uno stato estero!');
      return;
    }

    if (formDataFlow.date_of_birth !== '' && formDataFlow.date_of_birth !== null) {
      _userData.date_of_birth = moment(formDataFlow.date_of_birth).format('DD-MM-YYYY');
    } else {
      _userData.date_of_birth = null;
    }
    _userData.policy_effective_date = moment(formDataFlow.policy_effective_date).format('YYYY-MM-DD');
    _userData.fiscal_code = formDataFlow.gender !== 'G' ? getFiscalCode(formDataFlow.date_of_birth) : null;
    delete _userData.existing_customer;
    houseSubmitAnswers(_userData, navigate, requestToken);
  };

  useEffect(() => {
    if (skipCheckGenderRef.current === true) {
      skipCheckGenderRef.current = false;
      return;
    }

    const triggerValidation = {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    };

    if (formDataFlow.gender === 'G') {
      setValue('date_of_birth', null);
      setValue('country_of_birth_code', null);
      setValue('province_of_birth_code', null);
      setValue('commune_of_birth_code', null);
      setValue('born_abroad', false);
      setValue('name', null);
      setValue('surname', null);
    } else if (['M', 'F'].includes(formDataFlow.gender) && formDataFlow.date_of_birth === null) {
      setValue('date_of_birth', '', triggerValidation);
      setValue('country_of_birth_code', 'Z000', triggerValidation);
      setValue('province_of_birth_code', null, triggerValidation);
      setValue('commune_of_birth_code', null, triggerValidation);
      setValue('born_abroad', false, triggerValidation);
      setValue('name', '', triggerValidation);
      setValue('surname', '', triggerValidation);
    }
  }, [formDataFlow.gender, formDataFlow.date_of_birth]);

  useEffect(() => {
    const values = getValues();

    if (values !== undefined) {
      const objectKeys = Object.keys(values);

      objectKeys.forEach((key) => {
        if (values[key] !== '' && values[key] !== null) {
          setValue(key, values[key], {
            shouldTouch: true,
            shouldDirty: true,
            shouldValidate: true,
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    const callAsync = async () => {
      const {
        data: {
          data: { userData },
        },
      } = await modifyRequestDataBackoffice(requestToken);
      updateFormData(userData);
    };

    callAsync();
  }, []);

  if (loading) {
    return <QuoteGenerateLoading />;
  }
  return (
    <Layout>
      <HomeFormStyles className="w-50 mx-auto" onSubmit={handleSubmit(submitForm)}>
        <CenteredDivTitle className="d-flex align-items-center justify-content-center gap-3">
          <CenteredPageTitle title="Completa i dati" />
        </CenteredDivTitle>

        <div className="form-container ">
          <FormInputRadio
            options={genders}
            label="Il contraente è"
            name="gender"
            registration={register('gender')}
            error={errors.gender?.message}
          />

          <div>
            {formDataFlow.gender === 'G' ? (
              <div>
                <FormInputText
                  placeholder="Ragione Sociale"
                  label="Ragione Sociale"
                  registration={register('business_name')}
                  error={errors.business_name?.message}
                  valid={isValid('business_name')}
                />
                <FormInputText
                  placeholder="Partita Iva"
                  label="Partita Iva"
                  registration={register('vat_number')}
                  error={errors.vat_number?.message}
                  valid={isValid('vat_number')}
                />
                <FormInputSelect
                  label="Tipologia azienda"
                  registration={register('company_type')}
                  error={errors.company_type?.message}
                  valid={isValid('company_type')}
                  placeholder="-Seleziona-"
                  options={formData.company_types}
                />
              </div>
            ) : (
              <div>
                <FormInputText
                  placeholder="Nome"
                  label="Nome"
                  registration={register('name')}
                  error={errors.name?.message}
                  valid={isValid('name')}
                />
                <FormInputText
                  placeholder="Cognome"
                  label="Cognome"
                  registration={register('surname')}
                  error={errors.surname?.message}
                  valid={isValid('surname')}
                />
                <FormInputDate
                  label="Data di nascita"
                  registration={register('date_of_birth')}
                  minDate={getMinAllowedBirthday()}
                  maxDate="2021-01-01"
                  error={errors.date_of_birth?.message}
                  valid={isValid('date_of_birth')}
                />
                <FormReactSelectInputBirthPlace
                  states={states}
                  options={municipalities}
                  label="Luogo di nascita"
                  name="commune_of_birth_code"
                  values={{
                    commune_of_birth_code: formDataFlow.commune_of_birth_code,
                    province_of_birth_code: formDataFlow.province_of_birth_code,
                    born_abroad: formDataFlow.born_abroad,
                    country_of_birth_code: formDataFlow.country_of_birth_code,
                  }}
                  onChange={(value) => updateFormData({
                    commune_of_birth_code: value.commune_of_birth_code,
                    province_of_birth_code: value.province_of_birth_code,
                    born_abroad: value.born_abroad,
                    country_of_birth_code: value.country_of_birth_code,
                  })}
                  valid={isValid('commune_of_birth_code')}
                  error={errors.commune_of_birth_code?.message}
                  valid_country_of_birth_code={isValid('country_of_birth_code')}
                  error_country_of_birth_code={errors.country_of_birth_code?.message}
                />
              </div>
            )}

          </div>
          <FormInputText
            registration={register('email')}
            label="E-mail"
            error={errors.email?.message}
            valid={isValid('email')}
          />
          <FormInputText
            registration={register('phone')}
            label="Phone"
            paragraph="Numeri di rete fissa non accettati dalle aziende"
            error={errors.phone?.message}
            valid={isValid('phone')}
          />
          {showManualAdress ? (
            <FormInputAddressCompleteManual
              municipalities={municipalities}
              formData={formDataFlow}
              isValid={isValid}
              errors={errors}
              register={register}
              updateFormData={updateFormData}
            />
          ) : (
            <FormInputAddressComplete
              municipalities={municipalities}
              formData={formDataFlow}
              isValid={isValid}
              errors={errors}
              register={register}
              updateFormData={updateFormData}
              addressId="autocomplete-address-contractor"
              setShow={setShowManualAdress}
            />
          )}

          <FormInputSelect
            label="Tipo di abitazione"
            registration={register('house_type')}
            error={errors.house_type?.message}
            valid={isValid('house_type')}
            placeholder="-Seleziona-"
            options={houseTypes}
          />

          <FormInputSelect
            label="Sei Propietario?"
            registration={register('owner_typology')}
            error={errors.owner_typology?.message}
            valid={isValid('owner_typology')}
            placeholder="-Seleziona-"
            options={ownerTypes}
          />

          <FormInputText
            placeholder="Superficie abitazione"
            label="Superficie abitazione"
            registration={register('square_meters')}
            error={errors.square_meters?.message}
            valid={isValid('square_meters')}
          />

          <FormReactSelectHouseProvinceInput
            label="Provincia Abitazione"
            options={municipalities}
            values={{
              house_commune_code: formDataFlow.house_commune_code,
              house_province_code: formDataFlow.house_province_code,
            }}
            valid={isValid('house_province')}
            onChange={(val) => {
              updateFormData({
                house_commune_code: val.house_commune_code,
                house_province_code: val.house_province_code,
              });
            }}
          />

          <FormInputDate
            label="Data Decorrenza"
            registration={register('policy_effective_date')}
            minDate={moment().add(7, 'days').format('YYYY-MM-DD')}
            error={errors.policy_effective_date?.message}
            valid={isValid('policy_effective_date')}
          />
        </div>

        <div>
          <h5 className="text-center">Informativa Privacy e IVASS</h5>

          <div className="text-center text-decoration-underline mt-2">
            <a
              onClick={() => greeniaPrivacyPolicy()}
              style={{ cursor: 'pointer' }}
            >
              <small className="text-center">Greenia Privacy Policy</small>
            </a>
          </div>
          {dataPrivacy.map((privacy, i) => (
            <InputPrivacy
              required={privacy.required}
              label={privacy.content}
              name={privacy.type}
              id={privacy.type}
              key={i}
              checked={formDataFlow?.privacies?.includes(privacy.id)}
              onChange={(value) => privacyItemChangeHandler(value, privacy)}
            />
          ))}
        </div>

        <div className="footer-buttons button-travel-step">
          <div className="d-flex justify-content-between">
            <button type="submit" className="btn btn-questionnaire">
              Vai a preventivo
            </button>
          </div>
        </div>
      </HomeFormStyles>
    </Layout>
  );
}

export default BackOfficeHome;
