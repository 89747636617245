import React from 'react';
import { guideType, maxBasedBrokerId } from '../../../../common/constants';
import {
  PreventivassCardStyles, LeftCol, LogoContainer, LogoPriceContainer, PriceContainer, QuoteInformationContainer, QuoteInformationItem, RightCol, AquistaButtonsContainer, AquistaButton, GuaranteesList, GuaranteesListItem, SelectedGuaranteesContainer, Price,
} from '../../../../common/components/redesignComponents/Cards/PreventivassCard.styles';
import { BACKEND_BASE_URL } from '../../../../config';
import { SavedButton } from '../../../../common/components/redesignComponents/Cards/common/Buttons.styles';

export function PreventivassCardLinearAndDallbogg(props) {
  const {
    quotation,
    requestInfo,
    selectQuotation,
  } = props;

  // const processQuote = async (quotationId) => {
  //   purchaseVehicleQuotation(quotationId, refresh);
  // };

  return (
    <PreventivassCardStyles className={quotation.is_aquista === 1 ? 'selected' : ''}>
      <LeftCol>
        <LogoPriceContainer>
          <LogoContainer>
            <img
              src={BACKEND_BASE_URL + quotation.company.logo}
              alt={`${quotation.company.name} Logo`}
            />
          </LogoContainer>
          <PriceContainer>
            <Price>
              <h5>
                Prezzo annuo:
              </h5>
              <h2>
                &euro;
                {Number(quotation.amount).toLocaleString('it-IT', {
                  minimumFractionDigits: 2,
                })}
              </h2>
            </Price>
          </PriceContainer>
        </LogoPriceContainer>
        <QuoteInformationContainer>
          <QuoteInformationItem>
            <h6>
              Compagnia:
            </h6>
            <p>
              {quotation.company.name}
            </p>
          </QuoteInformationItem>
          <QuoteInformationItem>
            <h6>
              Emissione:
            </h6>
            <p>
              Tradizionale
            </p>
          </QuoteInformationItem>
          <QuoteInformationItem>
            <h6>
              Guida:
            </h6>
            <p>
              {guideType.find((v) => v.id === requestInfo.guide_type).name}
            </p>
          </QuoteInformationItem>
          <QuoteInformationItem>
            <h6>
              Frequenza pagamento:
            </h6>
            <p>
              {quotation.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}
            </p>
          </QuoteInformationItem>
        </QuoteInformationContainer>
        <QuoteInformationContainer>
          <QuoteInformationItem>
            <h6>
              Risarcimento:
            </h6>
            <p>
              Rc con
              {' '}
              <b>Risarcimento</b>
              {' '}
              <br />
              {' '}
              <b>Diretto</b>
              {' '}
              e
              {' '}
              <b>RFS</b>
            </p>
          </QuoteInformationItem>
          <QuoteInformationItem>
            <h6>
              Massimale:
            </h6>
            <p>
              <b>
                {maxBasedBrokerId[requestInfo.broker_agent_id] ? maxBasedBrokerId[requestInfo.broker_agent_id][0] : "6'450'000"}
                &euro;
              </b>
              {' '}
              Danni a persone
              <br />
              <b>{maxBasedBrokerId[requestInfo.broker_agent_id] ? maxBasedBrokerId[requestInfo.broker_agent_id][0] : "1'050'000"}</b>
              {' '}
              Danni a cose
            </p>
          </QuoteInformationItem>
        </QuoteInformationContainer>
      </LeftCol>
      <RightCol>
        <SelectedGuaranteesContainer>
          <h2>
            <b>Garanzie</b>
            {' '}
            Aggiunte
          </h2>
          <GuaranteesList>
            <GuaranteesListItem>
              <h6>
                <span />
                {' '}
                Responsabilità civile
              </h6>
              <p>
                &euro;
                {Number(quotation.amount).toLocaleString('it-IT', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </GuaranteesListItem>
          </GuaranteesList>
        </SelectedGuaranteesContainer>
        <AquistaButtonsContainer>
          {requestInfo.processing_quotation_id !== quotation.id ? (
            <AquistaButton
              onClick={() => selectQuotation(quotation.id)}
              className="result-moto-nine-step purchase-quote-nine-step"
            >
              Seleziona
            </AquistaButton>
          ) : requestInfo.processing_quotation_id === quotation.id && <SavedButton>Aggiunto</SavedButton>}
        </AquistaButtonsContainer>
      </RightCol>
    </PreventivassCardStyles>
  );
}
