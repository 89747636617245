import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { PurchasedQuotationsStyles } from '../PurchasedQuotations/PurchasedQuotations.styles';
import { generalAlertError } from '../../common/utils/alerts';
import {
  listFastQuotes,
  resetListFastQuotes,
} from '../../features/insuranceRequests/insuranceRequestsActions';
import Pagination from '../../common/components/elements/Pagination';
import ErrorComponent from '../../common/components/ErrorComponent';
import {
  getProductNameById,
} from '../../common/constants';

function FastQuotesPage() {
  const state = useSelector(
    (store) => store.insuranceRequests.fast_quotes_list,
  );

  const {
    loading, fast_quotes, page_number, total_pages, error,
  } = state;

  const [search, setSearch] = useState({
    search: '',
    date_from: moment().startOf('month').format('YYYY-MM-DD'),
    date_to: moment().format('YYYY-MM-DD'),
  });

  const searchHandler = (e) => {
    e.preventDefault();

    if (search.date_from !== '' && search.date_to === '') {
      generalAlertError('Devi selezionare anche data al');
      return;
    }

    if (search.date_from === '' && search.date_to !== '') {
      generalAlertError('Devi selezionare anche data da');
      return;
    }

    const trimmedSearch = {
      ...search,
      search: search.search.trim(),
    };

    listFastQuotes(1, trimmedSearch);
  };

  const handleChange = ({ target: { value, name } }) => {
    setSearch({ ...search, [name]: value });
  };

  useEffect(() => {
    listFastQuotes(1, search);
    return () => {
      resetListFastQuotes();
    };
  }, []);

  const getUrlTag = (product) => {
    if (product === 1) {
      return 'auto';
    }
    if (product === 4) {
      return 'profession';
    }
    if (product === 10) {
      return 'commercial';
    }

    return product;
  };

  const getItemLink = (fast_quote) => {
    if (fast_quote.vat_number != null) {
      return (
        <Link
          to={`/quote/${getUrlTag(fast_quote.product_id)}/${fast_quote.vat_number}/report`}
        >
          <span className="badge btn-to-quote px-4 py-2 rounded-3">
            Vai a preventivi
          </span>
        </Link>
      );
    }
    return (
      <Link
        to={`/quote/${getUrlTag(fast_quote.product_id).toLocaleLowerCase()}/${fast_quote.id}`}
      >
        <span className="badge btn-to-quote px-4 py-2 rounded-3">
          Vai a preventivi
        </span>
      </Link>
    );
  };
  return error === null ? (
    <PurchasedQuotationsStyles>
      <h5 className="mb-4 mt-3 mt-lg-0 ">Fast Quotes</h5>
      <div className="d-flex flex-md-row flex-column-reverse">
        <div className="col-xl-8 col-md-7 col-lg-8 ">
          <div className="mt-3 not-saved-quotations-one">
            {loading ? (
              <div className="d-flex justify-content-center align-items-center flex-column">
                <div className="spinner-border" role="status" />
                <span>Attendere prego...</span>
              </div>
            ) : (
              <ul className="list-group ">
                {fast_quotes.length > 0 ? (
                  fast_quotes.map((fast_quote, index) => (
                    <li
                      key={index}
                      className="not-saved-quotations-two list-group-item d-flex flex-sm-row flex-column justify-content-between align-items-start align-items-sm-center"
                    >
                      <div className="ms-1 me-auto mb-2 mt-1 mt-sm-0 mb-sm-0 ">
                        <>
                          Prodotto:
                          {getProductNameById(fast_quote.product_id)}
                        </>
                        {fast_quote.vehicle_plate && (
                          <>
                            <br />
                            Targa:
                            {' '}
                            {fast_quote.vehicle_plate}
                          </>
                        )}
                        {fast_quote.vat_number && (
                          <>
                            <br />
                            Partita IVA:
                            {' '}
                            {fast_quote.vat_number}
                          </>
                        )}
                        <>
                          <br />
                          Totale:
                          {' '}
                          {`${parseFloat(fast_quote.total_price).toFixed(2)} €`}
                        </>
                        {/* //todo check when we finish with profession flow */}
                        {/* {fast_quote.profession_desc && (
                          <>
                            <br />
                            Professione:
                            {' '}
                            {fast_quote.profession_desc}
                          </>
                        )} */}
                        <br />
                        <small>
                          <strong>
                            Creato:
                            {new Date(
                              fast_quote.created_at,
                            ).toLocaleDateString('it-IT', {
                              day: '2-digit',
                              month: '2-digit',
                              year: 'numeric',
                            })}
                          </strong>
                        </small>
                      </div>
                      {getItemLink(fast_quote)}
                    </li>
                  ))
                ) : (
                  <li className="list-group-item d-flex justify-content-between align-items-start not-saved-quotations-no-result">
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Nessuna richiesta trovata!</div>
                    </div>
                  </li>
                )}
              </ul>
            )}
          </div>
          <div className="mt-2">
            <Pagination
              total_pages={total_pages}
              current_page={page_number}
              onClick={listFastQuotes}
              search={search}
            />
          </div>
        </div>
        <div className="col-lg-3 col-md-5 col-xl-3 p-3 quotation-filters not-saved-quotations-three">
          <form
            onSubmit={searchHandler}
            className="d-flex align-items-end justify-content-center flex-column"
          >
            <input
              type="text"
              name="search"
              className="search-input form-control mb-2"
              value={search.search}
              onChange={handleChange}
              placeholder="Cerca per targa o prodotto"
            />

            <div className="w-100">
              <label htmlFor="date_from">Data creazione da</label>
              <input
                type="date"
                name="date_from"
                onChange={handleChange}
                value={search.date_from}
                className="date-input form-control mb-2"
                placeholder="Data creazione da"
                lang="it-IT"
              />
            </div>
            <div className="w-100">
              <label htmlFor="date_to">Data creazione al</label>
              <input
                type="date"
                name="date_to"
                className="date-input form-control mb-0"
                onChange={handleChange}
                value={search.date_to}
                min={search.date_from}
                placeholder="Data creazione al"
                lang="it-IT"
              />
            </div>
            <button type="submit" className="search-btn mt-2">
              Cerca
            </button>
          </form>
        </div>
      </div>
    </PurchasedQuotationsStyles>
  ) : (
    <ErrorComponent error={error} />
  );
}

export default FastQuotesPage;
