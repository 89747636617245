import React, { useRef } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { UppyAccidentsContainer } from './Uploader.styles';
import uploadIcon from '../../../../assets/redesign_icons/upload-icon.svg';
import { confirmAlert, generalAlertError } from '../../../utils/alerts';

const schema = yup.object({
  files: yup.array().of(
    yup.object({
      original_filename: yup.string().required('Nome e obligatorio'),
      content: yup.string().required('File e obigatorio!'),
      file_type: yup.string().nullable(),
    }),
  ),
});

function AccidentsUploader({ uploadDocuments, accidentId }) {
  const {
    setValue,
    control,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      files: [
        {
          original_filename: '',
          content: '',
          file_type: '',
        },
      ],
    },
  });

  const { append, remove } = useFieldArray({
    control,
    name: 'files',
  });

  const fileInputRefs = useRef({});
  const formData = watch();
  const handleFileChange = (event, index) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onloadend = () => {
      const data = {
        file_type: `${reader.result.split(',')[0]},`,
        original_filename: file.name,
        content: reader.result,
      };

      setValue(`files.${index}`, data, { shouldValidate: true });
    };

    reader.readAsDataURL(file);
  };

  const confirmDocumentsAndSend = () => {
    if (formData.files.length === 1 && formData.files[0].content === '') {
      generalAlertError('Deve caricare al meno un file!');
      return;
    }

    if (formData.files.length > 10) {
      generalAlertError('Massimo file permesi sono 10!');
      return;
    }

    uploadDocuments(accidentId, formData.files);
  };

  return (
    <div className="row g-2">
      {formData.files.map((field, index) => (
        <div className="col-4 position-relative" key={index}>
          <UppyAccidentsContainer icon={uploadIcon}>
            <Controller
              control={control}
              name={`files.${index}.content`}
              render={() => (
                <>
                  {/* File Input (Hidden) */}
                  <input
                    type="file"
                    accept="image/jpeg,image/png,application/pdf"
                    ref={(el) => {
                      fileInputRefs.current[index] = el;
                    }}
                    style={{ display: 'none' }}
                    onChange={(e) => handleFileChange(e, index)}
                  />

                  {/* Show either the uploaded file or the file upload input */}
                  <div
                    style={{
                      border: '2px dashed gray',
                      padding: '20px',
                      textAlign: 'center',
                      borderRadius: '15px',
                      cursor: 'pointer',
                      height: '200px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      background: field.content ? '#f8f9fa' : 'transparent',
                    }}
                    onClick={() => !field.content && fileInputRefs.current[index]?.click()}
                  >
                    {field.content ? (
                      <p>{field.original_filename}</p>
                    ) : (
                      <>
                        <img src={uploadIcon} width={50} className="mb-3" alt="" />
                        <p>Sfoglia Per Caricare File</p>
                      </>
                    )}
                  </div>
                </>
              )}
            />
          </UppyAccidentsContainer>

          {/* Remove Button */}
          <button type="button" className="btn btn-danger mt-2 top-0 end-0 position-absolute me-4" onClick={() => remove(index)}>
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      ))}

      {/* Add More Files Button */}
      {formData.files.length < 10 && (
        <div className="col-4">
          <div className="d-flex align-items-center justify-content-start h-100">
            <button type="button" className="btn btn-success d-flex align-items-center justify-content-center" onClick={() => append({ original_filename: '', content: '', file_type: '' })}>
              <FontAwesomeIcon icon={faPlus} color="white" size="lg" />
              <p className="m-0 ms-2">Aggiungi</p>
            </button>
          </div>
        </div>
      )}
      <div className="col-12">
        <div className="mt-3">
          <button onClick={() => confirmAlert('Hai finito di aggiungere tutti i file?', () => confirmDocumentsAndSend())} className="btn btn-success px-5 fw-bold" style={{ fontSize: '18px' }}>Invia</button>
        </div>
      </div>
    </div>
  );
}

export default AccidentsUploader;
