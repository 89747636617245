import React from 'react';
import {
  BoxWrapper,
  ClientBox,
} from './CommonPurchase.styles';
import { guideType, primaQuoteTypes, quotationTypes } from '../../../../constants';
import MessagesModal from '../../../elements/MessagesModal';
import { apiDownloadDocument } from '../../../../../services/axios-client/axeCommons';

function InfoBoxMoto({
  insuranceRequest,
  statues,
  quoteStatus,
  messagesModalData,
  rc_quotation,
  risk_quotations,
}) {
  const doc = insuranceRequest.documents.find((doc) => doc.document_type_id === 34);

  const downloadDocument = async (documentId) => {
    if (documentId === undefined) {
      return;
    }
    const response = await apiDownloadDocument(documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const getTotal = () => {
    let primaGuaranteesPrices = 0;
    let risksTotalAmount = 0;

    if (risk_quotations && risk_quotations.length > 0) {
      for (let i = 0; i < risk_quotations.length; i++) {
        const item = risk_quotations[i];

        risksTotalAmount += rc_quotation.payment_frequency === 1 ? parseFloat(item.amount) : parseFloat(item.amount / rc_quotation.payment_frequency);
      }
    }

    if (rc_quotation.company_id === 2) {
      rc_quotation.guarantees.forEach((guarantee) => {
        primaGuaranteesPrices += rc_quotation.payment_frequency === 1 ? parseFloat(guarantee.amount) : parseFloat(guarantee.amount / rc_quotation.payment_frequency) || 0;
      });
    }

    const rcTotalAmount = rc_quotation.payment_frequency === 1 ? rc_quotation.amount : rc_quotation.amount / rc_quotation.payment_frequency;

    return parseFloat(rcTotalAmount + primaGuaranteesPrices + rc_quotation.brokerage_amount + risksTotalAmount).toFixed(2);
  };

  return (
    <>
      <BoxWrapper>
        <div className="row gap-2 gap-xl-0">
          <div className="col-12 col-xl-4">
            <ClientBox>
              <h6>Cliente:</h6>
              <h5>
                {insuranceRequest.customer.gender !== 'G'
                  ? `${insuranceRequest.customer.name
                  } ${insuranceRequest.customer.surname}`
                  : insuranceRequest.customer.business_name}
              </h5>
              <h6 className="mt-2">Veicolo:</h6>
              <h5>
                {insuranceRequest.moto.version.full_description}
              </h5>
              <h6 className="mt-2">Veicolo:</h6>
              <h5>
                {insuranceRequest.moto.moto_plate}
              </h5>
              <h6 className="mt-2">Decorrenza:</h6>
              <h5>
                {new Date(
                  insuranceRequest.policy_effective_date,
                ).toLocaleDateString('it-IT')}
              </h5>
              <h6 className="mt-2">Tipo Di Guida:</h6>
              <h5>
                {guideType.find((v) => v.id === insuranceRequest.guide_type).name}
              </h5>
              <h6 className="mt-2">Frequenza Pagamento</h6>
              <h5>
                {rc_quotation.payment_frequency === 1 ? 'Annuale' : 'Semestrale'}
              </h5>
              <h6 className="mt-2">Status:</h6>
              <h5>
                {statues.find((st) => st.id === quoteStatus).name}
              </h5>

              {rc_quotation.link_to_endpoint
                && <a href={rc_quotation.link_to_endpoint} className="mt-2 btn btn-success" target="_blank" rel="noreferrer">Paga Ora</a>}

              <button className="mt-2 btn btn-success" onClick={() => downloadDocument(doc.id)}>
                Scarica Documento Da Firmare
              </button>
            </ClientBox>
          </div>
          <div className="col-12 col-xl-8">
            <ClientBox>
              <h4>Informazioni Prodotti</h4>
              <hr />
              <div className="table table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Prodotto</th>
                      <th scope="col">Compagnia</th>
                      <th scope="col">Frequenza Pagamento</th>
                      <th scope="col">Premio</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{rc_quotation.product.name}</td>
                      <td>
                        {rc_quotation.company_id !== null ? rc_quotation.company.name : rc_quotation.preventivas_company.company_name}
                      </td>
                      <td>{rc_quotation.payment_frequency === 1 ? 'Annuale' : 'Semestrale'}</td>
                      <td>
                        {rc_quotation.payment_frequency === 1 ? parseFloat(rc_quotation.amount).toFixed(2) : parseFloat(rc_quotation.amount / rc_quotation.payment_frequency).toFixed(2)}
                        {/* {parseFloat(rc_quotation.amount).toFixed(2)} */}
                        {' '}
                        &euro;
                      </td>
                    </tr>
                    {(rc_quotation !== null && rc_quotation.company_id === 2) && (
                      rc_quotation.guarantees.map((guarantee, index) => (
                        <tr key={index}>
                          <td>{primaQuoteTypes.find((type) => type.id === guarantee.guarantees_slug).name}</td>
                          <td>
                            Prima.it
                          </td>
                          <td>{rc_quotation.payment_frequency === 1 ? 'Annuale' : 'Semestrale'}</td>
                          <td>
                            {' '}
                            {rc_quotation.payment_frequency === 1 ? parseFloat(guarantee.amount).toFixed(2) : parseFloat(guarantee.amount / rc_quotation.payment_frequency).toFixed(2)}
                            {/* {parseFloat(guarantee.amount).toFixed(2)} */}
                            {' '}
                            €
                          </td>
                        </tr>
                      ))
                    )}
                    {risk_quotations
                      && risk_quotations.map((quote, index) => (
                        <tr key={index}>
                          <td>
                            {
                              quotationTypes.find(
                                (type) => type.id === quote.quote_type,
                              ).name
                            }
                          </td>
                          <td>{quote.company.name}</td>
                          <td>
                            {quote.payment_frequency === 1
                              ? 'Annuale'
                              : 'Semestrale'}
                          </td>
                          <td>
                            {rc_quotation.payment_frequency === 1 ? parseFloat(quote.amount).toFixed(2) : parseFloat(quote.amount / rc_quotation.payment_frequency).toFixed(2)}
                            {' '}
                            &euro;
                          </td>
                        </tr>
                      ))}
                    <tr>
                      <td>Brokeraggio</td>
                      <td />
                      <td>
                        {rc_quotation.payment_frequency === 1
                          ? 'Annuale'
                          : 'Semestrale'}
                      </td>
                      <td>
                        {parseFloat(rc_quotation.brokerage_amount).toFixed(2)}
                        {' '}
                        &euro;
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td className="fw-bold">
                        Totale
                      </td>
                      <td />
                      <td />
                      <td className="fw-bold">
                        &euro;
                        {' '}
                        {getTotal()}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              {/* <hr />
              <ContactBox
                as="button"
                data-bs-toggle="modal"
                data-bs-target="#messages-modal"
              >
                <img src={contactSupport} alt="Conttato Suporto" />
                {insuranceRequest.threads.length > 0 && insuranceRequest.threads[0].messages.filter((mes) => mes.read_by_broker === 0).length > 0 ? <MessageNumber>{insuranceRequest.threads[0].messages.filter((mes) => mes.read_by_broker === 0).length}</MessageNumber> : ''}
                <h5>{insuranceRequest.threads.length > 0 ? 'Apri Chat' : 'Apri Nuova Chat'}</h5>
              </ContactBox> */}
            </ClientBox>
          </div>
        </div>
      </BoxWrapper>
      <MessagesModal
        {...messagesModalData}
        threads={insuranceRequest.threads}
      />
    </>
  );
}

export default InfoBoxMoto;
