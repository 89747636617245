import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import Joyride from 'react-joyride';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { addAccidentNote, getAccident, resetShowAccident } from '../../features/accidents/accidentsActions';
import PageLoading from '../../common/components/elements/PageLoading';
import axe from '../../services/axios-client/axiosClient';

import ProvidersMap from '../../common/components/ProvidersMap';
import FileUpload from '../../common/components/inputs/FileUpload';
import { apiAfterSaleOptionalDocumentMethodManualUpload, apiDownloadAfterSaleOptionalDocument } from '../../services/axios-client/axeCommons';
import { generalAlertError, generalAlertSuccessToast } from '../../common/utils/alerts';
// import { useTourContext } from '../../context/useTourContext';
import ErrorComponent from '../../common/components/ErrorComponent';
import AfterSaleToSignDocumentComponent from '../../common/components/redesignComponents/Cards/AfterSaleToSignDocumentComponent';
import { QuoteInfoContainerAfterSale, WaitingForPaymentQuoteInfoAfterSale } from '../../common/components/redesignComponents/Purchase/PaymentStatusAfterSale.styles';
import AfterSaleDocumentComponent from '../../common/components/redesignComponents/Cards/AfterSaleDocumentComponent';
import { after_sale_purchase_statues } from '../../common/constants';
// import { joyrideOptions } from '../../common/utils/joyrideUtils';
// import { handleCallbackAccident } from '../../common/utils/joyrideCallbacks';
// import { accidentSteps } from '../../common/utils/joyrideSteps';

const schema = yup.object({
  note: yup.string().required('Questo campo e obligatorio'),
});

function AccidentPage() {
  const navigate = useNavigate();
  const { accidentId } = useParams();
  const {
    register, formState: { errors }, handleSubmit, reset,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      note: '',
    },
  });
  const state = useSelector((store) => store.accidents.accident_show);
  const {
    loading, accident, accidentFiles, error,
  } = state;

  // const {
  //   setState,
  //   state: { run, showBeacon },
  // } = useTourContext();

  // useEffect(() => {
  //   if (localStorage.getItem('singleAccident') === 'done') {
  //     setState({ run: false });
  //   } else {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 1500);
  //   }
  // }, [localStorage.getItem('singleAccident')]);

  // const fireNewQuoteTour = () => {
  //   localStorage.removeItem('singleAccident');
  //   setState({ run: true, tourActive: true });
  // };

  const addNote = (data) => {
    addAccidentNote(accidentId, data);
    reset({ note: '' });
    getAccident(accidentId);
  };

  useEffect(() => {
    getAccident(accidentId);
    return () => {
      resetShowAccident();
    };
  }, []);

  const downloadDocument = async (documentId) => {
    const response = await axe.get(`/document/${documentId}/download`);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const downloadOptionalDocument = async (documentId) => {
    const response = await apiDownloadAfterSaleOptionalDocument(documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const showAfterSaleUploadedDocuments = () => {
    const docs = [
      ...accident.after_sale_optional_documents,
    ];

    return (
      <>

        <div className="ps-3 mt-3">
          {docs.length === 0 && (
            <p>Nessun documento caricato.</p>
          )}

          {docs.map((doc) => (
            <AfterSaleToSignDocumentComponent key={doc.id} document={doc} downloadDocument={downloadOptionalDocument} />
          ))}

        </div>

      </>
    );
  };

  const uploadSignedOptionalDocument = async (documentId, file) => {
    const uploadedFile = file[0];
    const reader = new FileReader();
    reader.readAsDataURL(uploadedFile);
    if (uploadedFile.type !== 'application/pdf') {
      generalAlertError('Formato di file errato. Il file deve essere in .pdf');
      return;
    }
    reader.onload = () => {
      apiAfterSaleOptionalDocumentMethodManualUpload(documentId, {
        document: reader.result.replace('data:application/pdf;base64,', ''),
      }).then(() => {
        getAccident(accidentId);
        generalAlertSuccessToast('File caricato');
      });
    };
  };

  if (loading) {
    return <PageLoading />;
  }

  if (accident && accident.status_id !== 5) {
    navigate(`/after-sale/purchase/accident/${accident.id}`);
  }

  return (error === null || error === undefined)
    ? (
      <>
        {/* <Joyride
          steps={accidentSteps}
          continuous
          showBeacon={showBeacon}
          locale={{
            back: 'Indietro',
            close: 'Chiudi',
            last: 'Fine',
            next: 'Prossima',
            open: 'Apri il modale',
            restart: 'Riavvia il tour ',
            skip: 'Salta',
          }}
          styles={{
            options: joyrideOptions,

            buttonNext: {
              backgroundColor: '#00a651',
              outline: 'none',
            },
            buttonBack: {
              color: '#00a651',
              outline: 'none',
            },
            beaconInner: {
              backgroundColor: '#00a651',
            },
            beaconOuter: {
              borderColor: '#00a651',
              backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
            },
            buttonSkip: {
              outline: 'none',
            },
            buttonClose: {
              outline: 'none',
            },
          }}
          run={run}
          callback={(data) => handleCallbackAccident(data, accidentSteps, setState)}
          showSkipButton
          showProgress
        /> */}
        <div className="d-flex gap-2">
          <div className="btn btn-success btn-sm text-white px-4 mb-4" onClick={() => navigate('/accidents')}>Torna indietro</div>
          {/* {localStorage.getItem('singleAccident') === 'done' && (
            <div style={{ cursor: 'pointer' }} onClick={() => fireNewQuoteTour()}>
              <button className="btn btn-success btn-sm text-white px-4 mb-4">Tutorial</button>
            </div>
          )} */}
        </div>

        <div className="row">
          <div className="col-12 col-md-6 mt-3 mt-md-0">
            <WaitingForPaymentQuoteInfoAfterSale>
              <QuoteInfoContainerAfterSale>
                <h3 className="ps-3">
                  <strong>
                    Sinistro #
                    {accident.id}
                  </strong>
                </h3>
                <div className="single-accident-one ps-3 mb-4 mt-0">

                  {accident.quotation.active_customer && (
                    <div>
                      <p className="my-1">
                        <strong>   Cliente:</strong>
                        {' '}
                        <span>
                          {accident.quotation.active_customer.gender === 'G' ? accident.quotation.active_customer.business_name : `${accident.quotation.active_customer.name} ${accident.quotation.active_customer.surname}`}

                        </span>
                      </p>
                    </div>
                  )}
                  {accident.quotation.insurance_request.product
                    && (
                      <div>
                        <p className="my-1">
                          <strong>   Prodotto:</strong>
                          {' '}
                          <span>
                            {accident.quotation.insurance_request.product.name}
                            {' '}
                            {accident.quotation.insurance_request.product_id == 5 ? `(${accident.quotation.insurance_request.infortuni && accident.quotation.insurance_request.infortuni.company})` : ''}

                          </span>
                        </p>
                      </div>

                    )}
                  {accident.quotation.insurance_request.vehicle
                    && (

                      <div>
                        <p className="my-1">
                          <strong>  Targa:</strong>
                          {' '}
                          <span>
                            {accident.quotation.insurance_request.vehicle.vehicle_plate}
                          </span>
                        </p>
                      </div>

                    )}

                  <div>
                    <p className="my-1">
                      <strong> Stato:</strong>
                      {' '}
                      <div className="badge bg-success">{after_sale_purchase_statues.find((status) => status.id == accident.status_id)?.name}</div>
                    </p>
                  </div>
                  {accident.quotation.insurance_request.professional
                    && (
                      <div>
                        <p className="my-1">
                          <strong>  Professione:</strong>
                          {' '}
                          <span>
                            {accident.quotation.insurance_request.professional.profession_desc}
                          </span>
                        </p>
                      </div>

                    )}
                  {accident.quotation.insurance_request.infortuni
                    && (
                      <div>
                        <p className="my-1">
                          <strong>  Infortuni:</strong>
                          {' '}
                          <span>
                            {accident.quotation.insurance_request.infortuni.pack_type}
                            {' '}
                            -
                            {' '}
                            {accident.quotation.insurance_request.infortuni.name}
                          </span>
                        </p>
                      </div>

                    )}
                  <div>
                    <p className="my-1">
                      <strong> Creato:</strong>
                      {' '}
                      <span>
                        {new Date(accident.created_at).toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                      </span>
                    </p>
                  </div>

                </div>
              </QuoteInfoContainerAfterSale>
            </WaitingForPaymentQuoteInfoAfterSale>
          </div>

        </div>

        <div className="row mt-3">
          <div className="col-12">
            <WaitingForPaymentQuoteInfoAfterSale>
              <QuoteInfoContainerAfterSale>
                <div className="single-accident-two">
                  <h3 className="ps-3">
                    <strong>
                      Note
                    </strong>
                  </h3>
                  {accident.notes.length
                    ? accident.notes.map((note, index) => (
                      <p key={index} className="mx-3 p-2 mt-3 bg-light shadow-sm">{note.note}</p>
                    ))
                    : <p>Nessuna nota trovata!</p>}

                </div>
                <form onSubmit={handleSubmit(addNote)} className="single-accident-three px-3">
                  <div className="mb-3 ">
                    <label className="form-label" htmlFor="note">Aggiungi nuova nota</label>
                    <textarea name="note" className={`form-control ${errors.note?.message ? 'is-invalid' : ''}`} rows="5" {...register('note')} />
                    <div className="invalid-feedback">
                      {errors.note?.message}
                    </div>
                    <button type="submit" className="btn btn-success px-4 mt-2">Invia</button>
                  </div>
                </form>
                {
                  accident.amount !== null
                  && (
                    <>
                      <h3 className="mt-4">Ammontare</h3>
                      €
                      {' '}
                      {Number(accident.amount).toLocaleString('it-IT', { minimumFractionDigits: 2 })}
                    </>
                  )
                }

              </QuoteInfoContainerAfterSale>
            </WaitingForPaymentQuoteInfoAfterSale>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12 col-md-6 ">
            <WaitingForPaymentQuoteInfoAfterSale>
              <QuoteInfoContainerAfterSale>
                <div className="single-accident-four">
                  <h3 className="ps-3">
                    <strong>
                      Documenti
                    </strong>
                  </h3>

                  <div className="ps-3 mt-3">
                    {accidentFiles.length !== 0
                      ? accidentFiles.map((accidentFile) => (

                        <AfterSaleDocumentComponent key={accidentFile.id} document={accidentFile.document} downloadDocument={downloadDocument} />

                      )) : <p>Nessun Documento Trovato</p>}
                  </div>
                </div>
              </QuoteInfoContainerAfterSale>
            </WaitingForPaymentQuoteInfoAfterSale>
          </div>
          <div className="col-12 col-md-6">
            <WaitingForPaymentQuoteInfoAfterSale>
              <QuoteInfoContainerAfterSale>
                <h3 className="ps-3">
                  <strong>
                    Documenti Da Firmare
                  </strong>
                </h3>
                <div>
                  {showAfterSaleUploadedDocuments()}
                </div>
                <div className="col-12 mt-3 mt-xl-2 d-flex single-cancellation-three">
                  <div className="col-6 mt-3 mt-xl-0">
                    <div className="card ps-3">
                      <div className="card-body px-0">
                        {accident.after_sale_optional_documents.filter((document) => document.signable_document === 1 && document.is_uploaded_valid === 1 && ((document.is_signed_valid === 0 && document.signed_document_filename !== null) || (document.is_signed_valid === null && document.signed_document_filename === null))).map((file) => (

                          <div className="mb-3">
                            <FileUpload
                              key={file.id}
                              multiple={false}
                              label={`CARICA ${file.optional_document_name.toUpperCase()}`}
                              handleFile={(f) => uploadSignedOptionalDocument(file.id, f)}
                            />
                          </div>

                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </QuoteInfoContainerAfterSale>
            </WaitingForPaymentQuoteInfoAfterSale>
          </div>
        </div>

        <div className="row mt-3 mt-xl-0 d-flex single-accident-five" />
        <div className="row single-accident-six">
          <h3 className="mt-3">
            <strong>
              Detagli
            </strong>
          </h3>
          <div>
            <label className="custom-label mb-1">Indirizzo</label>
            <input
              name="address"
              id="autocomplete"
              placeholder="Inserisci Indirizzo..."
              className={`form-control ${errors.address?.message ? 'is-invalid' : ''} `}
              value={accident?.address}
              disabled
            />
          </div>
          <div className="invalid-feedback">
            {errors.address?.message}
          </div>
          <ProvidersMap mainLatitude={accident.latitude} mainLongitude={accident.longitude} providers={accident.providers} />
        </div>

      </>
    )
    : <ErrorComponent error={error} />;
}

export default AccidentPage;
