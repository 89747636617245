import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faDownload, faXmark } from '@fortawesome/free-solid-svg-icons';
import pdfIMG from '../../../../assets/images/purchase/pdf.png';
import { AfterSaleDocumentListItem } from '../Purchase/PaymentStatusAfterSale.styles';

function AfterSaleToSignDocumentComponent({ downloadDocument, document }) {
  return (
    <AfterSaleDocumentListItem>
      <div className="d-flex align-items-center justify-content-between mt-2">
        <div>
          <p className="fw-bold mb-2">
            <small>
              {document.optional_document_name}
            </small>
          </p>
          <div className="d-flex align-items-center justify-content-start">
            <div className="d-flex align-items-center">
              <div>
                {
                  document.is_signed_valid === 1 ? (
                    <div
                      className="badge rounded-circle text-bg-success d-flex align-items-center justify-content-center me-2"
                      style={{ width: '30px', height: '30px' }}
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </div>
                  ) : (
                    ''
                  )
                }

                {document.is_signed_valid === 0 && (
                  <div
                    className="badge rounded-circle text-bg-danger d-flex align-items-center justify-content-center me-2"
                    style={{ width: '30px', height: '30px' }}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </div>
                )}
              </div>
              <img src={pdfIMG} className="img-fluid me-2" width="30" alt="PDF" />
            </div>
            <div>
              <h5 className="h6 mb-0">
                {document.original_filename}
              </h5>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center flex-column">
          {document.is_uploaded_valid === null && document.signable_document === 0 ? (
            <span className="badge text-bg-warning rounded-pill p-2 mb-2">
              Documento in verifica
            </span>
          ) : (
            ''
          )}
          {document.is_uploaded_valid === 1 && document.signable_document === 0 ? (
            <span className="badge text-bg-success rounded-pill p-2 mb-2">
              Valido
            </span>
          ) : (
            ''
          )}
          {document.signable_document === 1 && document.signed_document_filename === null && document.is_signed_valid === null ? (
            <span className="badge text-bg-warning rounded-pill p-2 mb-2">
              In Attesa Firma
            </span>
          ) : (
            ''
          )}
          {document.signable_document === 1 && document.signed_document_filename !== null && document.is_signed_valid === null ? (
            <span className="badge text-bg-warning rounded-pill p-2 mb-2">
              Firma in verifica
            </span>
          ) : (
            ''
          )}
          {document.signable_document === 1 && document.is_signed_valid === 1 ? (
            <span className="badge text-bg-success rounded-pill p-2 mb-2">
              Firmato
            </span>
          ) : (
            ''
          )}
          {document.signable_document === 1 && document.is_signed_valid === 0 ? (
            <span className="badge text-bg-danger rounded-pill p-2 mb-2">
              Firma non valida
            </span>
          ) : (
            ''
          )}
          <button
            className="btn btn-success"
            onClick={() => downloadDocument(document.id)}
          >
            <FontAwesomeIcon icon={faDownload} size="lg" />
          </button>
        </div>
      </div>
    </AfterSaleDocumentListItem>
  );
}

export default AfterSaleToSignDocumentComponent;
