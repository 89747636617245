import React from 'react';
import { Link } from 'react-router-dom';
// import Joyride from 'react-joyride';
import { PostVenditaSection } from './SelectPostVenditaAction.styles';
import AfterSalesList from './AfterSalesList/AfterSalesList';
// import { useTourContext } from '../context/useTourContext';
// import { joyrideOptions } from '../common/utils/joyrideUtils';
// import { handleCallbackPostVenditaAction } from '../common/utils/joyrideCallbacks';
// import { selectPostVenditaSteps } from '../common/utils/joyrideSteps';

function SelectPostVenditaAction() {
  return (
    <>
      <PostVenditaSection className="container mt-5 mb-4">
        {/* <Joyride
          steps={selectPostVenditaSteps}
          continuous
          showBeacon={showBeacon}
          locale={{
            back: 'Indietro',
            close: 'Chiudi',
            last: 'Fine',
            next: 'Prossima',
            open: 'Apri il modale',
            restart: 'Riavvia il tour ',
            skip: 'Salta',
          }}
          styles={{
            options: joyrideOptions,
            buttonNext: {
              backgroundColor: '#00a651',
              outline: 'none',
            },
            buttonBack: {
              color: '#00a651',
              outline: 'none',
            },
            beaconInner: {
              backgroundColor: '#00a651',
            },
            beaconOuter: {
              borderColor: '#00a651',
              backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
            },
            buttonSkip: {
              outline: 'none',
            },
            buttonClose: {
              outline: 'none',
            },
            overlay: {
              height: '100vh',
            },
          }}
          run={run}
          callback={(data) => { handleCallbackPostVenditaAction(data, selectPostVenditaSteps, setState); }}
          showSkipButton
          showProgress
        />
        {localStorage.getItem('postVendita') === 'done' && (
          <div style={{ cursor: 'pointer', top: '8%' }} onClick={() => fireNewQuoteTour()} className="position-fixed end-0 mt-1 me-3">
            <button className="btn btn-success px-2 py-1 rounded-3">Tutorial</button>
          </div>
        )} */}
        <h2 className="h2 text-center text-success">Cosa Vuoi Fare?</h2>

        <hr />
        <div className="d-flex align-items-stretch align-items-lg-center text-center justify-content-center flex-column flex-md-row w-100 mx-auto mt-5 mb-3 gap-4">
          <Link
            to="/after-sale"
            className=" rounded-3  research-class px-5 py-2 post-vendita-one"
          >
            Apri Nuovo Ticket
          </Link>
          <Link
            to="/after-sale/select"
            className="rounded-3 research-class px-5 py-2 post-vendita-two"
          >
            Gestisci
          </Link>
        </div>
      </PostVenditaSection>
      <AfterSalesList className="mt-4" />
    </>
  );
}

export default SelectPostVenditaAction;
