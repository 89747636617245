import React, { useRef, useState } from 'react';
import {
  LeftCol,
  LogoPriceContainer,
  QuotationCardResultStyles,
} from './QuotationCardResult.styles';
import LogoComponentResult from './smallerComponents/LogoComponentResult';
import PriceComponentResult from './smallerComponents/PriceComponentResult';
import QuoteInfoComponentResult from './smallerComponents/QuoteInfoComponentResult';
import { Chat } from '../components/chats/MiniChat/MiniChat';
import SparklesIcon from '../../assets/images/icons/SparklesIcon';

function QuotationCardResult({ processingQuote, requestInfo, profession }) {
  const chatRef = useRef();
  const [openChat, setOpenChat] = useState(false);
  const [extraText, setExtraText] = useState('');

  return (
    <QuotationCardResultStyles
      className={processingQuote.is_aquista === 1 ? 'selected' : ''}
    >
      <LeftCol>
        {processingQuote.company_id === 9
          && requestInfo.payment_frequency === 2 && (
            <div className="alert alert-warning mt-1 mb-2 p-1 w-100">
              <b>Attenzione:</b>
              {' '}
              Linear non supporta il pagamento semestrale.
            </div>
        )}
        <LogoPriceContainer>
          {/* LOGO Component */}
          <LogoComponentResult quote={processingQuote} />
          {/* Price Component */}
          <PriceComponentResult quote={processingQuote} />
          <button
            onClick={() => setOpenChat(true)}
            className="open-chat-button border-0  text-white bg-success rounded p-1"
          >
            <SparklesIcon width={40} height={40} />
          </button>
          <Chat
            ref={chatRef}
            openChat={openChat}
            setOpenChat={setOpenChat}
            company_id={
              processingQuote.company
                ? processingQuote.company_id
                : processingQuote.preventivas_company_id
            }
            product_id={processingQuote.product_id}
            quote_type={processingQuote.quote_type}
            company_name={
              processingQuote.company
                ? processingQuote.company.name
                : processingQuote.preventivas_company.company_name
            }
            extraText={extraText}
            professionId={profession?.profession}
          />
        </LogoPriceContainer>
        <QuoteInfoComponentResult
          quote={processingQuote}
          requestInfo={requestInfo}
          profession={profession}
          setExtraText={setExtraText}
        />
      </LeftCol>
    </QuotationCardResultStyles>
  );
}
export default QuotationCardResult;
