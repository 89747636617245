import React from 'react';
import { guideType, maxBasedBrokerId } from '../../../constants';
import {
  PreventivassCardStyles, LeftCol, LogoContainer, LogoPriceContainer, PriceContainer, QuoteInformationContainer, QuoteInformationItem, RightCol, AquistaButton, AquistaButtonsContainer, SelectedGuaranteesContainer, Price, CompanyInfoElements, CompanyInformationContainer,
} from './PreventivassCard.styles';
import { BACKEND_BASE_URL } from '../../../../config';
import { SavedButton } from './common/Buttons.styles';
import preventiveIcon from '../../../../assets/redesign_icons/preventivo-icon.svg';
import preContractIcon from '../../../../assets/redesign_icons/pre-contract-icon.svg';
import { apiDownloadManuallyAddedQuotationDocument } from '../../../../services/axios-client/axeCommons';

// const Storage = new StorageService();

export function PreventivassCard(props) {
  // const navigate = useNavigate();
  const {
    quotation,
    requestInfo,
    selectQuotation,
  } = props;

  // const processQuote = (quotation_id) => {
  //   Storage.removeVehicleQuoteAnswers();
  //   Storage.removeVehicleOwnerData();
  //   Storage.removeVehicleDriverData();

  //   if (quotation.product_id == 1) {
  //     navigate(`/quotes/auto/purchase/${quotation_id}`);
  //   }

  //   if (quotation.product_id == 3) {
  //     navigate(`/quotes/autocarro/purchase/${quotation_id}`);
  //   }
  // };

  const downloadDocument = async (document_id) => {
    const response = await apiDownloadManuallyAddedQuotationDocument(document_id);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.data.contents;
    alink.download = response.data.data.data.filename;
    alink.click();
  };

  return (
    <PreventivassCardStyles className={quotation.is_aquista === 1 ? 'selected' : ''}>
      <LeftCol>
        {quotation.preventivas_company_quality && (
          <div className="row mb-3">
            <div className="col-12">
              <div className={`p-2 badge ${quotation.preventivas_company_quality.color === 'green' ? 'bg-success' : quotation.preventivas_company_quality.color === 'yellow' ? 'bg-warning' : 'bg-danger'}`}>
                <p className="mb-0 d-flex align-items-center justify-content-between gap-2">
                  Indice Di Qualità:
                  {' '}
                  <b className="fs-6">{parseFloat(quotation.preventivas_company_quality.coefficiente).toFixed(2)}</b>
                </p>
              </div>
            </div>
          </div>
        )}
        <LogoPriceContainer>
          <LogoContainer>
            <img src={`${BACKEND_BASE_URL}/preventivass/companies/${quotation.preventivas_company.logo_filename}`} alt={`${quotation.preventivas_company.company_name} Logo`} />
          </LogoContainer>
          <PriceContainer>
            <Price>
              <h5>
                Prezzo annuo:
              </h5>
              <h2>
                &euro;
                {Number(quotation.amount).toLocaleString('it-IT', {
                  minimumFractionDigits: 2,
                })}
              </h2>
            </Price>
            {quotation.payment_frequency === 2 && (
              <Price>
                <h5>
                  Prezzo Semestrale:
                </h5>
                <h6>
                  Primo Pagamento
                </h6>
                <h2>
                  &euro;
                  {(Number(quotation.amount) / quotation.payment_frequency).toLocaleString('it-IT', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </h2>
              </Price>
            )}
          </PriceContainer>
        </LogoPriceContainer>
        {(quotation.manually_added_quotation_documents && quotation.manually_added_quotation_documents.length > 0) && (
          <CompanyInformationContainer>
            {quotation.manually_added_quotation_documents.map((doc) => (
              <CompanyInfoElements onClick={() => downloadDocument(doc.id)}>
                <img src={doc.type === 'preventive' ? preventiveIcon : preContractIcon} alt="" />
                <p>{doc.type === 'preventive' ? 'Preventivo' : 'Informazioni Precontrattuali'}</p>
              </CompanyInfoElements>
            ))}
          </CompanyInformationContainer>
        )}
        <QuoteInformationContainer>
          <QuoteInformationItem>
            <h6>
              Compagnia:
            </h6>
            <p>
              {quotation.preventivas_company.company_name}
            </p>
          </QuoteInformationItem>
          <QuoteInformationItem>
            <h6>
              Emissione:
            </h6>
            <p>
              Tradizionale
            </p>
          </QuoteInformationItem>
          <QuoteInformationItem>
            <h6>
              Guida:
            </h6>
            <p>
              {guideType.find((v) => v.id === requestInfo.guide_type).name}
            </p>
          </QuoteInformationItem>
          <QuoteInformationItem>
            <h6>
              Frequenza pagamento:
            </h6>
            <p>
              {quotation.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}
            </p>
          </QuoteInformationItem>
        </QuoteInformationContainer>
        <QuoteInformationContainer>
          <QuoteInformationItem>
            <h6>
              Risarcimento:
            </h6>
            <p>
              Rc con
              {' '}
              <b>Risarcimento</b>
              {' '}
              <br />
              {' '}
              <b>Diretto</b>
              {' '}
              e
              {' '}
              <b>RFS</b>
            </p>
          </QuoteInformationItem>
          <QuoteInformationItem>
            <h6>
              Massimale:
            </h6>
            <p>
              <b>
                {maxBasedBrokerId[requestInfo.broker_agent_id] ? maxBasedBrokerId[requestInfo.broker_agent_id][0] : "6'450'000"}
                &euro;
              </b>
              {' '}
              Danni a persone
              <br />
              <b>{maxBasedBrokerId[requestInfo.broker_agent_id] ? maxBasedBrokerId[requestInfo.broker_agent_id][0] : "1'050'000"}</b>
              {' '}
              Danni a cose
            </p>
          </QuoteInformationItem>
        </QuoteInformationContainer>
      </LeftCol>
      <RightCol>
        <SelectedGuaranteesContainer>
          {/* <h2>
            <b>Garanzie</b>
            {' '}
            Aggiunte
          </h2> */}
          {/* <GuaranteesList>
            <GuaranteesListItem>
              <h6>
                <span />
                {' '}
                Responsabilità civile
              </h6>
              <p>
                &euro;
                {Number(quotation.amount).toLocaleString('it-IT', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </GuaranteesListItem>
          </GuaranteesList> */}
        </SelectedGuaranteesContainer>
        <AquistaButtonsContainer>
          {requestInfo.processing_quotation_id !== quotation.id ? (
            <AquistaButton
              onClick={() => selectQuotation(quotation.id)}
              className="purchase-quote-nine-step"
            >
              Seleziona
            </AquistaButton>
          ) : requestInfo.processing_quotation_id === quotation.id && <SavedButton>Aggiunto</SavedButton>}
        </AquistaButtonsContainer>
      </RightCol>
    </PreventivassCardStyles>
  );
}
