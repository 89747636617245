import Swal from 'sweetalert2';

import 'sweetalert2/dist/sweetalert2.min.css';

export const generalAlertSuccessToast = (message) => {
  Swal.fire({
    icon: 'success',
    title: 'Successo',
    toast: true,
    position: 'bottom-right',
    timer: 6000,
    timerProgressBar: true,
    showConfirmButton: false,
    text: message,
  });
};

export const generalAlertErrorToast = (message, zIndexEnabled = false) => {
  Swal.fire({
    icon: 'error',
    title: 'Errore',
    color: '#ffffff',
    background: '#FF8585',
    toast: true,
    position: 'bottom-right',
    timer: 6000,
    timerProgressBar: true,
    showConfirmButton: false,
    text: message,
    didOpen: () => {
      if (zIndexEnabled) {
        const container = document.querySelector('.swal2-container');
        if (container) {
          container.style.zIndex = '2001';
        }
      }
    },
  });
};

export const generalAlertSuccess = (message, action = null) => {
  if (action) {
    Swal.fire({
      icon: 'success',
      title: 'Successo',
      text: message,
    }).then(() => { action(); });
  } else {
    Swal.fire({
      icon: 'success',
      title: 'Successo',
      text: message,
    });
  }
};

export const generalAlertError = (message, errorTitle) => {
  Swal.fire({
    icon: 'error',
    title: errorTitle ?? 'Errore',
    text: message,
  });
};

export const confirmAlert = (message, action, cancelAction = () => { }, title = null, confirmButtonColor = '#00A651', zIndexEnabled = false) => {
  Swal.fire({
    title: title || 'Sei Sicuro?',
    text: message,
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor,
    confirmButtonText: 'Si, Sono Sicuro!',
    cancelButtonText: 'No, cancella!',
    closeOnConfirm: false,
    closeOnCancel: false,
    didOpen: () => {
      if (zIndexEnabled) {
        const container = document.querySelector('.swal2-container');
        if (container) {
          container.style.zIndex = '2200';
        }
      }
    },
  }).then((isConfirm) => {
    if (isConfirm.isConfirmed) {
      action();
    } else {
      cancelAction();
    }
  });
};

export const confirmAlertFastQuote = (message, action, secondAction) => {
  Swal.fire({
    title: 'Fast Quote',
    text: message,
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#00a651',
    confirmButtonText: 'Procedi',
    cancelButtonText: 'No',
    closeOnConfirm: false,
    closeOnCancel: false,
  }).then((isConfirm) => {
    if (isConfirm.isConfirmed) {
      action();
    }

    if (isConfirm.isDismissed) {
      secondAction();
    }
  });
};

export const confirmAlertForBackofficeBrokerQuote = (
  message,
  action,
  navigate,
  requestToken,
) => {
  Swal.fire({
    title: 'Sei sicuro di procedere?',
    text: message,
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#ff0000',
    confirmButtonText: 'Si, Sono Sicuro!',
    cancelButtonText: 'No, cancella!',
    closeOnConfirm: false,
    closeOnCancel: false,
  }).then((isConfirm) => {
    if (isConfirm.isConfirmed) {
      action();
    } else {
      navigate(`/quotes/${requestToken}`);
    }
  });
};

export const warningAlert = (message, action, title = null, zIndexEnabled = false) => {
  Swal.fire({
    title: title || 'Attenzione',
    text: message,
    type: 'warning',
    showCancelButton: false,
    confirmButtonColor: '#DD6B55',
    confirmButtonText: 'Ok',
    closeOnConfirm: true,
    didOpen: () => {
      if (zIndexEnabled) {
        const container = document.querySelector('.swal2-container');
        if (container) {
          container.style.zIndex = '2200';
        }
      }
    },
  }).then(() => {
    action();
  });
};
