import React from 'react';
import moment from 'moment';
import { BoxWrapper, ClientBox } from '../components/redesignComponents/Purchase/common/CommonPurchase.styles';
import { after_sale_purchase_statues, reasonForReplacement } from '../constants';

function AfterSaleInfoBox({ afterSale, afterSaleType }) {
  return (
    <BoxWrapper>
      <div className="row gap-2 gap-xl-0">
        <div className="col-12 col-xl-6">
          <ClientBox className="documents-status-step-one pagament-status-step-one">
            <h6>Cliente:</h6>
            <h5>
              {afterSale.quotation.insurance_request.active_customer.gender === 'G' ? afterSale.quotation.insurance_request.active_customer.business_name : `${afterSale.quotation.insurance_request.active_customer.name} ${afterSale.quotation.insurance_request.active_customer.surname}`}
            </h5>
            {afterSale.quotation.insurance_request.vehicle && (
              <>
                <h6 className="mt-2">Veicolo:</h6>
                <h5>{`${afterSale.quotation.insurance_request.vehicle.brand.name} ${afterSale.quotation.insurance_request.vehicle.model.name}`}</h5>
              </>
            )}
            {afterSale.quotation.insurance_request.moto && (
              <>
                <h6 className="mt-2">Moto:</h6>
                <h5>{`${afterSale.quotation.insurance_request.moto.brand.name} ${afterSale.quotation.insurance_request.moto.model.name}`}</h5>
              </>
            )}
            {afterSale.quotation.insurance_request.vehicle && (
              <>
                <h6 className="mt-2">Targa:</h6>
                <h5>{ afterSale.quotation.insurance_request.vehicle.vehicle_plate}</h5>
              </>
            )}
            {afterSale.quotation.insurance_request.moto && (
            <>
              <h6 className="mt-2">Targa:</h6>
              <h5>{afterSale.quotation.insurance_request.moto.moto_plate}</h5>
            </>
            )}

            <h6 className="mt-2">Prodotto:</h6>
            <h5>{afterSale.quotation.product.name}</h5>
            <h6 className="mt-2">Compagnia:</h6>
            <h5>{afterSale.quotation.company.name}</h5>

          </ClientBox>
        </div>
        <div className="col-12 col-xl-6">
          <ClientBox className="documents-status-step-two pagament-status-step-two">
            {afterSaleType === 'suspension' && (
              <>
                <h6>Suspension Date:</h6>
                <h5>
                  {moment(afterSale.suspension_date).format('DD/MM/YYYY')}
                </h5>

                <h6 className="mt-2">Riactivation Date:</h6>
                <h5>
                  {moment(afterSale.riactivation_date).format('DD/MM/YYYY')}
                </h5>
              </>
            )}
            {afterSaleType === 'cancellation' && (
              <>
                <h6>Date:</h6>
                <h5>
                  {moment(afterSale.date).format('DD/MM/YYYY')}
                </h5>

              </>
            )}

            {afterSale.address && (
              <>
                <h6>Address:</h6>
                <h5>{afterSale.address}</h5>
              </>
            )}

            <h6 className="mt-2">Status:</h6>
            <h5>{after_sale_purchase_statues.find((f) => (afterSale.status_id ? f.id === afterSale.status_id : f.id === afterSale.status)).name}</h5>
            {afterSaleType === 'cancellation' && reasonForReplacement.find((f) => f.id === afterSale.cancellation_reason) && (
              <>
                <h6 className="mt-2">Motivo:</h6>
                <h5>
                  {reasonForReplacement.find((f) => f.id === afterSale.cancellation_reason).name}
                </h5>
              </>
            )}
            {afterSaleType === 'replacement' && reasonForReplacement.find((f) => f.id === afterSale.replacement_reason) !== undefined && (
              <>
                <h6 className="mt-2">Motivo:</h6>
                <h5>
                  {reasonForReplacement.find((f) => f.id === afterSale.replacement_reason).name}
                </h5>
              </>
            )}

            {afterSale.note && (
              <>
                <h6 className="mt-2">Nota:</h6>
                <h5>{afterSale.note}</h5>
              </>
            )}
            {afterSaleType === 'accident' && afterSale.status_id == 1 && afterSale.notes && afterSale.notes.map((note) => (
              <>
                <h6 className="mt-2">Nota:</h6>
                <h5>{note.note}</h5>
              </>
            ))}

          </ClientBox>
        </div>
      </div>
    </BoxWrapper>
  );
}

export default AfterSaleInfoBox;
