import React from 'react';
import { LeftCol, LogoPriceContainer, PriceContainer } from './AdriaticCard.styles';
import {
  LogoResultContainer, PriceResult, QuoteInformationResultContainer, QuoteInformationResultItem,
} from '../../../../screens/BackOffice/results/cards/PrimaCard.styles';
import { BACKEND_BASE_URL } from '../../../../config';
import { guideType, maxBasedBrokerId } from '../../../constants';
import { PreventivassCardStyles } from './PreventivassCard.styles';

function PreventivassResultCard({ processingQuote, requestInfo }) {
  return (
    <PreventivassCardStyles className={processingQuote.is_aquista === 1 ? 'selected' : ''}>
      <LeftCol>
        <LogoPriceContainer>
          <LogoResultContainer>
            <img src={`${BACKEND_BASE_URL}/preventivass/companies/${processingQuote.preventivas_company.logo_filename}`} alt={`${processingQuote.preventivas_company.company_name} Logo`} />
          </LogoResultContainer>
          <PriceContainer>
            <PriceResult>
              <h5>
                Prezzo annuo:
              </h5>
              <h2>
                &euro;
                {Number(processingQuote.amount).toLocaleString('it-IT', {
                  minimumFractionDigits: 2,
                })}
              </h2>
            </PriceResult>
            {/* {quotation.payment_frequency === 2 && (
            <Price>
              <h5>
                Prezzo Semestrale:
              </h5>
              <h6>
                Primo Pagamento
              </h6>
              <h2>
                &euro;
                {(Number(quotation.amount) / quotation.payment_frequency).toLocaleString('it-IT', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </h2>
            </Price>
            )} */}
          </PriceContainer>
        </LogoPriceContainer>

        <QuoteInformationResultContainer>
          <QuoteInformationResultItem>
            <h6>
              Compagnia:
            </h6>
            <p>
              {processingQuote.preventivas_company.company_name}
            </p>
          </QuoteInformationResultItem>
          <QuoteInformationResultItem>
            <h6>
              Emissione:
            </h6>
            <p>
              Tradizionale
            </p>
          </QuoteInformationResultItem>
          <QuoteInformationResultItem>
            <h6>
              Guida:
            </h6>
            <p>
              {guideType.find((v) => v.id === requestInfo.guide_type).name}
            </p>
          </QuoteInformationResultItem>
          <QuoteInformationResultItem>
            <h6>
              Frequenza pagamento:
            </h6>
            {/* <p>
              {quotation.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}
            </p> */}
          </QuoteInformationResultItem>
        </QuoteInformationResultContainer>
        <QuoteInformationResultContainer>
          <QuoteInformationResultItem>
            <h6>
              Risarcimento:
            </h6>
            <p>
              Rc con
              {' '}
              <b>Risarcimento</b>
              {' '}
              <br />
              {' '}
              <b>Diretto</b>
              {' '}
              e
              {' '}
              <b>RFS</b>
            </p>
          </QuoteInformationResultItem>
          <QuoteInformationResultItem>
            <h6>
              Massimale:
            </h6>
            <p>
              <b>
                {maxBasedBrokerId[requestInfo.broker_agent_id] ? maxBasedBrokerId[requestInfo.broker_agent_id][0] : "6'450'000"}
                &euro;
              </b>
              {' '}
              Danni a persone
              <br />
              <b>{maxBasedBrokerId[requestInfo.broker_agent_id] ? maxBasedBrokerId[requestInfo.broker_agent_id][0] : "1'050'000"}</b>
              {' '}
              Danni a cose
            </p>
          </QuoteInformationResultItem>
        </QuoteInformationResultContainer>
      </LeftCol>
    </PreventivassCardStyles>
  );
}

export default PreventivassResultCard;
