import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Loader } from '@googlemaps/js-api-loader';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  AfterSaleCreateFormFlowStyles,
  AfterSaleCreateFormStyles,
} from '../../common/AfterSale/AfterSaleCreate.styles';
import { CenteredDivTitle } from '../../common/components/CenteredPageTitle.styles';
import { FormInputTextarea } from '../../common/formInputs/FormInputTextarea';
import { Label } from '../../common/formInputs/styles/common';
import { MapContainerStyles } from '../BrokersPage/NewBroker.styles';
import { addNewAccident } from '../../features/accidents/accidentsActions';

const schema = yup.object({
  note: yup.string().required('Il campo è obbligatorio'),
  address: yup.string().required('Il campo è obbligatorio').max(191),

});

function CreateAccident(location) {
  const { quoteId } = useParams();
  const navigate = useNavigate();

  const isOpenedByCommand = location.state !== undefined
    && location.state?.type === 'command'
    && location.state.data?.page === 'accident';
  const {
    register, formState: { errors, touchedFields }, trigger, watch, setValue,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
    values: isOpenedByCommand
      ? {
        note: location.state.data.note ?? '',
        address: location.state.data.address ?? '',
        latitude: 41.902782,
        longitude: 12.496366,
      }
      : {
        note: '',
        address: '',
        latitude: 41.902782,
        longitude: 12.496366,
      },
  });

  const answers = watch();

  const submitData = async (formData) => {
    const triggerValidation = await trigger(['note', 'address']);
    if (!triggerValidation) {
      return;
    }

    addNewAccident(quoteId, formData, navigate);
  };

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      version: 'weekly',
      libraries: ['places'],
    });

    loader.load()
      .then((google) => {
        const myLatLng = {
          lat: answers.latitude,
          lng: answers.longitude,
        };

        const map = new google.maps.Map(document.getElementById('map'), {
          center: myLatLng,
          zoom: 15,
        });

        new google.maps.Marker({
          position: myLatLng,
          map,
          title: 'Hello Greenia!',
        });

        const autocomplete = new google.maps.places.Autocomplete(document.getElementById('autocomplete'), {
          fields: ['formatted_address', 'geometry', 'name'],
          types: ['address'],
          componentRestrictions: { country: 'it' },
        });

        if (isOpenedByCommand && location.state.data.address !== '') {
          setTimeout(() => {
            document.getElementById('autocomplete').focus();
          }, 2000);
        }

        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();

          if (!place.geometry) {
            setValue('address', '');
          } else {
            const map = new google.maps.Map(document.getElementById('map'), {
              zoom: 15,
              center: place.geometry.location,
            });

            new google.maps.Marker({
              position: place.geometry.location,
              map,
            });

            setValue('address', place.formatted_address);
            setValue('latitude', place.geometry.location.lat());
            setValue('longitude', place.geometry.location.lng());
          }
        });
      });

    return () => {
      if (loader) {
        loader.reset();
        delete window.google;
        Loader.instance = undefined;
      }
    };
  }, []);

  return (
    <AfterSaleCreateFormFlowStyles>
      <AfterSaleCreateFormStyles>
        <CenteredDivTitle className="d-flex align-items-center justify-content-center gap-3 mt-3">
          <div className="after-sale-title divider">
            <h2 className="text-center">Completa i dati Sinistro</h2>
          </div>

        </CenteredDivTitle>

        <div className="form-container-after-sale">
          <FormInputTextarea
            placeholder="Note"
            label="Nota"
            registration={register('note')}
            rows="3"
            error={errors.note?.message}
            valid={isValid('note')}
          />
          <Label>Inserisci indirizzo del sinistro</Label>
          <input
            name="address"
            id="autocomplete"
            placeholder="Inserisci Indirizzo..."
            className={`form-control ${errors.address?.message ? 'is-invalid' : ''}`}
            {...register('address')}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
          />
          <div className="invalid-feedback">
            {errors.address?.message}
          </div>

        </div>
        <MapContainerStyles>
          <div id="map" />
        </MapContainerStyles>
        <div className="mt-3">
          <div className="d-flex justify-content-between">
            <button
              className="btn btn-questionnaire "
              onClick={() => submitData(answers)}
            >
              Continua
            </button>
          </div>
        </div>
      </AfterSaleCreateFormStyles>
    </AfterSaleCreateFormFlowStyles>
  );
}

export default CreateAccident;
