import styled from 'styled-components';

export const Label = styled.label`
  text-align: center;
  font-weight: bold;
  width: 100%;
  color: rgb(100, 100, 100);
`;

export const FormGroup = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;

  .invalid-feedback {
    text-align: center;
  }

  .sub-text {
    font-size: 13px;
  }

  #input-number {
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    -moz-appearance: textfield;
  }
`;

export const FormGroupAddress = styled.div`
  margin-top: 10px;
  /* margin-bottom: 10px; */

  .invalid-feedback {
    text-align: center;
  }

  .sub-text {
    font-size: 13px;
  }

  input.form-control{
    margin-bottom: 0;
  }

  #input-number {
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    -moz-appearance: textfield;
  }
`;

export const SelectContainer = styled.div`
  /* {
    border-width: 2px !important;

    &:hover {
      border-color: #cbcbcd !important;
    }
  } */
`;
