import { Range } from 'react-range';
import { useEffect, useRef, useState } from 'react';
import {
  AddGuaranteesButton, DefaultInfo, FormInputGroupBrokeragio, MarkupContainer, MarkupPrice, MarkupSlider, MarkupSliderContainer, MarkupTitle,
} from './WaitingForPaymentStatus.styles';
import warningIcon from '../../../../assets/redesign_icons/warning-icon.svg';

const STEP = 1;
const MIN = 0;

function BrokeraggioComponent({ rc_quotation, sendBrokerageAmount, getTotal }) {
  const [val, setVal] = useState({ values: [0] });
  const valRef = useRef(null);
  valRef.current = val;

  useEffect(() => {
    if (rc_quotation) {
      setVal({ values: [rc_quotation.brokerage_amount] });
    }
  }, [rc_quotation]);

  return (
    <>
      {rc_quotation.product_id !== 12
                && rc_quotation.product_id !== 11
                && rc_quotation.product_id !== 10 && (
                <MarkupContainer>
                  <div className="row">
                    <div className="col-12 col-xxl-8">
                      <MarkupTitle>
                        Aggiungi Brokeraggio
                      </MarkupTitle>
                      <DefaultInfo>
                        <img src={warningIcon} alt="" />
                        <p>
                          Fai attenzione perché il brokeraggio non
                          può essere modificato dopo che è stato
                          effettuato un pagamento.
                        </p>
                      </DefaultInfo>
                    </div>
                    <div className="d-flex align-items-center justify-content-start col-xxl-4">
                      <AddGuaranteesButton
                        onClick={() => sendBrokerageAmount(valRef.current.values[0])}
                      >
                        Aggiungi
                      </AddGuaranteesButton>
                    </div>
                  </div>
                  <FormInputGroupBrokeragio>
                    <label htmlFor="">Inserisci Brokeraggio</label>
                    <input
                      type="number"
                      placeholder="Inserisci il numero della carta"
                      value={val.values}
                      max={(getTotal() * 30) / 100}
                      onChange={(e) => {
                        const maxVal = (getTotal() * 30) / 100;
                        if (e.target.value > maxVal) {
                          setVal({
                            values: [parseInt(maxVal)],
                          });
                        } else {
                          setVal({
                            values: [
                              Math.floor(e.target.value * 100)
                                / 100,
                            ],
                          });
                        }
                      }}
                    />
                  </FormInputGroupBrokeragio>
                  <div className="row">
                    <div className="col-12">
                      <MarkupSliderContainer>
                        <MarkupPrice>&euro; 0.00</MarkupPrice>
                        <MarkupSlider>
                          <Range
                            values={val.values}
                            step={STEP}
                            min={MIN}
                            max={(getTotal() * 30) / 100}
                            onChange={(values) => {
                              const maxAllowed = (getTotal() * 30) / 100;
                              const amount = values[0] + 0.5;
                              if (maxAllowed < amount) {
                                setVal({
                                  values: [
                                    Math.floor(maxAllowed * 100)
                                        / 100,
                                  ],
                                });
                              } else {
                                setVal({
                                  values: [
                                    Math.floor(values * 100) / 100,
                                  ],
                                });
                              }
                            }}
                            renderTrack={({ props, children }) => (
                              <div
                                onMouseDown={props.onMouseDown}
                                onTouchStart={props.onTouchStart}
                                style={{
                                  ...props.style,
                                  height: '36px',
                                  display: 'flex',
                                  width: '95%',
                                  margin: '0 auto',
                                }}
                              >
                                <div
                                  className="range-responsive-slide"
                                  ref={props.ref}
                                  style={{
                                    height: '20px',
                                    width: '100%',
                                    borderRadius: '140px',
                                    alignSelf: 'center',
                                    backgroundColor: '#DFF3EA',
                                  }}
                                >
                                  {children}
                                </div>
                              </div>
                            )}
                            renderThumb={({ props }) => (
                              <div
                                className="range-responsive-circle"
                                {...props}
                                style={{
                                  ...props.style,
                                  height: '40px',
                                  width: '40px',
                                  borderRadius: '50%',
                                  backgroundColor: '#00A651',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  border: 'none',
                                  boxShadow: 'none',
                                  outline: 'none',
                                }}
                              >
                                <div
                                  className="range-responsive-text"
                                  style={{
                                    position: 'absolute',
                                    top: '-35px',
                                    width: '100px',
                                    color: '#00A551',
                                    textAlign: 'center',
                                    fontFamily: 'Poppins',
                                    fontSize: '20px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '24px',
                                  }}
                                >
                                  &euro;
                                  {' '}
                                  {Math.floor(val.values[0] * 100)
                                    / 100}
                                </div>
                              </div>
                            )}
                          />
                        </MarkupSlider>
                        <MarkupPrice>
                          &euro;
                          {' '}
                          {((getTotal() * 30) / 100).toFixed(2)}
                        </MarkupPrice>
                      </MarkupSliderContainer>
                    </div>
                  </div>
                </MarkupContainer>
      )}
    </>
  );
}

export default BrokeraggioComponent;
