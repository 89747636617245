import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
// import Joyride, { ACTIONS } from 'react-joyride';

import {
  BadgeOptional,
  OptionalDocumentBox,
  PurchaseStyles,
  RedirectToPrimaAlert,
  UploadDocumentsContainer,
  UploadWrapper,
} from './Purchase.styles';
import {
  deleteDocument,
  apiDownloadDocument,
  apiDeleteOptionalDocument,
  apiDownloadOptionalDocument,
} from '../../services/axios-client/axeCommons';
import PageLoading from '../../common/components/elements/PageLoading';
import {
  confirmAlert,
  confirmAlertForBackofficeBrokerQuote,
  generalAlertError,
  generalAlertSuccess,
} from '../../common/utils/alerts';
import {
  autoGetBackofficeUsers,
  confirmSelectedForBackofficeQuote,
  // continueWithSignature,
  purchaseInsuranceRequest,
  resetAutoPurchase,
  // skipSignature,
  updateBrokerage,
} from '../../features/purchases/purchasesActions';
import OptionalDocumentModal from '../../common/components/elements/OptionalDocumentModal';
import InfoBoxAuto from '../../common/components/redesignComponents/Purchase/common/InfoBoxAuto';
import uploadIcon from '../../assets/redesign_icons/upload-icon.svg';
import WaitingForPaymentStatus from '../../common/components/redesignComponents/Purchase/WaitingForPaymentStatus';
import { SaveBox } from './common/SaveBox';
import {
  NumberCurrent,
  SkipSignedDocs,
} from '../../common/components/redesignComponents/Purchase/SignatureType.styles';
import NonSignableDocumentComponent from './common/NonSignableDocumentComponent';
import NonSIgnableDocumentRequestComponent from './common/NonSIgnableDocumentRequestComponent';
import CompletedScreenAuto from '../../common/components/elements/PurchaseAuto/CompletedScreenAuto';
import BrokeraggioComponent from '../../common/components/redesignComponents/Purchase/BrokeraggioComponent';
import axe from '../../services/axios-client/axiosClient';
// import { useTourContext } from '../../context/useTourContext';

function PurchaseAutoRequest() {
  const { requestToken } = useParams();
  const navigate = useNavigate();
  const state = useSelector((store) => store.purchases.auto);
  const {
    loading,
    quote,
    insuranceRequest,
    quoteStatus,
    backofficeUsers,
    rc_quotation,
    nonSignableDocs,
    requestedOptionalDocumentsNonSignable,
    // signableDocs,
    // requestedOptionalDocumentsSignable,
    risk_quotations,
  } = state;

  const [showOptionalDocumentModal, setShowOptionalDocumentModal] = useState(false);
  const [supportMessage, setSupportMessage] = useState({
    subject: '',
    request_id: '',
    body: '',
    recipient: '',
  });

  const statues = [
    {
      id: 1,
      name: 'Attesa Documenti',
    },
    {
      id: 2,
      name: 'Problemi Con i Documenti',
    },
    {
      id: 3,
      name: 'Attesa Firma Documenti',
    },
    {
      id: 4,
      name: 'Attesa Pagamento',
    },
    {
      id: 5,
      name: 'Attesa Smarcatura',
    },
    {
      id: 6,
      name: 'In Attesa Emissione',
    },
    {
      id: 7,
      name: 'Polizza Emessa',
    },
  ];

  const collectQuoteData = () => {
    purchaseInsuranceRequest(requestToken, 'auto', setSupportMessage);
    // autoCollectData(requestToken, setSupportMessage);
  };

  const downloadDocument = async (documentId) => {
    const response = await apiDownloadDocument(documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const downloadOptionalDocument = async (documentId) => {
    const response = await apiDownloadOptionalDocument(documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const openOptionalDocumentModal = () => {
    setShowOptionalDocumentModal(true);
  };

  // const continueWithoutSignDocs = () => {
  //   confirmAlert('Sei sicuro di voler firmare più tardi?', () => skipSignature(rc_quotation.id, navigate));
  // };

  // const continueWithSignDocs = () => {
  //   confirmAlert('Sei sicuro di voler firmare adesso?', () => continueWithSignature(rc_quotation.id, collectQuoteData));
  // };

  const agreedDocuments = async () => {
    try {
      const response = await axe.post(`/agree-to-upload-documents/${insuranceRequest.id}`, {
        agree_to_upload_document: 1,
      });
      if (response.status !== 200) {
        generalAlertError('Non Accetato');
        return;
      }

      collectQuoteData();
    } catch (error) {
      //
    }
  };

  const sendBrokerageAmount = async (amount, skipCollect = false) => {
    if (skipCollect) {
      await updateBrokerage(
        rc_quotation.id,
        amount,
        () => { },
        skipCollect,
      );
    } else {
      await updateBrokerage(
        rc_quotation.id,
        amount,
        collectQuoteData,
        skipCollect,
      );
    }
  };

  const getTotal = () => {
    let rcTotalAmount = 0;
    let primaGuaranteesPrices = 0;
    let risksTotalAmount = 0;

    if (risk_quotations && risk_quotations.length > 0) {
      for (let i = 0; i < risk_quotations.length; i++) {
        const item = risk_quotations[i];

        risksTotalAmount += rc_quotation.payment_frequency === 1 ? parseFloat(item.amount) : parseFloat(item.amount / rc_quotation.payment_frequency);
      }
    }

    if (rc_quotation.company_id === 2) {
      rc_quotation.guarantees.forEach((guarantee) => {
        primaGuaranteesPrices += rc_quotation.payment_frequency === 1 ? parseFloat(guarantee.amount) : parseFloat(guarantee.amount / rc_quotation.payment_frequency) || 0;
      });
    }

    rcTotalAmount = rc_quotation.payment_frequency === 1 ? rc_quotation.amount : rc_quotation.amount / rc_quotation.payment_frequency;

    return parseFloat(
      rcTotalAmount + primaGuaranteesPrices + risksTotalAmount,
    ).toFixed(2);
  };

  useEffect(() => {
    if (insuranceRequest && insuranceRequest.redirect_user === true) {
      navigate('/');
      generalAlertSuccess('La pratica è completa, attendi nostro feedback!');
    }
  }, [insuranceRequest]);

  useEffect(() => {
    collectQuoteData();
    autoGetBackofficeUsers();

    return () => {
      resetAutoPurchase();
    };
  }, []);

  // const {
  //   setState,
  //   state: { run },
  // } = useTourContext();

  // useMount(() => {
  //   if (localStorage.getItem('purchaseTour') === 'done') {
  //     setState({ run: false });
  //   }
  // });

  // useEffect(() => {
  //   if (localStorage.getItem('purchaseTour') === 'done') {
  //     setState({ run: false });
  //   } else {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 1500);
  //   }
  // }, [localStorage.getItem('purchaseTour')]);

  // const handleCallback = (data) => {
  //   const {
  //     action,
  //     index,
  //     status,
  //     type,
  //   } = data;

  //   if (type === 'step:after') {
  //     if (index === (steps.length - 1)) {
  //       if (action === ACTIONS.NEXT) {
  //         localStorage.setItem('purchaseTour', 'done');
  //       }
  //     }

  //     if (action === ACTIONS.CLOSE) {
  //       localStorage.setItem('purchaseTour', 'done');
  //       setState({ run: false });
  //       setShowBeacon(false);
  //     }
  //   }

  //   if (status === 'skipped') {
  //     if (action === ACTIONS.SKIP) {
  //       localStorage.setItem('purchaseTour', 'done');
  //     }
  //   }
  // };

  // const firePurchaseTour = () => {
  //   localStorage.removeItem('purchaseTour');
  //   setState({ run: true });
  // };

  // useEffect(() => {
  //   if (quote !== null) {
  //     if (quote.status === 1 || quote.status === 2) {
  //       setSteps(
  //         [
  //           {
  //             target: '.documents-status-step-one',
  //             title: <h3 className="h5">Informazioni Quotazione e Cliente</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione troverete le informazioni per
  //                 {' '}
  //                 <b>cliente</b>
  //                 ,
  //                 {' '}
  //                 <b>veicolo</b>
  //                 ,
  //                 {' '}
  //                 <b>targa</b>
  //                 ,
  //                 {' '}
  //                 <b>prodotto</b>
  //                 e
  //                 {' '}
  //                 <b>stato</b>
  //                 .
  //               </p>
  //             ),
  //             disableBeacon: true,
  //             placement: 'right',
  //           },
  //           {
  //             target: '.documents-status-step-two',
  //             title: <h3 className="h5">Informazioni Compagnia</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione troverete le informazioni sulla compagnia,
  //                 {' '}
  //                 <b>la data decorrenza de la polizza, lo stato del preventivo, le informazioni precontrattuali e il questionario.</b>
  //               </p>
  //             ),
  //             placement: 'bottom',
  //           },
  //           {
  //             target: '.documents-status-step-three',
  //             title: <h3 className="h5">Premio e Supporto</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione troverete il prezzo di quotazione e l'icona di
  //                 {' '}
  //                 <b>supporto</b>
  //                 . Se cliccate sull'icona potete iniziare una conversazione con
  //                 {' '}
  //                 <b>gli agenti del Backoffice di Greenia.</b>
  //               </p>
  //             ),
  //             placement: 'left',
  //           },
  //           {
  //             target: '.documents-status-step-four',
  //             title: <h3 className="h5">Documenti Del Cliente</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione devi caricare i documenti obbligatori per il cliente come la
  //                 {' '}
  //                 <b>carta d'identità</b>
  //                 {' '}
  //                 <b>o altri documenti richiesti dal backoffice. </b>
  //                 <hr />
  //                 <b>
  //                   Se sono presenti documenti non validi, è necessario rimuovere il documento caricato cliccando sull'icona X e caricare il file.
  //                 </b>
  //               </p>
  //             ),
  //           },
  //           {
  //             target: '.documents-status-step-five',
  //             title: <h3 className="h5">Vuoi Firmare Dopo</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione puoi scegliere se vuoi
  //                 {' '}
  //                 <b>scaricare e firmare i documenti richiesti ora o dopo che il preventivo è completo. </b>
  //                 Cliccando
  //                 {' '}
  //                 <b>Sì</b>
  //                 {' '}
  //                 il preventivo verrà spostato nel backoffice per controllare i documenti. Se clicchi
  //                 {' '}
  //                 <b>No</b>
  //                 {' '}
  //                 devi caricare il documenti firmati e poi verrà spostato nel backoffice per controllare i documenti.
  //               </p>
  //             ),
  //           },
  //         ],
  //       );
  //     }

  //     if (quote.status === 4) {
  //       setSteps(
  //         [
  //           {
  //             target: '.pagament-status-step-one',
  //             title: <h3 className="h5">Informazioni Quotazione e Cliente</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione troverete le informazioni per
  //                 {' '}
  //                 <b>cliente</b>
  //                 ,
  //                 {' '}
  //                 <b>veicolo</b>
  //                 ,
  //                 {' '}
  //                 <b>targa</b>
  //                 ,
  //                 {' '}
  //                 <b>prodotto</b>
  //                 e
  //                 {' '}
  //                 <b>stato</b>
  //                 .
  //               </p>
  //             ),
  //             disableBeacon: true,
  //             placement: 'right',
  //           },
  //           {
  //             target: '.pagament-status-step-two',
  //             title: <h3 className="h5">Informazioni Compagnia</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione troverete le informazioni sulla compagnia,
  //                 {' '}
  //                 <b>la data decorrenza de la polizza, lo stato del preventivo, le informazioni precontrattuali e il questionario.</b>
  //               </p>
  //             ),
  //             placement: 'bottom',
  //           },
  //           {
  //             target: '.pagament-status-step-three',
  //             title: <h3 className="h5">Premio e Supporto</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione troverete il prezzo di quotazione e l'icona di
  //                 {' '}
  //                 <b>supporto</b>
  //                 . Se cliccate sull'icona potete iniziare una conversazione con
  //                 {' '}
  //                 <b>gli agenti del Backoffice di Greenia.</b>
  //               </p>
  //             ),
  //             placement: 'left',
  //           },
  //           {
  //             target: '.pagament-status-step-four',
  //             title: <h3 className="h5">Documenti Del Cliente</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione devi caricare i documenti obbligatori per il cliente come la
  //                 {' '}
  //                 <b>carta d'identità</b>
  //                 {' '}
  //                 <b>o altri documenti richiesti dal backoffice. </b>
  //                 <hr />
  //                 <b>
  //                   Se sono presenti documenti non validi, è necessario rimuovere il documento caricato cliccando sull'icona X e caricare il file.
  //                 </b>
  //               </p>
  //             ),
  //             placement: 'top',
  //           },
  //           {
  //             target: '.pagament-status-step-five',
  //             title: <h3 className="h5">Vuoi Firmare Dopo</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione puoi scegliere se vuoi
  //                 {' '}
  //                 <b>scaricare e firmare i documenti richiesti ora o dopo che il preventivo è completo. </b>
  //                 Cliccando
  //                 {' '}
  //                 <b>Sì</b>
  //                 {' '}
  //                 il preventivo verrà spostato nel backoffice per controllare i documenti. Se clicchi
  //                 {' '}
  //                 <b>No</b>
  //                 {' '}
  //                 devi caricare il documenti firmati e poi verrà spostato nel backoffice per controllare i documenti.
  //               </p>
  //             ),
  //             placement: 'top',
  //           },
  //           {
  //             target: '.pagament-status-step-six',
  //             title: <h3 className="h5">Seleziona il prodotto</h3>,
  //             content: (
  //               <p className="m-0">
  //                 Seleziona uno dei nostri prodotti tra
  //                 {' '}
  //                 <b>Auto, Autocarro, Moto, Professione, Viaggi.</b>
  //               </p>
  //             ),
  //             placement: 'top',

  //           },
  //           {
  //             target: '.pagament-status-step-seven',
  //             title: <h3 className="h5">Seleziona il prodotto</h3>,
  //             content: (
  //               <p className="m-0">
  //                 Seleziona uno dei nostri prodotti tra
  //                 {' '}
  //                 <b>Auto, Autocarro, Moto, Professione, Viaggi.</b>
  //               </p>
  //             ),
  //             placement: 'top',

  //           },
  //           {
  //             target: '.pagament-status-step-eight',
  //             title: <h3 className="h5">Seleziona il prodotto</h3>,
  //             content: (
  //               <p className="m-0">
  //                 Seleziona uno dei nostri prodotti tra
  //                 {' '}
  //                 <b>Auto, Autocarro, Moto, Professione, Viaggi.</b>
  //               </p>
  //             ),
  //             placement: 'top',

  //           },

  //         ],
  //       );
  //     }

  //     if (quote.status === 7) {
  //       setSteps(
  //         [
  //           {
  //             target: '.polizze-emessa-status-step-one',
  //             title: <h3 className="h5">Anagrafica</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione puoi trovare l'anagrafica del cliente
  //               </p>
  //             ),
  //             disableBeacon: true,
  //             placement: 'bottom',
  //           },
  //           {
  //             target: '.polizze-emessa-status-step-two',
  //             title: <h3 className="h5">Dati Veicolo</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione puoi trovare i dati del veicolo
  //               </p>
  //             ),
  //             placement: 'top',
  //           },
  //           {
  //             target: '.polizze-emessa-status-step-three',
  //             title: <h3 className="h5">Dati Polizza</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione puoi trovare i dati della polizza.
  //               </p>
  //             ),
  //             placement: 'bottom',
  //           },
  //           {
  //             target: '.polizze-emessa-status-step-four',
  //             title: <h3 className="h5">Documenti Della Polizza</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione puoi trovare i documenti del preventivo, puoi scaricarli e se hai documenti non firmati puoi caricare quelli firmati.
  //               </p>
  //             ),
  //             placement: 'top',
  //           },
  //           {
  //             target: '.polizze-emessa-status-step-five',
  //             title: <h3 className="h5">Documenti Della Polizza</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione puoi trovare i documenti del preventivo, puoi scaricarli e se hai documenti non firmati puoi caricare quelli firmati.
  //               </p>
  //             ),
  //             placement: 'top',
  //           },
  //         ],
  //       );
  //     }
  //   }
  // }, [quote]);

  // const options = {
  //   arrowColor: '#fff',
  //   backgroundColor: '#fff',
  //   beaconSize: 36,
  //   overlayColor: 'rgba(0, 0, 0, 0.5)',
  //   spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
  //   textColor: '#333',
  //   zIndex: 99999,
  // };

  useEffect(() => {
    if (loading === false && rc_quotation.prima_expired === true) {
      navigate('/quote/auto', {
        state: { requestToken: insuranceRequest.request_token },
      });
      generalAlertError(
        'È necessario compilare nuovamente il preventivo!',
        'Link e scaduto!',
      );
    } else if (
      loading === false
      && rc_quotation.prima_link !== undefined
      && rc_quotation.prima_link !== null
    ) {
      window.open(
        `/redirect?url=${encodeURIComponent(rc_quotation.prima_link)}`,
      );
    }

    if (loading === false && rc_quotation.adriatic_expired === true) {
      navigate('/quote/auto', {
        state: { requestToken: insuranceRequest.request_token },
      });
      generalAlertError(
        'È necessario compilare nuovamente il preventivo!',
        'Link e scaduto!',
      );
    }
  }, [loading, rc_quotation]);

  const removeDocument = async (documentId) => {
    try {
      const response = await deleteDocument(documentId);

      if (response.status === 200) {
        collectQuoteData();
      }
    } catch (err) {
      // console.error(err);
    }
  };

  const removeOptionalDocument = async (documentId) => {
    try {
      const response = await apiDeleteOptionalDocument(documentId);

      if (response.status === 200) {
        collectQuoteData();
      }
    } catch (err) {
      // console.error(err);
    }
  };

  const navigateBack = () => {
    navigate(`/quotes/${insuranceRequest.request_token}`);
  };

  const acceptSelectedForBackoffice = () => {
    confirmSelectedForBackofficeQuote(rc_quotation.id, collectQuoteData);
  };

  useEffect(() => {
    if (insuranceRequest !== null && insuranceRequest.should_redirect === 1) {
      navigate(`/quotes/${insuranceRequest.request_token}`);
      generalAlertError(
        'Se vuoi selezionare un altro preventivo devi fare modifica dati!',
      );
    }
  }, [insuranceRequest]);

  useEffect(() => {
    if (
      rc_quotation !== null
      && rc_quotation.preventivas_company_id !== null
      && rc_quotation.added_manually !== 1
      && rc_quotation.selected_for_backoffice === null
    ) {
      confirmAlertForBackofficeBrokerQuote(
        'Se confermi di procedere con questa quota e cambi idea devi fare modifica dati!',
        () => acceptSelectedForBackoffice(),
        navigate,
        insuranceRequest.request_token,
      );
    }
  }, [rc_quotation]);

  const checkIfAllNonSignableDocsAreUploaded = () => {
    let allUploaded = true;
    const allDocs = [
      ...insuranceRequest.documents.filter((d) => d.signable_document === 0),
      ...rc_quotation.documents.filter((d) => d.signable_document === 0),
    ];

    const nonSignaleDocTypeIds = nonSignableDocs.map((d) => d.id);

    for (let i = 0; i < allDocs.length; i++) {
      const document = allDocs[i];

      if (!nonSignaleDocTypeIds.includes(document.document_type_id)) {
        allUploaded = false;
      }
    }

    if (
      allDocs.length === 0
      || nonSignaleDocTypeIds.length !== allDocs.length
    ) {
      return false;
    }

    return allUploaded;
  };

  if (loading) {
    return <PageLoading />;
  }

  // const documentRequestsThatNeedSignature = [
  //   ...insuranceRequest.document_requests.filter(
  //     (dr) => dr.document !== null && dr.document.signable_document === 1,
  //   ),
  //   ...quote.document_requests.filter(
  //     (dr) => dr.document !== null && dr.document.signable_document === 1,
  //   ),
  // ];

  return (
    <PurchaseStyles>
      {/* <Joyride
        callback={handleCallback}
        continuous
        run={run}
        steps={steps}
        showBeacon={showBeacon}
        styles={{
          options,
          buttonNext: {
            backgroundColor: '#00a651',
            outline: 'none',
          },
          buttonBack: {
            color: '#00a651',
            outline: 'none',
          },
          beaconInner: {
            backgroundColor: '#00a651',
          },
          beaconOuter: {
            borderColor: '#00a651',
            backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
          },
          buttonSkip: {
            outline: 'none',
          },
          buttonClose: {
            outline: 'none',
          },
        }}
        showProgress
        locale={{
          back: 'Indietro',
          close: 'Chiudi',
          last: 'Fine',
          next: 'Prossima',
          open: 'Apri il modale',
          restart: 'Riavvia il tour ',
          skip: 'Salta',
        }}
        showSkipButton
      /> */}
      {quoteStatus !== 7 && (
        <>
          <div className="container mt-0 gap-2 align-items-center justify-content-start mb-3">
            <div className="px-2 py-1 d-flex align-items-center gap-2">
              <div
                className="cursor-pointer d-flex align-items-center justify-content-start gap-2 fw-bold text-success"
                onClick={() => navigateBack()}
              >
                <div id="back-button">
                  <FontAwesomeIcon icon={faArrowLeftLong} />
                </div>
                <p className="ml-2 mb-0  cursor-pointer">Modifica Preventivo</p>
              </div>
              {/* <div>

                  {localStorage.getItem('purchaseTour') === 'done' && (
                    <div style={{ cursor: 'pointer' }} onClick={() => firePurchaseTour()}>
                      <button className="btn btn-success px-2 py-1">Tutorial</button>
                    </div>
                  )}
                </div> */}
            </div>
            {/* <AutoStepper quoteStatus={quoteStatus} /> */}
            <InfoBoxAuto
              rc_quotation={rc_quotation}
              insuranceRequest={insuranceRequest}
              statues={statues}
              quoteStatus={quoteStatus}
              messagesModalData={{
                backofficeUsers,
                setSupportMessage,
                supportMessage,
                collectData: collectQuoteData,
              }}
              risk_quotations={risk_quotations}
            />
          </div>
          <div className="container mt-3">
            {rc_quotation.company_id === 2
              && rc_quotation.prima_link !== null
              && !rc_quotation.indirect_prima_quote_process ? (
                <RedirectToPrimaAlert>
                  Verrai reindirizzato alla piattaforma Prima.it
                </RedirectToPrimaAlert>
              ) : (
                <>
                  {quoteStatus === 1 || quoteStatus === 2 || quoteStatus === 5 ? (
                    <>
                      <SaveBox
                        insuranceRequest={insuranceRequest}
                        quotationId={rc_quotation.id}
                        refresh={collectQuoteData}
                      />

                      {(rc_quotation.amount_changed === true || rc_quotation.risk_quote_change === true) && (
                      <div className="alert alert-warning">
                        <b>Attenzione:</b>
                        {' '}
                        L’importo totale del preventivo è stato aggiornato. Di conseguenza, il brokeraggio è stato modificato qualora superasse il limite massimo consentito del 30% del valore del preventivo.
                      </div>
                      )}

                      {/* <div className="documents-status-step-five">
                        {rc_quotation.sign_later === null && (
                        <div className="row mt-3 ">
                          <div className="col-12 col-xxl-12 mt-2 mt-xxl-0">
                            <SkipSignedDocs className="skip-doc">
                              <p className="text-center">Vuoi Firmare Dopo?</p>
                              <div className="d-flex justify-content-center align-items-center gap-3">
                                <button
                                  className="btn btn-success border py-1 px-4"
                                  onClick={() => continueWithoutSignDocs()}
                                >
                                  Si
                                </button>
                                <button
                                  className="btn btn-danger border py-1 px-4"
                                  onClick={() => continueWithSignDocs()}
                                >
                                  No
                                </button>
                              </div>
                            </SkipSignedDocs>
                          </div>
                        </div>
                        )}
                        {rc_quotation.sign_later === 1 && (
                        <div className="alert alert-success mt-3 border rounded-4">
                          Hai scelto di firmare i documenti più tardi.
                        </div>
                        )}
                        {rc_quotation.sign_later === 0 && (
                        <div className="row mt-3">
                          <div className="col-12 col-xxl-12 mt-2 mt-xxl-0">
                            <SignatureType
                              quote={rc_quotation}
                              insuranceRequest={insuranceRequest}
                              collectQuoteData={collectQuoteData}
                              documentRequestsThatNeedSignature={
                                documentRequestsThatNeedSignature
                              }
                              signableDocs={signableDocs}
                              requestedOptionalDocumentsSignable={
                                requestedOptionalDocumentsSignable
                              }
                            />
                          </div>
                        </div>
                        )}
                      </div> */}
                      <div className="row mt-3 ">
                        <div className="col-12 col-xxl-12 mt-2 mt-xxl-0">
                          <SkipSignedDocs className="skip-doc">
                            <p className="text-center mb-1">Accetta obblighi di inviare documenti</p>
                            <div className="d-flex justify-content-center align-items-center gap-3">
                              <label className="m-0">
                                <input type="checkbox" className="me-1" checked={insuranceRequest.agree_upload_documents === 1} disabled={insuranceRequest.agree_upload_documents === 1} onClick={() => confirmAlert('', () => agreedDocuments())} />
                                Dichiaro di aver adempiuto agli obblighi in ambito di invio documentazione precontrattuale

                              </label>
                            </div>
                          </SkipSignedDocs>
                        </div>
                      </div>

                      {insuranceRequest.agree_upload_documents !== null && (nonSignableDocs.length > 0
                      || requestedOptionalDocumentsNonSignable.length > 0) && (
                        <div className="row">
                          <div className="col-12 documents-status-step-four mt-3">
                            <UploadDocumentsContainer
                              className={
                                checkIfAllNonSignableDocsAreUploaded() === false
                                  ? 'document-all-uploaded-border'
                                  : ''
                              }
                            >
                              <div className="d-flex align-items-center justify-content-start">
                                <NumberCurrent>1</NumberCurrent>
                                <h2>
                                  Carica i
                                  {' '}
                                  <b>documenti</b>
                                  {' '}
                                  del cliente
                                </h2>
                              </div>
                              <UploadWrapper>
                                {nonSignableDocs.map((docType, index) => (
                                  <NonSignableDocumentComponent
                                    key={index}
                                    insuranceRequest={
                                      rc_quotation.initial_quotation
                                        ? rc_quotation.initial_quotation
                                          .insurance_request
                                        : insuranceRequest
                                    }
                                    documentType={docType}
                                    downloadDocument={downloadDocument}
                                    removeDocument={removeDocument}
                                    rc_quotation={rc_quotation}
                                    collectQuoteData={collectQuoteData}
                                  />
                                ))}
                                {requestedOptionalDocumentsNonSignable.map(
                                  (document_request, index) => (
                                    <NonSIgnableDocumentRequestComponent
                                      key={index}
                                      document_request={document_request}
                                      removeOptionalDocument={
                                        removeOptionalDocument
                                      }
                                      downloadOptionalDocument={
                                        downloadOptionalDocument
                                      }
                                      collectQuoteData={collectQuoteData}
                                    />
                                  ),
                                )}
                                <OptionalDocumentBox>
                                  <BadgeOptional>Facoltativo</BadgeOptional>
                                  <img src={uploadIcon} alt="" />
                                  <button
                                    type="button"
                                    className="bg-secondary"
                                    onClick={() => openOptionalDocumentModal()}
                                  >
                                    Richiedi di aggiungere un documento
                                  </button>
                                </OptionalDocumentBox>
                              </UploadWrapper>
                            </UploadDocumentsContainer>
                          </div>
                        </div>
                      )}

                    </>
                  ) : (
                    ''
                  )}
                  {quoteStatus === 1 && (
                  <BrokeraggioComponent rc_quotation={rc_quotation} getTotal={getTotal} sendBrokerageAmount={sendBrokerageAmount} />
                  )}
                  {quoteStatus === 4 && (
                  <WaitingForPaymentStatus
                    risk_quotations={risk_quotations}
                    insuranceRequest={insuranceRequest}
                    collectQuoteData={collectQuoteData}
                    rc_quotation={rc_quotation}
                  />
                  )}
                  {quoteStatus === 6 && (
                  <div className="card-body">
                    <div className="row">
                      <div className="col-6 offset-3">
                        <div className="alert alert-success" role="alert">
                          <h4 className="alert-heading">In Attesa Emissione</h4>
                          <p>
                            Quando l'agente di backoffice carica la polizza,
                            riceverai una notifica e il contratto potrà essere
                            scaricato.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  )}
                </>
              )}
          </div>
        </>
      )}
      {showOptionalDocumentModal === true && (
        <OptionalDocumentModal
          quotationId={rc_quotation.id}
          closeModal={() => setShowOptionalDocumentModal(false)}
          collectQuoteData={collectQuoteData}
        />
      )}
      {quoteStatus === 7 && (
        <>
          <div className="mt-0 gap-2 align-items-center justify-content-start mb-3">
            {/* <div>
                {localStorage.getItem('purchaseTour') === 'done' && (
                  <div style={{ cursor: 'pointer' }} onClick={() => firePurchaseTour()}>
                    <button className="btn btn-success px-2 py-1">Tutorial</button>
                  </div>
                )}
              </div> */}
          </div>

          <CompletedScreenAuto
            quote={quote}
            quoteStatus={quoteStatus}
            insuranceRequest={insuranceRequest}
            rc_quotation={rc_quotation}
            risk_quotations={risk_quotations}
          />
        </>
      )}
    </PurchaseStyles>
  );
}

export default PurchaseAutoRequest;
