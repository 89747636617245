import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import NotificationItem from '../common/components/NotificationItem';
import PageLoading from '../common/components/elements/PageLoading';
import { getAllNotifications, resetNotifications } from '../features/utilities/utilitiesActions';
import Pagination from '../common/components/elements/Pagination';
import ErrorComponent from '../common/components/ErrorComponent';

export default function Notifications() {
  const {
    notifications, loading, total_pages, page_number, error,
  } = useSelector((store) => store.utilities);

  // HOOKS
  useEffect(() => {
    getAllNotifications(page_number);

    return () => { resetNotifications(); };
  }, []);

  if (loading) {
    return <PageLoading />;
  }

  return error === null
    ? (
      <div className="mt-3">
        <div>
          <div>
            <h5>Notifiche</h5>
          </div>
          {notifications.find((notification) => notification.event === 'renewals-generated' && notification.is_read !== 1) !== undefined && (
            <>
              <hr />
              <div className="d-flex align-items-center justify-content-start">
                <h6 className="m-0 me-2">
                  Dei rinnovi sono stati generati. Vai a controllare.
                </h6>
                <Link to="/renewals" className="btn btn-success">
                  Vai su rinnovi
                </Link>
              </div>
            </>
          )}
        </div>
        <div className="mt-4">
          {notifications.length !== 0 ? (
            notifications.map((notification, i) => (
              <NotificationItem key={i} notification={notification} />
            ))
          ) : (
            <div className="alert alert-info">Nessuna Notifica</div>
          )}
          <Pagination total_pages={total_pages} current_page={page_number} onClick={getAllNotifications} />
        </div>
      </div>
    ) : <ErrorComponent error={error} />;
}
