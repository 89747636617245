import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { PurchaseStyles } from '../purchase/Purchase.styles';
import InfoBoxSalute from '../../common/components/redesignComponents/Purchase/common/InfoBoxSalute';
import { SaveBox } from '../purchase/common/SaveBox';
import { SkipSignedDocs } from '../../common/components/redesignComponents/Purchase/SignatureType.styles';
import WaitingForPaymentStatus from '../../common/components/redesignComponents/Purchase/WaitingForPaymentStatus';
import {
  purchaseInsuranceRequest, resetSalutePurchase,
} from '../../features/purchases/purchasesActions';
import { confirmAlert, generalAlertError, generalAlertSuccess } from '../../common/utils/alerts';
import PageLoading from '../../common/components/elements/PageLoading';
import CompletedScreenSalute from './CompletedScreenSalute';
import axe from '../../services/axios-client/axiosClient';

const statuses = [
  {
    id: 1,
    name: 'Attesa Documenti',
  },
  {
    id: 2,
    name: 'Problemi Con i Documenti',
  },
  {
    id: 3,
    name: 'Attesa Firma Documenti',
  },
  {
    id: 4,
    name: 'Attesa Pagamento',
  },
  {
    id: 5,
    name: 'Attesa Smarcatura',
  },
  {
    id: 6,
    name: 'In Attesa Emissione',
  },
  {
    id: 7,
    name: 'Polizza Emessa',
  },
];

function PurchaseSaluteRequest() {
  const navigate = useNavigate();
  const { requestToken } = useParams();

  const state = useSelector((store) => store.purchases.salute);
  const {
    loading,
    quote,
    insuranceRequest,
    quoteStatus,
    // signMethod,
    backofficeUsers,
    rc_quotation,
    speseLegaliFamigliaQuote,
    incendioContenutoQuote,
    danniFamigliaConduzioneQuote,
    danniPropietarioQuote,
    incendioFabbricatoQuote,
    // signableDocs,
    // requestedOptionalDocumentsSignable,
    risk_quotations,
  } = state;

  const navigateBack = () => {
    navigate(`/quotes/salute/${insuranceRequest.request_token}`);
  };

  const [supportMessage, setSupportMessage] = useState({
    subject: '',
    request_id: '',
    body: '',
    recipient: '',
  });

  const collectQuoteData = async () => {
    purchaseInsuranceRequest(requestToken, 'salute', setSupportMessage);
  };

  const agreedDocuments = async () => {
    try {
      const response = await axe.post(`/agree-to-upload-documents/${insuranceRequest.id}`, {
        agree_to_upload_document: 1,
      });
      if (response.status !== 200) {
        generalAlertError('Non Accetato');
        return;
      }

      collectQuoteData();
    } catch (error) {
      //
    }
  };

  useEffect(() => {
    if (insuranceRequest && insuranceRequest.redirect_user === true) {
      navigate('/');
      generalAlertSuccess('La pratica è completa, attendi nostro feedback!');
    }
  }, [insuranceRequest]);

  // const continueWithoutSignDocs = () => {
  //   confirmAlert('Sei sicuro di voler firmare più tardi?', () => skipSignatureSalute(rc_quotation.id, navigate));
  // };

  // const continueWithSignDocs = () => {
  //   confirmAlert('Sei sicuro di voler firmare adesso?', () => continueWithSignatureSalute(rc_quotation.id, collectQuoteData));
  // };

  useEffect(() => {
    collectQuoteData();

    return () => {
      resetSalutePurchase();
    };
  }, []);

  useEffect(() => {
    if (loading === false && rc_quotation.is_expired === true) {
      navigate('/quote/salute', {
        state: { requestToken: insuranceRequest.request_token },
      });
      generalAlertError(
        'È necessario compilare nuovamente il preventivo!',
        'Link e scaduto!',
      );
    }
  }, [loading, quote]);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <PurchaseStyles>
      {quoteStatus !== 7 && (
        <>
          <div className="container mt-0 d-flex gap-2 align-items-center justify-content-start mb-3">
            <div className="px-2 py-1 d-flex align-items-center gap-2">
              <div
                className="cursor-pointer d-flex align-items-center justify-content-start gap-2 fw-bold text-success"
                onClick={() => navigateBack()}
              >
                <div id="back-button">
                  <FontAwesomeIcon icon={faArrowLeftLong} />
                </div>
                <p className="ml-2 mb-0  cursor-pointer">Modifica Preventivo</p>
              </div>
            </div>
          </div>
          <div className="container mt-0">
            <InfoBoxSalute
              rc_quotation={rc_quotation}
              statuses={statuses}
              insuranceRequest={insuranceRequest}
              quoteStatus={quoteStatus}
              messagesModalData={{
                backofficeUsers,
                setSupportMessage,
                supportMessage,
                collectData: collectQuoteData,
              }}
              risk_quotations={risk_quotations}
            />
            {quoteStatus === 1 || quoteStatus === 2 || quoteStatus === 5 ? (
              <>
                <div className="documents-status-step-four">
                  <SaveBox
                    insuranceRequest={insuranceRequest}
                    quotationId={rc_quotation.id}
                    refresh={collectQuoteData}
                  />

                </div>

                <div className="row mt-3 ">
                  <div className="col-12 col-xxl-12 mt-2 mt-xxl-0">
                    <SkipSignedDocs className="skip-doc">
                      <p className="text-center mb-1">Accetta obblighi di inviare documenti</p>
                      <div className="d-flex justify-content-center align-items-center gap-3">
                        <label className="m-0">
                          <input type="checkbox" className="me-1" checked={insuranceRequest.agree_upload_documents === 1} disabled={insuranceRequest.agree_upload_documents === 1} onClick={() => confirmAlert('', () => agreedDocuments())} />
                          Dichiaro di aver adempiuto agli obblighi in ambito di invio documentazione precontrattuale

                        </label>
                      </div>
                    </SkipSignedDocs>
                  </div>
                </div>

              </>
            ) : (
              ''
            )}

            {quoteStatus === 4 && (
              <WaitingForPaymentStatus
                quote={quote}
                rc_quotation={rc_quotation}
                spese_legali_famiglia_quote={speseLegaliFamigliaQuote}
                incendio_contenuto_quote={incendioContenutoQuote}
                danni_famiglia_conduzione_quote={danniFamigliaConduzioneQuote}
                danni_propietario_quote={danniPropietarioQuote}
                incendio_fabbricato_quote={incendioFabbricatoQuote}
                insuranceRequest={insuranceRequest}
                collectQuoteData={collectQuoteData}
                assistenza_quotation={null}
                rivalsa_quotation={null}
                infortuni_conducente_quotation={null}
                cristalli_quotation={null}
                risk_quotations={risk_quotations}
              />
            )}

            {quoteStatus === 6 && (
              <div className="card-body mt-3">
                <div className="row">
                  <div className="col-6 offset-3">
                    <div className="alert alert-success" role="alert">
                      <h4 className="alert-heading">In Attesa Emissione</h4>
                      <p>
                        Quando l'agente di backoffice carica la polizza,
                        riceverai una notifica e il contratto potrà essere
                        scaricato.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {quoteStatus === 7 && (
        <>
          <div className="mt-0 gap-2 align-items-center justify-content-start mb-3">
            {/* <div>
                {localStorage.getItem('purchaseTour') === 'done' && (
                  <div style={{ cursor: 'pointer' }} onClick={() => firePurchaseTour()}>
                    <button className="btn btn-success px-2 py-1">Tutorial</button>
                  </div>
                )}
              </div> */}
          </div>

          <CompletedScreenSalute
            risk_quotations={risk_quotations}
            quoteStatus={quoteStatus}
            customer={insuranceRequest.customer}
            rc_quotation={rc_quotation}
          />
        </>
      )}
    </PurchaseStyles>
  );
}

export default PurchaseSaluteRequest;
