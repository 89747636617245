import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
// import Joyride from 'react-joyride';
import { generalAlertError } from '../common/utils/alerts';
import { listRenewals, resetRenewals } from '../features/insuranceRequests/insuranceRequestsActions';
import { PurchasedQuotationsStyles } from './PurchasedQuotations/PurchasedQuotations.styles';
import ErrorComponent from '../common/components/ErrorComponent';
// import { useTourContext } from '../context/useTourContext';
// import { joyrideOptions } from '../common/utils/joyrideUtils';
// import { handleCallbackRenewalsPage } from '../common/utils/joyrideCallbacks';
// import { renewalsSteps } from '../common/utils/joyrideSteps';
import managementAxe from '../services/axios-client/managementServisAxeClient';
import { downloadFile } from '../services/axios-client/axeHelpers';
import Pagination from '../common/components/elements/Pagination';

function RenewalsPage() {
  const state = useSelector((store) => store.insuranceRequests.renewals);

  const {
    loading, renewals, error, page_number, total_pages,
  } = state;
  // const [steps, setSteps] = useState([]);

  const [search, setSearch] = useState({
    search: '',
    date_from: moment().subtract(60, 'days').format('YYYY-MM-DD'),
    date_to: moment().format('YYYY-MM-DD'),
  });

  const [exportsSearch, setExportSearch] = useState({
    date_from: moment().subtract(60, 'days').format('YYYY-MM-DD'),
    date_to: moment().format('YYYY-MM-DD'),
  });

  // const {
  //   setState,
  //   state: { run, showBeacon },
  // } = useTourContext();

  // useEffect(() => {
  //   if (localStorage.getItem('renewals') === 'done') {
  //     setState({ run: false });
  //   } else if (loading === false) {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 3000);
  //   }
  // }, [localStorage.getItem('renewals'), loading]);

  const searchHandler = (e) => {
    e.preventDefault();

    if (search.date_from !== '' && search.date_to === '') {
      generalAlertError('Devi selezionare anche data al');
      return;
    }

    if (search.date_from === '' && search.date_to !== '') {
      generalAlertError('Devi selezionare anche data da');
      return;
    }

    const trimmedSearch = {
      ...search,
      search: search.search.trim(),
    };

    if (search.search !== '') {
      setSearch({
        ...search,
        date_from: '',
        date_to: '',
      });
    }

    listRenewals(1, trimmedSearch);
  };

  const handleChange = ({ target: { value, name } }) => {
    setSearch({ ...search, [name]: value });
  };

  // const fireNewQuoteTour = () => {
  //   localStorage.removeItem('renewals');
  //   setState({ run: true, tourActive: true });
  // };

  const downloadExcel = async () => {
    const res = await managementAxe.get('/broker/download/rinnovi-export', {
      params: exportsSearch,
      responseType: 'blob',
    });

    if (res.status !== 200) {
      generalAlertError('Nessun Preventivo Trovato Per Questo Intervallo!');
      return;
    }

    downloadFile(res.data);
  };

  // useEffect(() => {
  //   renewalsSteps(renewals, setSteps);
  // }, [renewals]);

  useEffect(() => {
    listRenewals(1, search);

    return () => {
      resetRenewals();
    };
  }, []);

  return error === null ? (
    <PurchasedQuotationsStyles>
      {/* <Joyride
        steps={steps}
        continuous
        showBeacon={showBeacon}
        locale={{
          back: 'Indietro',
          close: 'Chiudi',
          last: 'Fine',
          next: 'Prossima',
          open: 'Apri il modale',
          restart: 'Riavvia il tour ',
          skip: 'Salta',
        }}
        styles={{
          options: joyrideOptions,
          buttonNext: {
            backgroundColor: '#00a651',
            outline: 'none',
          },
          buttonBack: {
            color: '#00a651',
            outline: 'none',
          },
          beaconInner: {
            backgroundColor: '#00a651',
          },
          beaconOuter: {
            borderColor: '#00a651',
            backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
          },
          buttonSkip: {
            outline: 'none',
          },
          buttonClose: {
            outline: 'none',
          },
        }}
        run={run}
        callback={(data) => handleCallbackRenewalsPage(data, steps, setState)}
        showSkipButton
        showProgress
      /> */}
      <h5 className="mb-4">Rinnovi</h5>
      {/* {localStorage.getItem('renewals') === 'done' && (
        <div style={{ cursor: 'pointer', top: '8%' }} onClick={() => fireNewQuoteTour()} className="position-fixed end-0 mt-3 me-3">
          <button className="btn btn-success px-2 py-1">Tutorial</button>
        </div>
      )} */}

      <div className="d-flex flex-md-row flex-column-reverse">
        <div className="col-xl-8 col-md-7 col-lg-8 ">
          <div className="mt-3 renewal-one">
            {loading
              ? (
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <div className="spinner-border" role="status" />
                  <span>Attendere prego...</span>
                </div>
              )
              : (
                <ul className="list-group">
                  {renewals && renewals.length > 0
                    ? renewals.map((insuranceRequest, index) => (
                      <li key={index} className="renewal-two list-group-item ">
                        <div className="row">
                          <div className="col-12 col-xl-4">
                            <div>
                              {insuranceRequest.customer && (
                                <div>
                                  <p className="m-0" style={{ fontSize: '14px' }}>
                                    Cliente:
                                    <span>
                                      {' '}
                                      <strong>
                                        {insuranceRequest.customer.gender === 'G' ? insuranceRequest.customer.business_name : `${insuranceRequest.customer.name} ${insuranceRequest.customer.surname}`}
                                      </strong>
                                    </span>
                                  </p>
                                </div>
                              )}
                              {insuranceRequest.product
                                && (
                                  <div>
                                    <p className="m-0" style={{ fontSize: '14px' }}>
                                      Prodotto:
                                      <span>
                                        {' '}
                                        <strong>
                                          {insuranceRequest.product.name}
                                        </strong>
                                      </span>
                                    </p>

                                  </div>
                                )}
                              {insuranceRequest.vehicle
                                && (
                                  <div>
                                    <p className="m-0" style={{ fontSize: '14px' }}>
                                      Targa:
                                      <span>
                                        {' '}
                                        <strong>
                                          {insuranceRequest.vehicle.vehicle_plate}
                                        </strong>
                                      </span>
                                    </p>

                                  </div>
                                )}
                              {insuranceRequest.professional
                                && (
                                  <div>
                                    <p className="m-0" style={{ fontSize: '14px' }}>
                                      Professione:
                                      <span>
                                        {' '}
                                        <strong>
                                          {insuranceRequest.professional.profession_desc}
                                        </strong>
                                      </span>
                                    </p>
                                  </div>
                                )}
                            </div>

                          </div>
                          <div className="col-12 col-xl-4 d-flex justify-content-center align-items-start flex-column">
                            <div className="fw-bold">
                              <small className="m-0">
                                Decorrenza:
                                {' '}
                                <span>
                                  {' '}
                                  {new Date(insuranceRequest.policy_effective_date).toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                </span>
                              </small>

                            </div>
                            {insuranceRequest.new === true
                              && (
                                <div className="badge bg-primary mx-5 my-1">Nuovo</div>
                              )}
                          </div>
                          <div className="col-12 col-xl-4 d-flex justify-content-end align-items-center">

                            {insuranceRequest.product_id == 1 && (
                              <Link to={`/quotes/auto/purchase/${insuranceRequest.request_token}`}>
                                <span className="badge btn-to-quote px-4 py-2 rounded-3">
                                  Vai a preventivi
                                </span>
                              </Link>
                            )}
                            {insuranceRequest.product_id == 3 && (
                              <Link to={`/quotes/autocarro/purchase/${insuranceRequest.request_token}`}>
                                <span className="badge btn-to-quote px-4 py-2 rounded-3">
                                  Vai a preventivi
                                </span>
                              </Link>
                            )}

                            {insuranceRequest.product_id == 4 && (
                              <Link to={`/quotes/profession/purchase/${insuranceRequest.request_token}`}>
                                <span className="badge btn-to-quote px-4 py-2 rounded-3">
                                  Vai a preventivi
                                </span>
                              </Link>
                            )}

                            {insuranceRequest.product_id == 8 && (
                              <Link to={`/quotes/travel/purchase/${insuranceRequest.request_token}`}>
                                <span className="badge btn-to-quote px-4 py-2 rounded-3">
                                  Vai a preventivi
                                </span>
                              </Link>
                            )}

                            {insuranceRequest.product_id == 2 && (
                              <Link to={`/quotes/moto/purchase/${insuranceRequest.request_token}`}>
                                <span className="badge btn-to-quote px-4 py-2 rounded-3">
                                  Vai a preventivi
                                </span>
                              </Link>
                            )}

                            {insuranceRequest.product_id == 10 && (
                              <Link to={`/quotes/commercial/purchase/${insuranceRequest.request_token}`}>
                                <span className="badge btn-to-quote px-4 py-2 rounded-3">
                                  Vai a preventivi
                                </span>
                              </Link>
                            )}
                          </div>
                        </div>
                      </li>
                    ))
                    : (
                      <li className="list-group-item d-flex justify-content-between align-items-start renewal-no-result">
                        <div className="ms-2 me-auto ">
                          <div className="fw-bold">
                            Nessun rinnovo trovato!
                          </div>
                        </div>
                      </li>
                    )}
                </ul>
              )}
          </div>
          <div className="mt-2">
            <Pagination total_pages={total_pages} current_page={page_number} onClick={listRenewals} search={search} />
          </div>
        </div>
        <div className="col-lg-3 col-md-5 col-xl-3 p-3 quotation-filters renewal-search">
          <form onSubmit={searchHandler} className="d-flex align-items-end justify-content-center flex-column">
            <input type="text" name="search" className="search-input form-control mb-2" value={search.search} onChange={handleChange} placeholder="Nome Cognome Targa..." />

            <div className="w-100">
              <label htmlFor="date_from">Decorrenza da</label>
              <input type="date" name="date_from" onChange={handleChange} value={search.date_from} className="date-input form-control mb-2" placeholder="Decorrenza da" />
            </div>
            <div className="w-100">
              <label htmlFor="date_to">Decorrenza al</label>
              <input type="date" name="date_to" className="date-input form-control mb-0" onChange={handleChange} value={search.date_to} min={search.date_from} placeholder="Decorrenza al" />
            </div>
            <button type="submit" className="search-btn mt-2">
              Cerca
            </button>
          </form>
          <div className="border-top mt-3">
            <div className="my-2 fw-bold ">
              Scarica Rinnovi Excel
            </div>
            <div>
              <label htmlFor="date_from">Decorrenza da</label>
              <input type="date" name="date_from" onChange={(e) => setExportSearch({ ...exportsSearch, date_from: e.target.value })} value={exportsSearch.date_from} className="date-input form-control mb-2" placeholder="Decorrenza da" />
            </div>
            <div>
              <label htmlFor="date_to">Decorrenza al</label>
              <input type="date" name="date_to" className="date-input form-control mb-0" onChange={(e) => setExportSearch({ ...exportsSearch, date_to: e.target.value })} value={exportsSearch.date_to} placeholder="Decorrenza al" />
            </div>
            <button className="search-btn mt-2" onClick={() => downloadExcel()}>Scarica Excel</button>

          </div>
        </div>
      </div>
    </PurchasedQuotationsStyles>
  ) : <ErrorComponent error={error} />;
}

export default RenewalsPage;
