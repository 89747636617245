import styled from 'styled-components';

export const CenteredPageTitleStyles = styled.div`
  .title-h2 {
    text-transform: uppercase;
    color: #00a651;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
  }
`;

export const CenteredDivTitle = styled.div`
margin-bottom: 30px;

.after-sale-title {
  text-transform: uppercase;
  color: #00a651;
  display: flex;
  justify-content: center;
  font-size: 35px;
}
`;
