import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PurchasedQuotationsStyles } from '../PurchasedQuotations/PurchasedQuotations.styles';
import ErrorComponent from '../../common/components/ErrorComponent';
import {
  after_sale_purchase_statues,
} from '../../common/constants';
import { listAfterSales, resetListAfterSales } from '../../features/afterSale/afterSaleActions';

function AfterSalesList() {
  const state = useSelector((store) => store.afterSale.after_sales);

  const {
    loading, after_sales, error,
  } = state;

  // const [search, setSearch] = useState({
  //   search: '',
  //   date_from: moment().startOf('month').format('YYYY-MM-DD'),
  //   date_to: moment().format('YYYY-MM-DD'),
  //   quotation_status: 'all',
  // });

  const getAfterSalesType = (type) => {
    if (type === 'accident') return 'Sinistro';
    if (type === 'replacement') return 'Sostituzione';
    if (type === 'cancellation') return 'Cessazione';
    if (type === 'modification') return 'Modifica';
    if (type === 'suspension') return 'Sospensione';
    return '';
  };

  useEffect(() => {
    listAfterSales();
    return () => {
      resetListAfterSales();
    };
  }, []);

  const getItemLink = (item) => `/after-sale/purchase/${item.after_sale_type}/${item.id}`;

  return error === null ? (
    <PurchasedQuotationsStyles>
      <h5 className="mb-0 mt-5 mt-lg-0 text-center">Post Vendita Attive</h5>
      <div className="d-flex flex-md-row flex-column-reverse">
        <div className="col-xl-12 col-md-12 col-lg-12 ">
          <div className="mt-3 not-saved-quotations-one">
            {loading ? (
              <div className="d-flex justify-content-center align-items-center flex-column">
                <div className="spinner-border" role="status" />
                <span>Attendere prego...</span>
              </div>
            ) : (
              <ul className="list-group ">
                {after_sales.length > 0 ? (
                  after_sales.map((item, index) => (
                    <li
                      key={index}
                      className="not-saved-quotations-two list-group-item d-flex flex-sm-row flex-column justify-content-between align-items-start align-items-sm-center"
                    >
                      <div className="ms-1 me-auto mb-2 mt-1 mt-sm-0 mb-sm-0 ">
                        {item.quotation.active_customer
                           && (
                           <div className="fw-bold">
                             Cliente:
                             {' '}
                             {item.quotation.active_customer.gender === 'G'
                               ? item.quotation.active_customer.business_name : `${item.quotation.active_customer.name} ${item.quotation.active_customer.surname}`}
                           </div>
                           )}
                        {item.quotation.insurance_request.customer
                            && (
                            <div className="fw-bold">
                              Cliente:
                              {' '}
                              {item.quotation.insurance_request.customer
                                .gender !== 'G'
                                ? `${item.quotation.insurance_request.customer.name} ${item.quotation.insurance_request.customer.surname}`
                                : item.quotation.insurance_request.customer
                                  .business_name}
                            </div>
                            )}
                        {item.quotation.insurance_request.product
                        && (
                        <div>
                          <p className="m-0">
                            Prodotto:
                            {' '}
                            {item.quotation.insurance_request.product.name }
                          </p>
                        </div>
                        )}
                        <div>
                          <p className="m-0">
                            Tipo:
                            {' '}
                            {getAfterSalesType(item.after_sale_type)}
                          </p>
                        </div>
                        {item.quotation.insurance_request && item.quotation.insurance_request.vehicle
                              !== null && (
                              <div>
                                <span className="me-1"> Targa:</span>
                                {
                                  item.quotation.insurance_request.vehicle
                                    .vehicle_plate
                                }
                              </div>
                        )}
                        {item.quotation.insurance_request && item.quotation.insurance_request.moto && (
                        <div>
                          <span className="me-1"> Targa:</span>
                          {
                                  item.quotation.insurance_request.moto
                                    .moto_plate
                                }
                        </div>
                        )}
                        {item.after_sale_type == 'accident'
                            && (
                            <div>
                              <span className="me-1">Indirizio:</span>
                              <span>{item.address}</span>
                            </div>
                            )}
                        <small>
                          <strong>
                            Creato:
                            {' '}
                            {new Date(item.created_at).toLocaleDateString(
                              'it-IT',
                              {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                              },
                            )}
                          </strong>
                        </small>
                      </div>
                      <div className="me-4 my-2">
                        <span className="badge bg-dark">
                          {
                            after_sale_purchase_statues.find(
                              (status) => status.id === Number(item.status)
                                || status.id === Number(item.status_id),
                            )?.name
                          }
                        </span>
                      </div>
                      <Link to={getItemLink(item)}>
                        <span className="badge btn-to-quote px-4 py-2 rounded-3">
                          Vai a
                          {' '}
                          {getAfterSalesType(item.after_sale_type)}
                        </span>
                      </Link>
                    </li>
                  ))
                ) : (
                  <li className="list-group-item d-flex justify-content-between align-items-start not-saved-quotations-no-result">
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Nessuna richiesta trovata!</div>
                    </div>
                  </li>
                )}
              </ul>
            )}
          </div>
        </div>
      </div>
    </PurchasedQuotationsStyles>
  ) : (
    <ErrorComponent error={error} />
  );
}

export default AfterSalesList;
