import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import Joyride from 'react-joyride';
import { useSelector } from 'react-redux';
import moment from 'moment';
import PageLoading from '../../common/components/elements/PageLoading';
import { getReplacement, resetReplacement } from '../../features/afterSale/afterSaleActions';
import FileUpload from '../../common/components/inputs/FileUpload';
import { apiAfterSaleOptionalDocumentMethodManualUpload, apiDownloadAfterSaleOptionalDocument } from '../../services/axios-client/axeCommons';
import { generalAlertError, generalAlertSuccessToast } from '../../common/utils/alerts';
import managementAxe from '../../services/axios-client/managementServisAxeClient';
import ErrorComponent from '../../common/components/ErrorComponent';
// import { useTourContext } from '../../context/useTourContext';
// import { joyrideOptions } from '../../common/utils/joyrideUtils';
// import { handleCallbackReplacement } from '../../common/utils/joyrideCallbacks';
// import { replacementSteps } from '../../common/utils/joyrideSteps';
import PaymentStatusAfterSale from '../../common/components/redesignComponents/Purchase/PaymentStatusAfterSale';
import { QuoteInfoContainerAfterSale, WaitingForPaymentQuoteInfoAfterSale } from '../../common/components/redesignComponents/Purchase/PaymentStatusAfterSale.styles';
import { after_sale_purchase_statues, getReasonForReplacement } from '../../common/constants';
import AfterSaleDocumentComponent from '../../common/components/redesignComponents/Cards/AfterSaleDocumentComponent';
import AfterSaleToSignDocumentComponent from '../../common/components/redesignComponents/Cards/AfterSaleToSignDocumentComponent';

export function ReplacementPage() {
  const navigate = useNavigate();
  const { replacementId } = useParams();
  // const [steps, setSteps] = useState([]);

  const state = useSelector((store) => store.afterSale.replacement_page);
  const { loading, replacement, error } = state;

  // const {
  //   setState,
  //   state: { run, showBeacon },
  // } = useTourContext();

  // useEffect(() => {
  //   if (localStorage.getItem('singleReplacement') === 'done') {
  //     setState({ run: false });
  //   } else {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 1500);
  //   }
  // }, [localStorage.getItem('singleReplacement')]);

  // const fireNewQuoteTour = () => {
  //   localStorage.removeItem('singleReplacement');
  //   setState({ run: true, tourActive: true });
  // };

  // useEffect(() => {
  //   replacementSteps(replacement, setSteps);
  // }, [replacement]);

  useEffect(() => {
    getReplacement(replacementId);
    return () => {
      resetReplacement();
    };
  }, []);

  const downloadDocument = async (replacementRequestDocumentId) => {
    const response = await managementAxe.get(`/broker/after-sale/replacement/documents/${replacementRequestDocumentId}/download`);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const downloadOptionalDocument = async (documentId) => {
    const response = await apiDownloadAfterSaleOptionalDocument(documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const showAfterSaleUploadedDocuments = () => {
    const docs = [
      ...replacement.after_sale_optional_documents,
    ];

    return (
      <div className="ps-3 mt-3">
        {docs.length === 0 && (
          <p className="ps-3">Nessun documento caricato.</p>
        )}

        {docs.map((doc) => (
          <AfterSaleToSignDocumentComponent key={doc.id} document={doc} downloadDocument={downloadOptionalDocument} />
        ))}

      </div>
    );
  };

  const uploadSignedOptionalDocument = async (documentId, file) => {
    const uploadedFile = file[0];
    const reader = new FileReader();
    reader.readAsDataURL(uploadedFile);
    if (uploadedFile.type !== 'application/pdf') {
      generalAlertError('Formato di file errato. Il file deve essere in .pdf');
      return;
    }
    reader.onload = () => {
      apiAfterSaleOptionalDocumentMethodManualUpload(documentId, {
        document: reader.result.replace('data:application/pdf;base64,', ''),
      }).then(() => {
        getReplacement(replacementId);
        generalAlertSuccessToast('File caricato');
      });
    };
  };

  if (loading) {
    return <PageLoading />;
  }

  if (error !== null) {
    return <ErrorComponent error={error} />;
  }

  if (replacement && replacement.status !== 5) {
    navigate(`/after-sale/purchase/replacement/${replacement.id}`);
  }

  return (
    <>
      {/* <Joyride
        steps={steps}
        continuous
        showBeacon={showBeacon}
        locale={{
          back: 'Indietro',
          close: 'Chiudi',
          last: 'Fine',
          next: 'Prossima',
          open: 'Apri il modale',
          restart: 'Riavvia il tour ',
          skip: 'Salta',
        }}
        styles={{
          options: joyrideOptions,

          buttonNext: {
            backgroundColor: '#00a651',
            outline: 'none',
          },
          buttonBack: {
            color: '#00a651',
            outline: 'none',
          },
          beaconInner: {
            backgroundColor: '#00a651',
          },
          beaconOuter: {
            borderColor: '#00a651',
            backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
          },
          buttonSkip: {
            outline: 'none',
          },
          buttonClose: {
            outline: 'none',
          },
        }}
        run={run}
        callback={(data) => handleCallbackReplacement(data, steps, setState)}
        showSkipButton
        showProgress
      /> */}
      <div className="row">
        <div className="d-flex gap-2">
          <div className="btn btn-success btn-sm text-white px-4 mb-4" onClick={() => navigate('/replacements')}>Torna indietro</div>
          {/* {localStorage.getItem('singleReplacement') === 'done' && (
              <div style={{ cursor: 'pointer' }} onClick={() => fireNewQuoteTour()}>
                <button className="btn btn-success btn-sm text-white px-4 mb-4">Tutorial</button>
              </div>
            )} */}
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <WaitingForPaymentQuoteInfoAfterSale>
            <QuoteInfoContainerAfterSale>
              <h3 className="ps-3">
                <strong>
                  Sostituzione #
                  {replacement.id}
                </strong>
              </h3>
              <div className="single-replacement-one ps-3 mb-4 mt-0">
                {replacement.quotation.insurance_request.customer && (
                  <div>
                    <p className="my-1">
                      <strong> Cliente:</strong>
                      {' '}
                      <span>
                        {replacement.quotation.insurance_request.customer.business_name === null ? `${replacement.quotation.insurance_request.customer.name} ${replacement.quotation.insurance_request.customer.surname}` : replacement.quotation.insurance_request.customer.business_name}
                      </span>
                    </p>
                    <p className="my-1">
                      <strong> Stato:</strong>
                      {' '}
                      <div className="badge bg-success">{after_sale_purchase_statues.find((status) => status.id == replacement.status)?.name}</div>
                    </p>
                  </div>
                )}
                {replacement.quotation.insurance_request.product
                  && (
                    <div>
                      <p className="my-1">
                        <strong> Prodotto:</strong>
                        {' '}
                        <span>
                          {replacement.quotation.insurance_request.product.name}
                        </span>
                      </p>
                    </div>
                  )}
                {replacement.quotation.company
                  && (
                    <div>
                      <p className="my-1">
                        <strong>Compagnia:</strong>
                        {' '}
                        <span>
                          {replacement.quotation.company.name}
                        </span>
                      </p>
                    </div>
                  )}
                {(replacement.quotation.insurance_request.vehicle !== null) && (
                  <div>
                    <p className="my-1">
                      <strong>Targa:</strong>
                      {' '}
                      <span>
                        {replacement.quotation.insurance_request.vehicle.vehicle_plate}
                      </span>
                    </p>
                  </div>
                )}
                {(replacement.quotation.insurance_request.moto !== null) && (
                  <div>
                    <p className="my-1">
                      <strong>Targa:</strong>
                      {' '}
                      <span>
                        {replacement.quotation.insurance_request.moto.moto_plate}
                      </span>
                    </p>
                  </div>
                )}
                {(replacement.quotation.insurance_request.vehicle && replacement.quotation.insurance_request.vehicle.old_vehicle_plate !== null)
                  && (
                    <div>
                      <p className="my-1">
                        <strong>Vecchia Targa:</strong>
                        {' '}
                        <span>
                          {replacement.quotation.insurance_request.vehicle.old_vehicle_plate}
                        </span>
                      </p>
                    </div>
                  )}
                {(replacement.quotation.insurance_request.moto && replacement.quotation.insurance_request.moto.old_moto_plate !== null)
                  && (
                    <div>
                      <p className="my-1">
                        <strong>Vecchia Targa:</strong>
                        {' '}
                        <span>
                          {replacement.quotation.insurance_request.moto.old_moto_plate}
                        </span>
                      </p>
                    </div>
                  )}
                <div>
                  <p className="my-1">
                    <strong>Motivi della sostituzione:</strong>
                    {' '}
                    <span>{getReasonForReplacement(replacement?.replacement_reason)}</span>
                  </p>
                </div>
                <div>
                  <p className="my-1">
                    <strong>
                      Creato:
                    </strong>
                    {' '}
                    <span>
                      {new Date(replacement.created_at).toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                    </span>
                  </p>
                </div>
                <div>
                  <p className="my-1">
                    <b>Note:</b>
                  </p>
                  <p className="m-0">
                    {replacement.note}
                  </p>
                </div>
              </div>
            </QuoteInfoContainerAfterSale>
          </WaitingForPaymentQuoteInfoAfterSale>
        </div>
        <div className="col-12 col-md-6">
          {(replacement.after_sale_payment_request && replacement.after_sale_payment_request.is_paid === 1) && (
            <div className="row">
              <div className="col-12 mt-2 mt-xxl-0 payment-information mb-3">
                <WaitingForPaymentQuoteInfoAfterSale>
                  <QuoteInfoContainerAfterSale>
                    <div>
                      <h3 className="ps-3 mb-4 mt-0">
                        Detagli
                        {' '}
                        <b>Pagamento</b>
                      </h3>
                    </div>
                    <div className="ps-3 mb-4 mt-0">
                      <p className="my-1">
                        <strong> Premio:</strong>
                        {' '}
                        <span>
                          {parseFloat(replacement.after_sale_payment_request.amount)}
                          {' '}
                          &euro;
                        </span>
                      </p>
                      <p className="my-1">
                        <strong>Stato Pagamento:</strong>
                        {' '}
                        <span>{replacement.after_sale_payment_request.is_paid === 1 ? 'Pagato' : replacement.after_sale_payment_request.is_paid === 0 ? 'Pagamento Non valido' : 'In Attesa Pagamento'}</span>
                      </p>
                      <p className="my-1">
                        <strong>Data Richiesta:</strong>
                        {' '}
                        <span>{moment(replacement.after_sale_payment_request.created_at).format('DD-MM-YYYY')}</span>
                      </p>
                      <p className="my-1">
                        <strong>Informazione Pagamento:</strong>
                        {' '}
                        <span>{replacement.after_sale_payment_request.payment_description}</span>
                      </p>
                    </div>

                  </QuoteInfoContainerAfterSale>
                </WaitingForPaymentQuoteInfoAfterSale>
              </div>
              <div className="col-12 mt-2 mt-xxl-0 payment-information mb-3">
                <WaitingForPaymentQuoteInfoAfterSale>
                  <QuoteInfoContainerAfterSale>
                    <h3 className="ps-3 mb-4 mt-0">Polizza</h3>
                    <div className="ps-3">
                      {replacement.documents.find((d) => d.after_sale_document_type_id === 3) === undefined && (
                        <p className="mt-3">Nessun documento caricato.</p>
                      )}
                      {replacement.documents.filter((d) => d.after_sale_document_type_id === 3).map((document) => (
                        <AfterSaleDocumentComponent key={document.id} document={document} downloadDocument={downloadDocument} />
                      ))}
                    </div>

                  </QuoteInfoContainerAfterSale>
                </WaitingForPaymentQuoteInfoAfterSale>

              </div>

            </div>
          )}
        </div>
      </div>

      {(replacement.after_sale_payment_request && replacement.after_sale_payment_request.is_paid === null) && <PaymentStatusAfterSale afterSale={replacement} afterSalePaymentRequest={replacement.after_sale_payment_request} collectData={getReplacement} />}

      <div className="row mt-3">
        <div className="col-12 col-xl-6">
          <WaitingForPaymentQuoteInfoAfterSale>
            <QuoteInfoContainerAfterSale>
              <div className="single-replacement-two">
                <h3 className="ps-3">
                  <strong>
                    Documenti
                  </strong>
                </h3>
                {replacement.documents.length !== 0
                  ? (
                    <>

                      <div className="ps-3 mt-4">

                        {
                          replacement.documents.filter((d) => d.after_sale_document_type_id !== 3).map((document) => (
                            <AfterSaleDocumentComponent key={document.id} document={document} downloadDocument={downloadDocument} />
                          ))
                        }
                      </div>
                    </>
                  ) : <p className="ms-3 mt-3"> Nessun Documento Trovato</p>}
              </div>

            </QuoteInfoContainerAfterSale>
          </WaitingForPaymentQuoteInfoAfterSale>
        </div>
        <div className="col-12 col-xl-6">
          <WaitingForPaymentQuoteInfoAfterSale>
            <QuoteInfoContainerAfterSale>
              <h3 className="ps-3">
                <strong>
                  Documenti Da Firmare
                </strong>
              </h3>
              <div>
                {showAfterSaleUploadedDocuments()}
              </div>
              <div className="col-12 mt-3 mt-xl-0 d-flex single-replacement-three">
                <div className="col-6 mt-3 mt-xl-0">
                  <div className="card ps-3">
                    <div className="card-body px-0">
                      {replacement.after_sale_optional_documents.filter((document) => document.signable_document === 1 && document.is_uploaded_valid === 1 && ((document.is_signed_valid === 0 && document.signed_document_filename !== null) || (document.is_signed_valid === null && document.signed_document_filename === null))).map((file) => (
                        <div className="mb-3">
                          <FileUpload
                            key={file.id}
                            multiple={false}
                            label={`CARICA ${file.optional_document_name.toUpperCase()}`}
                            handleFile={(f) => uploadSignedOptionalDocument(file.id, f)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </QuoteInfoContainerAfterSale>
          </WaitingForPaymentQuoteInfoAfterSale>
        </div>
      </div>

    </>
  );
}
