import { useNavigate } from 'react-router-dom';
import axe from '../../services/axios-client/axiosClient';
import { generalAlertError } from '../utils/alerts';
import { BACKOFFICE_PANEL_URL } from '../../config';

export default function NotificationItem({ notification }) {
  const navigate = useNavigate();
  const getProductNameForUrl = (id) => {
    if (id === 1) return 'auto';
    if (id === 2) return 'moto';
    if (id === 3) return 'autocarro';
    if (id === 4) return 'profession';
    if (id === 5) return 'metlife';
    if (id === 6) return 'infodrive';
    if (id === 8) return 'travel';
    if (id === 9) return 'tutela-legale';
    if (id === 10) return 'commercial';
    if (id === 11) return 'casa';
    if (id === 12) return 'salute';
    if (id === 13) return 'ancillari-auto';

    return null;
  };

  const getUrl = () => {
    const payload = notification.event_payload;
    if (payload.status && payload.status == 7) {
      return navigate(
        `/quotes/purchased/${payload.id}/quotation/${getProductNameForUrl(payload.product_id)}`,
      );
    }
    if (notification.event === 'thread') {
      return navigate(
        `/messages/${payload.id}`,
      );
    }
    if (notification.event === 'ticket') {
      return navigate(
        `/tickets/${payload.id}`,
      );
    }
    if (
      (notification.event === 'quotation.status_change'
        || notification.event === 'quotation.amount_change')
      && payload.product_id
    ) {
      return navigate(
        `/quotes/${getProductNameForUrl(payload.product_id)
        }/purchase/${payload.request_token}`,
      );
    }
    if (notification.event === 'replacement-requests.document' || notification.event === 'replacement-requests') {
      return navigate(
        `/replacements/${payload.id}`,
      );
    }
    if (notification.event === 'modification-requests.document' || notification.event === 'modification-requests') {
      return navigate(
        `/modifications/${payload.id}`,
      );
    }
    if (notification.event === 'replacement-requests.document' || notification.event === 'replacement-requests' || notification.event === 'replacement_request') {
      return navigate(
        `/replacements/${payload.id}`,
      );
    }
    if (notification.event === 'modification-requests.document' || notification.event === 'modification-requests' || notification.event === 'modification_request') {
      return navigate(
        `/modifications/${payload.id}`,
      );
    }
    if (notification.event === 'cancellation-requests.document' || notification.event === 'cancellation-requests' || notification.event === 'cancellation_request') {
      return navigate(
        `/cancellations/${payload.id}`,
      );
    }

    if (notification.event === 'suspension-requests.document' || notification.event === 'suspension-requests' || notification.event === 'suspension_request') {
      return navigate(
        `/suspensions/${payload.id}`,
      );
    }
    if (notification.event === 'accident') {
      return navigate(
        `/accidents/${payload.id}`,
      );
    }
    if (notification.event === 'compensation.validation') {
      return navigate(
        '/profile/',
      );
    }
    if (notification.event === 'renewals-generated') {
      return navigate('/renewals');
    }

    if (notification.event === 'quotation.new_quotation') {
      if (payload.product_id == 1) {
        return navigate(`/quotes/${payload.request_token}`);
      }
      if (payload.product_id == 2) {
        return navigate(`/quotes/moto/${payload.request_token}`);
      }
      if (payload.product_id == 3) {
        return navigate(`/quotes/${payload.request_token}`);
      }
      return navigate(`/quotes/${payload.request_token}`);
    }

    if (notification.event === 'replacements') {
      return navigate(`/replacements/${payload.id}`);
    }

    if (notification.event === 'modifications') {
      return navigate(`/modifications/${payload.id}`);
    }

    if (notification.event === 'cancellations') {
      return navigate(`/cancellations/${payload.id}`);
    }

    if (notification.event === 'accidents') {
      return navigate(`/accidents/${payload.id}`);
    }

    return null;
  };

  const loginToPanel = async () => {
    const res = await axe.post('/backoffice/signin');

    if (res.status !== 200) {
      generalAlertError("Qualcosa e andato storto! Riprova o chiama l'amministratore dell sistema!");
    }
    window.open(`${BACKOFFICE_PANEL_URL}/broker-back-office/login/${res.data.data.token}`, '_blank');
  };

  return (
    <div
      className={`alert alert-${notification.is_read === 0 ? 'success' : 'secondary'
      } mt-2 py-2 py-sm-4`}
    >
      <div className="d-flex flex-column flex-sm-row align-items-center justify-content-between">
        <span>{notification.text}</span>
        {notification.event === 'quotation.to_capture' ? (
          <div
            onClick={() => loginToPanel()}
            className={`btn btn-sm m-2 py-2 btn-${notification.is_read === 0 ? 'success' : 'secondary'
            }`}
          >
            Vai
          </div>
        ) : (
          <div
            onClick={() => getUrl()}
            className={`btn btn-sm mt-3 mt-sm-0 py-2 px-3 btn-${notification.is_read === 0 ? 'success' : 'secondary'
            }`}
          >
            Vai
          </div>
        )}
      </div>
    </div>
  );
}
