import styled from 'styled-components';

export const SignatureTypeWrapper = styled.div`
  border-radius: 30px;
  padding: 20px 30px;
  border: 2px solid #dbdde6;
  background: #fff;
  height: 100%;

  &.document-signed-uploaded{
  border: 2px solid #00A551;

  }

  @media (max-width: 1200px) {
    padding: 10px 15px;
  }
`;

export const MainTitle = styled.h3`
  color: var(--common-black, #1a1921);
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 0;
  flex: 1;
`;

export const NumberCurrent = styled.div`
  background-color: rgb(0, 166, 81);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 50%;
  font-weight: bold;
  margin-right: 3px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 1200px) {
    justify-content: center;
    margin-top: 10px;
  }
`;
export const ButtonDigital = styled.div`
  border-radius: 18px;
  padding: 20px 20px;
  border: 2px solid #dbdde6;
  width: 180px;
  text-align: center;
  z-index: 999;
  margin-right: -8%;
  background-color: #ffffff;
  color: #343434;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  transition: all 300ms linear;

  &.active-signature {
    z-index: 9999;
    background-color: #99dbb9;
    border-color: #99dbb9;
    transition: all 300ms linear;
  }

  @media (max-width: 1200px) {
    padding: 15px;
    font-size: 13px;

    &.active-signature {
      padding: 15px;
    }
  }
`;
export const ButtonManual = styled.div`
  border-radius: 18px;
  padding: 20px 20px;
  border: 2px solid #dbdde6;
  width: 180px;
  text-align: center;
  background-color: #ffffff;
  color: #343434;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  transition: all 300ms linear;


  &.active-signature {
    z-index: 1000;
    background-color: #99dbb9;
    border-color: #99dbb9;
    transition: all 300ms linear;
  }

  @media (max-width: 1200px) {
    padding: 15px;
    font-size: 13px;

    &.active-signature {
      padding: 15px;
    }
  }
`;

export const TitleButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const SignatureInfoWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin-top: 30px;
`;

export const TextContainer = styled.div`
  max-width: 400px;

  h3 {
    color: #343434;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  @media (max-width: 1200px) {
    h3 {
      font-size: 14px;
    }
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  img {
    max-width: 50px;
    transform: rotate(180deg);
  }
`;

export const WarningContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  p {
    color: #343434;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 0;
  }

  @media (max-width: 1200px) {
    p {
      font-size: 11px;
    }
  }
`;

export const SignaturePaperContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const DocumentInfo = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  border-radius: 15px;
  border: 2px dashed #00A551;
  padding: 12px;
  background: #fff;
  min-height: 150px;

  @media (max-width: 1200px) {
    padding: 10px;
  }
`;

export const DocumentInfoTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const StatusBadge = styled.div`
  border-radius: 8px;
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 4px 8px;

  @media (max-width: 1200px) {
    padding: 4px 8px;
    font-size: 10px;
    border-radius: 8px;
  }
`;

export const FileInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  p {
    text-wrap: wrap;
    color: #343434;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
  }

  @media (max-width: 1200px) {
    p {
      font-size: 12px;
    }
  }
`;

export const DocumentFileActions = styled.div`
  p {
    color: #343434;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    margin-top: 15px;
    margin-bottom: 5px;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;
  margin-top: 10px;

  .action-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;

    p {
      color: #343434;
      font-family: Poppins;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: auto;
    }
  }

  @media (max-width: 1200px) {
    img {
      width: 20px;
    }
    p {
      font-size: 13px !important;
    }
  }
`;

export const ActionsWrapperResultPage = styled.div`
.action-button {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.action-button img {
  margin-right: 5px;
}

.doc-btn {
  margin-left: 10px;
}

`;

export const SignatureDigitalContainer = styled.div`
  h3 {
    color: #343434;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 20px;
  }
`;

export const SelectEmailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;

  @media (max-width: 1200px) {
  }
`;

export const InputContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const InputCircle = styled.div`
  fill: #fff;
  border: 2px solid #99dbb9;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: relative;

  &.selected-input {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.selected-input::before {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: #99dbb9;
    border-radius: 50%;
  }

  @media (max-width: 1200px) {
    width: 20px;
    height: 20px;

    &.selected-input::before {
      width: 12px;
      height: 12px;
    }
  }
`;

export const InputText = styled.p`
  color: #343434;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;

  @media (max-width: 1200px) {
    font-size: 12px;
  }
`;

export const SendEmailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  gap: 20px;

  button {
    border-radius: 15px;
    background: #99dbb9;
    width: 45%;
    border: 2px solid #99dbb9;
    padding: 12px 15px;
    color: #343434;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    transition: all 250ms linear;

    &:hover {
      border: 2px solid #99dbb9;
      background-color: transparent;
    }
  }

  @media (max-width: 1200px) {
    flex-direction: column;

    button {
      margin-left: auto;
      width: 70%;
    }
  }
`;

export const InputEmail = styled.input`
  margin-top: 15px;
  padding: 8px 15px;
  border-radius: 12px;
  border: 2px solid #dbdde6;
  background: #fff;
  width: 60%;

  &:focus,
  &:active,
  &:focus-visible {
    border: 2px solid #dbdde6;
    outline: none;
  }

  ::placeholder {
    color: #dbdde6;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

export const SignatureDigitalSentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;

  button {
    display: table;
    margin-left: auto;
    margin-top: 35px;
    border-radius: 15px;
    background: #ffc907;
    width: 45%;
    border: 2px solid #ffc907;
    padding: 12px 15px;
    color: #343434;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    transition: all 250ms linear;

    &:hover {
      border: 2px solid #ffc907;
      background-color: transparent;
    }
  }

  @media (max-width: 1200px) {
    button {
      margin-left: auto;
      width: 70%;
    }
  }
`;

export const SkipSignedDocs = styled.div`
  border-radius: 30px;
  padding: 20px 30px;
  background: #fff;
  border: 2px solid #dbdde6;
  height: 100%;

  &.skip-doc{
    border: 2px solid #00A551;
  }

  label.label-privacy {
    font-size: 12px;
}

  p{
    font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  }

  @media (max-width: 1200px) {
    padding: 10px 15px;
  }`;
