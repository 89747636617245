import React from 'react';
import { BACKEND_BASE_URL } from '../../../../../config';
import { guideType, maxBasedBrokerId } from '../../../../constants';
import {
  PreventivassCardStyles, LeftCol, LogoContainer, LogoPriceContainer, PriceContainer, QuoteInformationContainer, QuoteInformationItem, RightCol, Price,
} from '../../../redesignComponents/Cards/PreventivassCard.styles';

function PreventivassCardNoAquista({ quotation, requestInfo }) {
  return (
    <PreventivassCardStyles className="mt-2">
      <LeftCol>
        {quotation.preventivas_company_quality
          && (
            <div className="row mb-3">
              <div className="col-12">
                <div className={`p-2 badge ${quotation.preventivas_company_quality.color === 'green' ? 'bg-success' : quotation.preventivas_company_quality.color === 'yellow' ? 'bg-warning' : 'bg-danger'}`}>
                  <p className="mb-0 d-flex align-items-center justify-content-between gap-2">
                    Indice Di Qualità:
                    {' '}
                    <b className="fs-6">{parseFloat(quotation.preventivas_company_quality.coefficiente).toFixed(2)}</b>
                  </p>
                </div>
              </div>
            </div>
          )}
        <LogoPriceContainer>
          <LogoContainer>
            <img src={`${BACKEND_BASE_URL}/preventivass/companies/${quotation.logo_filename}`} alt={`${quotation.company_name} Logo`} />
          </LogoContainer>
          <PriceContainer>
            <Price>
              <h5>
                Compagnia:
              </h5>
              <h3 className="mt-2">
                {quotation.company_name}
              </h3>
            </Price>
          </PriceContainer>
        </LogoPriceContainer>
        <QuoteInformationContainer>
          <QuoteInformationItem>
            <h6>
              Guida:
            </h6>
            <p>
              {guideType.find((v) => v.id === requestInfo.guide_type).name}
            </p>
          </QuoteInformationItem>
          <QuoteInformationItem>
            <h6>
              Risarcimento:
            </h6>
            <p>
              Rc con
              {' '}
              <b>Risarcimento</b>
              {' '}
              <br />
              {' '}
              <b>Diretto</b>
              {' '}
              e
              {' '}
              <b>RFS</b>
            </p>
          </QuoteInformationItem>
          <QuoteInformationItem>
            <h6>
              Massimale:
            </h6>
            <p>
              <b>
                {maxBasedBrokerId[requestInfo.broker_agent_id] ? maxBasedBrokerId[requestInfo.broker_agent_id][0] : "6'450'000"}
                &euro;
              </b>
              {' '}
              Danni a persone
              <br />
              <b>{maxBasedBrokerId[requestInfo.broker_agent_id] ? maxBasedBrokerId[requestInfo.broker_agent_id][0] : "1'050'000"}</b>
              {' '}
              Danni a cose
            </p>
          </QuoteInformationItem>
        </QuoteInformationContainer>
      </LeftCol>
      <RightCol>
        <PriceContainer>
          <Price>
            <h5>
              Prezzo annuo:
            </h5>
            <h2>
              &euro;
              {Number(quotation.amount).toLocaleString('it-IT', {
                minimumFractionDigits: 2,
              })}
            </h2>
          </Price>
        </PriceContainer>
      </RightCol>
    </PreventivassCardStyles>
  );
}

export default PreventivassCardNoAquista;
