import caller from './quotationsSlice';
import managementAxe from '../../services/axios-client/managementServisAxeClient';

export const listQuotationsPurchasedRequests = async (page, search) => {
  caller.listQuotationsPurchasedPending();
  try {
    const res = await managementAxe.get('/broker/quotes/purchased/list', {
      params: {
        ...search,
        page,
      },
    });

    if (res.status !== 200) {
      caller.listQuotationsPurchasedReject('Errore Interno');
      return;
    }

    caller.listQuotationsPurchasedSuccess(res.data);
  } catch (error) {
    caller.listQuotationsPurchasedReject('Errore Interno');
  }
};

export const resetQuotationsPurchasedRequests = () => {
  caller.quotationsPurchasedReset();
};

export const listQuotationsSemestralToPay = async (page, search) => {
  caller.listQuotationsSemestralToPayPending();
  try {
    const searchParams = {
      ...search,
      page,
    };
    if (searchParams.search !== '') {
      delete searchParams.date_from;
      delete searchParams.date_to;
    }

    const res = await managementAxe.get('/broker/quotes/semestral-quotation-to-pay', {
      params: searchParams,
    });

    if (res.status !== 200) {
      caller.listQuotationsSemestralToPayReject('Errore Interno');
      return;
    }

    caller.listQuotationsSemestralToPaySuccess(res.data);
  } catch (error) {
    caller.listQuotationsSemestralToPayReject('Errore Interno');
  }
};

export const resetQuotationsSemestralToPay = () => {
  caller.quotationsSemestralToPayReset();
};
