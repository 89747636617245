import React from 'react';
import { UploadedDocumentWrapper } from '../../screens/purchase/Purchase.styles';
import { Uploader } from '../components/redesignComponents/Uploader/Uploader';
import pdfUploaded from '../../assets/redesign_icons/file.svg';
import axe from '../../services/axios-client/axiosClient';
import { generalAlertSuccessToast } from '../utils/alerts';
import managementAxe from '../../services/axios-client/managementServisAxeClient';

function AfterSaleReplacementDocuments({
  afterSale,
  document_types,
  collectQuoteData,
  afterSaleType,
}) {
  const uploadReplacementDocs = async (data) => {
    const response = await axe.post(`/after-sale/replacement/${afterSale.id}/upload/document`, data);
    return response;
  };

  const downloadDocument = async () => {
    const response = await managementAxe.get(`/broker/after-sale/${afterSaleType}/documents/${afterSale.id}/download`);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const downloadDocumentCancellation = async () => {
    const response = await managementAxe.get(`/broker/after-sale/cancellations/documents/${afterSale.id}/download`);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const uploadCancellationDoc = async (data) => {
    const response = await axe.post(`/after-sale/cancellation/${afterSale.id}/upload/document`, {
      content: data.content,
      original_filename: data.original_filename,
    });
    return response;
  };

  const uploadModificationDoc = async (data) => {
    const response = await axe.post(`/after-sale/modification/${afterSale.id}/upload/document`, {
      content: data.content,
      original_filename: data.original_filename,
    });
    return response;
  };

  const updloadDocument = (file, typeId) => {
    let fileInfo;
    let baseURL = '';
    const uploadedFile = file;
    // Make new FileReader
    const reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(uploadedFile);
    // on reader load pdf...
    reader.onload = async () => {
      fileInfo = uploadedFile.name;

      const fileType = uploadedFile.type;
      baseURL = reader.result;
      const data = {
        type: typeId,
        original_filename: fileInfo,
        content: baseURL.replace(`data:${fileType};base64,`, ''),
      };

      if (afterSaleType === 'replacement') {
        const response = await uploadReplacementDocs(data);

        if (response.status !== 200) {
          return;
        }
      }

      if (afterSaleType === 'cancellation') {
        const response = await uploadCancellationDoc(data);
        if (response.status !== 200) {
          return;
        }
      }

      if (afterSaleType === 'modification') {
        const response = await uploadModificationDoc(data);
        if (response.status !== 200) {
          return;
        }
      }

      collectQuoteData();
      generalAlertSuccessToast('File caricato');
    };
  };

  return (
    <>
      {afterSaleType === 'replacement' && document_types.map((type, index) => {
        const afterSaleDoc = afterSale.documents.find((doc) => doc.after_sale_document_type_id === type.id);
        return (
          <React.Fragment key={index}>
            {afterSaleDoc !== undefined ? (
              <UploadedDocumentWrapper>
                <img src={pdfUploaded} alt="" width={50} />
                <p onClick={() => downloadDocument()}>
                  Tocca qui per scaricare
                </p>
                <h3>
                  {afterSaleDoc.after_sale_document_type.name}
                </h3>
              </UploadedDocumentWrapper>
            ) : <Uploader onFileUpload={(file) => updloadDocument(file, type.id)} note={`CARICA ${type.name.toUpperCase()}`} />}
          </React.Fragment>
        );
      })}
      {afterSaleType === 'cancellation' && (
        <>
          {afterSale.documents.length !== 0
            ? (
              <UploadedDocumentWrapper>
                <img src={pdfUploaded} alt="" width={50} />
                <p onClick={() => downloadDocumentCancellation()}>
                  Tocca qui per scaricare
                </p>
                <h3>
                  DOCUMENTO CARICATO
                </h3>
              </UploadedDocumentWrapper>
            ) : <Uploader onFileUpload={(file) => updloadDocument(file, null)} note="Certificato di rottamazione / Constatazione amichevole" />}
        </>
      )}
      {afterSaleType === 'modification' && (
        <>
          {afterSale.documents.length !== 0
            ? (
              <UploadedDocumentWrapper>
                <img src={pdfUploaded} alt="" width={50} />
                <p onClick={() => downloadDocument()}>
                  Tocca qui per scaricare
                </p>
                <h3>
                  DOCUMENTO CARICATO
                </h3>
              </UploadedDocumentWrapper>
            ) : <Uploader onFileUpload={(file) => updloadDocument(file, null)} note="CARICA UN DOCUMENTO" />}
        </>
      )}
    </>
  );
}

export default AfterSaleReplacementDocuments;
