import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import pdfIMG from '../../../../assets/images/purchase/pdf.png';
import { AfterSaleDocumentListItem } from '../Purchase/PaymentStatusAfterSale.styles';

function AfterSaleDocumentComponent({ downloadDocument, document }) {
  return (
    <AfterSaleDocumentListItem>
      <div className="d-flex align-items-center justify-content-between mt-2">
        <div>
          {document.after_sale_document_type_name && (
            <p className="mb-2 fw-bold">
              <small>
                {document.after_sale_document_type_name}
              </small>
            </p>
          )}
          <div className="d-flex align-items-center justify-content-start">
            <img src={pdfIMG} className="img-fluid me-2" width="30" alt="PDF" />
            <div>
              <h5 className="h6 mb-0">
                {document.original_filename}
              </h5>
            </div>
          </div>
        </div>
        <button
          className="btn btn-success"
          onClick={() => downloadDocument(document.id)}
        >
          <FontAwesomeIcon icon={faDownload} size="lg" />
        </button>
      </div>
    </AfterSaleDocumentListItem>
  );
}

export default AfterSaleDocumentComponent;
