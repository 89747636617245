import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import PageLoading from '../../common/components/elements/PageLoading';
import {
  commercialGetBackofficeUsers,
  // confirmSelectedForBackofficeQuote,
  purchaseInsuranceRequest,
  resetCommercialPurchase,
} from '../../features/purchases/purchasesActions';
import { purchase_statues } from '../../common/constants';
import uploadIcon from '../../assets/redesign_icons/upload-icon.svg';
import WaitingForPaymentStatus from '../../common/components/redesignComponents/Purchase/WaitingForPaymentStatus';
import { SaveBox } from './common/SaveBox';
import {
  BadgeOptional,
  OptionalDocumentBox,
  PurchaseStyles,
  UploadDocumentsContainer,
  UploadWrapper,
} from './Purchase.styles';
import {
  NumberCurrent,
  SkipSignedDocs,
} from '../../common/components/redesignComponents/Purchase/SignatureType.styles';
import NonSignableDocumentComponent from './common/NonSignableDocumentComponent';
import NonSIgnableDocumentRequestComponent from './common/NonSIgnableDocumentRequestComponent';
import InfoBoxCommercial from '../../common/components/redesignComponents/Purchase/common/InfoBoxCommercial';
import {
  confirmAlert,
  generalAlertError,
  generalAlertSuccess,
} from '../../common/utils/alerts';
import {
  apiDeleteOptionalDocument,
  apiDownloadDocument,
  apiDownloadOptionalDocument,
  deleteDocument,
} from '../../services/axios-client/axeCommons';
import OptionalDocumentModal from '../../common/components/elements/OptionalDocumentModal';
import CompletedScreenCommercial from '../../common/components/elements/PurchaseAuto/CompletedScreenCommercial';
import axe from '../../services/axios-client/axiosClient';

function PurchaseCommercial() {
  const { insuranceRequestToken } = useParams();
  const navigate = useNavigate();
  const state = useSelector((store) => store.purchases.commercial);
  const {
    loading,
    quote,
    insuranceRequest,
    quoteStatus,
    backofficeUsers,
    rc_quotation,
    nonSignableDocs,
    requestedOptionalDocumentsNonSignable,
    risk_quotations,
  } = state;
  const [showOptionalDocumentModal, setShowOptionalDocumentModal] = useState(false);

  const [supportMessage, setSupportMessage] = useState({
    subject: '',
    request_id: '',
    body: '',
    recipient: '',
  });

  const collectQuoteData = async () => {
    purchaseInsuranceRequest(
      insuranceRequestToken,
      'commercial',
      setSupportMessage,
    );
  };

  const downloadDocument = async (documentId) => {
    const response = await apiDownloadDocument(documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const downloadOptionalDocument = async (documentId) => {
    const response = await apiDownloadOptionalDocument(documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const openOptionalDocumentModal = () => {
    setShowOptionalDocumentModal(true);
  };

  const agreedDocuments = async () => {
    try {
      const response = await axe.post(`/agree-to-upload-documents/${insuranceRequest.id}`, {
        agree_to_upload_document: 1,
      });
      if (response.status !== 200) {
        generalAlertError('Non Accetato');
        return;
      }

      collectQuoteData();
    } catch (error) {
      //
    }
  };

  useEffect(() => {
    if (insuranceRequest && insuranceRequest.redirect_user === true) {
      navigate('/');
      generalAlertSuccess('La pratica è completa, attendi nostro feedback!');
    }
  }, [insuranceRequest]);

  useEffect(() => {
    collectQuoteData();
    commercialGetBackofficeUsers();

    return () => {
      resetCommercialPurchase();
    };
  }, []);

  useEffect(() => {
    if (loading === false && rc_quotation.is_expired === true) {
      navigate('/quote/tutela-legale', {
        state: { requestToken: insuranceRequest.request_token },
      });
      generalAlertError(
        'È necessario compilare nuovamente il preventivo!',
        'Link e scaduto!',
      );
    }
  }, [loading, rc_quotation]);

  const removeDocument = async (documentId) => {
    try {
      const response = await deleteDocument(documentId);

      if (response.status === 200) {
        collectQuoteData();
      }
    } catch (err) {
      // console.error(err);
    }
  };

  const removeOptionalDocument = async (documentId) => {
    try {
      const response = await apiDeleteOptionalDocument(documentId);

      if (response.status === 200) {
        collectQuoteData();
      }
    } catch (err) {
      // console.error(err);
    }
  };

  const navigateBack = () => {
    navigate(`/quotes/commercial/${insuranceRequest.request_token}`);
  };

  // const acceptSelectedForBackoffice = () => {
  //   confirmSelectedForBackofficeQuote(rc_quotation.id, collectQuoteData);
  // };

  useEffect(() => {
    if (insuranceRequest !== null && insuranceRequest.should_redirect === 1) {
      navigate(`/quotes/${insuranceRequest.request_token}`);
      generalAlertError(
        'Se vuoi selezionare un altro preventivo devi fare modifica dati!',
      );
    }
  }, [insuranceRequest]);

  const checkIfAllNonSignableDocsAreUploaded = () => {
    let allUploaded = true;
    const allDocs = [
      ...insuranceRequest.documents.filter((d) => d.signable_document === 0),
      ...rc_quotation.documents.filter((d) => d.signable_document === 0),
    ];

    const nonSignaleDocTypeIds = nonSignableDocs.map((d) => d.id);

    for (let i = 0; i < allDocs.length; i++) {
      const document = allDocs[i];

      if (!nonSignaleDocTypeIds.includes(document.document_type_id)) {
        allUploaded = false;
      }
    }

    if (
      allDocs.length === 0
      || nonSignaleDocTypeIds.length !== allDocs.length
    ) {
      return false;
    }

    return allUploaded;
  };

  if (loading) {
    return <PageLoading />;
  }

  return (
    <PurchaseStyles>
      {quoteStatus !== 7 && (
        <>
          <div className="container mt-0 gap-2 align-items-center justify-content-start mb-3">
            <div className="px-2 py-1 d-flex align-items-center gap-2">
              <div
                className="cursor-pointer d-flex align-items-center justify-content-start gap-2 fw-bold text-success"
                onClick={() => navigateBack()}
              >
                <div id="back-button">
                  <FontAwesomeIcon icon={faArrowLeftLong} />
                </div>
                <p className="ml-2 mb-0  cursor-pointer">Modifica Preventivo</p>
              </div>
              {/* <div>

                {localStorage.getItem('purchaseTour') === 'done' && (
                  <div style={{ cursor: 'pointer' }} onClick={() => firePurchaseTour()}>
                    <button className="btn btn-success px-2 py-1">Tutorial</button>
                  </div>
                )}
              </div> */}
            </div>
            {/* <AutoStepper quoteStatus={quoteStatus} /> */}
            <InfoBoxCommercial
              quote={quote}
              rc_quotation={rc_quotation}
              insuranceRequest={insuranceRequest}
              statues={purchase_statues}
              quoteStatus={quoteStatus}
              risk_quotations={risk_quotations}
              messagesModalData={{
                backofficeUsers,
                setSupportMessage,
                supportMessage,
                collectData: collectQuoteData,
              }}
            />
          </div>
          <div className="container mt-3">
            <>
              {quoteStatus === 1 || quoteStatus === 2 || quoteStatus === 5 ? (
                <>
                  <SaveBox
                    insuranceRequest={insuranceRequest}
                    quotationId={rc_quotation.id}
                    refresh={collectQuoteData}
                  />

                  <div className="row mt-3 ">
                    <div className="col-12 col-xxl-12 mt-2 mt-xxl-0">
                      <SkipSignedDocs className="skip-doc">
                        <p className="text-center mb-1">Accetta obblighi di inviare documenti</p>
                        <div className="d-flex justify-content-center align-items-center gap-3">
                          <label className="m-0">
                            <input type="checkbox" className="me-1" checked={insuranceRequest.agree_upload_documents === 1} disabled={insuranceRequest.agree_upload_documents === 1} onClick={() => confirmAlert('', () => agreedDocuments())} />
                            Dichiaro di aver adempiuto agli obblighi in ambito di invio documentazione precontrattuale

                          </label>
                        </div>
                      </SkipSignedDocs>
                    </div>
                  </div>
                  {insuranceRequest.agree_upload_documents !== null && (nonSignableDocs.length > 0
                    || requestedOptionalDocumentsNonSignable.length > 0) && (
                    <div className="row">
                      <div className="col-12 documents-status-step-four mt-3">
                        <UploadDocumentsContainer
                          className={
                            checkIfAllNonSignableDocsAreUploaded() === false
                              ? 'document-all-uploaded-border'
                              : ''
                          }
                        >
                          <div className="d-flex align-items-center justify-content-start">
                            <NumberCurrent>1</NumberCurrent>
                            <h2>
                              Carica i
                              {' '}
                              <b>documenti</b>
                              {' '}
                              del cliente
                            </h2>
                          </div>
                          <UploadWrapper>
                            {nonSignableDocs.map((docType, index) => (
                              <NonSignableDocumentComponent
                                key={index}
                                insuranceRequest={insuranceRequest}
                                documentType={docType}
                                downloadDocument={downloadDocument}
                                removeDocument={removeDocument}
                                rc_quotation={rc_quotation}
                                collectQuoteData={collectQuoteData}
                              />
                            ))}
                            {requestedOptionalDocumentsNonSignable.map(
                              (document_request, index) => (
                                <NonSIgnableDocumentRequestComponent
                                  key={index}
                                  document_request={document_request}
                                  removeOptionalDocument={
                                    removeOptionalDocument
                                  }
                                  downloadOptionalDocument={
                                    downloadOptionalDocument
                                  }
                                  collectQuoteData={collectQuoteData}
                                />
                              ),
                            )}
                            <OptionalDocumentBox>
                              <BadgeOptional>Facoltativo</BadgeOptional>
                              <img src={uploadIcon} alt="" />
                              <button
                                type="button"
                                className="bg-secondary"
                                onClick={() => openOptionalDocumentModal()}
                              >
                                Richiedi di aggiungere un documento
                              </button>
                            </OptionalDocumentBox>
                          </UploadWrapper>
                        </UploadDocumentsContainer>
                      </div>
                    </div>
                  )}
                  {/* <div className="documents-status-step-five">
                    {rc_quotation.sign_later === null && (
                      <div className="row mt-3 ">
                        <div className="col-12 col-xxl-12 mt-2 mt-xxl-0">
                          <SkipSignedDocs className="skip-doc">
                            <p className="text-center">Vuoi Firmare Dopo?</p>
                            <div className="d-flex justify-content-center align-items-center gap-3">
                              <button
                                className="btn btn-success border py-1 px-4"
                                onClick={() => continueWithoutSignDocs()}
                              >
                                Si
                              </button>
                              <button
                                className="btn btn-danger border py-1 px-4"
                                onClick={() => continueWithSignDocs()}
                              >
                                No
                              </button>
                            </div>
                          </SkipSignedDocs>
                        </div>
                      </div>
                    )}
                    {rc_quotation.sign_later === 1 && (
                      <div className="alert alert-success mt-3 border rounded-4">
                        Hai scelto di firmare i documenti più tardi.
                      </div>
                    )}
                    {rc_quotation.sign_later === 0 && (
                      <div className="row mt-3">
                        <div className="col-12 col-xxl-12 mt-2 mt-xxl-0">
                          <SignatureType
                            quote={rc_quotation}
                            insuranceRequest={insuranceRequest}
                            collectQuoteData={collectQuoteData}
                            documentRequestsThatNeedSignature={
                              documentRequestsThatNeedSignature
                            }
                            signableDocs={signableDocs}
                            requestedOptionalDocumentsSignable={
                              requestedOptionalDocumentsSignable
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div> */}
                </>
              ) : (
                ''
              )}
              {quoteStatus === 4 && (
                <WaitingForPaymentStatus
                  insuranceRequest={insuranceRequest}
                  collectQuoteData={collectQuoteData}
                  rc_quotation={rc_quotation}
                  risk_quotations={risk_quotations}
                />
              )}
              {quoteStatus === 6 && (
                <div className="card-body">
                  <div className="row">
                    <div className="col-6 offset-3">
                      <div className="alert alert-success" role="alert">
                        <h4 className="alert-heading">In Attesa Emissione</h4>
                        <p>
                          Quando l'agente di backoffice carica la polizza,
                          riceverai una notifica e il contratto potrà essere
                          scaricato.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        </>
      )}
      {showOptionalDocumentModal === true && (
        <OptionalDocumentModal
          quotationId={rc_quotation.id}
          closeModal={() => setShowOptionalDocumentModal(false)}
          collectQuoteData={collectQuoteData}
        />
      )}
      {quoteStatus === 7 && (
        <>
          <div className="mt-0 gap-2 align-items-center justify-content-start mb-3">
            {/* <div>
              {localStorage.getItem('purchaseTour') === 'done' && (
                <div style={{ cursor: 'pointer' }} onClick={() => firePurchaseTour()}>
                  <button className="btn btn-success px-2 py-1">Tutorial</button>
                </div>
              )}
            </div> */}
          </div>

          <CompletedScreenCommercial
            insuranceRequest={insuranceRequest}
            rc_quotation={rc_quotation}
            risk_quotations={risk_quotations}
          />
        </>
      )}
    </PurchaseStyles>
  );
}

export default PurchaseCommercial;
