import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { CommercialCompanyDataStyles } from './CommercialCompanyDataStyles.styles';
import {
  getCommercialReport,
  updateCommercialReport,
} from '../../features/commercialReport/commercialReportActions';
import PageLoading from '../../common/components/elements/PageLoading';
import CommercialCompany from './Tabs/CommercialCompany';
import CompanyComentaries from './Tabs/CompanyComentaries';
import Shareholders from './Tabs/Shareholders';
import CommercialCompanyDirectors from './Tabs/CommercialCompanyDirectors';
import CompanyExtendedGroupStructure from './Tabs/CompanyExtendedGroupStructure';
import CompanyFinancialStatements from './Tabs/CompanyFinancialStatements';
import CompanyBranches from './Tabs/CompanyBranches';
import CompanyIndicators from './Tabs/CompanyIndicators';
import { CenteredDivTitle } from '../../common/components/CenteredPageTitle.styles';
import ContactInfo from './Tabs/ContactInfo';
import ProgressBar from '../../common/components/ProgressBar';
import {
  answersFieldsBySteps,
  commercialCompanyDefaultValues,
  validationsSchema,
} from './CommercialCompanyData.validation';
import {
  commercial_company_static_labels,
  commercial_company_tabs,
} from '../../common/constants';

function CommercialCompanyData() {
  const { vatNumber } = useParams();
  const navigate = useNavigate();
  const { loading, commercial_report } = useSelector(
    (store) => store.commercialReport.report,
  );

  const [activeTab, setActiveTab] = useState(1);
  const [dynamicHeaders, setDynamicHeaders] = useState([]);
  const {
    register,
    formState: { errors, touchedFields },
    setValue,
    trigger,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationsSchema),
    shouldFocusError: true,
    defaultValues: commercialCompanyDefaultValues,
  });

  const answers = watch();

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  const updateAnswers = (data) => {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      setValue(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    });
    trigger(keys);
  };

  useEffect(() => {
    getCommercialReport(vatNumber, navigate);
  }, [vatNumber]);

  useEffect(() => {
    if (commercial_report !== null) {
      updateAnswers({
        activity_code: commercial_report.commercial_company.activity_code,
        activity_description:
          commercial_report.commercial_company.activity_description,
        activity_industry_sector:
          commercial_report.commercial_company.activity_industry_sector
            === ''
            || commercial_report.commercial_company.activity_industry_sector === null
            ? null
            : commercial_report.commercial_company.activity_industry_sector,
        alter_summary_address:
          commercial_report.commercial_company.alter_summary_address,
        alter_summary_employees_nr:
          commercial_report.commercial_company.alter_summary_employees_nr,
        alter_summary_incorporation_date: moment(
          commercial_report.commercial_company.alter_summary_incorporation_date,
        ).format('YYYY-MM-DD'),
        alter_summary_province:
          commercial_report.commercial_company.alter_summary_province,
        alter_summary_share_capital:
          commercial_report.commercial_company.alter_summary_share_capital,
        business_name: commercial_report.commercial_company.business_name,
        company_id: commercial_report.commercial_company.company_id,
        company_number: commercial_report.commercial_company.company_number,
        company_registration_number:
          commercial_report.commercial_company.company_registration_number,
        company_status: commercial_report.commercial_company.company_status,
        credit_rating_common_desc:
          commercial_report.commercial_company.credit_rating_common_desc,
        credit_rating_common_value:
          commercial_report.commercial_company.credit_rating_common_value,
        credit_rating_limit_value:
          commercial_report.commercial_company.credit_rating_limit_value,
        email: commercial_report.commercial_company.email,
        phone: commercial_report.commercial_company.phone,

        summary_extra_has_cigs_events:
          commercial_report.commercial_company.summary_extra_has_cigs_events
            === true
            ? '1'
            : '0',
        summary_extra_has_company_insolvency:
          commercial_report.commercial_company
            .summary_extra_has_company_insolvency === true
            ? '1'
            : '0',
        summary_extra_has_insolvency:
          commercial_report.commercial_company.summary_extra_has_insolvency
            === true
            ? '1'
            : '0',
        summary_extra_has_prejudicials:
          commercial_report.summary_extra_has_prejudicials === true ? '1' : '0',
        summary_extra_has_protesti:
          commercial_report.commercial_company.summary_extra_has_protesti
            === true
            ? '1'
            : '0',
        summary_extra_has_severe_prejudicials:
          commercial_report.commercial_company
            .summary_extra_has_severe_prejudicials === true
            ? '1'
            : '0',
        summary_extra_has_severe_protesti:
          commercial_report.commercial_company
            .summary_extra_has_severe_protesti === true
            ? '1'
            : '0',
        turnover_value: commercial_report.commercial_company.turnover_value,
        company_commentaries: commercial_report.commercial_company_commentaries,
        extended_group_structure:
          commercial_report.commercial_company_extended_group_structure,
        company_indicators: commercial_report.commercial_company_indicators,
      });

      const holders = [];
      for (let x = 0; x < commercial_report.share_holders.length; x++) {
        const item = commercial_report.share_holders[x];
        holders.push({
          ...item,
          address: item.address === '' ? null : item.address,
          protesti: item.protesti === 1 ? '1' : '0',
          has_severe_protesti: item.has_severe_protesti === 1 ? '1' : '0',
          prejudicials: item.prejudicials === 1 ? '1' : '0',
          has_severe_prejudicials:
            item.has_severe_prejudicials === 1 ? '1' : '0',
          company_representative: item.company_representative === 1 ? '1' : '0',
        });
      }

      const directors = [];
      for (
        let x = 0;
        x < commercial_report.commercial_company_directors.length;
        x++
      ) {
        const item = commercial_report.commercial_company_directors[x];
        directors.push({
          ...item,
          has_prejudicials: item.has_prejudicials === 1 ? '1' : '0',
          has_severe_prejudicials:
            item.has_severe_prejudicials === 1 ? '1' : '0',
          has_protesti: item.has_protesti === 1 ? '1' : '0',
          has_severe_protesti: item.has_severe_protesti === 1 ? '1' : '0',
        });
      }

      const branches = [];
      for (
        let x = 0;
        x < commercial_report.commercial_company_branches.length;
        x++
      ) {
        const item = commercial_report.commercial_company_branches[x];
        branches.push({
          ...item,
          description: item.description.length === 0 ? null : item.description,
          presence_of_alarm_system:
            item.presence_of_alarm_system == 1 ? '1' : '0',
        });
      }
      updateAnswers({
        share_holders: holders,
        directors,
        company_branches: branches,
      });

      const headers = commercial_report.commercial_company_financial_statements?.length > 0
        ? Object.keys(
          commercial_report.commercial_company_financial_statements[0],
        ).filter((key) => key !== 'year_end_date')
        : [];

      setDynamicHeaders(headers);
    }
  }, [commercial_report]);

  const navigateToNextPage = () => {
    navigate(
      `/quote/commercial/${vatNumber}/questionnaire/${commercial_report.commercial_company.id}`,
    );
  };

  const nextStep = async () => {
    // check first to be no errors on form
    const res = await trigger(answersFieldsBySteps[activeTab - 1], {
      shouldFocus: true,
    });

    if (res !== true) {
      return;
    }
    if (activeTab === 9) {
      updateCommercialReport(vatNumber, answers, navigateToNextPage);
    } else {
      setActiveTab(activeTab + 1);
      window.scrollTo(0, 0);
    }
  };

  if (loading && commercial_report === null) {
    return <PageLoading />;
  }

  return (
    <CommercialCompanyDataStyles>
      <CenteredDivTitle className="d-flex align-items-center justify-content-center gap-3 mt-3">
        <div className="row title-questionnaire">
          <div className="quote-h2 divider">
            <h2>Completa I Dati</h2>
          </div>
        </div>
      </CenteredDivTitle>
      <div className="d-flex align-content-center justify-content-center ">
        <ProgressBar activeStep={activeTab} steps={commercial_company_tabs} />
      </div>
      <div className="card container">
        <div className="card-body">
          <div className="tab-content pt-2">
            {activeTab === 1 && (
              <CommercialCompany
                errors={errors}
                isValid={isValid}
                register={register}
                formData={answers}
                setValue={setValue}
              />
            )}
            {activeTab === 2 && (
              <CompanyComentaries
                errors={errors}
                isValid={isValid}
                answers={answers}
                register={register}
              />
            )}
            {activeTab === 3 && (
              <Shareholders
                errors={errors}
                isValid={isValid}
                register={register}
                answers={answers}
                updateAnswers={updateAnswers}
              />
            )}
            {activeTab === 4 && (
              <CommercialCompanyDirectors
                errors={errors}
                isValid={isValid}
                register={register}
                answers={answers}
              />
            )}
            {activeTab === 5 && (
              <CompanyExtendedGroupStructure
                errors={errors}
                isValid={isValid}
                register={register}
                answers={answers}
              />
            )}
            {activeTab === 6 && (
              <CompanyFinancialStatements
                commercial_report={commercial_report}
                dynamicHeaders={dynamicHeaders}
                staticLabels={commercial_company_static_labels}
              />
            )}
            {activeTab === 7 && (
              <CompanyBranches
                branchErrors={errors}
                branchIsValid={isValid}
                branchRegister={register}
                answers={answers}
                fetchData={() => getCommercialReport(vatNumber)}
                vatNumber={vatNumber}
                setBranchValue={setValue}
              />
            )}
            {activeTab === 8 && (
              <CompanyIndicators
                errors={errors}
                isValid={isValid}
                register={register}
                answers={answers}
              />
            )}
            {activeTab === 9 && (
              <ContactInfo
                errors={errors}
                isValid={isValid}
                register={register}
              />
            )}
          </div>
        </div>
      </div>

      <div className="footer-buttons">
        <div className="d-flex justify-content-between">
          {activeTab > 1 && (
            <button
              className="btn btn-questionnaire"
              onClick={() => {
                setActiveTab(activeTab - 1);
                window.scrollTo(0, 0);
              }}
            >
              Back
            </button>
          )}
          <button className="btn btn-questionnaire" onClick={nextStep}>
            Continua
          </button>
        </div>
      </div>
    </CommercialCompanyDataStyles>
  );
}

export default CommercialCompanyData;
