import React, { useRef, useState } from 'react';
import {
  AquistaButton,
  AquistaButtonsContainer,
  LeftCol,
  LogoPriceContainer,
  QuotationCardStyles,
  RightCol,
  SavedButton,
} from './QuotationCard.styles';
import LogoComponent from './smallerComponents/LogoComponent';
import CompanyQualityComponent from './smallerComponents/CompanyQualityComponent';
import PriceComponent from './smallerComponents/PriceComponent';
import CompanyInfoComponent from './smallerComponents/CompanyInfoComponent';
import QuoteInfoComponent from './smallerComponents/QuoteInfoComponent';
import SparklesIcon from '../../assets/images/icons/SparklesIcon';
import { Chat } from '../components/chats/MiniChat/MiniChat';

function QuotationCard({
  quote,
  requestInfo,
  selectQuotation,
  guaranteesPrima,
  set_informativi,
  docs,
  extensions,
  requestToken,
  selectableExtensions,
  profession,
  type,

}) {
  const [openChat, setOpenChat] = useState(false);
  const [extraText, setExtraText] = useState();
  const chatRef = useRef();

  return (
    <QuotationCardStyles className={quote.is_aquista === 1 ? 'selected' : ''}>

      <LeftCol>
        <div>
          {/* Company Quality Component */}
          <CompanyQualityComponent quote={quote} />
          {quote.company_id === 9
          && requestInfo.payment_frequency === 2 && (
            <div className="alert alert-warning mt-1 mb-2 p-1 w-100">
              <b>Attenzione:</b>
              {' '}
              Linear non supporta il pagamento semestrale.
            </div>
          )}
          <LogoPriceContainer>
            {/* Logo Component */}
            <LogoComponent quote={quote} />
            {/* Price Component */}
            <PriceComponent quote={quote} />
          </LogoPriceContainer>
        </div>
        <>
          <button
            onClick={() => setOpenChat(true)}
            className="open-chat-button border-0  text-white bg-success rounded p-1"
            style={{ position: 'absolute', right: '30px', top: '30px' }}
          >
            <SparklesIcon width={40} height={40} />
          </button>
          <Chat
            ref={chatRef}
            openChat={openChat}
            setOpenChat={setOpenChat}
            company_id={
              quote.company ? quote.company_id : quote.preventivas_company_id
            }
            product_id={quote.product_id}
            quote_type={quote.quote_type}
            company_name={
              quote.company
                ? quote.company.name
                : quote.preventivas_company.company_name
            }
            extraText={extraText}
            professionId={profession?.profession}
          />
        </>
      </LeftCol>
      <RightCol>
        {/* Company Info Component */}
        <div>
          <CompanyInfoComponent
            quote={quote}
            guaranteesPrima={guaranteesPrima}
            set_informativi={set_informativi}
            docs={docs}
            extensions={extensions}
            requestToken={requestToken}
            selectableExtensions={selectableExtensions}
            profession={profession}
          />
          {/* Quote Info Component */}
          <QuoteInfoComponent
            setExtraText={setExtraText}
            quote={quote}
            requestInfo={requestInfo}
            profession={profession}
            type={type}
          />
        </div>
        <AquistaButtonsContainer>
          {requestInfo.processing_quotation_id !== quote.id ? (
            <AquistaButton
              onClick={() => selectQuotation(quote.id)}
              className="purchase-quote-nine-step"
            >
              Seleziona
            </AquistaButton>
          ) : (
            requestInfo.processing_quotation_id === quote.id && (
              <SavedButton>Aggiunto</SavedButton>
            )
          )}
        </AquistaButtonsContainer>
      </RightCol>
    </QuotationCardStyles>
  );
}

export default QuotationCard;
