import styled from 'styled-components';

export const SaluteFormFlowStyles = styled.div``;

export const SaluteFormStyles = styled.form`
  width: 50%;
  margin: 0 auto;

 
    @media (min-width: 300px) and (max-width: 1000px) {
      width: 100%;
      margin: 0;
  }
  @media (min-width: 1001px) and (max-width: 1300px) {
      width: 80%;
  }`;

export const SaluteQuestionsStyles = styled.form`
  width: 70%;
  margin: 0 auto;

 
    @media (min-width: 300px) and (max-width: 1000px) {
      width: 100%;
      margin: 0;

  }
  @media (min-width: 1001px) and (max-width: 1300px) {
      width: 80%;
  }`;

export const TooltipStyles = styled.div`
top: -18px;
border-radius: 5px;
left: 0px !important;
background-color: #FCFCFC;
border: 0.5px solid #f1f1f1;
width: 400px;
height: 600px;
overflow: auto;
z-index: 99999;
box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Add shadow */

`;

export const ToolTipHeader = styled.div`
font-weight: bold;
font-size: 14px;
`;

export const TooltipTextBold = styled.div`
font-weight: bold;
font-size: 12px;
`;

export const TooltipText = styled.div`
font-weight: light;
font-size: 12px;
font-style: italic;
margin-bottom: 20px;
`;
