import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import quotationsAxe from '../../../../services/axios-client/quotationsAxeClient';
import { getQuotationTypeName } from '../../../constants';

function CompletedScreenAuto({
  quoteStatus,
  quote,
  insuranceRequest,
  rc_quotation,
  risk_quotations,
}) {
  const [residenceInfo, setResidenceInfo] = useState(null);

  const collectMunicipalities = () => {
    try {
      quotationsAxe.get('/form-data/communes').then((res) => {
        const commune = res.data.data.find(
          (commune) => commune.cadastral_code
            == quote.insurance_request.customer.residence_commune_code,
        );
        setResidenceInfo(commune);
      });
    } catch (err) {
      // Do nothing
    }
  };

  useEffect(() => {
    if (quoteStatus === 7) {
      collectMunicipalities();
    }
  }, [quoteStatus]);

  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="row mb-2">
          <div className="col-12 col-md-12">
            <div className="card border mb-2">
              <div className="card-header bg-secondary text-white">
                <h2 className="h3 mb-0 card-title">Anagrafica Cliente</h2>
              </div>
              <div className="card-body px-0 py-2 p-md-2">
                <ul className="list-group list-group-flush ">
                  <li className="list-group-item">
                    {insuranceRequest.customer.gender === 'G' && (
                      <>
                        {' '}
                        <b>Compagnia:</b>
                        {' '}
                        {insuranceRequest.customer.business_name}
                      </>
                    )}
                    {insuranceRequest.customer.gender !== 'G' && (
                      <>
                        <b>Nome e Cognome:</b>
                        {' '}
                        {insuranceRequest.customer.name}
                        {' '}
                        {insuranceRequest.customer.surname}
                      </>
                    )}
                  </li>
                  <li className="list-group-item">
                    <b>Email:</b>
                    {' '}
                    {insuranceRequest.customer.email}
                  </li>
                  <li className="list-group-item">
                    <b>Telefono:</b>
                    {' '}
                    {insuranceRequest.customer.phone}
                  </li>
                  <li className="list-group-item">
                    <b>Indirizo:</b>
                    {' '}
                    {insuranceRequest.customer.address}
                    ,
                    {' '}
                    {insuranceRequest.customer.house_number}
                    ,
                    {' '}
                    {insuranceRequest.customer.postal_code}
                  </li>
                  <li className="list-group-item">
                    <b>Residenza:</b>
                    {' '}
                    {residenceInfo !== null && residenceInfo.name}
                  </li>
                  <li className="list-group-item">
                    <b>Citta:</b>
                    {' '}
                    {residenceInfo !== null
                      && residenceInfo.communal_territory.name}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-12">
            <div className="card border mb-2">
              <div className="card-header bg-secondary text-white">
                <h2 className="h3 mb-0 card-title">Preventivi</h2>
              </div>
              <div className="card-body px-0 py-2 p-md-2">
                <ul className="list-group list-group-flush ">
                  {[1, 3].includes(rc_quotation.product_id) && (
                    <li className="list-group-item">
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <b>Prodotto:</b>
                          {' '}
                          Responsabilita Civile
                          <br />
                          <b>Compagnia:</b>
                          {' '}
                          {rc_quotation.company !== null ? rc_quotation.company.name : rc_quotation.preventivas_company.company_name}
                        </div>
                        <div>
                          <Link to={`/quotes/purchased/${rc_quotation.id}/quotation/${rc_quotation.product_id === 1 ? 'auto' : 'autocarro'}`} className="btn btn-success text-white py-1 px-1 py-md-2 px-md-2">Vai Al Preventivo</Link>
                        </div>
                      </div>
                    </li>
                  )}
                  {risk_quotations.filter((q) => q.company_id != rc_quotation.company_id).map((risk_quotation, index) => (
                    <li className="list-group-item" key={index}>
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <b>Prodotto:</b>
                          {' '}
                          {getQuotationTypeName(risk_quotation.quote_type)}
                          <br />
                          <b>Compagnia:</b>
                          {' '}
                          {risk_quotation.company.name}
                        </div>
                        <div>
                          <Link to={`/quotes/purchased/${risk_quotation.id}/quotation/${risk_quotation.product.slug}`} className="btn btn-success text-white py-1 px-1 py-md-2 px-md-2">Vai Al Preventivo</Link>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompletedScreenAuto;
