import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import Joyride from 'react-joyride';
import { useSelector } from 'react-redux';
import PageLoading from '../../common/components/elements/PageLoading';
import { getCancellation, resetCancellation } from '../../features/afterSale/afterSaleActions';
import managementAxe from '../../services/axios-client/managementServisAxeClient';
import FileUpload from '../../common/components/inputs/FileUpload';
import { apiAfterSaleOptionalDocumentMethodManualUpload, apiDownloadAfterSaleOptionalDocument } from '../../services/axios-client/axeCommons';
import { generalAlertError, generalAlertSuccessToast } from '../../common/utils/alerts';
// import { useTourContext } from '../../context/useTourContext';
import ErrorComponent from '../../common/components/ErrorComponent';
import AfterSaleToSignDocumentComponent from '../../common/components/redesignComponents/Cards/AfterSaleToSignDocumentComponent';
import { QuoteInfoContainerAfterSale, WaitingForPaymentQuoteInfoAfterSale } from '../../common/components/redesignComponents/Purchase/PaymentStatusAfterSale.styles';
import AfterSaleDocumentComponent from '../../common/components/redesignComponents/Cards/AfterSaleDocumentComponent';
import { after_sale_purchase_statues } from '../../common/constants';
// import { joyrideOptions } from '../../common/utils/joyrideUtils';
// import { handleCallbackCancellation } from '../../common/utils/joyrideCallbacks';
// import { cancellationSteps } from '../../common/utils/joyrideSteps';

export function CancellationPage() {
  const navigate = useNavigate();
  const { cancellationId } = useParams();

  const state = useSelector((store) => store.afterSale.cancellation_page);
  const { loading, cancellation, error } = state;

  // const {
  //   setState,
  //   state: { run, showBeacon },
  // } = useTourContext();

  // useEffect(() => {
  //   if (localStorage.getItem('singleCancellation') === 'done') {
  //     setState({ run: false });
  //   } else {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 1500);
  //   }
  // }, [localStorage.getItem('singleCancellation')]);

  // const fireNewQuoteTour = () => {
  //   localStorage.removeItem('singleCancellation');
  //   setState({ run: true, tourActive: true });
  // };

  useEffect(() => {
    getCancellation(cancellationId);
    return () => {
      resetCancellation();
    };
  }, []);

  const downloadDocument = async (cancellationRequestDocumentId) => {
    const response = await managementAxe.get(`/broker/after-sale/cancellations/documents/${cancellationRequestDocumentId}/download`);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const downloadOptionalDocument = async (documentId) => {
    const response = await apiDownloadAfterSaleOptionalDocument(documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const showAfterSaleUploadedDocuments = () => {
    const docs = [
      ...cancellation.after_sale_optional_documents,
    ];

    return (
      <div className="ps-3 mt-3">
        {docs.length === 0 && (
          <p>Nessun documento caricato.</p>
        )}

        {docs.map((doc) => (
          <AfterSaleToSignDocumentComponent key={doc.id} document={doc} downloadDocument={downloadOptionalDocument} />

        ))}

      </div>
    );
  };

  const uploadSignedOptionalDocument = async (documentId, file) => {
    const uploadedFile = file[0];
    const reader = new FileReader();
    reader.readAsDataURL(uploadedFile);
    if (uploadedFile.type !== 'application/pdf') {
      generalAlertError('Formato di file errato. Il file deve essere in .pdf');
      return;
    }
    reader.onload = () => {
      apiAfterSaleOptionalDocumentMethodManualUpload(documentId, {
        document: reader.result.replace('data:application/pdf;base64,', ''),
      }).then(() => {
        getCancellation(cancellationId);
        generalAlertSuccessToast('File caricato');
      });
    };
  };

  if (loading) {
    return <PageLoading />;
  }

  if (error !== null) {
    return <ErrorComponent error={error} />;
  }

  if (cancellation && cancellation.status !== 5) {
    navigate(`/after-sale/purchase/cancellation/${cancellation.id}`);
  }

  return (
    <>
      {/* <Joyride
        steps={cancellationSteps}
        continuous
        showBeacon={showBeacon}
        locale={{
          back: 'Indietro',
          close: 'Chiudi',
          last: 'Fine',
          next: 'Prossima',
          open: 'Apri il modale',
          restart: 'Riavvia il tour ',
          skip: 'Salta',
        }}
        styles={{
          options: joyrideOptions,
          buttonNext: {
            backgroundColor: '#00a651',
            outline: 'none',
          },
          buttonBack: {
            color: '#00a651',
            outline: 'none',
          },
          beaconInner: {
            backgroundColor: '#00a651',
          },
          beaconOuter: {
            borderColor: '#00a651',
            backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
          },
          buttonSkip: {
            outline: 'none',
          },
          buttonClose: {
            outline: 'none',
          },
        }}
        run={run}
        callback={(data) => handleCallbackCancellation(data, cancellationSteps, setState)}
        showSkipButton
        showProgress
      /> */}
      <div className="d-flex gap-2">
        <div className="btn btn-success btn-sm text-white px-4 mb-4" onClick={() => navigate('/cancellations')}>Torna indietro</div>
        {/* {localStorage.getItem('singleCancellation') === 'done' && (
          <div style={{ cursor: 'pointer' }} onClick={() => fireNewQuoteTour()}>
            <button className="btn btn-success btn-sm text-white px-4 mb-4">Tutorial</button>
          </div>
        )} */}
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <WaitingForPaymentQuoteInfoAfterSale>
            <QuoteInfoContainerAfterSale>
              <h3 className="ps-3">
                <strong>
                  Cessazione #
                </strong>
                {cancellation.id}
              </h3>
              <div className="single-cancellation-one ps-3 mb-4 mt-0">
                {cancellation.quotation.insurance_request.customer && (
                  <div>
                    <p className="my-1">
                      <strong> Cliente:</strong>
                      {' '}
                      <span>
                        {cancellation.quotation.insurance_request.customer.gender !== 'G' ? `${cancellation.quotation.insurance_request.customer.name} ${cancellation.quotation.insurance_request.customer.surname}` : cancellation.quotation.insurance_request.customer.business_name}
                      </span>
                    </p>
                    <p className="my-1">
                      <strong> Stato:</strong>
                      {' '}
                      <div className="badge bg-success">{after_sale_purchase_statues.find((status) => status.id === cancellation.status)?.name}</div>

                    </p>
                  </div>
                )}
                {cancellation.quotation.insurance_request.product
                  && (
                    <div>
                      <p className="my-1">
                        <strong> Prodotto:</strong>
                        {' '}
                        <span>
                          {cancellation.quotation.insurance_request.product.name}
                        </span>
                      </p>
                    </div>

                  )}
                {cancellation.quotation.company
                  && (
                    <div>
                      <p className="my-1">
                        <strong>  Compagnia:</strong>
                        {' '}
                        <span>
                          {cancellation.quotation.company.name}
                        </span>
                      </p>
                    </div>
                  )}
                {cancellation.quotation.insurance_request.vehicle
                  && (
                    <div>
                      <p className="my-1">
                        <strong> Targa:</strong>
                        {' '}
                        <span>
                          {cancellation.quotation.insurance_request.vehicle.vehicle_plate}
                        </span>
                      </p>
                    </div>
                  )}

                <div>
                  <p className="my-1">
                    <strong>
                      Data:
                    </strong>
                    {' '}
                    <span>
                      {new Date(cancellation.date).toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                    </span>
                  </p>
                </div>
                <div>
                  <p className="my-1">
                    <strong>
                      Creato:
                    </strong>
                    {' '}
                    <span>
                      {new Date(cancellation.created_at).toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                    </span>
                  </p>
                </div>
                <div>
                  <p className="my-1">
                    <b>Note:</b>
                  </p>
                  <p className="m-0">
                    {cancellation.note}
                  </p>
                </div>
              </div>
            </QuoteInfoContainerAfterSale>

          </WaitingForPaymentQuoteInfoAfterSale>
        </div>
        <div className="col-12 col-md-6 mt-3 mt-md-0">
          <WaitingForPaymentQuoteInfoAfterSale>
            <QuoteInfoContainerAfterSale>
              <div className="single-cancellation-two">
                <h3 className="ps-3">
                  <strong>
                    Documenti
                  </strong>
                </h3>
                <div className="ps-3 mt-3">
                  {
                    cancellation.documents.length !== 0 ? cancellation.documents.map((document) => (
                      // <div key={document.id} className="d-flex border border-success rounded py-3 m-1" style={{ maxWidth: 600 }}>
                      //   <div className="d-flex align-items-center justify-content-start ms-3">
                      //     <img src={pdfIMG} className="img-fluid me-2" width="30" alt="PDF" />
                      //     <h5 className="h6 mb-0">
                      //       {document.original_filename}
                      //     </h5>
                      //   </div>
                      //   <div className="d-flex align-items-center justify-content-center ms-auto">
                      //     <button
                      //       className="doc-btn border-0 bg-transparent"
                      //       onClick={() => downloadDocument(document.id)}
                      //     >
                      //       <img src={downloadIMG} width="30" alt="" />
                      //     </button>
                      //   </div>
                      // </div>
                      <AfterSaleDocumentComponent key={document.id} document={document} downloadDocument={downloadDocument} />
                    )) : <p>Nessun Documento Trovato</p>
                  }
                </div>
              </div>

            </QuoteInfoContainerAfterSale>
          </WaitingForPaymentQuoteInfoAfterSale>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12 col-xl-6">
          <WaitingForPaymentQuoteInfoAfterSale>
            <QuoteInfoContainerAfterSale>
              <h3 className="ps-3">
                <strong>
                  Documenti Da Firmare
                </strong>
              </h3>
              <div>
                {showAfterSaleUploadedDocuments()}
              </div>
              <div className="col-12 mt-3 mt-xl-2 d-flex single-cancellation-three">
                <div className="col-6 mt-3 mt-xl-0">
                  <div className="card ps-3">
                    <div className="card-body px-0">
                      {cancellation.after_sale_optional_documents.filter((document) => document.signable_document === 1 && document.is_uploaded_valid === 1 && ((document.is_signed_valid === 0 && document.signed_document_filename !== null) || (document.is_signed_valid === null && document.signed_document_filename === null))).map((file) => (
                        <div className="mb-3">
                          <FileUpload
                            key={file.id}
                            multiple={false}
                            label={`CARICA ${file.optional_document_name.toUpperCase()}`}
                            handleFile={(f) => uploadSignedOptionalDocument(file.id, f)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

              </div>
            </QuoteInfoContainerAfterSale>
          </WaitingForPaymentQuoteInfoAfterSale>
        </div>

      </div>

    </>
  );
}
