import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  LeftSide, ModalBody, ModalContent, ModalHeader, SaluteInfoModalStyles,
  TextContainer,
  TitleBox,
} from './SaluteInfoModal.styles';
import infoIcon from '../../../../assets/redesign_icons/info-icon.svg';
import { saluteInfo } from '../../../constants';

function SaluteInfoModal({ setShowModal, quote }) {
  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <SaluteInfoModalStyles>
      <ModalBody>
        <ModalHeader>
          <LeftSide>
            <FontAwesomeIcon fontSize={34} icon={faTimes} cursor="pointer" onClick={() => closeModal()} />
            <TextContainer>
              <TitleBox>
                <img src={infoIcon} alt="" />
                <h3>Informazione</h3>
              </TitleBox>
              <p>
                Qui puoi controllare le informazioni su questa offerta.
              </p>
            </TextContainer>
          </LeftSide>
          {/* <RightSide>
            {docs[userData.profession] !== '' && (
            <CompanyInfoElements
              onClick={() => downloadResourceDocs(4, userData.profession)}
              title={`${docs[userData.profession]}`}
            >
              <img src={preventiveIcon} alt="" />
              <p>Questionario</p>
            </CompanyInfoElements>
            )}
            {set_informativi[userData.profession] !== '' && (
            <CompanyInfoElements
              onClick={() => downloadResourceDocs(8, userData.profession)}
              title={`${set_informativi[userData.profession]}`}
            >
              <img src={preContractIcon} alt="" />
              <p>Informativa Precontrattuale</p>
            </CompanyInfoElements>
            )}
          </RightSide> */}
        </ModalHeader>
        <ModalContent>
          <div className="card-extra-information">
            <h3 className="mb-3">Informazioni</h3>
            {saluteInfo.map((info) => (
              quote.quote_type === info.quote_type && (
              <>
                <p>{info.desc}</p>
                {info.limits.length > 0 && (
                <ul>
                  {info.limits.map((item) => (
                    <li>{item}</li>
                  ))}
                </ul>
                )}
                {info.numberList.length > 0 && (
                <ol>
                  {info.numberList.map((item) => (
                    <li>{item}</li>
                  ))}
                </ol>
                )}
                {info.subText && (
                <p className="mt-3">{info.subText}</p>
                )}
                {info.extra && (
                <p className="mt-4">
                  <b>Estensione valida per tutte le Garanzie: </b>
                  {' '}
                  {info.extra}
                </p>
                )}

                {quote.quote_type === 'indennitaria_da_malattia'
                 && (
                 <>
                   <p className="mt-3">Massimale:</p>
                   <ul>
                     <li>Classe 1: 4.800,00 €</li>
                     <li>Classe 2: 6.300,00 €</li>
                     <li>Classe 3: 8.700,00 €</li>
                     <li>Classe 4: 15.000,00 €</li>

                   </ul>
                 </>
                 )}
              </>

              )
            ))}

          </div>
        </ModalContent>
      </ModalBody>
    </SaluteInfoModalStyles>
  );
}

export default SaluteInfoModal;
