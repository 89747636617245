import { createSlice } from '@reduxjs/toolkit';

import { callerCreator } from '../callerCreator';

const initialState = {
  after_sale_purchase: {
    loading: true,
    error: null,
    afterSale: null,
    documents: [],
  },
};

export const afterSalePurchaseSlice = createSlice({
  name: 'afterSalePurchase',
  initialState,
  reducers: {
    afteSalePurchasePending: (state) => {
      state.after_sale_purchase.loading = true;
      state.after_sale_purchase.error = null;
    },
    afteSalePurchaseRejected: (state, { payload }) => {
      state.after_sale_purchase.loading = false;
      state.after_sale_purchase.error = payload;
    },
    afteSalePurchaseSuccess: (state, { payload }) => {
      state.after_sale_purchase.loading = false;
      state.after_sale_purchase.afterSale = payload.after_sale;
      state.after_sale_purchase.documents = payload.documents;
    },
    afterSalePurchaseResetState: (state) => {
      state.after_sale_purchase = { ...initialState.after_sale_purchase };
    },
  },
});

export default callerCreator(afterSalePurchaseSlice.actions);
