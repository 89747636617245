import React from 'react';
import { BoxWrapper, ClientBox, InfoTable } from './CommonPurchase.styles';
import {
  guideType,
  primaQuoteTypes,
  quotationTypes,
} from '../../../../constants';
import MessagesModal from '../../../elements/MessagesModal';
import { downloadFile } from '../../../../../services/axios-client/axeHelpers';
import axe from '../../../../../services/axios-client/axiosClient';
import { apiDownloadDocument } from '../../../../../services/axios-client/axeCommons';

function InfoBoxAuto({
  insuranceRequest,
  statues,
  quoteStatus,
  messagesModalData,
  rc_quotation,
  risk_quotations,
}) {
  const downloadResourceDocs = async (id) => {
    const res = await axe.get(`/download-resource-document/${id}/0`, {
      responseType: 'blob',
    });

    downloadFile(res.data);
  };

  const downloadPreventive = async () => {
    const res = await axe.get(
      `/quotations/${rc_quotation.id}/preventivo/generate`,
      {
        responseType: 'blob',
      },
    );

    downloadFile(res.data);
  };

  const doc = insuranceRequest.documents.find((doc) => doc.document_type_id === 34);

  const downloadDocument = async (documentId) => {
    if (documentId === undefined) {
      return;
    }
    const response = await apiDownloadDocument(documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const getTotal = () => {
    let rcTotalAmount = 0;
    let primaGuaranteesPrices = 0;
    let risksTotalAmount = 0;

    if (risk_quotations && risk_quotations.length > 0) {
      for (let i = 0; i < risk_quotations.length; i++) {
        const item = risk_quotations[i];

        risksTotalAmount += rc_quotation.payment_frequency === 1 ? parseFloat(item.amount) : parseFloat(item.amount / rc_quotation.payment_frequency);
      }
    }

    if (rc_quotation.company_id === 2) {
      rc_quotation.guarantees.forEach((guarantee) => {
        primaGuaranteesPrices += rc_quotation.payment_frequency === 1 ? parseFloat(guarantee.amount) : parseFloat(guarantee.amount / rc_quotation.payment_frequency) || 0;
      });
    }

    rcTotalAmount = rc_quotation.payment_frequency === 1 ? rc_quotation.amount : rc_quotation.amount / rc_quotation.payment_frequency;

    return parseFloat(
      rcTotalAmount + primaGuaranteesPrices + risksTotalAmount + rc_quotation.brokerage_amount,
    ).toFixed(2);
  };

  return (
    <>
      <BoxWrapper>
        <div className="row gap-2 gap-xl-0">
          <div className="col-12 col-xl-4">
            <ClientBox className="documents-status-step-one pagament-status-step-one">
              <h6>Cliente:</h6>
              <h5>
                {insuranceRequest.customer.gender !== 'G'
                  ? `${insuranceRequest.customer.name} ${insuranceRequest.customer.surname}`
                  : insuranceRequest.customer.business_name}
              </h5>
              <h6 className="mt-2">Veicolo:</h6>
              <h5>{insuranceRequest.vehicle.version.full_description}</h5>
              <h6 className="mt-2">Targa:</h6>
              <h5>{insuranceRequest.vehicle.vehicle_plate}</h5>
              <h6 className="mt-2">Decorrenza:</h6>
              <h5>
                {new Date(
                  insuranceRequest.policy_effective_date,
                ).toLocaleDateString('it-IT')}
              </h5>
              <h6 className="mt-2">Tipo Di Guida:</h6>
              <h5>
                {
                  guideType.find((v) => v.id === insuranceRequest.guide_type)
                    .name
                }
              </h5>
              <h6 className="mt-2">Status:</h6>
              <h5>{statues.find((st) => st.id === quoteStatus).name}</h5>
              {/* <h6
                className="mt-2 text-decoration-underline"
                style={{ cursor: 'pointer' }}
                onClick={() => (rc_quotation.company_id === 1
                  ? downloadResourceDocs(
                    rc_quotation.product_id == 1 ? 1 : 5,
                    0,
                  )
                  : downloadResourceDocs(
                    rc_quotation.product_id == 1 ? 6 : 7,
                    0,
                  ))}
              >
                Informazioni Precontrattuali RCA
              </h6> */}
              {rc_quotation.company_id === 1 && rc_quotation.product_id === 1 && (
              <h6
                className="mt-2 text-decoration-underline"
                style={{ cursor: 'pointer' }}
                onClick={() => downloadResourceDocs(
                  1,
                  0,
                )}
              >
                Informazioni Precontrattuali RCA
              </h6>
              )}

              {rc_quotation.company_id === 1 && rc_quotation.product_id === 3 && (
              <h6
                className="mt-2 text-decoration-underline"
                style={{ cursor: 'pointer' }}
                onClick={() => downloadResourceDocs(
                  5,
                  0,
                )}
              >
                Informazioni Precontrattuali RCA
              </h6>
              )}

              {rc_quotation.company_id === 2 && rc_quotation.product_id === 1 && (
              <h6
                className="mt-2 text-decoration-underline"
                style={{ cursor: 'pointer' }}
                onClick={() => downloadResourceDocs(
                  6,
                  0,
                )}
              >
                Informazioni Precontrattuali RCA
              </h6>
              )}

              {rc_quotation.company_id === 2 && rc_quotation.product_id === 3 && (
              <h6
                className="mt-2 text-decoration-underline"
                style={{ cursor: 'pointer' }}
                onClick={() => downloadResourceDocs(
                  7,
                  0,
                )}
              >
                Informazioni Precontrattuali RCA
              </h6>
              )}

              {rc_quotation.company_id === 9 && rc_quotation.product_id === 1 && (
              <h6
                className="mt-2 text-decoration-underline"
                style={{ cursor: 'pointer' }}
                onClick={() => downloadResourceDocs(
                  17,
                  0,
                )}
              >
                Informazioni Precontrattuali RCA
              </h6>
              )}
              {risk_quotations
                && risk_quotations.find((q) => q.company_id === 16)
                  !== undefined && (
                  <h6
                    className="mt-2 text-decoration-underline"
                    style={{ cursor: 'pointer' }}
                    onClick={() => downloadResourceDocs(13)}
                  >
                    Informazioni Precontrattuali Global Assistance
                  </h6>
              )}
              {risk_quotations
                && risk_quotations.find((q) => q.company_id === 8)
                  !== undefined && (
                  <h6
                    className="mt-2 text-decoration-underline"
                    onClick={() => downloadResourceDocs(14)}
                    style={{ cursor: 'pointer' }}
                  >
                    Informazioni Precontrattuali Europe Assistance
                  </h6>
              )}
              <h6
                className="mt-2 text-decoration-underline"
                onClick={() => downloadPreventive()}
                style={{ cursor: 'pointer' }}
              >
                Preventivo
              </h6>

              {rc_quotation.link_to_endpoint && (
                <a
                  href={rc_quotation.link_to_endpoint}
                  className="mt-2 btn btn-success"
                  target="_blank"
                  rel="noreferrer"
                >
                  Paga Ora
                </a>
              )}
              <button
                className="mt-2 btn btn-success"
                onClick={() => downloadDocument(doc.id)}
              >
                Scarica Documento Da Firmare
              </button>
            </ClientBox>
          </div>
          <div className="col-12 col-xl-8">
            <ClientBox className="documents-status-step-two pagament-status-step-two">
              <h4>Informazioni Prodotti</h4>
              <hr />
              <InfoTable>
                <table className="table table-striped ">
                  <thead>
                    <tr>
                      <th scope="col">Prodotto</th>
                      <th scope="col">Compagnia</th>
                      <th scope="col">Frequenza Pagamento</th>
                      <th scope="col">Premio</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{rc_quotation.product.name}</td>
                      <td>
                        {rc_quotation.company_id !== null
                          ? rc_quotation.company.name
                          : rc_quotation.preventivas_company.company_name}
                      </td>
                      <td>
                        {rc_quotation.payment_frequency === 1
                          ? 'Annuale'
                          : 'Semestrale'}
                      </td>
                      <td>
                        {rc_quotation.payment_frequency === 1
                          ? parseFloat(rc_quotation.amount).toFixed(2)
                          : parseFloat(
                            rc_quotation.amount
                                / rc_quotation.payment_frequency,
                          ).toFixed(2)}
                        {' '}
                        &euro;
                      </td>
                    </tr>
                    {rc_quotation !== null
                      && rc_quotation.company_id === 2
                      && rc_quotation.guarantees.map((guarantee, index) => (
                        <tr key={index}>
                          <td>
                            {
                              primaQuoteTypes.find(
                                (type) => type.id === guarantee.guarantees_slug,
                              ).name
                            }
                          </td>
                          <td>Prima.it</td>
                          <td>
                            {rc_quotation.payment_frequency === 1
                              ? 'Annuale'
                              : 'Semestrale'}
                          </td>
                          <td>
                            {' '}
                            {rc_quotation.payment_frequency === 1
                              ? parseFloat(guarantee.amount).toFixed(2)
                              : parseFloat(
                                guarantee.amount
                                    / rc_quotation.payment_frequency,
                              ).toFixed(2)}
                            {' '}
                            €
                          </td>
                        </tr>
                      ))}
                    {risk_quotations
                      && risk_quotations.map((quote, index) => (
                        <tr key={index}>
                          <td>
                            {
                              quotationTypes.find(
                                (type) => type.id === quote.quote_type,
                              ).name
                            }
                          </td>
                          <td>{quote.company.name}</td>
                          <td>
                            {quote.payment_frequency === 1
                              ? 'Annuale'
                              : 'Semestrale'}
                          </td>
                          <td>
                            {rc_quotation.payment_frequency === 1
                              ? parseFloat(quote.amount).toFixed(2)
                              : parseFloat(
                                quote.amount / rc_quotation.payment_frequency,
                              ).toFixed(2)}
                            {' '}
                            &euro;
                          </td>
                        </tr>
                      ))}
                    <tr>
                      <td>Brokeraggio</td>
                      <td />
                      <td>
                        {rc_quotation.payment_frequency === 1
                          ? 'Annuale'
                          : 'Semestrale'}
                      </td>
                      <td>
                        {parseFloat(rc_quotation.brokerage_amount).toFixed(2)}
                        {' '}
                        &euro;
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td className="fw-bold">Totale</td>
                      <td />
                      <td />
                      <td className="fw-bold">
                        &euro;
                        {getTotal()}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </InfoTable>
            </ClientBox>
          </div>
        </div>
      </BoxWrapper>
      <MessagesModal
        {...messagesModalData}
        threads={insuranceRequest.threads}
      />
    </>
  );
}

export default InfoBoxAuto;
