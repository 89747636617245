import React, { useRef } from 'react';
import { RespCivileModal } from '../Cards/ProfessionInfoCard.styles';
import QuotationCard from '../../../reusable/QuotationCard';
import RiskCard from '../Cards/RiskCard';

function ModalQuotesMoto({
  selectQuotation, type, addingQuote, requestInfo, quotations, guaranteesPrima, refresh, processingQuote, riskQuotes,
}) {
  const closeButtonRef = useRef(null);

  const closeButton = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click(); // Close the modal
    }
  };

  const selectQuote = (quoteId) => {
    selectQuotation(quoteId, closeButton);
  };

  return (
    <RespCivileModal
      className="modal fade"
      id={type.id}
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {type.name}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="buttonClose"
              ref={closeButtonRef}
            />
          </div>
          <div className="modal-body">
            {addingQuote && (
              <div className="d-flex align-items-center justify-content-between flex-column">
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Aggiungendo...</span>
                  </div>
                </div>
                <div className="ms-2">Aggiungendo...</div>
              </div>
            )}
            {addingQuote === false && (
              <>
                <div className="row purchase-quote-eight-step">
                  {addingQuote === false
                    && quotations
                    && quotations[type.id]
                    && type.id === 'res_civile'
                    ? quotations[type.id].map((quote, index) => (
                      <React.Fragment key={index}>
                        {((quote.company_id === null
                          && quote.added_manually === 1)
                          || (quote.company_id !== null
                            && quote.added_manually == 0)) && (
                            <div className="col-12 mb-2">
                              <QuotationCard
                                quote={quote}
                                selectQuotation={selectQuote}
                                requestInfo={requestInfo}
                                guaranteesPrima={guaranteesPrima}
                                refresh={refresh}
                              />
                            </div>
                        )}
                        {quote.product_id === 10 && (
                          <div className="col-12 mb-2">
                            <QuotationCard
                              quote={quote}
                              selectQuotation={selectQuote}
                              requestInfo={requestInfo}
                            />
                          </div>
                        )}
                      </React.Fragment>
                    ))
                    : ''}
                  {type.id === 'assistenza_stradale'
                    && quotations
                    && quotations[type.id] && (
                      <div>
                        {processingQuote !== null ? (
                          quotations[type.id].map((quote, index) => (
                            <React.Fragment key={index}>
                              {(processingQuote.company_id === 1
                                && quote.company_id === 1)
                                || (processingQuote.company_id === 17
                                  && quote.company_id === 17) || (processingQuote.company_id === 9
                                    && quote.company_id === 9)
                                || quote.company_id === 16
                                || quote.company_id === 8 ? (
                                  <div className="col-12 mb-2">
                                    <RiskCard
                                      quote={quote}
                                      type={type}
                                      selectQuotation={selectQuote}
                                      requestInfo={requestInfo}
                                      riskQuotes={riskQuotes}
                                    />
                                  </div>
                                ) : (
                                  ''
                                )}
                            </React.Fragment>
                          ))
                        ) : (
                          <h5 className="fw-bold">
                            Nessuna Quotazione Trovata
                          </h5>
                        )}
                      </div>
                  )}

                  {type.id === 'tutela_legale'
                    && quotations
                    && quotations[type.id]
                    ? quotations[type.id].map((quote, index) => (
                      <div className="col-12 mb-2" key={index}>
                        <RiskCard
                          quote={quote}
                          type={type}
                          selectQuotation={selectQuote}
                          requestInfo={requestInfo}
                          riskQuotes={riskQuotes}
                        />
                      </div>
                    ))
                    : ''}

                  {type.id === 'rc_transportati_minori'
                    && quotations
                    && quotations[type.id]
                    ? quotations[type.id].map((quote, index) => (
                      <div className="col-12 mb-2" key={index}>
                        <RiskCard
                          quote={quote}
                          type={type}
                          selectQuotation={selectQuote}
                          requestInfo={requestInfo}
                          riskQuotes={riskQuotes}
                        />
                      </div>
                    ))
                    : ''}

                  {type.id === 'infortuni_conducente'
                    && quotations
                    && quotations[type.id] && (
                      <div>
                        {processingQuote !== null ? (
                          quotations[type.id].map((quote, index) => (
                            <React.Fragment key={index}>
                              {(processingQuote.company_id === 1
                                && quote.company_id === 1)
                                || (processingQuote.company_id === 17
                                  && quote.company_id === 17) || (processingQuote.company_id === 9
                                    && quote.company_id === 9)
                                || quote.company_id === 16
                                || quote.company_id === 8 ? (
                                  <div className="col-12 mb-2">
                                    <RiskCard
                                      quote={quote}
                                      type={type}
                                      selectQuotation={selectQuote}
                                      requestInfo={requestInfo}
                                      riskQuotes={riskQuotes}
                                    />
                                  </div>
                                ) : (
                                  ''
                                )}
                            </React.Fragment>
                          ))
                        ) : (
                          <h5 className="fw-bold">
                            Nessuna Quotazione Trovata
                          </h5>
                        )}
                      </div>
                  )}

                  {quotations
                    && quotations[type.id]
                    && quotations[type.id].length === 0 && (
                      <h5 className="fw-bold">Nessuna Quotazione Trovata</h5>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger px-4"
              data-bs-dismiss="modal"
            >
              Chiudi
            </button>
          </div>
        </div>
      </div>
    </RespCivileModal>
  );
}

export default ModalQuotesMoto;
