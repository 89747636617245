import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { PurchaseStyles, UploadDocumentsContainer, UploadWrapper } from '../purchase/Purchase.styles';
import { SaveBox } from '../purchase/common/SaveBox';
import { NumberCurrent, SkipSignedDocs } from '../../common/components/redesignComponents/Purchase/SignatureType.styles';
import WaitingForPaymentStatus from '../../common/components/redesignComponents/Purchase/WaitingForPaymentStatus';
import PageLoading from '../../common/components/elements/PageLoading';
import { confirmAlert, generalAlertError, generalAlertSuccess } from '../../common/utils/alerts';
import {
  purchaseInsuranceRequest, resetAncillariAutoPurchase,
  updateBrokerage,
} from '../../features/purchases/purchasesActions';
import InfoBoxAncillariAuto from '../../common/components/redesignComponents/Purchase/common/InfoBoxAncillariAuto';
import CompletedScreenAncillariAuto from '../../common/components/elements/PurchaseAuto/CompletedScreenAncillariAuto';
import NonSignableDocumentComponent from '../purchase/common/NonSignableDocumentComponent';
import { apiDownloadDocument, deleteDocument } from '../../services/axios-client/axeCommons';
import BrokeraggioComponent from '../../common/components/redesignComponents/Purchase/BrokeraggioComponent';
import axe from '../../services/axios-client/axiosClient';

const statuses = [
  {
    id: 1,
    name: 'Attesa Documenti',
  },
  {
    id: 2,
    name: 'Problemi Con i Documenti',
  },
  {
    id: 3,
    name: 'Attesa Firma Documenti',
  },
  {
    id: 4,
    name: 'Attesa Pagamento',
  },
  {
    id: 5,
    name: 'Attesa Smarcatura',
  },
  {
    id: 6,
    name: 'In Attesa Emissione',
  },
  {
    id: 7,
    name: 'Polizza Emessa',
  },
];

function PurchaseAncillariAutoRequest() {
  const navigate = useNavigate();
  const { requestToken } = useParams();

  const state = useSelector((store) => store.purchases.ancillari_auto);
  const {
    loading,
    quote,
    insuranceRequest,
    quoteStatus,
    // signMethod,
    backofficeUsers,
    nonSignableDocs,
    rc_quotation,
    // signableDocs,
    // requestedOptionalDocumentsSignable,
    risk_quotations,
  } = state;

  const navigateBack = () => {
    navigate(`/quotes/ancillari-auto/${insuranceRequest.request_token}`);
  };

  const [supportMessage, setSupportMessage] = useState({
    subject: '',
    request_id: '',
    body: '',
    recipient: '',
  });

  const collectQuoteData = async () => {
    purchaseInsuranceRequest(requestToken, 'ancillari-auto', setSupportMessage);
  };

  const sendBrokerageAmount = async (amount, skipCollect = false) => {
    if (skipCollect) {
      await updateBrokerage(
        rc_quotation.id,
        amount,
        () => { },
        skipCollect,
      );
    } else {
      await updateBrokerage(
        rc_quotation.id,
        amount,
        collectQuoteData,
        skipCollect,
      );
    }
  };
  useEffect(() => {
    if (insuranceRequest && insuranceRequest.redirect_user === true) {
      navigate('/');
      generalAlertSuccess('La pratica è completa, attendi nostro feedback!');
    }
  }, [insuranceRequest]);

  const downloadDocument = async (documentId) => {
    const response = await apiDownloadDocument(documentId);

    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const removeDocument = async (documentId) => {
    try {
      const response = await deleteDocument(documentId);

      if (response.status === 200) {
        collectQuoteData();
      }
    } catch (err) {
      // console.error(err);
    }
  };

  const checkIfAllNonSignableDocsAreUploaded = () => {
    let allUploaded = true;
    const allDocs = [
      ...insuranceRequest.documents.filter((d) => d.signable_document === 0),
      ...rc_quotation.documents.filter((d) => d.signable_document === 0),
    ];

    const nonSignaleDocTypeIds = nonSignableDocs.map((d) => d.id);

    for (let i = 0; i < allDocs.length; i++) {
      const document = allDocs[i];

      if (!nonSignaleDocTypeIds.includes(document.document_type_id)) {
        allUploaded = false;
      }
    }

    if (
      allDocs.length === 0
      || nonSignaleDocTypeIds.length !== allDocs.length
    ) {
      return false;
    }

    return allUploaded;
  };

  const agreedDocuments = async () => {
    try {
      const response = await axe.post(`/agree-to-upload-documents/${insuranceRequest.id}`, {
        agree_to_upload_document: 1,
      });
      if (response.status !== 200) {
        generalAlertError('Non Accetato');
        return;
      }

      collectQuoteData();
    } catch (error) {
      //
    }
  };

  // const continueWithoutSignDocs = () => {
  //   confirmAlert('Sei sicuro di voler firmare più tardi?', () => skipSignatureAncillariAuto(rc_quotation.id, navigate));
  // };

  // const continueWithSignDocs = () => {
  //   confirmAlert('Sei sicuro di voler firmare adesso?', () => continueWithSignatureAncillariAuto(rc_quotation.id, collectQuoteData));
  // };

  useEffect(() => {
    collectQuoteData();

    return () => {
      resetAncillariAutoPurchase();
    };
  }, []);

  useEffect(() => {
    if (loading === false && rc_quotation.is_expired === true) {
      navigate('/quote/ancillari-auto', {
        state: { requestToken: insuranceRequest.request_token },
      });
      generalAlertError(
        'È necessario compilare nuovamente il preventivo!',
        'Link e scaduto!',
      );
    }
  }, [loading, quote]);

  const getTotal = () => {
    let rcTotalAmount = 0;
    let primaGuaranteesPrices = 0;
    let risksTotalAmount = 0;

    if (risk_quotations && risk_quotations.length > 0) {
      for (let i = 0; i < risk_quotations.length; i++) {
        const item = risk_quotations[i];

        risksTotalAmount += rc_quotation.payment_frequency === 1 ? parseFloat(item.amount) : parseFloat(item.amount / rc_quotation.payment_frequency);
      }
    }

    if (rc_quotation.company_id === 2) {
      rc_quotation.guarantees.forEach((guarantee) => {
        primaGuaranteesPrices += rc_quotation.payment_frequency === 1 ? parseFloat(guarantee.amount) : parseFloat(guarantee.amount / rc_quotation.payment_frequency) || 0;
      });
    }

    rcTotalAmount = rc_quotation.payment_frequency === 1 ? rc_quotation.amount : rc_quotation.amount / rc_quotation.payment_frequency;

    return parseFloat(
      rcTotalAmount + primaGuaranteesPrices + risksTotalAmount,
    ).toFixed(2);
  };

  if (loading) {
    return <PageLoading />;
  }
  return (
    <PurchaseStyles>
      {quoteStatus !== 7 && (
        <>
          <div className="container mt-0 d-flex gap-2 align-items-center justify-content-start mb-3">
            <div className="px-2 py-1 d-flex align-items-center gap-2">
              <div
                className="cursor-pointer d-flex align-items-center justify-content-start gap-2 fw-bold text-success"
                onClick={() => navigateBack()}
              >
                <div id="back-button">
                  <FontAwesomeIcon icon={faArrowLeftLong} />
                </div>
                <p className="ml-2 mb-0  cursor-pointer">Modifica Preventivo</p>
              </div>
            </div>
          </div>
          <div className="container mt-0">
            <InfoBoxAncillariAuto
              rc_quotation={rc_quotation}
              statuses={statuses}
              insuranceRequest={insuranceRequest}
              quoteStatus={quoteStatus}
              messagesModalData={{
                backofficeUsers,
                setSupportMessage,
                supportMessage,
                collectData: collectQuoteData,
              }}
              risk_quotations={risk_quotations}
            />
            {quoteStatus === 1 || quoteStatus === 2 || quoteStatus === 5 ? (
              <>
                <div className="documents-status-step-four">
                  <SaveBox
                    insuranceRequest={insuranceRequest}
                    quotationId={rc_quotation.id}
                    refresh={collectQuoteData}
                  />
                  {(rc_quotation.amount_changed === true || rc_quotation.risk_quote_change === true) && (
                    <div className="alert alert-warning">
                      <b>Attenzione:</b>
                      {' '}
                      L’importo totale del preventivo è stato aggiornato. Di conseguenza, il brokeraggio è stato modificato qualora superasse il limite massimo consentito del 30% del valore del preventivo.
                    </div>
                  )}

                  <div className="row mt-3 ">
                    <div className="col-12 col-xxl-12 mt-2 mt-xxl-0">
                      <SkipSignedDocs className="skip-doc">
                        <p className="text-center mb-1">Accetta obblighi di inviare documenti</p>
                        <div className="d-flex justify-content-center align-items-center gap-3">
                          <label className="m-0">
                            <input type="checkbox" className="me-1" checked={insuranceRequest.agree_upload_documents === 1} disabled={insuranceRequest.agree_upload_documents === 1} onClick={() => confirmAlert('', () => agreedDocuments())} />
                            Dichiaro di aver adempiuto agli obblighi in ambito di invio documentazione precontrattuale

                          </label>
                        </div>
                      </SkipSignedDocs>
                    </div>
                  </div>
                  {insuranceRequest.agree_upload_documents !== null && insuranceRequest.customer.gender === 'G' && (
                    <div className="row mt-3">
                      <div className="col-12">
                        <UploadDocumentsContainer
                          className={
                            checkIfAllNonSignableDocsAreUploaded() === false
                              ? 'document-all-uploaded-border'
                              : ''
                          }
                        >
                          <div className="d-flex align-items-center justify-content-start">
                            <NumberCurrent>1</NumberCurrent>
                            <h2>
                              Carica i
                              {' '}
                              <b>documenti</b>
                              {' '}
                              del cliente
                            </h2>
                          </div>
                          <UploadWrapper>
                            {nonSignableDocs.map((docType, index) => (
                              <NonSignableDocumentComponent
                                key={index}
                                insuranceRequest={insuranceRequest}
                                documentType={docType}
                                downloadDocument={downloadDocument}
                                removeDocument={removeDocument}
                                rc_quotation={rc_quotation}
                                collectQuoteData={collectQuoteData}
                              />
                            ))}
                          </UploadWrapper>
                        </UploadDocumentsContainer>
                      </div>
                    </div>
                  )}
                </div>
                {/* <div className="documents-status-step-five">
                  {rc_quotation.sign_later === null && (
                    <div className="row mt-3">
                      <div className="col-12 col-xxl-12 mt-2 mt-xxl-0">
                        <SkipSignedDocs className="skip-doc">
                          <p className="text-center">Vuoi Firmare Dopo?</p>
                          <div className="d-flex justify-content-center align-items-center gap-3">
                            <button
                              className="btn btn-success border py-1 px-4"
                              onClick={() => continueWithoutSignDocs()}
                            >
                              Si
                            </button>
                            <button
                              className="btn btn-danger border py-1 px-4"
                              onClick={() => continueWithSignDocs()}
                            >
                              No
                            </button>
                          </div>
                        </SkipSignedDocs>
                      </div>
                    </div>
                  )}
                  {rc_quotation.sign_later === 1 && (
                    <div className="alert alert-success mt-3 border rounded-4">
                      Hai scelto di firmare i documenti più tardi.
                    </div>
                  )}
                  {rc_quotation.sign_later === 0 && (
                    <div className="row mt-3">
                      <div className="col-12 col-xxl-12 mt-2 mt-xxl-0">
                        <SignatureTypeAncillariAuto
                          quote={rc_quotation}
                          insuranceRequest={insuranceRequest}
                          collectQuoteData={collectQuoteData}
                          signableDocs={signableDocs}
                          requestedOptionalDocumentsSignable={
                            requestedOptionalDocumentsSignable
                          }
                        />
                      </div>
                    </div>
                  )}
                </div> */}

              </>
            ) : (
              ''
            )}
            {quoteStatus === 1 && (
              <BrokeraggioComponent
                rc_quotation={rc_quotation}
                getTotal={getTotal}
                sendBrokerageAmount={sendBrokerageAmount}
              />
            )}
            {quoteStatus === 4 && (
              <WaitingForPaymentStatus
                rc_quotation={rc_quotation}
                insuranceRequest={insuranceRequest}
                collectQuoteData={collectQuoteData}
                risk_quotations={risk_quotations}
              />
            )}

            {quoteStatus === 6 && (
              <div className="card-body mt-3">
                <div className="row">
                  <div className="col-6 offset-3">
                    <div className="alert alert-success" role="alert">
                      <h4 className="alert-heading">In Attesa Emissione</h4>
                      <p>
                        Quando l'agente di backoffice carica la polizza,
                        riceverai una notifica e il contratto potrà essere
                        scaricato.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {quoteStatus === 7 && (
        <>
          <div className="mt-0 gap-2 align-items-center justify-content-start mb-3">
            {/* <div>
              {localStorage.getItem('purchaseTour') === 'done' && (
                <div style={{ cursor: 'pointer' }} onClick={() => firePurchaseTour()}>
                  <button className="btn btn-success px-2 py-1">Tutorial</button>
                </div>
              )}
            </div> */}
          </div>

          <CompletedScreenAncillariAuto
            risk_quotations={risk_quotations}
            quoteStatus={quoteStatus}
            customer={insuranceRequest.customer}
            rc_quotation={rc_quotation}
          />
        </>
      )}
    </PurchaseStyles>
  );
}

export default PurchaseAncillariAutoRequest;
