import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { AfterSalePurchaseStyles } from '../../common/AfterSale/AfterSaleCreate.styles';
import AfterSaleInfoBox from '../../common/AfterSale/AfterSaleInfoBox';
import { UploadDocumentsContainer, UploadWrapper } from '../purchase/Purchase.styles';
import AfterSaleReplacementDocuments from '../../common/AfterSale/AfterSaleReplacementDocuments';
import { getAfterSalePurchase, resetAfterSalePurchase } from '../../features/afterSalePurchase/afterSalePurchaseActions';
import PageLoading from '../../common/components/elements/PageLoading';
import AfterSaleSignatureTypeDocuments from '../../common/AfterSale/AfterSaleSignatureTypeDocuments';
import { getDocumentTypes } from '../../features/afterSale/afterSaleActions';
import PaymentStatusAfterSale from '../../common/components/redesignComponents/Purchase/PaymentStatusAfterSale';
import AccidentsUploader from '../../common/components/redesignComponents/Uploader/AccidentsUploader';
import { generalAlertError, generalAlertSuccess } from '../../common/utils/alerts';
import managementAxe from '../../services/axios-client/managementServisAxeClient';
import { QuoteInfoContainerAfterSale, WaitingForPaymentQuoteInfoAfterSale } from '../../common/components/redesignComponents/Purchase/PaymentStatusAfterSale.styles';
import { addAccidentNote } from '../../features/accidents/accidentsActions';

const schema = yup.object({
  note: yup.string().required('Questo campo e obligatorio'),
});

function AfterSalePurchase() {
  const { afterSaleId, afterSaleType } = useParams();
  const { document_types } = useSelector((store) => store.afterSale);
  const { loading, afterSale, documents } = useSelector((store) => store.afterSalePurchase.after_sale_purchase);
  const navigate = useNavigate();

  const {
    register, formState: { errors }, handleSubmit, reset,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      note: '',
    },
  });

  const collectData = async () => {
    getAfterSalePurchase(afterSaleId, afterSaleType);
  };

  const addNote = (data) => {
    addAccidentNote(afterSaleId, data);
    reset({ note: '' });
    collectData();
  };

  const uploadAccidentDocuments = async (accidentId, data) => {
    const response = await managementAxe.post(`/broker/after-sale/accidents/documents/${accidentId}`, { files: data });
    if (response.status !== 200) {
      generalAlertError('I File non sono stati caricati. Riprova!');
      return;
    }

    generalAlertSuccess('I file sono stati caricati con successo!');
    collectData();
  };

  useEffect(() => {
    collectData();
    return () => {
      resetAfterSalePurchase();
    };
  }, []);

  useEffect(() => {
    getDocumentTypes(afterSaleType);
  }, [afterSaleType]);

  if (loading) {
    return <PageLoading />;
  }

  const getPostVenditaName = (type) => {
    if (type === 'replacement') {
      return 'per la sostituzione';
    }

    if (type === 'suspension') {
      return 'per la sospensione';
    }

    if (type === 'cancellation') {
      return 'per la cessazione';
    }

    if (type === 'modification') {
      return 'per modifica dati';
    }

    if (type === 'accident') {
      return 'per il sinistro (massimo 10 file)';
    }

    return '';
  };

  const getPostVenditaNameSign = (type) => {
    if (type === 'replacement') {
      return 'per la sostituzione';
    }

    if (type === 'suspension') {
      return 'per la sospensione';
    }

    if (type === 'cancellation') {
      return 'per la cessazione';
    }

    if (type === 'modification') {
      return 'per modifica dati';
    }

    if (type === 'accident') {
      return 'per il sinistro';
    }

    return '';
  };

  const navigateToFinalPage = (type) => {
    if (type === 'replacement') {
      navigate(`/replacements/${afterSale.id}`);
    }
    if (type === 'modification') {
      navigate(`/modifications/${afterSale.id}`);
    }
    if (type === 'cancellation') {
      navigate(`/cancellations/${afterSale.id}`);
    }
    if (type === 'accident') {
      navigate(`/accidents/${afterSale.id}`);
    }
    if (type === 'suspension') {
      navigate(`/suspensions/${afterSale.id}`);
    }
  };

  if (afterSale !== null && (afterSale.status === 5 || afterSale.status_id === 5)) {
    navigateToFinalPage(afterSaleType);
  }

  return (
    <AfterSalePurchaseStyles>
      <AfterSaleInfoBox afterSale={afterSale} afterSaleType={afterSaleType} />
      {afterSaleType === 'accident' && afterSale.status_id === 4 && (
      <div className="row mt-3">
        <div className="col-12">
          <WaitingForPaymentQuoteInfoAfterSale>
            <QuoteInfoContainerAfterSale>
              <div className="single-accident-two">
                <h3 className="ps-3">
                  <strong>
                    Nota
                  </strong>
                </h3>
                {afterSale.notes.length
                  ? afterSale.notes.map((note, index) => (
                    <p key={index} className="mx-3 p-2 mt-3 bg-light shadow-sm">{note.note}</p>
                  ))
                  : <p>Nessuna nota trovata!</p>}

              </div>
              <form onSubmit={handleSubmit(addNote)} className="single-accident-three px-3">
                <div className="mb-3 ">
                  <label className="form-label" htmlFor="note">Aggiungi nuova nota</label>
                  <textarea name="note" className={`form-control ${errors.note?.message ? 'is-invalid' : ''}`} rows="5" {...register('note')} />
                  <div className="invalid-feedback">
                    {errors.note?.message}
                  </div>
                  <button type="submit" className="btn btn-success px-4 mt-2">Invia</button>
                </div>
              </form>
              {/* {
                        accident.amount !== null
                        && (
                          <>
                            <h3 className="mt-4">Ammontare</h3>
                            €
                            {' '}
                            {Number(accident.amount).toLocaleString('it-IT', { minimumFractionDigits: 2 })}
                          </>
                        )
                      } */}

            </QuoteInfoContainerAfterSale>
          </WaitingForPaymentQuoteInfoAfterSale>
        </div>
      </div>
      )}
      {([1, 2].includes(afterSale.status) || [1, 2].includes(afterSale.status_id)) && (
        <div className="mt-3">
          {
            ['cancellation', 'modification', 'replacement'].includes(afterSaleType) && (
              <UploadDocumentsContainer>
                <div className="d-flex align-items-center justify-content-start">
                  <h2>
                    Carica i
                    {' '}
                    <b>documenti</b>
                    {' '}
                    {getPostVenditaName(afterSaleType)}
                  </h2>
                </div>
                <UploadWrapper>
                  <AfterSaleReplacementDocuments afterSaleType={afterSaleType} afterSale={afterSale} document_types={document_types} collectQuoteData={collectData} />
                </UploadWrapper>

              </UploadDocumentsContainer>
            )
          }
          {afterSaleType === 'accident' && (
            <>
              <UploadDocumentsContainer>

                {/* If 10 files are uploaded hide this component */}
                {afterSale.accident_files.length < 10 && (
                  <>
                    <div className="d-flex align-items-center justify-content-start">
                      <h2 className="mb-3">
                        Carica i
                        {' '}
                        <b>documenti</b>
                        {' '}
                        {getPostVenditaName(afterSaleType)}
                      </h2>
                    </div>
                    <div>
                      <AccidentsUploader
                        uploadDocuments={uploadAccidentDocuments}
                        accidentId={afterSaleId}
                      />
                    </div>
                  </>
                )}

              </UploadDocumentsContainer>

            </>
          )}
          {documents.length > 0 && (
            <AfterSaleSignatureTypeDocuments getPostVenditaNameSign={() => getPostVenditaNameSign(afterSaleType)} afterSale={afterSale} documents={documents} collectData={collectData} document_types={document_types} />
          )}
        </div>
      )}

      {(afterSale.status === 4 || afterSale.status_id === 4) && (
        <div className="card-body mt-3 ">
          <div className="row">
            <div className="col-12 col-lg-6 offset-lg-3">
              <div className="alert alert-success py-4" role="alert">
                <h4 className="alert-heading">In Attesa Smarcatura</h4>
                <p className="mt-2 mb-0">
                  Il nostro backoffice sta verificando questo caso. Riceverai una notifica doppo la verifica.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {(afterSale.status === 3 || afterSale.status_id === 3) && (
        <div className="mt-3">
          {afterSale.status === 3 && <PaymentStatusAfterSale afterSale={afterSale} afterSalePaymentRequest={afterSale.after_sale_payment_requests[0]} collectData={collectData} />}
        </div>
      )}
    </AfterSalePurchaseStyles>
  );
}

export default AfterSalePurchase;
