import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AfterSaleCreateFormFlowStyles,
  AfterSaleCreateFormStyles,
} from '../../common/AfterSale/AfterSaleCreate.styles';
import { CenteredDivTitle } from '../../common/components/CenteredPageTitle.styles';
import { FormInputTextarea } from '../../common/formInputs/FormInputTextarea';
import { FormInputDate } from '../../common/formInputs/FormInputDate';
import { addNewCancellation } from '../../features/afterSale/afterSaleActions';
import { reasonForReplacement } from '../../common/constants';
import { FormInputSelect } from '../../common/formInputs/FormInputSelect';

const schema = yup.object({
  note: yup.string().required('Il campo è obbligatorio'),
  date: yup.string().required('Il campo è obbligatorio'),
  cancellation_reason: yup.string().required('Il campo è obbligatorio'),
});

function NewCancellation() {
  const { quoteId } = useParams();
  const navigate = useNavigate();
  const {
    register, formState: { errors, touchedFields }, trigger, watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      note: '',
      date: '',
      cancellation_reason: '',
    },
  });

  const formData = watch();

  const submitData = async (formData) => {
    const triggerValidation = await trigger(
      'note',
      'date',
      'cancellation_reason',
    );
    if (!triggerValidation) {
      return;
    }
    addNewCancellation(quoteId, formData, navigate);
  };

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  return (
    <AfterSaleCreateFormFlowStyles>
      <AfterSaleCreateFormStyles>
        <CenteredDivTitle className="d-flex align-items-center justify-content-center gap-3 mt-3">
          <div className="after-sale-title divider">
            <h2 className="text-center">Completa i dati Cessazione</h2>
          </div>
        </CenteredDivTitle>

        <div className="form-container-after-sale">
          <FormInputDate
            label="Data"
            minDate={moment().format('YYYY-MM-DD')}
            registration={register('date')}
            error={errors.date?.message}
            valid={isValid('date')}
          />
          <FormInputSelect
            placeholder="Motivi della cessazione"
            label="Motivi della cessazione"
            registration={register('cancellation_reason')}
            error={errors.cancellation_reason?.message}
            valid={isValid('cancellation_reason')}
            options={reasonForReplacement}
          />
          <FormInputTextarea
            placeholder="Note"
            label="Note"
            registration={register('note')}
            rows="3"
            error={errors.note?.message}
            valid={isValid('note')}
          />

        </div>
        <div className="mt-3">
          <div className="d-flex justify-content-between">
            <button
              className="btn btn-questionnaire"
              type="button"
              onClick={() => submitData(formData)}
            >
              Continua
            </button>
          </div>
        </div>
      </AfterSaleCreateFormStyles>
    </AfterSaleCreateFormFlowStyles>
  );
}

export default NewCancellation;
