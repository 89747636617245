import React, { useEffect } from 'react';
import {
  QuoteInformationContainer,
  QuoteInformationItem,
} from '../RiskCard.styles';

function RiskQuoteInfoComponent({ quote, type, setExtraText }) {
  useEffect(() => {
    setExtraText(
      `${
        quote.risk_massimale !== null
      && quote.risk_massimale !== undefined
      && quote.risk_massimale != 0
          ? `Massimale: ${quote.risk_massimale}`
          : ''
      }  ${
        quote.massimale !== null
      && quote.massimale !== undefined
      && quote.massimale != 0
          ? `Massimale: ${quote.massimale}`
          : ''
      } Frequenza pagamento: ${
        quote.payment_frequency === 1 ? 'Annuo' : 'Semestrale'
      } ${
        quote.product_id !== 10 && quote.product_name
          ? `Nome Prodotto: ${quote.product_name}`
          : ''
      } ${
        quote.product_id === 10
      && quote.product_name
      && quote.quote_type === 'tutela_legale'
          ? `Nome Prodotto: ${quote.product_name}`
          : ''
      }`,
    );
  }, [quote, type]);
  return (
    <>
      <QuoteInformationContainer>
        <QuoteInformationItem>
          <h6>Compagnia:</h6>
          <p>{quote.company.name}</p>
        </QuoteInformationItem>
        <QuoteInformationItem>
          <h6>Prodotto:</h6>
          <p>{type.name}</p>
        </QuoteInformationItem>
        <QuoteInformationItem>
          <h6>Emissione:</h6>
          <p>Tradizionale</p>
        </QuoteInformationItem>
        <QuoteInformationItem>
          <h6>Frequenza pagamento:</h6>
          <p>Annuo</p>
        </QuoteInformationItem>
      </QuoteInformationContainer>
      <QuoteInformationContainer>
        {quote.risk_massimale
          && quote.risk_massimale !== null
          && quote.risk_massimale != '0.00' && quote.company_id !== 18 && (
            <QuoteInformationItem>
              <h6>Massimale:</h6>
              <p>
                {/* {quote.risk_massimale} */}
                {Number(quote.risk_massimale).toLocaleString('it-IT', {
                  minimumFractionDigits: 2,
                })}
                &euro;
              </p>
            </QuoteInformationItem>
        )}
        {quote.risk_massimale
          && quote.risk_massimale !== null
          && quote.risk_massimale != '0.00' && quote.company_id === 18 && (
            <QuoteInformationItem>
              <h6>Massimale:</h6>
              <p>
                {quote.risk_massimale}
              </p>
            </QuoteInformationItem>
        )}
        {quote.massimale !== null && quote.massimale != '0.00' && (
          <QuoteInformationItem>
            <h6>Massimale:</h6>
            <p>
              {Number(quote.massimale).toLocaleString('it-IT', {
                minimumFractionDigits: 2,
              })}
              &euro;
            </p>
          </QuoteInformationItem>
        )}
        {quote.product_id !== 10 && quote.product_name && (
          <QuoteInformationItem>
            <h6>Nome Prodotto:</h6>
            <p>{quote.product_name}</p>
          </QuoteInformationItem>
        )}

        {quote.product_id === 10
          && quote.product_name
          && quote.quote_type === 'tutela_legale' && (
            <QuoteInformationItem>
              <h6>Nome Prodotto:</h6>
              <p>{quote.product_name}</p>
            </QuoteInformationItem>
        )}
      </QuoteInformationContainer>
    </>
  );
}

export default RiskQuoteInfoComponent;
