import caller from './accidentsSlice';
import managementAxe from '../../services/axios-client/managementServisAxeClient';
import {
  generalAlertError,
  generalAlertSuccess,
} from '../../common/utils/alerts';

export const updateSelectedCustomer = async (customer) => {
  caller.setSelectedCustomer(customer);
};

export const updateSelectedQuote = async (quote) => {
  caller.setSelectedQuote(quote);
};

export const listCustomerQuotes = async (customerId) => {
  caller.listCustomerQuotesPending();
  try {
    const res = await managementAxe.get(`/broker/after-sale/customer/${customerId}/quotes`);

    if (res.status !== 200) {
      caller.listCustomerQuotesRejected('Errore Interno!');
      return;
    }

    caller.listCustomerQuotesSuccess(res.data.data);
  } catch (error) {
    caller.listCustomerQuotesRejected('Errore Interno!');
  }
};

// export const listCustomerInsurances = async (search) => {
//   const res = await managementAxe.post('/broker/after-sale/customer/insurances', { search_string: search });
//   if (res.status === 200) {
//     caller.listCustomerInsurancesSuccess(res.data.data);
//   }
// };

export const listCustomerInsurances = async (page, searchData) => {
  const res = await managementAxe.get('/broker/after-sale/customer/insurances', {
    params: {
      ...searchData,
      page,
    },
  });
  if (res.status === 200) {
    caller.listCustomerInsurancesSuccess(res.data);
  }
};

export const resetAddNewAccident = async () => {
  caller.addNewAccidentReset();
};

export const addNewAccident = async (selectedQuoteId, formData, navigate) => {
  caller.accidentPending();
  try {
    const res = await managementAxe.post(`/broker/after-sale/accidents/quotation/${selectedQuoteId}`, formData);
    if (res.status !== 200) {
      generalAlertError(res.data.message);
      return;
    }

    if (res.status !== 200) {
      caller.accidentRejected('Errore Interno');
      return;
    }
    const { after_sale_type, after_sale_id } = res.data.data;
    caller.accidentFulfilled();
    navigate(`/after-sale/purchase/${after_sale_type}/${after_sale_id}`);
  } catch (error) {
    caller.accidentRejected('Errore Interno');
  }
};

export const getAccidents = async (page, search) => {
  caller.listAccidentsPending();
  try {
    const response = await managementAxe.get('/broker/after-sale/accidents', {
      params: {
        ...search,
        page,
      },
    });

    if (response.status !== 200) {
      caller.listAccidentsRejected('Errore Interno');
      return;
    }

    caller.listAccidentsSuccess(response.data);
  } catch (error) {
    caller.listAccidentsRejected('Errore Interno');
  }
};

export const resetAccidentsList = () => {
  caller.listAccidentsReset();
};

export const getAccident = async (accidentId) => {
  caller.showAccidentPending();

  try {
    const response = await managementAxe.get(`/broker/after-sale/accidents/${accidentId}`);

    if (response.status !== 200) {
      caller.showAccidentRejected('Errore Interno');
      return;
    }
    caller.showAccidentSuccess(response.data.data);
  } catch (error) {
    caller.showAccidentRejected('Errore Interno');
  }
};

export const resetShowAccident = () => {
  caller.showAccidentReset();
};

export const addAccidentNote = async (accidentId, data) => {
  caller.accidentAddNote({
    loading: true,
  });

  const response = await managementAxe.post(`broker/after-sale/accident/${accidentId}/add-note`, data);

  if (response.status !== 200) {
    generalAlertError(response.data.message);
    return;
  }
  caller.accidentAddNote({
    loading: false,
  });
  generalAlertSuccess('La tua nota e stata aggiunta!');
};
