import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import StorageService from '../services/StorageService';

const Storage = new StorageService();
function LuceGas() {
  const user = Storage.getUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.id !== 26) {
      navigate('/');
    }
  }, [user]);

  return (
    <div>
      <iframe src="https://app.fastsign.it/?appTitle=Greenia SRL&appBackgroundNumber=8" className="w-100" height={800} title="Greenia SRL Luce Gas" />
    </div>
  );
}

export default LuceGas;
