import React from 'react';
import {
  MainTitle, SignaturePaperContainer, SignatureTypeWrapper, TitleButtonsWrapper,
} from '../components/redesignComponents/Purchase/SignatureType.styles';
import AfterSaleDocuments from './AfterSaleDocuments';

function AfterSaleSignatureTypeDocuments({ documents, collectData, getPostVenditaNameSign }) {
  return (
    <SignatureTypeWrapper className="mt-3">
      <TitleButtonsWrapper>
        <div className="d-flex align-items-center justify-content-start">
          {/* <NumberCurrent>2</NumberCurrent> */}
          <MainTitle>
            {' '}
            Firma i documenti
            {' '}
            <b>{getPostVenditaNameSign()}</b>
          </MainTitle>
        </div>

      </TitleButtonsWrapper>
      <SignaturePaperContainer>
        <AfterSaleDocuments documents={documents} collectData={collectData} />
      </SignaturePaperContainer>
    </SignatureTypeWrapper>
  );
}

export default AfterSaleSignatureTypeDocuments;
