import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import Joyride from 'react-joyride';
import PageLoading from '../common/components/elements/PageLoading';
import { BACKEND_BASE_URL } from '../config';
import {
  FailedQuoteTitle,
  FailedQuotesContainer,
  RcQuotesStyles,
} from './RcQuotes.styles';
import {
  loadMotoQuotes,
  toggleMotoCoveragesAction,
  updateMotoQuotesData,
  resetMotoQuotes,
  addQuoteMotoToCart,
  removeQuoteMotoFromCart,
} from '../features/insuranceRequests/insuranceRequestsActions';
import { confirmAlert, generalAlertError } from '../common/utils/alerts';
import MotoInfoCard from '../common/components/redesignComponents/Cards/MotoInfoCard';
import { EstimateContainer } from '../common/components/redesignComponents/common/Redesign.styles';
import GuaranteesMotoModal from '../common/components/redesignComponents/Modal/GuaranteesMotoModal';
import ErrorComponent from '../common/components/ErrorComponent';
// import { useTourContext } from '../context/useTourContext';
// import { joyrideOptions } from '../common/utils/joyrideUtils';
// import { handleCallbackRcMotoQuote } from '../common/utils/joyrideCallbacks';
// import { RcMotoQuoteSteps } from '../common/utils/joyrideSteps';
import { GuaranteesDefaultStateStyles } from '../common/components/redesignComponents/common/GuaranteesDefaultState.styles';
import { primaQuoteTypes, quotationTypes } from '../common/constants';
import {
  RiskCard,
  RiskContainer,
} from '../common/components/redesignComponents/Cards/ProfessionInfoCard.styles';
// import AragCard from '../common/components/redesignComponents/Cards/AragCard';
import {
  riskCardPrimaStyles,
  riskCardStyles,
} from '../common/components/redesignComponents/ResultPageComponents/RcQuotesModal.style';
import SelectedQuotesCardsPrima from '../common/components/redesignComponents/ResultPageComponents/SelectedQuotesCardsPrima';
import ButtonComponentLogicPrima from '../common/components/redesignComponents/ResultPageComponents/ButtonComponentLogicPrima';
import ModalQuotesPrima from '../common/components/redesignComponents/ResultPageComponents/ModalQuotesPrima';
import ModalQuotesMoto from '../common/components/redesignComponents/ResultPageComponents/ModalQuotesMoto';
import SelectedQuotesCards from '../common/components/redesignComponents/ResultPageComponents/SelectedQuotesCards';
import ButtonComponentLogic from '../common/components/redesignComponents/ResultPageComponents/ButtonComponentLogic';

function RcMotoQuotes() {
  const navigate = useNavigate();
  const { requestToken } = useParams();
  const [showGuaranteesModal, setShowGuaranteesModal] = useState(false);
  const { updateMarkupLoading } = useSelector(
    (store) => store.insuranceRequests.infodrive_quotes,
  );

  const clearQuotesIntervalRef = useRef(null);
  const requestInfoRef = useRef(null);
  const [intervalQuotes, setIntervalQuotes] = useState(null);
  const state = useSelector((store) => store.insuranceRequests.moto_quotes);
  const {
    loading,
    requestInfo,
    failedQuotes,
    coverages,
    error,
    quotations,
    processingQuote,
    tutelaLegaleQuote,
    addingQuote,
    risk_quotations,
  } = state;

  // const [showSellingDistanceModal, setShowSellingDistanceModal] = useState(false);
  const [counter, setCounter] = useState(180);
  // const [currentQuotes, setCurrentQuotes] = useState('diretta');
  // ? diretta = Greenia Companies
  // ? indiretta = Preventivass Companies
  // ? quotazioni = All Preventivass Quotes

  // const handleCurrentQuotesDisplay = (val) => {
  //   setCurrentQuotes(val);
  // };

  // const [steps, setSteps] = useState([]);
  const guaranteesPrima = (quote) => {
    const guarantees = quote.quotation_data_prima_guarantees
      .map((guarantee) => guarantee.slug)
      .filter((g) => g != 'rca' && g != 'furto_incendio');
    if (
      quote.quotation_data_prima_guarantees.find(
        (qg) => qg.slug == 'furto_incendio',
      ) != undefined
    ) {
      guarantees.push('incendio');
      guarantees.push('furto');
    }
    return guarantees;
  };

  const refresh = () => loadMotoQuotes(requestToken);

  const updateCoverages = (item) => {
    toggleMotoCoveragesAction(item.id);
  };

  const includesCoverage = (key) => coverages !== null && coverages.includes(key);

  // const getSellingDistanceLink = () => `${SELLING_DISTANCE_URL}/request/${requestInfo.distance_token}/motorcycle/results`;

  // const copySellingDistanceLink = async () => {
  //   try {
  //     const link = getSellingDistanceLink();

  //     await navigator.clipboard.writeText(link);
  //     generalAlertSuccess('Il collegamento e stato copiato');
  //   } catch (err) {
  //     // console.log(err);
  //   }
  // };

  // const {
  //   setState,
  //   state: { run, showBeacon },
  // } = useTourContext();

  // useEffect(() => {
  //   if (localStorage.getItem('newResultMoto') === 'done') {
  //     setState({ run: false });
  //   } else {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 1500);
  //   }
  // }, [localStorage.getItem('newResultMoto')]);

  // const firePurchaseTour = () => {
  //   localStorage.removeItem('newResultMoto');
  //   setState({ run: true });
  // };

  useEffect(() => {
    loadMotoQuotes(requestToken);

    return () => {
      resetMotoQuotes();
    };
  }, []);

  const clearQuotesInterval = () => {
    if (intervalQuotes !== null) {
      clearInterval(intervalQuotes);
      setIntervalQuotes(null);
    }
  };

  clearQuotesIntervalRef.current = clearQuotesInterval;
  requestInfoRef.current = requestInfo;

  useEffect(() => {
    const iq = setInterval(() => {
      const requestInfo = requestInfoRef.current;
      if (requestInfo !== null && requestInfo.product_id == 1) {
        if (requestInfo.with_preventivas === null) {
          loadMotoQuotes(requestToken);
        }

        if (requestInfo.with_preventivas !== null) {
          clearQuotesIntervalRef.current();
        }
      }
    }, 10000);

    setIntervalQuotes(iq);

    return () => {
      clearQuotesIntervalRef.current();
    };
  }, []);

  useEffect(() => {
    if (requestInfo !== null && requestInfo.quotations) {
      updateMotoQuotesData(requestInfo);
    }
  }, [requestInfo]);

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const modifyData = () => {
    navigate('/quote/motorcycle', {
      state: { requestToken },
    });
  };

  // const leadSourceName = {
  //   0: 'Intermediario',
  //   1: 'Sito publico',
  // };

  useEffect(() => {
    if (requestInfo && requestInfo.is_expired === true) {
      navigate('/quote/motorcycle', {
        state: { requestToken },
      });
      generalAlertError(
        'È necessario compilare nuovamente il preventivo!',
        'Link e scaduto!',
      );
    }
  }, [requestInfo]);

  const fetchQuotes = () => {
    loadMotoQuotes(requestToken);
  };

  const selectQuotation = (quoteId) => {
    confirmAlert('Sei Sicuro di aggiungere questa quotazione?', () => addQuoteMotoToCart(quoteId, fetchQuotes, modifyData));
  };

  const handleRequestSave = () => {
    if (processingQuote === null) {
      generalAlertError('Seleziona Prima La Responsabilita Civile', 'Errore');
      return;
    }

    navigate(`/quotes/moto/purchase/${requestToken}`);
  };

  const removeItemFromCart = (quoteId) => {
    confirmAlert('Sei Sicuro di rimuovere questa quotazione?', () => removeQuoteMotoFromCart(quoteId, fetchQuotes, modifyData));
  };

  // useEffect(() => {
  //   RcMotoQuoteSteps(quotations, setSteps);
  // }, [quotations]);

  const getTotal = () => {
    let quoteInProccessAmount = 0;
    let tutelaInProccessAmount = 0;
    let primaGuaranteesPrices = 0;
    let risksTotalAmount = 0;

    const riskTypes = quotationTypes.filter((t) => t.id !== 'res_civile');

    for (let x = 0; x < riskTypes.length; x++) {
      const type = riskTypes[x];
      if (risk_quotations && risk_quotations[type.id] !== undefined) {
        for (let i = 0; i < risk_quotations[type.id].length; i++) {
          const item = risk_quotations[type.id][i];

          risksTotalAmount += parseFloat(item.amount);
        }
      }
    }

    if (processingQuote !== null) {
      if (processingQuote.company_id === 2) {
        quoteInProccessAmount = processingQuote.quotation_data_prima?.quotation_data_prima_guarantees ? parseFloat(
          processingQuote.quotation_data_prima?.quotation_data_prima_guarantees.find(
            (price) => price.slug === 'rca',
          ).quotation_data_prima_guarantee_prices[0].discounted_to_display,
        ) : parseFloat(processingQuote.amount);
      } else {
        quoteInProccessAmount = parseFloat(processingQuote.amount);
      }
    }
    if (tutelaLegaleQuote !== null) {
      tutelaInProccessAmount = parseFloat(tutelaLegaleQuote.amount);
    }

    if (processingQuote.company_id === 2) {
      processingQuote.guarantees.forEach((guarantee) => {
        primaGuaranteesPrices += parseFloat(guarantee.amount) || 0;
      });
    }

    return (
      quoteInProccessAmount
      + tutelaInProccessAmount
      + primaGuaranteesPrices
      + risksTotalAmount
    ).toFixed(2);
  };

  if (loading || updateMarkupLoading) {
    return <PageLoading />;
  }

  return error !== null ? (
    <ErrorComponent error={error} />
  ) : (
    <>
      {/* <Joyride
            steps={steps}
            run={run}
            continuous
            showBeacon={showBeacon}
            locale={{
              back: 'Indietro',
              close: 'Chiudi',
              last: 'Fine',
              next: 'Prossima',
              open: 'Apri il modale',
              restart: 'Riavvia il tour ',
              skip: 'Salta',
            }}
            styles={{
              options: joyrideOptions,
              buttonNext: {
                backgroundColor: '#00a651',
                outline: 'none',
              },
              buttonBack: {
                color: '#00a651',
                outline: 'none',
              },
              beaconInner: {
                backgroundColor: '#00a651',
              },
              beaconOuter: {
                borderColor: '#00a651',
                backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
              },
              buttonSkip: {
                outline: 'none',
              },
              buttonClose: {
                outline: 'none',
              },
            }}
            callback={(data) => handleCallbackRcMotoQuote(data, steps, setState)}
            showSkipButton
            showProgress
          /> */}
      {showGuaranteesModal && (
        <GuaranteesMotoModal
          setShowGuaranteesModal={setShowGuaranteesModal}
          includesCoverage={includesCoverage}
          updateCoverages={updateCoverages}
          coverages={coverages}
          requestToken={requestToken}
        />
      )}
      <RcQuotesStyles>
        <div className="resultsPage">
          <div className="row">
            <div className="col-12 col-xxl-4 mb-2 mb-md-2 mb-xxl-0 result-moto-first-step">
              <MotoInfoCard
                insuranceRequest={requestInfo}
                modificaDati={modifyData}
              />
            </div>
            <div className="col-12 col-xxl-8 result-moto-second-step">
              <GuaranteesDefaultStateStyles>
                {processingQuote === null ? (
                  <h5 className="fw-semibold pt-2 ps-lg-2">
                    Seleziona Le Quotazioni Che Vuoi Aquistare
                  </h5>
                ) : (
                  <div className="container mt-4">
                    <ul className="list-unstyled">
                      {processingQuote !== null && (
                        <li>
                          <div className="d-flex justify-content-between align-items-center">
                            <h5>
                              {processingQuote.company.name}
                              {' '}
                              <span className="text-success">
                                (
                                {
                                  quotationTypes.find(
                                    (item) => item.id === processingQuote.quote_type,
                                  ).name
                                }
                                )
                              </span>
                            </h5>
                            <h5 className="fw-bold">
                              {parseFloat(
                                processingQuote.amount
                                  ? processingQuote.amount
                                  : 0,
                              ).toFixed(2)}
                              {' '}
                              €
                            </h5>
                          </div>
                        </li>
                      )}
                      {quotationTypes
                        .filter((t) => t.id !== 'res_civile')
                        .map((type, index) => (
                          <React.Fragment key={index}>
                            {risk_quotations && risk_quotations[type.id]
                              ? risk_quotations[type.id].map((quote, i) => (
                                <li key={i}>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <h5>
                                      {quote.company !== null
                                        ? quote.company.name
                                        : quote.preventivas_company
                                          .company_name}
                                      {' '}
                                      <span className="text-success">
                                        (
                                        {
                                          quotationTypes.find(
                                            (item) => item.id === quote.quote_type,
                                          ).name
                                        }
                                        )
                                      </span>
                                    </h5>
                                    <h5 className="fw-bold">
                                      {parseFloat(quote.amount).toFixed(2)}
                                      {' '}
                                      €
                                    </h5>
                                  </div>
                                </li>
                              ))
                              : ''}
                          </React.Fragment>
                        ))}
                      {processingQuote !== null
                        && processingQuote.company_id === 2
                        && processingQuote.guarantees.map((guarantee, index) => (
                          <li key={index}>
                            <div className="d-flex justify-content-between align-items-center">
                              <h5>
                                Prima.it
                                <span className="text-success">
                                  {' '}
                                  (
                                  {
                                    primaQuoteTypes.find(
                                      (type) => type.id === guarantee.guarantees_slug,
                                    ).name
                                  }
                                  )
                                </span>
                              </h5>
                              <h5 className="fw-bold">
                                {parseFloat(guarantee.amount).toFixed(2)}
                                {' '}
                                €
                              </h5>
                            </div>
                          </li>
                        ))}
                      <hr />
                      <li>
                        <div className="d-flex justify-content-end align-items-center">
                          <h5 className="me-5 fw-bold">Totale:</h5>
                          <h5 className="fw-bold">
                            {' '}
                            {getTotal()}
                            {' '}
                            €
                          </h5>
                        </div>
                      </li>
                    </ul>
                    <div className="d-flex justify-content-end mt-3">
                      <button
                        className="btn btn-success btn-lg rounded-4 px-5 fw-bold"
                        onClick={() => handleRequestSave()}
                      >
                        Procedi
                      </button>
                    </div>
                  </div>
                )}
              </GuaranteesDefaultStateStyles>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <EstimateContainer>
                <div className="d-flex gap-2 justify-content-end">
                  {/* <div>
                        <h4>
                          Preventivi assicurazione
                          {' '}
                          <b>Moto</b>
                        </h4>
                        <p>
                          Inizio Copertura:
                          {' '}
                          {requestInfo.vehicle !== null
                            && new Date(
                              requestInfo.policy_effective_date,
                            ).toLocaleDateString('it-IT', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                            })}
                          <br />
                          Provenienza:
                          {' '}
                          {leadSourceName[requestInfo.lead_source]}
                        </p>
                      </div> */}

                  {/* <div>
                        {localStorage.getItem('newResultMoto') === 'done' && (
                          <div style={{ cursor: 'pointer' }} onClick={() => firePurchaseTour()}>
                            <button className="btn btn-success px-2 py-1">Tutorial</button>
                          </div>
                        )}
                      </div> */}
                </div>
              </EstimateContainer>
            </div>
          </div>

          <RiskContainer className="mb-4 ">
            {processingQuote !== null && processingQuote.company_id === 2
              ? [{ id: 'res_civile', name: 'Responsabilita Civile' }].map(
                (type, index) => (
                  <React.Fragment key={index}>
                    {quotations && quotations[type.id] && (
                      <RiskCard
                        className={riskCardStyles(
                          requestInfo,
                          type,
                          risk_quotations,
                        )}
                      >
                        <div className="p-1 p-lg-3 me-auto">
                          <h5>{type.name}</h5>
                        </div>
                        <SelectedQuotesCards
                          processingQuote={processingQuote}
                          type={type}
                          riskQuotations={risk_quotations}
                          refresh={refresh}
                          requestInfo={requestInfo}
                        />
                        <ButtonComponentLogic
                          type={type}
                          requestInfo={requestInfo}
                          processingQuote={processingQuote}
                          removeItemFromCart={removeItemFromCart}
                          riskQuotations={risk_quotations}
                        />
                      </RiskCard>
                    )}
                  </React.Fragment>
                ),
              )
              : quotationTypes.map((type, k) => (
                <React.Fragment key={k}>
                  {quotations && quotations[type.id] ? (
                    <RiskCard
                      className={riskCardStyles(
                        requestInfo,
                        type,
                        risk_quotations,
                      )}
                    >
                      <div className="p-1 p-lg-3 me-auto">
                        <h5>{type.name}</h5>
                      </div>
                      <SelectedQuotesCards
                        processingQuote={processingQuote}
                        type={type}
                        refresh={refresh}
                        requestInfo={requestInfo}
                        riskQuotations={risk_quotations}
                      />

                      <ButtonComponentLogic
                        type={type}
                        processingQuote={processingQuote}
                        removeItemFromCart={removeItemFromCart}
                        requestInfo={requestInfo}
                        riskQuotations={risk_quotations}
                      />
                    </RiskCard>
                  ) : null}
                </React.Fragment>
              ))}
            {processingQuote !== null && processingQuote.company_id === 2 && (
              <>
                {processingQuote?.quotation_data_prima?.quotation_data_prima_guarantees.map(
                  (quote) => (
                    <>
                      {quote.slug !== 'rca' && (
                        <RiskCard
                          className={riskCardPrimaStyles(
                            primaQuoteTypes.find(
                              (type) => type.id === quote.slug,
                            ),
                            processingQuote,
                          )}
                        >
                          <div className="p-3 me-auto">
                            <h5>
                              {
                                primaQuoteTypes.find(
                                  (type) => type.id === quote.slug,
                                ).name
                              }
                            </h5>
                          </div>
                          <SelectedQuotesCardsPrima
                            processingQuote={processingQuote}
                            type={primaQuoteTypes.find(
                              (type) => type.id === quote.slug,
                            )}
                          />

                          <ButtonComponentLogicPrima
                            type={primaQuoteTypes.find(
                              (type) => type.id === quote.slug,
                            )}
                            processingQuote={processingQuote}
                            requestInfo={requestInfo}
                          />
                        </RiskCard>
                      )}
                    </>
                  ),
                )}
              </>
            )}
          </RiskContainer>

          {quotationTypes.map((type, index) => (
            <ModalQuotesMoto
              refresh={refresh}
              processingQuote={processingQuote}
              key={index}
              type={type}
              addingQuote={addingQuote}
              requestInfo={requestInfo}
              quotations={quotations}
              selectQuotation={selectQuotation}
              guaranteesPrima={guaranteesPrima}
              riskQuotes={risk_quotations}
            />
          ))}

          {processingQuote !== null
            && processingQuote.company_id === 2
            && primaQuoteTypes.map((type, index) => (
              <ModalQuotesPrima
                processingQuote={processingQuote}
                key={index}
                type={type}
                addingQuote={addingQuote}
                requestInfo={requestInfo}
                requestToken={requestToken}
                guaranteesPrima={guaranteesPrima}
              />
            ))}
          {failedQuotes !== null && failedQuotes.length > 0 && (
            <div className="row mt-5">
              <FailedQuoteTitle>
                Queste compagnie non hanno risposto con una quotazione.
              </FailedQuoteTitle>
              {failedQuotes.map((quote, index) => (
                <div className="col-3" key={index}>
                  <FailedQuotesContainer>
                    <img
                      src={BACKEND_BASE_URL + quote.company.logo}
                      alt={`${quote.company.name} Logo`}
                    />
                  </FailedQuotesContainer>
                </div>
              ))}
            </div>
          )}
        </div>
      </RcQuotesStyles>
    </>
  );
}

export default RcMotoQuotes;
