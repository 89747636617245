import React, { useEffect } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useLocation } from 'react-router-dom';
import CenteredPageTitle from '../common/components/CenteredPageTitle';
import { FormInputText } from '../common/formInputs/FormInputText';
import { setQuestionarioOldRequestToken } from '../features/commercialReport/commercialReportActions';

export const validationsSchema = yup.object({
  vat_number: yup
    .string()
    .max(11, 'La partiva iva non puo essere piu di 11 numeri!')
    .matches(/^[0-9]*$/, 'Solo numeri sono ammessi!')
    .required('Il campo è obbligatorio. Inserisci la tua partita iva!'),
});

function Commercial() {
  const navigate = useNavigate();
  const location = useLocation();
  // const [allowRequest, setAllowRequest] = useState(0);

  // const checkAllowRequest = async () => {
  //   const response = await managementAxe.get('/commercial/check-report-request');

  //   if (response.status !== 200) {
  //     setAllowRequest(false);
  //     return;
  //   }

  //   if (response.status === 200) {
  //     setAllowRequest(response.data.allow_request);
  //   }
  // };

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    setValue,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationsSchema),
    shouldFocusError: true,
    defaultValues: {
      vat_number: '',
    },
  });

  const commercialFormData = watch();

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  const submitForm = () => {
    navigate(`/quote/commercial/${commercialFormData.vat_number}/report`);
  };

  // useEffect(() => {
  //   checkAllowRequest();
  // }, []);

  useEffect(() => {
    const callAsync = async () => {
      if (
        location.state !== null
        && location.state.commercialCompanyVatNumber !== undefined
      ) {
        setValue('vat_number', location.state.commercialCompanyVatNumber);
      }
    };

    callAsync();
  }, []);

  useEffect(() => {
    if (location.state !== null && location.state.requestToken !== undefined) {
      setQuestionarioOldRequestToken(location.state.requestToken);
    }
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(submitForm)}>
        <CenteredPageTitle title="Inserisci Partita IVA" />
        <div className="form-container">
          <FormInputText
            placeholder="-Partita IVA-"
            label="Partita IVA"
            registration={register('vat_number')}
            error={errors.vat_number?.message}
            valid={isValid('vat_number')}
          />
        </div>
        <div className="footer-buttons">
          <div className="d-flex justify-content-between">
            {/* {allowRequest === 1 ? (
              <button className="btn btn-questionnaire" type="submit">
                Continua
              </button>
            ) : (
              <button
                className="btn btn-questionnaire"
                type="button"
                onClick={() => generalAlertError(
                  "Hai finito i check up gratuiti, contatta l'amministrazione per riprendere",
                )}
              >
                Continua
              </button>
            )} */}
            <button className="btn btn-questionnaire" type="submit">
              Continua
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default Commercial;
