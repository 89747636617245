import React, { useRef, useState } from 'react';

import { BACKEND_BASE_URL } from '../../../../config';
import {
  ButtonChat,
  LeftCol,
  LogoPriceContainer,
  LogoResultContainer,
  PriceContainer,
  PriceResult,
  QuoteInformationResultContainer,
  QuoteInformationResultItem,
  RiskCardSelectedStyles,
} from './RiskCardSelected.styles';
import CasaInfoModal from '../Modal/CasaInfoModal';
import {
  CompanyInfoElements,
  CompanyInformationContainer,
} from './RiskCard.styles';
import infoIcon from '../../../../assets/redesign_icons/info-icon.svg';
import SparklesIcon from '../../../../assets/images/icons/SparklesIcon';
import { Chat } from '../../chats/MiniChat/MiniChat';
import { stringToPrice } from '../../../constants';

function RiskCardSelected({
  quote, type, requestInfo, professionId = null,
}) {
  const [showModal, setShowModal] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const chatRef = useRef();

  const openModal = () => {
    setShowModal(!showModal);
  };

  const extraText = ` ${(quote.risk_massimale !== null
    && quote.risk_massimale !== undefined
    && quote.risk_massimale != 0)
    || (quote.massimale !== null
      && quote.massimale !== undefined
      && quote.massimale != 0)
    ? `Massimale: ${quote.risk_massimale !== null
      && quote.risk_massimale !== undefined
      && quote.risk_massimale != 0
      ? quote.risk_massimale
      : quote.massimale !== null
        && quote.massimale !== undefined
        && quote.massimale != 0
        ? quote.massimale
        : ''
    }`
    : ''
  }  - Frequenza pagamento: ${quote.payment_frequency === 1 ? 'Annuo' : 'Semestrale'
  } ${requestInfo?.commercial_company?.activity_description
    ? `- Tipologia di attività commerciale : ${requestInfo?.commercial_company?.activity_description}` : ''
  }`;

  return (
    <RiskCardSelectedStyles
      className={quote.is_aquista === 1 ? 'selected' : ''}
    >
      <div className="row-container">
        <LeftCol>
          {quote.company_id === 9 && requestInfo.payment_frequency === 2 && (
            <div className="alert alert-warning mt-1 mb-2 p-1 w-100">
              <b>Attenzione:</b>
              {' '}
              Linear non supporta il pagamento semestrale.
            </div>
          )}
          <LogoPriceContainer>
            <LogoResultContainer>
              <img
                src={BACKEND_BASE_URL + quote.company.logo}
                alt={quote.company.name}
              />
            </LogoResultContainer>
            {quote.amount != 0 && (
              <PriceContainer>
                <PriceResult>
                  <h5>Prezzo annuo:</h5>
                  <h2>
                    &euro;
                    {parseFloat(quote.amount).toFixed(2)}
                  </h2>
                </PriceResult>
                {quote.payment_frequency === 2 && (
                  <PriceResult>
                    <h5>Prezzo Semestrale:</h5>
                    <h6>Primo Pagamento</h6>
                    <h2>
                      &euro;
                      {(
                        Number(quote.amount) / quote.payment_frequency
                      ).toLocaleString('it-IT', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </h2>
                  </PriceResult>
                )}
              </PriceContainer>
            )}
          </LogoPriceContainer>
          {/* <CompanyInformationContainer>
            {quote.quote_type === 'tutela_legale' && (
              <>
                <CompanyInfoElements
                  onClick={() => downloadResourceDocs(10, 0)}
                  title="questionario_pi_tutela_legale"
                >
                  <img src={preventiveIcon} alt="" />
                  <p>Questionario</p>
                </CompanyInfoElements>
                <CompanyInfoElements
                  onClick={() => downloadResourceDocs(9, 0)}
                  title="set_informativo_pi_Tutela_legale"
                >
                  <img src={preContractIcon} alt="" />
                  <p>Informativa Precontrattuale</p>
                </CompanyInfoElements>
              </>
            )}
          </CompanyInformationContainer> */}

          {showModal && quote.company_id == 8 && quote.product_id == 11 && (
            <CasaInfoModal setShowModal={setShowModal} quote={quote} />
          )}

          {quote.company_id === 8
            && quote.product_id === 11
            && quote.quote_type !== 'danni_elettrici_al_fabricato'
            && quote.quote_type !== 'danni_elettrici_contenuto' && (
              <CompanyInformationContainer>
                <CompanyInfoElements onClick={() => openModal()}>
                  <img src={infoIcon} alt="" />
                  <p>Maggiori informazioni</p>
                </CompanyInfoElements>
              </CompanyInformationContainer>
          )}

          <QuoteInformationResultContainer>
            <QuoteInformationResultItem>
              <h6>Compagnia:</h6>
              <p>{quote.company.name}</p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>Prodotto:</h6>
              <p>{type.name}</p>
            </QuoteInformationResultItem>
            {((quote.risk_massimale !== null
              && quote.risk_massimale !== undefined
              && quote.risk_massimale != 0)
              || (quote.massimale !== null
                && quote.massimale !== undefined
                && quote.massimale != 0)) && (
                <QuoteInformationResultItem>
                  <h6>Massimale:</h6>
                  <p>
                    {quote.risk_massimale !== null
                      && quote.risk_massimale !== undefined
                      && quote.risk_massimale != 0
                      ? !String(quote.risk_massimale).includes('€')
                        ? stringToPrice(quote.risk_massimale)
                        : quote.risk_massimale
                      : quote.massimale !== null
                        && quote.massimale !== undefined
                        && quote.massimale != 0
                        ? !String(quote.massimale).includes('€')
                          ? stringToPrice(quote.massimale)
                          : quote.massimale
                        : ''}
                  </p>
                </QuoteInformationResultItem>
            )}

            {quote.quote_type === 'adattamento_abitazione_malattia'
              && (
                <QuoteInformationResultItem>
                  <h6>Massimale:</h6>
                  <p>
                    5.000,00
                    {' '}
                    &euro;
                  </p>
                </QuoteInformationResultItem>
              )}
            {quote.quote_type === 'indennita_sostitutiva_malattia'
              && (
                <QuoteInformationResultItem>
                  <h6>Massimale:</h6>
                  <p>
                    100,00 &euro;/giorno per 100 giorni
                  </p>
                </QuoteInformationResultItem>
              )}
            {quote.quote_type === 'daria_da_convalescenza_malattia'
              && (
                <QuoteInformationResultItem>
                  <h6>Massimale:</h6>
                  <p>
                    100,00 &euro;/giorno per 20 giorni
                  </p>
                </QuoteInformationResultItem>
              )}

            {quote.quote_type === 'rsm_post_ricovero_malattia'
              && (
                <QuoteInformationResultItem>
                  <h6>Visite specialistiche ed esami diagnostici: </h6>
                  <p>
                    5.000,00 €
                  </p>
                </QuoteInformationResultItem>
              )}
            {quote.quote_type === 'rsm_post_ricovero_malattia'
              && (
                <QuoteInformationResultItem>
                  <h6>Terapie oncologiche:</h6>
                  <p>
                    5.000,00 €
                  </p>
                </QuoteInformationResultItem>
              )}
            {quote.quote_type === 'assistenza_post_infortunio'
              && (
                <QuoteInformationResultItem>
                  <h6>Massimale:</h6>
                  <p>
                    1.000,00 €
                  </p>
                </QuoteInformationResultItem>
              )}
            {quote.quote_type === 'adattamento_abitazione_veicolo_infortunio'
              && (
                <QuoteInformationResultItem>
                  <h6>Massimale:</h6>
                  <p>
                    5.000,00 €
                  </p>
                </QuoteInformationResultItem>
              )}
            {quote.quote_type === 'rsm_post_infortunio'
              && (
                <QuoteInformationResultItem>
                  <h6>Massimale:</h6>
                  <p>
                    3.000,00 €
                  </p>
                </QuoteInformationResultItem>
              )}

            <QuoteInformationResultItem>
              <h6>Frequenza pagamento:</h6>
              <p>{quote.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}</p>
            </QuoteInformationResultItem>
          </QuoteInformationResultContainer>
          <ButtonChat>
            <button
              onClick={() => setOpenChat(true)}
              className="open-chat-button border-0  text-white bg-success rounded p-1"
            >
              <SparklesIcon width={40} height={40} />
            </button>
            <Chat
              ref={chatRef}
              openChat={openChat}
              setOpenChat={setOpenChat}
              company_id={quote.company_id}
              product_id={quote.product_id}
              quote_type={quote.quote_type}
              company_name={quote.company.name}
              extraText={extraText}
              professionId={professionId}
            />
          </ButtonChat>
        </LeftCol>
        {/* <RightCol /> */}
      </div>
    </RiskCardSelectedStyles>
  );
}

export default RiskCardSelected;
