import React from 'react';
import { BoxWrapper, ClientBox } from './CommonPurchase.styles';
import { productsTravel } from '../../../../constants';
import { downloadFile } from '../../../../../services/axios-client/axeHelpers';
import axe from '../../../../../services/axios-client/axiosClient';

function InfoBoxTravel({
  insuranceRequest,
  statues,
  quoteStatus,
  rc_quotation,
}) {
  const downloadResourceDocs = async (producCode) => {
    const res = await axe.get(`/travel/informativa/${producCode}`, {
      responseType: 'blob',
    });

    downloadFile(res.data);
  };

  const getTotal = () => {
    let quoteInProccessAmount = 0;

    if (rc_quotation !== null) {
      quoteInProccessAmount = parseFloat(rc_quotation.amount);
    }

    const total = quoteInProccessAmount;

    return total.toFixed(2);
  };

  return (
    <>
      <BoxWrapper>
        <div className="row gap-2 gap-xl-0">
          <div className="col-12 col-xl-4">
            <ClientBox className="pagament-status-step-one">
              <h6>Cliente:</h6>
              <h5>
                {insuranceRequest.customer.gender !== 'G'
                  ? `${insuranceRequest.customer.name} ${insuranceRequest.customer.surname}`
                  : insuranceRequest.customer.business_name}
              </h5>
              <h6 className="mt-2">Tipo Prodotto:</h6>
              <h5>
                {
                  productsTravel[
                    rc_quotation.quotation_data_travel_europeassistance
                      .product_code
                  ]
                }
              </h5>
              <h6 className="mt-2">Destinazione:</h6>
              <h5>{insuranceRequest.insurance_request_travel.country.name}</h5>
              <h6 className="mt-2">Numero Persone:</h6>
              <h5>
                {insuranceRequest.insurance_request_travel
                  .insurance_request_travel_people.length + 1}
              </h5>
              <h6 className="mt-2">Data Partenza:</h6>
              <h5>
                {new Date(
                  insuranceRequest.policy_effective_date,
                ).toLocaleDateString('it-IT')}
              </h5>
              <h6 className="mt-2">Il Contraente Viaggia:</h6>
              <h5>
                {insuranceRequest.insurance_request_travel
                  .is_contractor_included === 1
                  ? 'Si'
                  : 'No'}
              </h5>
              <h6 className="mt-2">Status:</h6>
              <h5>{statues.find((st) => st.id === quoteStatus).name}</h5>
              <h6
                className="mt-2 text-decoration-underline"
                style={{ cursor: 'pointer' }}
                onClick={() => downloadResourceDocs(
                  rc_quotation.quotation_data_travel_europeassistance
                    .product_code,
                )}
              >
                Informazioni Precontrattuali
              </h6>
            </ClientBox>
          </div>
          <div className="col-12 col-xl-8">
            <ClientBox className="pagament-status-step-two">
              <h4>Informazioni Prodotti</h4>
              <hr />
              <div className="table table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Prodotto</th>
                      <th scope="col">Compagnia</th>
                      <th scope="col">Frequenza Pagamento</th>
                      <th scope="col">Premio</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rc_quotation !== null
                      && rc_quotation.quotation_data_travel_europeassistance.quotation_data_travel_europeassistance_coverages.map(
                        (coverage, index) => (
                          <React.Fragment key={index}>
                            {coverage.enabled === 0
                              && coverage.group_id === null && (
                                <tr key={index}>
                                  <td>{coverage.title}</td>
                                  <td>
                                    {' '}
                                    {rc_quotation.company_id !== null
                                      ? rc_quotation.company.name
                                      : rc_quotation.preventivas_company
                                        .company_name}
                                  </td>
                                  <td>
                                    {rc_quotation.payment_frequency === 1
                                      ? 'Annuale'
                                      : 'Semestrale'}
                                  </td>
                                  <td>
                                    {parseFloat(
                                      coverage.coverage_amount_gros,
                                    ).toFixed(2)}
                                    {' '}
                                    &euro;
                                  </td>
                                </tr>
                            )}
                          </React.Fragment>
                        ),
                      )}
                    {rc_quotation.guarantees.map((cov, i) => (
                      <tr key={i}>
                        <td>{cov.guarantees_description}</td>
                        <td>
                          {' '}
                          {rc_quotation.company_id !== null
                            ? rc_quotation.company.name
                            : rc_quotation.preventivas_company.company_name}
                        </td>
                        <td>
                          {rc_quotation.payment_frequency === 1
                            ? 'Annuale'
                            : 'Semestrale'}
                        </td>
                        <td>
                          {parseFloat(cov.amount).toFixed(2)}
                          {' '}
                          &euro;
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td>Brokeraggio</td>
                      <td />
                      <td>
                        {rc_quotation.payment_frequency === 1
                          ? 'Annuale'
                          : 'Semestrale'}
                      </td>
                      <td>
                        {parseFloat(rc_quotation.brokerage_amount).toFixed(2)}
                        {' '}
                        &euro;
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td className="fw-bold">Totale</td>
                      <td />
                      <td />
                      <td className="fw-bold">
                        &euro;
                        {getTotal()}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </ClientBox>
          </div>
        </div>
      </BoxWrapper>
    </>
  );
}

export default InfoBoxTravel;
