import { createSlice } from '@reduxjs/toolkit';

import { callerCreator } from '../callerCreator';

const initialState = {
  loadingDocs: false,
  error: null,
  document_types: [],
  cancellation: {
    loading: false,
    error: null,
  },
  suspension: {
    loading: false,
    error: null,
  },
  replacement: {
    loading: false,
    error: null,
  },
  modification: {
    loading: false,
    error: null,
  },
  send_document: {
    loading: false,
    error: null,
    loadingDocs: true,
    documents: [],
    customer: null,
  },
  cancellations_page: {
    loading: true,
    error: null,
    cancellations: [],
    total_pages: 1,
    page_number: 1,
  },
  cancellation_page: {
    loading: true,
    error: null,
    cancellation: null,
  },
  replacements_page: {
    loading: true,
    error: null,
    replacements: [],
    total_pages: 1,
    page_number: 1,
  },
  replacement_page: {
    loading: true,
    error: null,
    replacement: null,
  },

  modifications_page: {
    loading: true,
    error: null,
    modifications: [],
    total_pages: 1,
    page_number: 1,
  },
  modification_page: {
    loading: true,
    error: null,
    modification: null,
  },

  send_documents_page: {
    loading: true,
    error: null,
    send_documents: [],
    total_pages: 1,
    page_number: 1,
  },

  suspensions_page: {
    loading: true,
    error: null,
    suspensions: [],
    total_pages: 1,
    page_number: 1,
  },

  suspension_page: {
    loading: true,
    error: null,
    suspension: null,
  },
  after_sales: {
    loading: true,
    error: null,
    after_sales: [],
  },
};

export const afterSaleSlice = createSlice({
  name: 'afterSale',
  initialState,
  reducers: {
    cancellationPending: (state) => {
      state.cancellation.loading = true;
      state.cancellation.error = null;
    },
    cancellationRejected: (state, { payload }) => {
      state.cancellation.loading = false;
      state.cancellation.error = payload;
    },
    cancellationFulfilled: (state) => {
      state.cancellation.loading = false;
    },
    suspensionPending: (state) => {
      state.suspension.loading = true;
      state.suspension.error = null;
    },
    suspensionRejected: (state, { payload }) => {
      state.suspension.loading = false;
      state.suspension.error = payload;
    },
    suspensionFulfilled: (state) => {
      state.suspension.loading = false;
    },

    replacementPending: (state) => {
      state.replacement.loading = true;
      state.replacement.error = null;
    },
    replacementRejected: (state, { payload }) => {
      state.replacement.loading = false;
      state.replacement.error = payload;
    },
    replacementFulfilled: (state) => {
      state.replacement.loading = false;
    },

    modificationPending: (state) => {
      state.modification.loading = true;
      state.modification.error = null;
    },
    modificationRejected: (state, { payload }) => {
      state.modification.loading = false;
      state.modification.error = payload;
    },
    modificationFulfilled: (state) => {
      state.modification.loading = false;
    },

    sendDocumentPending: (state) => {
      state.send_document.loading = true;
      state.send_document.error = null;
    },
    sendDocumentRejected: (state, { payload }) => {
      state.send_document.loading = false;
      state.send_document.error = payload;
    },
    sendDocumentFulfilled: (state) => {
      state.send_document.loading = false;
    },

    // Cancellations
    cancellationsPagePending: (state) => {
      state.cancellations_page.loading = true;
      state.cancellations_page.error = null;
    },
    cancellationsPageRejected: (state, { payload }) => {
      state.cancellations_page.loading = false;
      state.cancellations_page.error = payload;
    },
    cancellationsPageFulfilled: (state, { payload }) => {
      state.cancellations_page.loading = false;
      state.cancellations_page.cancellations = payload.data;
      state.cancellations_page.total_pages = payload.total_pages;
      state.cancellations_page.page_number = payload.current_page;
    },
    cancellationsPageReset: (state) => {
      state.cancellations_page = { ...initialState.cancellations_page };
    },

    cancellationPagePending: (state) => {
      state.cancellation_page.loading = true;
      state.cancellation_page.error = null;
    },
    cancellationPageRejected: (state, { payload }) => {
      state.cancellation_page.loading = false;
      state.cancellation_page.error = payload;
    },
    cancellationPageFulfilled: (state, { payload }) => {
      state.cancellation_page.loading = false;
      state.cancellation_page.cancellation = payload;
    },
    cancellationPageReset: (state) => {
      state.cancellation_page = { ...initialState.cancellation_page };
    },

    // Replacments
    replacementsPagePending: (state) => {
      state.replacements_page.loading = true;
      state.replacements_page.error = null;
    },
    replacementsPageRejected: (state, { payload }) => {
      state.replacements_page.loading = false;
      state.replacements_page.error = payload;
    },
    replacementsPageFulfilled: (state, { payload }) => {
      state.replacements_page.loading = false;
      state.replacements_page.replacements = payload.data;
      state.replacements_page.page_number = payload.current_page;
      state.replacements_page.total_pages = payload.total_pages;
    },
    replacementsPageReset: (state) => {
      state.replacements_page = { ...initialState.replacements_page };
    },

    replacementPagePending: (state) => {
      state.replacement_page.loading = true;
      state.replacement_page.error = null;
    },
    replacementPageRejected: (state, { payload }) => {
      state.replacement_page.loading = false;
      state.replacement_page.error = payload;
    },
    replacementPageFulfilled: (state, { payload }) => {
      state.replacement_page.loading = false;
      state.replacement_page.replacement = payload;
    },
    replacementPageReset: (state) => {
      state.replacement_page = { ...initialState.replacement_page };
    },

    // Modifications
    modificationsPagePending: (state) => {
      state.modifications_page.loading = true;
      state.modifications_page.error = null;
    },
    modificationsPageRejected: (state, { payload }) => {
      state.modifications_page.loading = false;
      state.modifications_page.error = payload;
    },
    modificationsPageFulfilled: (state, { payload }) => {
      state.modifications_page.loading = false;
      state.modifications_page.modifications = payload.data;
      state.modifications_page.page_number = payload.current_page;
      state.modifications_page.total_pages = payload.total_pages;
    },
    modificationsPageReset: (state) => {
      state.modifications_page = { ...initialState.modifications_page };
    },

    modificationPagePending: (state) => {
      state.modification_page.loading = true;
      state.modification_page.error = null;
    },
    modificationPageRejected: (state, { payload }) => {
      state.modification_page.loading = false;
      state.modification_page.error = payload;
    },
    modificationPageFulfilled: (state, { payload }) => {
      state.modification_page.loading = false;
      state.modification_page.modification = payload;
    },
    modificationPageReset: (state) => {
      state.modification_page = { ...initialState.modification_page };
    },

    // Send Documents
    sendDocumentsPagePending: (state) => {
      state.send_documents_page.loading = true;
      state.send_documents_page.error = null;
    },
    sendDocumentsPageRejected: (state, { payload }) => {
      state.send_documents_page.loading = false;
      state.send_documents_page.error = payload;
    },
    sendDocumentsPageFulfilled: (state, { payload }) => {
      state.send_documents_page.loading = false;
      state.send_documents_page.send_documents = payload.data;
      state.send_documents_page.total_pages = payload.total_pages;
      state.send_documents_page.page_number = payload.current_page;
    },
    sendDocumentsPageReset: (state) => {
      state.send_documents_page = { ...initialState.send_documents_page };
    },
    getDocumentsToSendPending: (state) => {
      state.send_document.loadingDocs = true;
      state.send_document.error = null;
    },
    getDocumentsToSendRejected: (state, { payload }) => {
      state.send_document.loadingDocs = false;
      state.send_document.error = payload;
    },
    getDocumentsToSendSuccess: (state, { payload }) => {
      state.send_document.loadingDocs = false;
      state.send_document.documents = payload.documents;
      state.send_document.customer = payload.customer;
    },
    sendDocumentsReset: (state) => {
      state.send_document = { ...initialState.send_document };
    },

    getDocumentTypesPending: (state) => {
      state.loadingDocs = true;
      state.error = null;
    },
    getDocumentTypesRejected: (state, { payload }) => {
      state.loadingDocs = false;
      state.error = payload;
    },
    getDocumentTypesFulfilled: (state, { payload }) => {
      state.loadingDocs = false;
      state.document_types = payload.documents;
    },

    // Suspensions

    suspensionsPagePending: (state) => {
      state.suspensions_page.loading = true;
      state.suspensions_page.error = null;
    },
    suspensionsPageRejected: (state, { payload }) => {
      state.suspensions_page.loading = false;
      state.suspensions_page.error = payload;
    },
    suspensionsPageFulfilled: (state, { payload }) => {
      state.suspensions_page.loading = false;
      state.suspensions_page.suspensions = payload.data;
      state.suspensions_page.total_pages = payload.total_pages;
      state.suspensions_page.page_number = payload.current_page;
    },
    suspensionsPageReset: (state) => {
      state.suspensions_page = { ...initialState.suspensions_page };
    },
    suspensionPagePending: (state) => {
      state.suspension_page.loading = true;
      state.suspension_page.error = null;
    },
    suspensionPageRejected: (state, { payload }) => {
      state.suspension_page.loading = false;
      state.suspension_page.error = payload;
    },
    suspensionPageFulfilled: (state, { payload }) => {
      state.suspension_page.loading = false;
      state.suspension_page.suspension = payload;
    },
    suspensionPageReset: (state) => {
      state.suspension_page = { ...initialState.suspension_page };
    },

    listAfterSalesPending: (state) => {
      state.after_sales.loading = true;
      state.after_sales.error = null;
    },

    listAfterSalesReject: (state, { payload }) => {
      state.after_sales.loading = false;
      state.after_sales.error = payload;
    },

    listAfterSalesSuccess: (state, { payload }) => {
      state.after_sales.loading = false;
      state.after_sales.after_sales = payload.data;
    },

    listAfterSalesReset: (state) => {
      state.after_sales = { ...initialState.after_sales };
    },
  },
});

export default callerCreator(afterSaleSlice.actions);
