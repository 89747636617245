import { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// import Joyride from 'react-joyride';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBan, faCarBurst, faEdit, faMailForward, faPause, faRotateRight,
} from '@fortawesome/free-solid-svg-icons';
import {
  listCustomerInsurances,
  listCustomerQuotes,
  resetAddNewAccident,
  updateSelectedQuote,
} from '../features/accidents/accidentsActions';
import { ClaimsManagementReportsStyles } from './ClaimsManagementReports.styles';
import { ReportAccident } from './ClaimsManagmentReportsOptions/ReportAccident';
import { Cancellation } from './ClaimsManagmentReportsOptions/Cancellation';
import { Replacement } from './ClaimsManagmentReportsOptions/Replacement';
import { Modification } from './ClaimsManagmentReportsOptions/Modification';
import { SendDocument } from './ClaimsManagmentReportsOptions/SendDocument';
import { SearchBtn } from './ActiveCustomers/ListActiveCustomers.styles';
import { productsList } from '../common/constants';
import Pagination from '../common/components/elements/Pagination';
import { getDocumentTypes } from '../features/afterSale/afterSaleActions';
// import { useTourContext } from '../context/useTourContext';
import PageLoading from '../common/components/elements/PageLoading';
import ErrorComponent from '../common/components/ErrorComponent';
// import { joyrideOptions } from '../common/utils/joyrideUtils';
// import { handleCallbackClaimsManagement } from '../common/utils/joyrideCallbacks';
// import { claimManagementReportSteps } from '../common/utils/joyrideSteps';

export default function ClaimsManagementReports() {
  const location = useLocation();
  const searchDataRef = useRef(null);
  const customerQuotesRef = useRef(null);
  const navigate = useNavigate();

  const state = useSelector((store) => store.accidents.add_new_accident);
  const { document_types, error: errorDocs } = useSelector((store) => store.afterSale);
  const [selectedOption, setSelectedOption] = useState(null);
  const [search, setSearch] = useState({
    search: '',
    vehicle_plate: '',
    product: '',
  });
  // const [steps, setSteps] = useState([]);

  const {
    selectedCustomer, customerQuotes, selectedQuote, customers, page_number, total_pages, error, loading,
  } = state;

  // const {
  //   setState,
  //   state: { run, showBeacon },
  // } = useTourContext();

  // useEffect(() => {
  //   if (localStorage.getItem('afterSale') === 'done') {
  //     setState({ run: false });
  //   } else {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 1500);
  //   }
  // }, [localStorage.getItem('afterSale')]);

  // const fireNewQuoteTour = () => {
  //   localStorage.removeItem('afterSale');
  //   setState({ run: true, tourActive: true });
  // };

  // useEffect(() => {
  //   claimManagementReportSteps(customers, setSteps);
  // }, [customers]);

  searchDataRef.current = customers;
  customerQuotesRef.current = customerQuotes;

  const searchHandler = () => {
    const trimmedSearch = search.search.trim();
    const trimmedPlate = search.vehicle_plate.trim();
    listCustomerInsurances(1, { ...search, search: trimmedSearch, vehicle_plate: trimmedPlate });
  };

  const handleInputChange = (e) => {
    setSearch({
      ...search,
      [e.target.name]: e.target.value,
    });
  };

  const selectQuote = (quote) => {
    updateSelectedQuote(quote);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    if (selectedCustomer !== null) {
      listCustomerQuotes(selectedCustomer.id);
    }
  }, [selectedCustomer]);

  useEffect(() => {
    if (location.state !== undefined && location.state !== null) {
      if (location.state.type === 'command') {
        if (location.state.data.page === 'accident') {
          const newSearch = {
            ...search,
            search: location.state.data.customer,
          };
          setSearch(newSearch);
          searchHandler(newSearch);

          setTimeout(() => {
            if (searchDataRef.current?.length !== 0) {
              selectQuote({ id: searchDataRef.current[0].quotation_id });
              selectOption(1);
            }
          }, 2000);
        }

        if (location.state.data.page === 'replacement') {
          const newSearch = {
            ...search,
            search: location.state.data.customer,
          };
          setSearch(newSearch);
          searchHandler(newSearch);

          setTimeout(() => {
            if (searchDataRef.current?.length !== 0) {
              selectQuote({ id: searchDataRef.current[0].quotation_id });
              selectOption(2);
            }
          }, 2000);
        }

        if (location.state.data.page === 'cancellation') {
          const newSearch = {
            ...search,
            search: location.state.data.customer,
          };
          setSearch(newSearch);
          searchHandler(newSearch);

          setTimeout(() => {
            if (searchDataRef.current?.length !== 0) {
              selectQuote({ id: searchDataRef.current[0].quotation_id });
              selectOption(3);
            }
          }, 2000);
        }

        if (location.state.data.page === 'send-documents') {
          const newSearch = {
            ...search,
            search: location.state.data.customer,
          };
          setSearch(newSearch);
          searchHandler(newSearch);

          setTimeout(() => {
            if (searchDataRef.current?.length !== 0) {
              selectQuote({ id: searchDataRef.current[0].quotation_id });
              selectOption(4);
            }
          }, 2000);
        }

        if (location.state.data.page === 'modification') {
          const newSearch = {
            ...search,
            search: location.state.data.customer,
          };
          setSearch(newSearch);
          searchHandler(newSearch);

          setTimeout(() => {
            if (searchDataRef.current?.length !== 0) {
              selectQuote({ id: searchDataRef.current[0].quotation_id });
              selectOption(5);
            }
          }, 2000);
        }
      }
    }

    if (location.state !== null) {
      const ls = location.state;

      if (ls.type === 'redirect') {
        const { data } = ls;
        const newSearch = {
          ...search,
          search: data.search,
        };
        setSearch(newSearch);
        searchHandler(newSearch);
      }
    }
    return () => {
      resetAddNewAccident();
    };
  }, []);

  useEffect(() => {
    listCustomerInsurances(1, search);
  }, []);

  useEffect(() => {
    if (selectedOption !== null) {
      let type = '';
      switch (selectedOption) {
        case 1:
          type = 'accident';
          break;
        case 2:
          type = 'replacement';
          break;
        case 3:
          type = 'cancellation';
          break;
        case 4:
          type = 'send-documents';
          break;
        case 5:
          type = 'modification';
          break;
        default:
          break;
      }
      getDocumentTypes(type);
    }
  }, [selectedOption]);

  if (loading) {
    return <PageLoading />;
  }

  if (error !== null || errorDocs !== null) {
    return <ErrorComponent error={error} />;
  }

  return (
    <ClaimsManagementReportsStyles>
      {/* <Joyride
        steps={steps}
        continuous
        showBeacon={showBeacon}
        locale={{
          back: 'Indietro',
          close: 'Chiudi',
          last: 'Fine',
          next: 'Prossima',
          open: 'Apri il modale',
          restart: 'Riavvia il tour ',
          skip: 'Salta',
        }}
        styles={{
          options: joyrideOptions,

          buttonNext: {
            backgroundColor: '#00a651',
            outline: 'none',
          },
          buttonBack: {
            color: '#00a651',
            outline: 'none',
          },
          beaconInner: {
            backgroundColor: '#00a651',
          },
          beaconOuter: {
            borderColor: '#00a651',
            backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
          },
          buttonSkip: {
            outline: 'none',
          },
          buttonClose: {
            outline: 'none',
          },
        }}
        run={run}
        callback={(data) => handleCallbackClaimsManagement(data, steps, setState)}
        showSkipButton
        showProgress
      />
      {localStorage.getItem('afterSale') === 'done' && (
        <div style={{ cursor: 'pointer', top: '8%' }} onClick={() => fireNewQuoteTour()} className="position-fixed end-0 mt-1 me-3">
          <button className="btn btn-success px-2 py-1 rounded-3">Tutorial</button>
        </div>
      )} */}
      <div className="row mb-5 px-2">
        <div className="col-12 col-md-9 col-lg-10 col-xl-6 post-vendita-text-border py-2 px-3 shadow-sm">
          <h5 className="fw-bold m-0">
            In questa sezione puoi denunciare un sinistro oppure puoi aprire un
            ticket per modificare un elemento contrattuale di uno dei tuoi
            clienti
          </h5>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <h5 className="mb-0 h3 ">Apri Nuovo Ticket</h5>
        <div />
      </div>
      <hr />
      <form className="w-100 d-flex flex-column flex-sm-row align-items-center justify-content-start after-sale-one">
        <input
          type="text"
          name="search"
          value={search.search}
          onChange={handleInputChange}
          className="search-input form-control mb-0 me-2 me-sm-2"
          placeholder="cerca con nome o cognome..."
        />
        <input
          type="text"
          name="search"
          value={search.vehicle_plate}
          onChange={(e) => setSearch({ ...search, vehicle_plate: e.target.value })}
          className="search-input form-control mb-0 me-2 me-sm-2"
          placeholder="cerca con targa..."
        />
        <select
          name="product"
          id=""
          className="form-select mb-0 me-0 me-sm-2 mt-2 mt-sm-0 custom-select-search"
          value={search.product}
          onChange={(e) => setSearch({ ...search, product: e.target.value })}
        >
          <option value=""> - Prodotto - </option>
          {productsList.map((product, index) => (
            <option key={index} value={product.id}>
              {product.name}
            </option>
          ))}
        </select>

        <SearchBtn
          className="mt-2 mt-sm-0"
          type="button"
          onClick={() => searchHandler()}
        >
          Cerca
        </SearchBtn>
        <div className="w-100 d-none d-md-block" />
      </form>
      <div className="mt-5 table-responsive after-sale-two">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Nome / Ragione Sociale</th>
              <th scope="col">Cognome</th>
              <th scope="col">Prodotto</th>
              <th scope="col">Compagnia</th>
              <th scope="col">Targa</th>
              <th scope="col">Azioni</th>

              <th />
            </tr>
          </thead>
          <tbody>
            {customers.length > 0 ? (
              customers.map((customer, index) => (
                <>
                  <tr key={index}>
                    <td>
                      {customer.gender !== 'G'
                        ? customer.name
                        : customer.business_name}
                    </td>
                    <td>{customer.surname}</td>
                    <td>{customer.product_name}</td>
                    <td>{customer.company_name}</td>
                    <td>{customer.vehicle_plate || customer.moto_plate}</td>
                    <td>
                      <div className="after-sale-three">
                        <button
                          className="btn btn-success post-vendita-button"
                          type="button"
                          data-bs-toggle="offcanvas"
                          data-bs-target={`#postVenditaCanvas-${customer.quotation_id}`}
                          aria-controls={`postVenditaCanvas-${customer.quotation_id}`}
                          onClick={() => selectQuote({ id: customer.quotation_id })}
                        >
                          Apri
                        </button>
                      </div>
                    </td>
                  </tr>
                  <div
                    className="offcanvas offcanvas-end"
                    tabIndex="-1"
                    id={`postVenditaCanvas-${customer.quotation_id}`}
                    aria-labelledby={`postVenditaCanvas-${customer.quotation_id}Label`}
                  >
                    <div className="offcanvas-header">
                      <h5
                        id={`postVenditaCanvas-${customer.quotation_id}Label`}
                        className="mt-3 ms-2 text-success"
                      >
                        Cosa Vuoi Denuncciare
                      </h5>
                      <button
                        type="button"
                        className="btn-close text-reset me-2"
                        data-bs-dismiss="offcanvas"
                        id={`postVenditaCanvas-${customer.quotation_id}Close`}
                        aria-label="Close"
                      />
                    </div>
                    <div className="offcanvas-body">
                      <div className="mx-2">
                        {customer.gender !== 'G' ? (
                          <>
                            <div className="mb-2">
                              <h6 className="m-0 d-flex align-items-center gap-2">
                                Nome:
                                <p className="m-0 fw-light">
                                  {'  '}
                                  {customer.name}
                                </p>
                              </h6>
                            </div>
                            <div className="mb-2">
                              <h6 className="m-0 d-flex align-items-center gap-2">
                                Cognome:
                                <p className="m-0 fw-light">
                                  {'  '}
                                  {customer.surname}
                                </p>
                              </h6>
                            </div>
                          </>
                        ) : (
                          <div className="mb-2">
                            <h6 className="m-0 d-flex align-items-center gap-2">
                              Ragione Sociale:
                              <p className="m-0 fw-light">
                                {'  '}
                                {customer.business_name}
                              </p>
                            </h6>
                          </div>
                        )}

                        <div className="mb-2">
                          <h6 className="m-0 d-flex align-items-center gap-2">
                            Prodotti:
                            <p className="m-0 fw-light">
                              {'  '}
                              {customer.product_name}
                            </p>
                          </h6>
                        </div>
                        <div className="mb-2">
                          <h6 className="m-0 d-flex align-items-center gap-2">
                            Compagnia:
                            <p className="m-0 fw-light">
                              {'  '}
                              {customer.company_name}
                            </p>
                          </h6>
                        </div>
                        {customer.vehicle_plate && (
                          <div className="mb-2">
                            <h6 className="m-0 d-flex align-items-center gap-2">
                              Targa:
                              <p className="m-0 fw-light">
                                {'  '}
                                {customer.vehicle_plate}
                              </p>
                            </h6>
                          </div>
                        )}
                      </div>
                      {selectedQuote !== null && (
                        <div className="options">
                          <div
                            data-bs-dismiss="offcanvas"
                            id={`postVenditaCanvas-${customer.quotation_id}Close`}
                            className="option-button"
                            onClick={() => {
                              document.body.style.overflow = '';
                              document.body.classList.remove('offcanvas-open');

                              navigate(
                                `/after-sale/new/accident/${customer.quotation_id}`,
                              );
                            }}
                          >
                            <div className="option-button-icon">
                              <FontAwesomeIcon
                                icon={faCarBurst}
                                color="var(--greenia-color)"
                              />
                            </div>
                            <div className="option-button-text">
                              Denuncia Sinistro
                            </div>
                          </div>
                          <div
                            className="option-button"
                            onClick={() => {
                              document.body.style.overflow = '';
                              document.body.classList.remove('offcanvas-open');

                              navigate(
                                `/after-sale/new/replacement/${customer.quotation_id}`,
                              );
                            }}
                          >
                            <div className="option-button-icon">
                              <FontAwesomeIcon
                                icon={faRotateRight}
                                color="var(--greenia-color)"
                              />
                            </div>
                            <div className="option-button-text">
                              Sostituzione
                            </div>
                          </div>
                          <div
                            data-bs-dismiss="offcanvas"
                            id={`postVenditaCanvas-${customer.quotation_id}Close`}
                            className="option-button"
                            onClick={() => {
                              document.body.style.overflow = '';
                              document.body.classList.remove('offcanvas-open');

                              navigate(
                                `/after-sale/new/cancellation/${customer.quotation_id}`,
                              );
                            }}
                          >
                            <div className="option-button-icon">
                              <FontAwesomeIcon
                                icon={faBan}
                                color="var(--greenia-color)"
                              />
                            </div>
                            <div className="option-button-text">Cessazione</div>
                          </div>
                          <div
                            id={`postVenditaCanvas-${customer.quotation_id}Close`}
                            data-bs-dismiss="offcanvas"
                            className="option-button"
                            onClick={() => {
                              document.body.style.overflow = '';
                              document.body.classList.remove('offcanvas-open');

                              navigate(
                                `/after-sale/new/suspension/${customer.quotation_id}`,
                              );
                            }}
                          >
                            <div className="option-button-icon">
                              <FontAwesomeIcon
                                icon={faPause}
                                color="var(--greenia-color)"
                              />
                            </div>
                            <div className="option-button-text">
                              Sospensione
                            </div>
                          </div>
                          <div
                            className="option-button"
                            data-bs-dismiss="offcanvas"
                            id={`postVenditaCanvas-${customer.quotation_id}Close`}
                            onClick={() => selectOption(4)}
                          >
                            <div className="option-button-icon">
                              <FontAwesomeIcon
                                icon={faMailForward}
                                color="var(--greenia-color)"
                              />
                            </div>
                            <div className="option-button-text">
                              Invio documentazione al cliente
                            </div>
                          </div>
                          <div
                            data-bs-dismiss="offcanvas"
                            id={`postVenditaCanvas-${customer.quotation_id}Close`}
                            className="option-button"
                            onClick={() => {
                              document.body.style.overflow = '';
                              document.body.classList.remove('offcanvas-open');

                              navigate(
                                `/after-sale/new/modify-data/${customer.quotation_id}`,
                              );
                            }}
                          >
                            <div className="option-button-icon">
                              <FontAwesomeIcon
                                icon={faEdit}
                                color="var(--greenia-color)"
                              />
                            </div>
                            <div className="option-button-text">
                              Modifica dati non tariffari
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ))
            ) : (
              <tr>
                <td colSpan="5">Nessun cliente attivo!</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="mt-2">
        <Pagination
          total_pages={total_pages}
          current_page={page_number}
          onClick={listCustomerInsurances}
          search={search}
        />
      </div>

      {selectedQuote !== null && selectedOption === 1 && (
        <ReportAccident
          quotationId={selectedQuote.id}
          selectOption={selectOption}
          location={location}
          collectData={listCustomerInsurances}
          search={search}
        />
      )}

      {selectedQuote !== null && selectedOption === 2 && (
        <Replacement
          quotationId={selectedQuote.id}
          customers={customers}
          selectOption={selectOption}
          location={location}
          document_types={document_types}
        />
      )}

      {selectedQuote !== null && selectedOption === 3 && (
        <Cancellation
          quotationId={selectedQuote.id}
          selectOption={selectOption}
          location={location}
        />
      )}

      {selectedQuote !== null && selectedOption === 4 && (
        <SendDocument
          quotationId={selectedQuote.id}
          selectOption={selectOption}
          location={location}
        />
      )}

      {selectedQuote !== null && selectedOption === 5 && (
        <Modification
          quotationId={selectedQuote.id}
          selectOption={selectOption}
          location={location}
        />
      )}
    </ClaimsManagementReportsStyles>
  );
}
