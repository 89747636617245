import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SaluteCompletedScreenStyles } from './completedScreens.styles';
import ErrorComponent from '../../common/components/ErrorComponent';
import PageLoading from '../../common/components/elements/PageLoading';
import { getSaluteCompletedData, saluteCompletedDataReset } from '../../features/purchases/purchasesActions';
import { apiDownloadDocument, apiDownloadOptionalDocument } from '../../services/axios-client/axeCommons';
import quotationsAxe from '../../services/axios-client/quotationsAxeClient';
import CompletedQuoteScreenInfoSalute from '../../common/components/elements/CompletedQuoteScreenInfoSalute';
import CompletedScreenSaluteDocuments from '../../common/components/elements/CompletedScreenSaluteDocuments';
import ContractDocuments from '../../common/components/elements/ContractDocuments';

function SaluteCompletedScreen() {
  const [residenceInfo, setResidenceInfo] = useState(null);
  const { quoteId } = useParams();
  // const { document_types } = useSelector(
  //   (store) => store.afterSale,
  // );
  const {
    loading, quote, error, risk_quotations,
  } = useSelector(
    (store) => store.purchases.salute_completed,
  );

  const collectMunicipalities = () => {
    try {
      quotationsAxe.get('/form-data/communes').then((res) => {
        const commune = res.data.data.find(
          (commune) => commune.cadastral_code
              == quote.insurance_request.customer.residence_commune_code,
        );
        setResidenceInfo(commune);
      });
    } catch (err) {
      // Do nothing
    }
  };

  const downloadDocument = async (documentId) => {
    const response = await apiDownloadDocument(documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const downloadOptionalDocument = async (documentId) => {
    const response = await apiDownloadOptionalDocument(documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const collectQuoteData = () => {
    getSaluteCompletedData(quoteId);
  };

  useEffect(() => {
    collectQuoteData();

    return () => {
      saluteCompletedDataReset();
    };
  }, [quoteId]);

  useEffect(() => {
    if (quote && quote.status === 7) {
      collectMunicipalities();
    }
  }, [quote]);

  if (loading) {
    return <PageLoading />;
  }

  if (loading === false && error !== null) {
    return <ErrorComponent error={error} />;
  }

  const documentsRequests = [
    ...quote.insurance_request.document_requests.filter(
      (dr) => dr.document !== null,
    ),
    ...quote.document_requests.filter((dr) => dr.document !== null),
  ];

  return (
    <SaluteCompletedScreenStyles className="row">
      <div className="col-xl-12">
        <div className="row mb-2">
          <div className="col-12">
            <ContractDocuments
              downloadDocument={downloadDocument}
              insuranceRequest={quote.insurance_request}
              quote={quote}
              collectQuoteData={collectQuoteData}
            />
          </div>
        </div>
      </div>
      <div className="col-xl-12">
        <div className="row mb-2">
          <div className="col-12 col-md-6">
            <div className="card border mb-2 polizze-emessa-status-step-one">
              <div className="card-header bg-secondary text-white">
                <h2 className="h3 mb-0 card-title">Anagrafica</h2>
              </div>
              <div className="card-body">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    {quote.insurance_request.customer.gender === 'G' && (
                    <>
                      {' '}
                      <b>Compagnia:</b>
                      {' '}
                      {quote.insurance_request.customer.business_name}
                    </>
                    )}
                    {quote.insurance_request.customer.gender !== 'G' && (
                    <>
                      <b>Nome e Cognome:</b>
                      {' '}
                      {quote.insurance_request.customer.name}
                      {' '}
                      {quote.insurance_request.customer.surname}
                    </>
                    )}
                  </li>
                  <li className="list-group-item">
                    <b>Email:</b>
                    {' '}
                    {quote.insurance_request.customer.email}
                  </li>
                  <li className="list-group-item">
                    <b>Telefono:</b>
                    {' '}
                    {quote.insurance_request.customer.phone}
                  </li>
                  <li className="list-group-item">
                    <b>Indirizo:</b>
                    {' '}
                    {quote.insurance_request.customer.address}
                    ,
                    {' '}
                    {quote.insurance_request.customer.house_number}
                    ,
                    {' '}
                    {quote.insurance_request.customer.postal_code}
                  </li>
                  <li className="list-group-item">
                    <b>Residenza:</b>
                    {' '}
                    {residenceInfo !== null && residenceInfo.name}
                  </li>
                  <li className="list-group-item">
                    <b>Citta:</b>
                    {' '}
                    {residenceInfo !== null
                    && residenceInfo.communal_territory.name}
                  </li>
                  <li className="list-group-item">
                    <b>Prodotto:</b>
                    {' '}
                    {quote.product.name}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <CompletedQuoteScreenInfoSalute
            rc_quotation={quote}
            risk_quotations={risk_quotations}
          />
        </div>
      </div>
      <div className="col-xl-12">
        <div className="row">
          <CompletedScreenSaluteDocuments
            downloadDocument={downloadDocument}
            insuranceRequest={quote.insurance_request}
            quote={quote}
            collectQuoteData={collectQuoteData}
            documentsRequests={documentsRequests}
            downloadOptionalDocument={downloadOptionalDocument}
            rc_quotation={quote}
          />
        </div>
      </div>
      {/* <CompletedQuoteScreenHomeAfterSale quote={quote} document_types={document_types} /> */}
    </SaluteCompletedScreenStyles>
  );
}

export default SaluteCompletedScreen;
