import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBan, faCarBurst, faEdit, faMailForward, faPause, faRotateRight,
} from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { CompletedQuoteScreenStyles } from './CompletedQuoteScreen.styles';
import { ReportAccident } from '../../../screens/ClaimsManagmentReportsOptions/ReportAccident';
import { Replacement } from '../../../screens/ClaimsManagmentReportsOptions/Replacement';
import { Cancellation } from '../../../screens/ClaimsManagmentReportsOptions/Cancellation';
import { SendDocument } from '../../../screens/ClaimsManagmentReportsOptions/SendDocument';
import { Modification } from '../../../screens/ClaimsManagmentReportsOptions/Modification';
import { getDocumentTypes } from '../../../features/afterSale/afterSaleActions';

function CompletedQuoteScreenAutoAfterSale({ quote, document_types }) {
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();

  const postVenditaTypes = {
    1: 'accident',
    2: 'replacement',
    3: 'cancellation',
    4: 'send-documents',
    5: 'modify-data',
    6: 'suspension',
  };

  const handleNavigation = (option) => {
    setSelectedOption(option);
    if (quote?.id) {
      navigate(`/after-sale/new/${postVenditaTypes[option]}/${quote.id}`);
    }
  };

  useEffect(() => {
    if (selectedOption !== null) {
      let type = '';
      switch (selectedOption) {
        case 1:
          type = 'accident';
          break;
        case 2:
          type = 'replacement';
          break;
        case 3:
          type = 'cancellation';
          break;
        case 4:
          type = 'send-documents';
          break;
        case 5:
          type = 'modify-data';
          break;
        case 6:
          type = 'suspension';
          break;
        default:
          break;
      }
      getDocumentTypes(type);
    }
  }, [selectedOption]);

  return (
    <div className="col-xl-12">
      <div className="card border mb-2 polizze-emessa-status-step-five mt-4">
        <div className="card-header bg-secondary text-white">
          <h2 className="h3 mb-0 card-title">Post Vendita</h2>
        </div>
        <div className="card-body">
          {/* FIX POST VENDITA FOR THE NEW LOGIC???? */}
          <CompletedQuoteScreenStyles>
            {quote !== null && (
              <div className="options">
                <div
                  className="option-button"
                  onClick={() => handleNavigation(1)}
                >
                  <div className="option-button-icon">
                    <FontAwesomeIcon
                      icon={faCarBurst}
                      color="var(--greenia-color)"
                    />
                  </div>
                  <div className="option-button-text">Denuncia Sinistro</div>
                </div>
                <div
                  className="option-button"
                  onClick={() => handleNavigation(2)}
                >
                  <div className="option-button-icon">
                    <FontAwesomeIcon
                      icon={faRotateRight}
                      color="var(--greenia-color)"
                    />
                  </div>
                  <div className="option-button-text">Sostituzione</div>
                </div>
                <div
                  className="option-button"
                  onClick={() => handleNavigation(3)}
                >
                  <div className="option-button-icon">
                    <FontAwesomeIcon
                      icon={faBan}
                      color="var(--greenia-color)"
                    />
                  </div>
                  <div className="option-button-text">Cessazione</div>
                </div>
                <div
                  className="option-button"
                  onClick={() => setSelectedOption(4)}
                >
                  <div className="option-button-icon">
                    <FontAwesomeIcon
                      icon={faMailForward}
                      color="var(--greenia-color)"
                    />
                  </div>
                  <div className="option-button-text">
                    Invio documentazione al cliente
                  </div>
                </div>
                <div
                  className="option-button"
                  onClick={() => handleNavigation(5)}
                >
                  <div className="option-button-icon">
                    <FontAwesomeIcon
                      icon={faEdit}
                      color="var(--greenia-color)"
                    />
                  </div>
                  <div className="option-button-text">
                    Modifica dati non tariffari
                  </div>
                </div>
                <div
                  className="option-button"
                  onClick={() => handleNavigation(6)}
                >
                  <div className="option-button-icon">
                    <FontAwesomeIcon
                      icon={faPause}
                      color="var(--greenia-color)"
                    />
                  </div>
                  <div className="option-button-text">Sospensione</div>
                </div>
              </div>
            )}

            {quote !== null && selectedOption === 1 && (
              <ReportAccident
                quotationId={quote.id}
                selectOption={setSelectedOption}
                location={location}
              />
            )}

            {quote !== null && selectedOption === 2 && (
              <Replacement
                quotationId={quote.id}
                selectOption={setSelectedOption}
                location={location}
                document_types={document_types}
                quote={quote}
              />
            )}

            {quote !== null && selectedOption === 3 && (
              <Cancellation
                quotationId={quote.id}
                selectOption={setSelectedOption}
                location={location}
              />
            )}

            {quote !== null && selectedOption === 4 && (
              <SendDocument
                quotationId={quote.id}
                selectOption={setSelectedOption}
                location={location}
              />
            )}

            {quote !== null && selectedOption === 5 && (
              <Modification
                quotationId={quote.id}
                selectOption={setSelectedOption}
                location={location}
              />
            )}
          </CompletedQuoteScreenStyles>
        </div>
      </div>
    </div>
  );
}

export default CompletedQuoteScreenAutoAfterSale;
