import React from 'react';
import { BoxWrapper, ClientBox } from './CommonPurchase.styles';
import MessagesModal from '../../../elements/MessagesModal';
import { apiDownloadDocument, downloadResourceDocs } from '../../../../../services/axios-client/axeCommons';
import { quotationTypes } from '../../../../constants';
import axe from '../../../../../services/axios-client/axiosClient';
import { downloadFile } from '../../../../../services/axios-client/axeHelpers';

const set_informativi = {
  1: 'set_informativo_hcc_ingegneri.pdf',
  2: 'set_informativo_hcc_architetto.pdf',
  3: 'set_informativo_hcc_avvocato.pdf',
  4: 'set_informativo_hcc_Commercialista.pdf',
  5: 'set_informativo_hcc_consulente_del_lavoro.pdf',
  6: 'set_informativo_hcc_agente_immobiliare.pdf',
  7: 'set_informativo_hcc_agenti_di_attivita_finanziaria.pdf',
  8: 'set_informativo_hcc_amministratore_condominio.pdf',
  9: 'set_informativo_hcc_geometri.pdf',
  10: 'set_informativo_hcc_mediatore_creditizi.pdf',
  11: 'set_informativo_hcc_periti.pdf',
  12: 'set_informativo_hcc_tributarista.pdf',
  13: '',
  14: 'set_informativo_hcc_ag_commercio.pdf',
  15: 'set_informativo_hcc_agronomi.pdf',
  16: 'set_informativo_hcc_societa_di_edp.pdf',
  17: 'set_informativo_hcc_chimichi.pdf',
  18: 'set_informativo_hcc_dpo.pdf',
  19: 'set_informativo_hcc_geologi.pdf',
  20: 'set_informativo_hcc_miscellaneous.pdf',
  21: 'set_informativo_hcc_miscellaneous_energetica.pdf',
  22: 'set_informativo_hcc_psicologo.pdf',
  23: 'set_informativo_hcc_revisore_legale_dei_conti.pdf',
  24: '',
  // "24": "set_informativo_hcc_singolo_componente_mediazione.pdf",
  25: 'set_informativo_hcc_tecnologo_alimentare.pdf',
  26: 'set_informativo_hcc_conformita.pdf',
};

function InfoBoxProfessione({
  insuranceRequest,
  statues,
  messagesModalData,
  rc_quotation,
  risk_quotations,
}) {
  const downloadPreventive = async () => {
    const res = await axe.get(
      `/quotations/${rc_quotation.id}/preventivo/generate`,
      {
        responseType: 'blob',
      },
    );

    downloadFile(res.data);
  };

  const doc = insuranceRequest.documents.find((doc) => doc.document_type_id === 34);

  const downloadDocument = async (documentId) => {
    if (documentId === undefined) {
      return;
    }
    const response = await apiDownloadDocument(documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const getTotal = () => {
    let quoteInProccessAmount = 0;
    let risksTotalAmount = 0;

    if (rc_quotation !== null) {
      quoteInProccessAmount = parseFloat(rc_quotation.amount);
    }

    if (risk_quotations && risk_quotations.length > 0) {
      for (let i = 0; i < risk_quotations.length; i++) {
        const item = risk_quotations[i];

        risksTotalAmount += parseFloat(item.amount);
      }
    }

    const total = quoteInProccessAmount + risksTotalAmount + rc_quotation.brokerage_amount;

    return total.toFixed(2);
  };

  return (
    <>
      <BoxWrapper>
        <div className="row gap-2 gap-xl-0">
          <div className="col-12 col-xl-4">
            <ClientBox className="documents-status-step-one pagament-status-step-one">
              <h6>Cliente:</h6>
              <h5>
                {insuranceRequest.customer.gender !== 'G'
                  ? `${insuranceRequest.customer.name} ${insuranceRequest.customer.surname}`
                  : insuranceRequest.customer.business_name}
              </h5>
              <h6 className="mt-2">Fatturato:</h6>
              <h5>
                &euro;
                {insuranceRequest.professional.billed}
              </h5>
              <h6 className="mt-2">Status:</h6>
              <h5>
                {statues.find((stat) => stat.id === rc_quotation.status).name}
              </h5>
              <h6 className="mt-2">Professione:</h6>
              <h5>{insuranceRequest.professional.profession_desc}</h5>
              <h6 className="mt-2">Alto rischio:</h6>
              <h5>{insuranceRequest.professional.high_risk}</h5>
              <h6 className="mt-2">Massimale Fatturato:</h6>
              <h5>
                &euro;
                {' '}
                {parseFloat(
                  insuranceRequest.professional.billed_maximum,
                ).toLocaleString('en-US')}
              </h5>
              <h6 className="mt-2">Decorrenza:</h6>
              <h5>
                {new Date(
                  insuranceRequest.policy_effective_date,
                ).toLocaleDateString('it-IT')}
              </h5>
              {set_informativi[insuranceRequest.professional.profession]
                !== '' && (
                <>
                  <h6
                    className="mt-2 text-decoration-underline"
                    style={{ cursor: 'pointer' }}
                    onClick={() => downloadResourceDocs(
                      8,
                      insuranceRequest.professional.profession,
                    )}
                  >
                    Informazioni Precontrattuali
                  </h6>
                  <h6
                    className="mt-2 text-decoration-underline"
                    onClick={() => downloadResourceDocs(
                      4,
                      insuranceRequest.professional.profession,
                    )}
                    style={{ cursor: 'pointer' }}
                  >
                    Questionario
                  </h6>
                </>
              )}
              <h6
                className="mt-2 text-decoration-underline"
                onClick={() => downloadPreventive()}
                style={{ cursor: 'pointer' }}
              >
                Preventivo
              </h6>

              <button
                className="mt-2 btn btn-success"
                onClick={() => downloadDocument(doc.id)}
              >
                Scarica Documento Da Firmare
              </button>
            </ClientBox>
          </div>
          <div className="col-12 col-xl-8">
            <ClientBox>
              <h4>Informazioni Prodotti</h4>
              <hr />
              <div className="table table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Prodotto</th>
                      <th scope="col">Compagnia</th>
                      <th scope="col">Frequenza Pagamento</th>
                      <th scope="col">Premio</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{rc_quotation.product.name}</td>
                      <td>
                        {rc_quotation.company_id !== null
                          ? rc_quotation.company.name
                          : rc_quotation.preventivas_company.company_name}
                      </td>
                      <td>
                        {rc_quotation.payment_frequency === 1
                          ? 'Annuale'
                          : 'Semestrale'}
                      </td>
                      <td>
                        {parseFloat(rc_quotation.amount).toFixed(2)}
                        {' '}
                        &euro;
                      </td>
                    </tr>

                    {risk_quotations
                      && risk_quotations.map((quote, index) => (
                        <tr key={index}>
                          <td>
                            {
                              quotationTypes.find(
                                (type) => type.id === quote.quote_type,
                              ).name
                            }
                          </td>
                          <td>{quote.company.name}</td>
                          <td>
                            {quote.payment_frequency === 1
                              ? 'Annuale'
                              : 'Semestrale'}
                          </td>
                          <td>
                            {parseFloat(quote.amount).toFixed(2)}
                            {' '}
                            &euro;
                          </td>
                        </tr>
                      ))}
                    <tr>
                      <td>Brokeraggio</td>
                      <td />
                      <td>
                        {rc_quotation.payment_frequency === 1
                          ? 'Annuale'
                          : 'Semestrale'}
                      </td>
                      <td>
                        {parseFloat(rc_quotation.brokerage_amount).toFixed(2)}
                        {' '}
                        &euro;
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td className="fw-bold">Totale</td>
                      <td />
                      <td />
                      <td className="fw-bold">
                        &euro;
                        {getTotal()}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </ClientBox>
          </div>
        </div>
      </BoxWrapper>
      <MessagesModal
        {...messagesModalData}
        threads={insuranceRequest.threads}
      />
    </>
  );
}

export default InfoBoxProfessione;
