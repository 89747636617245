import React from 'react';
import {
  ActionsWrapper, DocumentFileActions, DocumentInfo, DocumentInfoTitle, FileInfo, StatusBadge,
} from '../components/redesignComponents/Purchase/SignatureType.styles';
import downloadIcon from '../../assets/redesign_icons/download.svg';
import uploadIcon from '../../assets/redesign_icons/upload.svg';
import fileIcon from '../../assets/redesign_icons/file.svg';
import { apiAfterSaleOptionalDocumentMethodManualUpload, apiDownloadAfterSaleOptionalDocument } from '../../services/axios-client/axeCommons';
import { generalAlertError, generalAlertSuccessToast } from '../utils/alerts';

function AfterSaleDocuments({ documents, collectData }) {
  const uploadSignedDocument = async (documentId, files) => {
    const uploadedFile = files[0];
    const reader = new FileReader();

    reader.readAsDataURL(uploadedFile);
    if (uploadedFile.type !== 'application/pdf') {
      generalAlertError('Formato di file errato. Il file deve essere in .pdf');
      return;
    }
    reader.onload = () => {
      apiAfterSaleOptionalDocumentMethodManualUpload(documentId, {
        document: reader.result.replace('data:application/pdf;base64,', ''),
      }).then(() => {
        collectData();
        generalAlertSuccessToast('File caricato');
      });
    };
  };

  const downloadDocument = async (documentId) => {
    const response = await apiDownloadAfterSaleOptionalDocument(documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const fireSignedDocUpload = (docId) => {
    const inputElement = document.getElementById(`required-doc-${docId}`);
    if (inputElement) {
      inputElement.click();
    } else {
      console.log(`No input found for required document: ${docId}`);
    }
  };

  return (
    <>
      {documents.length === 0 && (
        <p>Nessun documento caricato</p>
      )}

      {documents.map((doc, index) => (
        <DocumentInfo key={index}>
          <DocumentInfoTitle>
            <FileInfo>
              <img src={fileIcon} alt={doc.document.original_filename} width={20} />
              <p>{doc.document.original_filename}</p>
            </FileInfo>
            {doc.document.signable_document === 1 && doc.document.is_signed_valid === null && doc.document.signed_document_filename === null
              ? (
                <StatusBadge background="#FFEAB4" color="#FF8A00">
                  Attesa Firma
                </StatusBadge>
              ) : ''}
            {doc.document.signable_document === 1 && doc.document.signed_document_filename !== null && doc.document.is_signed_valid === null ? (
              <StatusBadge background="#009232" color="#ffffff">
                Firma in verifica
              </StatusBadge>
            ) : ''}
            {doc.document.signable_document === 1 && doc.document.is_signed_valid === 1 ? (
              <StatusBadge background="#99DBB9" color="#009232">
                Firmato
              </StatusBadge>
            ) : (
              ''
            )}
            {doc.document.signable_document === 1 && doc.document.is_signed_valid === 0 ? (
              <StatusBadge background="#FFB4B4" color="#F00">
                Firma non valida
              </StatusBadge>
            ) : (
              ''
            )}

          </DocumentInfoTitle>
          <DocumentFileActions>
            <p>
              {doc.document.is_signed_valid === null && doc.document.motivation === null && doc.document.signed_document_filename !== null
                ? 'Il nostro backoffice sta verificando il documento' : ''}
              {doc.document.is_signed_valid === 0 && doc.document.motivation !== null && doc.document.signed_document_filename === null
                ? <span className="text-danger">{doc.motivation}</span> : ''}
              {doc.document.is_signed_valid === 1 && doc.document.signed_document_filename !== null
                ? <span className="text-success">Documento Validato</span> : ''}
              {doc.document.is_signed_valid === null && doc.document.signed_document_filename === null
                ? 'Scarica il documento e caricalo firmato' : ''}
            </p>
            <ActionsWrapper>
              <div className="action-button" onClick={() => downloadDocument(doc.document.id)}>
                <img src={downloadIcon} alt="Scarica" width={30} />
                <p>Scarica</p>
              </div>
              <div className="action-button" onClick={doc.document.signed_document_filename === null || doc.document.is_signed_valid !== 1 ? () => fireSignedDocUpload(doc.document.id) : () => { }}>
                <img src={uploadIcon} alt="Carica" width={30} />
                <p>Carica</p>
                <input type="file" name="" id={`required-doc-${doc.document.id}`} className="d-none" onChange={(e) => uploadSignedDocument(doc.document.id, e.target.files)} />
              </div>
            </ActionsWrapper>
          </DocumentFileActions>
        </DocumentInfo>
      ))}
    </>
  );
}

export default AfterSaleDocuments;
