import React from 'react';
import { BoxWrapper, ClientBox } from './CommonPurchase.styles';
// import { guideType, primaQuoteTypes } from '../../../../constants';
import MessagesModal from '../../../elements/MessagesModal';
import { downloadFile } from '../../../../../services/axios-client/axeHelpers';
import axe from '../../../../../services/axios-client/axiosClient';
import {
  commercialExtraQuoteTypes,
  quotationTypes,
} from '../../../../constants';
import { apiDownloadDocument } from '../../../../../services/axios-client/axeCommons';

function InfoBoxCommercial({
  insuranceRequest,
  statues,
  quoteStatus,
  messagesModalData,
  rc_quotation,
  risk_quotations,
}) {
  const downloadResourceDocs = async (id) => {
    const res = await axe.get(`/download-resource-document/${id}/0`, {
      responseType: 'blob',
    });

    downloadFile(res.data);
  };

  // const downloadPreventive = async () => {
  //   const res = await axe.get(
  //     `/quotations/${rc_quotation.id}/preventivo/generate`,
  //     {
  //       responseType: 'blob',
  //     },
  //   );

  //   downloadFile(res.data);
  // };

  const doc = insuranceRequest.documents.find((doc) => doc.document_type_id === 34);

  const downloadDocument = async (documentId) => {
    if (documentId === undefined) {
      return;
    }
    const response = await apiDownloadDocument(documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const getTotal = () => {
    let rcTotalAmount = 0;
    let risksTotalAmount = 0;

    rcTotalAmount = rc_quotation.amount;

    if (risk_quotations && risk_quotations.length > 0) {
      for (let i = 0; i < risk_quotations.length; i++) {
        const item = risk_quotations[i];

        risksTotalAmount += parseFloat(item.amount);
      }
    }

    return parseFloat(rcTotalAmount + risksTotalAmount).toFixed(2);
  };

  return (
    <>
      <BoxWrapper>
        <div className="row gap-2 gap-xl-0">
          <div className="col-12 col-xl-4">
            <ClientBox className="documents-status-step-one pagament-status-step-one">
              <h6>Cliente:</h6>
              <h5>{insuranceRequest.commercial_company.business_name}</h5>
              <h6>Company number:</h6>
              <h5>{insuranceRequest.commercial_company.company_number}</h5>
              <h6 className="mt-2">Decorrenza:</h6>
              <h5>
                {new Date(
                  insuranceRequest.policy_effective_date,
                ).toLocaleDateString('it-IT')}
              </h5>
              <h6 className="mt-2">Vat number:</h6>
              <h5>{insuranceRequest.commercial_company.vat_number}</h5>
              <h6 className="mt-2">Frequenza Pagamento:</h6>
              <h5>
                {insuranceRequest.payment_frequency === 1
                  ? 'Annuale'
                  : 'Semestrale'}
              </h5>

              <h6 className="mt-2">Status:</h6>
              <h5>{statues.find((st) => st.id === quoteStatus).name}</h5>
              <h6
                className="mt-2 text-decoration-underline"
                style={{ cursor: 'pointer' }}
                onClick={() => downloadResourceDocs(12, 0)}
              >
                Informazioni Precontrattuali
              </h6>
              {risk_quotations
                && risk_quotations.find((q) => q.company_id === 16)
                  !== undefined && (
                  <h6
                    className="mt-2 text-decoration-underline"
                    style={{ cursor: 'pointer' }}
                    onClick={() => downloadResourceDocs(13)}
                  >
                    Informazioni Precontrattuali Global Assistance
                  </h6>
              )}
              {risk_quotations
                && risk_quotations.find((q) => q.company_id === 8)
                  !== undefined && (
                  <h6
                    className="mt-2 text-decoration-underline"
                    onClick={() => downloadResourceDocs(14)}
                    style={{ cursor: 'pointer' }}
                  >
                    Informazioni Precontrattuali Europe Assistance
                  </h6>
              )}
              {/* <h6
                className="mt-2 text-decoration-underline"
                onClick={() => downloadPreventive()}
                style={{ cursor: 'pointer' }}
              >
                Preventivo
              </h6> */}

              <button className="mt-2 btn btn-success" onClick={() => downloadDocument(doc.id)}>
                Scarica Documento Da Firmare
              </button>
            </ClientBox>
          </div>
          <div className="col-12 col-xl-8">
            <ClientBox className="documents-status-step-two pagament-status-step-two">
              <h4>Informazioni Prodotti</h4>
              <hr />
              <div className="table table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Prodotto</th>
                      <th scope="col">Compagnia</th>
                      <th scope="col">Frequenza Pagamento</th>
                      <th scope="col">Premio</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{rc_quotation.product.name}</td>
                      <td>
                        {rc_quotation.company_id !== null
                        && rc_quotation.company.name}
                      </td>
                      <td>
                        {rc_quotation.payment_frequency === 1
                          ? 'Annuale'
                          : 'Semestrale'}
                      </td>
                      <td>
                        {parseFloat(rc_quotation.amount).toFixed(2)}
                        {' '}
                        &euro;
                      </td>
                    </tr>
                    {risk_quotations
                    && risk_quotations.map((quote, index) => (
                      <tr key={index}>
                        <td>
                          {quotationTypes.find(
                            (type) => type.id === quote.quote_type,
                          ) === undefined
                            ? commercialExtraQuoteTypes.find(
                              (type) => type.id === quote.quote_type,
                            ).name
                            : quotationTypes.find(
                              (type) => type.id === quote.quote_type,
                            ).name}
                        </td>
                        <td>{quote.company.name}</td>
                        <td>
                          {quote.payment_frequency === 1
                            ? 'Annuale'
                            : 'Semestrale'}
                        </td>
                        <td>
                          {parseFloat(quote.amount).toFixed(2)}
                          {' '}
                          &euro;
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td className="fw-bold">Totale</td>
                      <td />
                      <td />
                      <td className="fw-bold">
                        &euro;
                        {getTotal()}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </ClientBox>
          </div>
        </div>
      </BoxWrapper>
      <MessagesModal
        {...messagesModalData}
        threads={insuranceRequest.threads}
      />
    </>
  );
}

export default InfoBoxCommercial;
